import axios from 'axios';

const BASE_URL = 'https://chatbot.piosphere.ai/api';
const API_KEY = 'Yxbp-0Ll1ncvA8hyBli2hM3Vv5UOrrX8b2u1Jv_0t3M=';
// Get API key from localStorage
const getApiKeyFromLocalStorage = () => {
  return API_KEY;
};

// Create axios instance with dynamic headers
export const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Set initial API key from localStorage if available
const storedApiKey = getApiKeyFromLocalStorage();
if (storedApiKey) {
  api.defaults.headers['x-api-key'] = storedApiKey;
}

export const setApiKey = (key) => {
  api.defaults.headers['x-api-key'] = key;
};

export const validateApiKey = async (key) => {
  try {
    const apiKey = key || getApiKeyFromLocalStorage();
    if (!apiKey) {
      throw new Error('API key is missing');
    }

    const response = await api.post('/key/validate', { key: apiKey });

    if (response.status !== 200 || !response.data.data.is_valid) {
      console.error('Invalid API key');
      return false;
    }

    return response.data.data;
  } catch (error) {
    console.error('Validation error:', error);
    return false;
  }
};

export const getModelsHealth = async () => {
  try {
    const apiKey = getApiKeyFromLocalStorage();
    if (!apiKey) {
      throw new Error('API key is missing');
    }

    const response = await api.get('/models/health', {
      headers: { 'x-api-key': apiKey }
    });

    if (response.status !== 200) {
      console.error('Failed to retrieve models health');
      return false;
    }

    return response.data.data;
  } catch (error) {
    console.error('Models health error:', error);
    return false;
  }
};

export const generateResponse = async ({ query, model, domain }) => {
  if (!domain) {
      throw new Error('Domain is required for query generation');
  }

  const apiKey = getApiKeyFromLocalStorage();
  if (!apiKey) {
    throw new Error('API key is missing');
  }

  const response = await fetch(`${BASE_URL}/generate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey
      },
      body: JSON.stringify({ 
          query,
          model,
          domain
      })
  });

  if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
  }
  
  return response;
};

export const ingestFile = async (files, domain) => {
    if (!domain) {
        throw new Error('Domain is required for file ingestion');
    }
    
    const apiKey = getApiKeyFromLocalStorage();
    if (!apiKey) {
      throw new Error('API key is missing');
    }
    
    const formData = new FormData();
    
    if (Array.isArray(files)) {
        files.forEach(file => {
            formData.append('files', file);
        });
    } else {
        formData.append('files', files);
    }
    formData.append('domain', domain);
    console.log(formData);
    const response = await fetch(`${BASE_URL}/file/ingest`, {
        method: 'POST',
        headers: {
            'x-api-key': apiKey,
        },
        body: formData
    });

    if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const generateQuery = async ({ query, model, domain }) => {
    if (!domain) {
        throw new Error('Domain is required for query generation');
    }

    const apiKey = getApiKeyFromLocalStorage();
    if (!apiKey) {
      throw new Error('API key is missing');
    }

    const response = await fetch(`${BASE_URL}/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey
        },
        body: JSON.stringify({ 
            query,
            model,
            domain
        })
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return response;
};

export const getFiles = async (domain, page = 1) => {
    if (!domain) {
        throw new Error('Domain is required for fetching files');
    }

    const apiKey = getApiKeyFromLocalStorage();
    if (!apiKey) {
      throw new Error('API key is missing');
    }

    const response = await fetch(`${BASE_URL}/file?domain=${domain}&page=${page}`, {
        method: 'GET',
        headers: {
            'x-api-key': apiKey,
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const getFile = async (fileId) => {
    const apiKey = getApiKeyFromLocalStorage();
    if (!apiKey) {
      throw new Error('API key is missing');
    }

    const response = await fetch(`${BASE_URL}/file/${fileId}`, {
        method: 'GET',
        headers: {
            'x-api-key': apiKey,
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const deleteFile = async (fileId, domain) => {
    const apiKey = getApiKeyFromLocalStorage();
    if (!apiKey) {
      throw new Error('API key is missing');
    }

    const response = await fetch(`${BASE_URL}/file/${fileId}`, {
        method: 'DELETE',
        headers: {
            'x-api-key': apiKey,
        }
    });

    if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Failed to delete file: ${response.status}`);
    }
    return response.json();
};

export const createSession = async (title = 'New Chat') => {
    const apiKey = getApiKeyFromLocalStorage();
    if (!apiKey) {
      throw new Error('API key is missing');
    }

    const response = await fetch(`${BASE_URL}/session`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': apiKey
        },
        body: JSON.stringify({ title })
    });

    if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || `Failed to create session: ${response.status}`);
    }
    
    const responseData = await response.json();
    
    if (!responseData.data?.session_id) {
        throw new Error('Invalid session creation response');
    }
    
    return {
        sessionId: responseData.data.session_id,
        ...responseData.data
    };
};

export const sendChatMessage = async (sessionId, message, domain, model) => {
    if (!sessionId) {
        throw new Error('Valid session ID is required for chat messages');
    }
    
    if (!domain) {
        throw new Error('Domain is required');
    }
    
    if (!model) {
        throw new Error('Model is required');
    }

    const apiKey = getApiKeyFromLocalStorage();
    if (!apiKey) {
      throw new Error('API key is missing');
    }

    const response = await fetch(`${BASE_URL}/chat`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'text/event-stream',
            'x-api-key': apiKey
        },
        body: JSON.stringify({ 
            session: sessionId,
            message: message,
            domain: domain,
            model: model
        })
    });

    if (response.status === 404) {
        throw new Error('Session expired or invalid');
    }

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Chat request failed: ${response.status}`);
    }

    const reader = response.body.getReader();
    const stream = new ReadableStream({
        async start(controller) {
            try {
                while (true) {
                    const {done, value} = await reader.read();
                    if (done) {
                        controller.close();
                        break;
                    }
                    controller.enqueue(value);
                }
            } catch (error) {
                controller.error(error);
            }
        }
    });

    return new Response(stream, {
        headers: {
            'Content-Type': 'text/event-stream'
        }
    });
};

export const getModels = async () => {
  const apiKey = getApiKeyFromLocalStorage();
  if (!apiKey) {
    throw new Error('API key is missing');
  }

  const response = await fetch(`${BASE_URL}/models`, {
    method: 'GET',
    headers: {
      'x-api-key': apiKey,
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
};

export const sendMessageFeedback = async (messageId, isGood) => {
  const apiKey = getApiKeyFromLocalStorage();
  if (!apiKey) {
    throw new Error('API key is missing');
  }

  const response = await fetch(`${BASE_URL}/message`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': apiKey
    },
    body: JSON.stringify({
      message_id: messageId,
      is_good: isGood
    })
  });

  if (!response.ok) {
    const errorData = await response.json().catch(() => ({}));
    throw new Error(errorData.error || `Feedback submission failed: ${response.status}`);
  }
  
  return response.json();
};

export const getApiKeys = async () => {
    const response = await fetch(`${BASE_URL}/keys`, {
        method: 'GET',
        headers: {
            'x-api-key': API_KEY,
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
};

export const createApiKey = async ({ role, expiry, rate_limit }) => {
    // Validate and convert to integers
    const validatedData = {
        role,
        expiry: parseInt(expiry, 10),
        rate_limit: parseInt(rate_limit, 10)
    };

    // Validation checks
    if (!Number.isInteger(validatedData.expiry) || validatedData.expiry <= 0) {
        throw new Error('Expiry must be a positive integer');
    }

    if (!Number.isInteger(validatedData.rate_limit) || 
        validatedData.rate_limit <= 0 || 
        validatedData.rate_limit > 50) {
        throw new Error('Rate limit must be an integer between 1 and 50');
    }

    const response = await fetch(`${BASE_URL}/keys`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
        },
        body: JSON.stringify(validatedData)
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data.data;
};

export const deleteApiKey = async (keyId) => {
    const response = await fetch(`${BASE_URL}/keys/${keyId}`, {
        method: 'DELETE',
        headers: {
            'x-api-key': API_KEY
        }
    });

    if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const revokeApiKey = async (keyId) => {
    const response = await fetch(`${BASE_URL}/keys/${keyId}/revoke`, {
        method: 'POST',
        headers: {
            'x-api-key': API_KEY
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const updateApiKey = async (keyId, updates) => {
    const response = await fetch(`${BASE_URL}/keys/${keyId}`, {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY
        },
        body: JSON.stringify(updates)
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const getKeyUsageStats = async (keyId) => {
    const response = await fetch(`${BASE_URL}/key/${keyId}/stats`, {
        method: 'GET',
        headers: {
            'x-api-key': API_KEY
        }
    });
    console.log(response);
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const getKeyUsageByDateRange = async (keyId, startDate, endDate, groupByKey = true) => {
    const queryParams = new URLSearchParams({
        key_id: keyId,
        start_date: startDate,
        end_date: endDate,
        group_by_key: groupByKey
    }).toString();

    const response = await fetch(`${BASE_URL}/key/by-date?${queryParams}`, {
        method: 'GET',
        headers: {
            'x-api-key': API_KEY
        }
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};
