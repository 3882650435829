import React from 'react';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Box,
  LinearProgress,
  Chip,
  TextField,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ButtonGroup,
} from '@mui/material';
import {
  Memory as MemoryIcon,
  Speed as SpeedIcon,
  Timeline as TimelineIcon,
  Storage as StorageIcon,
  Build as BuildIcon,
  RocketLaunch as RocketLaunchIcon,
  MonitorHeart as MonitorIcon,
  Search as SearchIcon,
  GridView as GridViewIcon,
  ViewList as ListViewIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { theme } from '../helpers/theme';

const Overview = ({ onLaunchTraining, activeJobs, completedJobs, myModels, onCancelJob, onDeleteJob }) => {
  const navigate = useNavigate();
  const [viewMode, setViewMode] = React.useState('grid');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [sortConfig, setSortConfig] = React.useState({
    field: 'date',
    direction: 'desc' // 'asc' or 'desc'
  });

  // Sort function
  const sortJobs = (jobs) => {
    return [...jobs].sort((a, b) => {
      switch (sortConfig.field) {
        case 'date':
          const dateA = new Date(a.completedAt || a.startedAt);
          const dateB = new Date(b.completedAt || b.startedAt);
          return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        case 'name':
          return sortConfig.direction === 'asc' 
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        case 'accuracy':
          const accA = a.metrics?.finalMetrics?.accuracy || 0;
          const accB = b.metrics?.finalMetrics?.accuracy || 0;
          return sortConfig.direction === 'asc' ? accA - accB : accB - accA;
        default:
          return 0;
      }
    });
  };

  // Filter function
  const filterJobs = (jobs) => {
    const filtered = jobs.filter(job => {
      const matchesSearch = job.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          job.dataset.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesSearch;
    });
    return sortJobs(filtered);
  };

  const handleSort = (field) => {
    setSortConfig(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const renderSortButton = (field, label) => (
    <Button
      size="small"
      onClick={() => handleSort(field)}
      endIcon={sortConfig.field === field ? 
        (sortConfig.direction === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />) : 
        null
      }
      sx={{
        textTransform: 'none',
        color: sortConfig.field === field ? 'primary.main' : 'text.secondary',
        height: 48 // Match height of view mode toggle button
      }}
    >
      {label}
    </Button>
  );

  // View mode toggle handler
  const handleViewModeChange = (event, newMode) => {
    if (newMode !== null) {
      setViewMode(newMode);
    }
  };

  const handleMonitorJob = (jobId) => {
    navigate(`/training/monitor/${jobId}`);
  };

  const renderJobsTable = (jobs) => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {renderSortButton('name', 'Name')}
            </TableCell>
            <TableCell>Dataset</TableCell>
            <TableCell>
              {renderSortButton('date', 'Date')}
            </TableCell>
            <TableCell>
              {renderSortButton('accuracy', 'Metrics')}
            </TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filterJobs(jobs).map(job => (
            <TableRow key={job.id}>
              <TableCell>{job.name}</TableCell>
              <TableCell>{job.dataset}</TableCell>
              <TableCell>
                <Chip 
                  label={job.status || job.completedAt}
                  color="primary"
                  size="small"
                />
              </TableCell>
              <TableCell>
                {job.metrics.finalMetrics ? 
                  `Acc: ${job.metrics.finalMetrics.accuracy.toFixed(3)}` :
                  `Progress: ${job.progress}%`
                }
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => handleMonitorJob(job.id)}
                  startIcon={<MonitorIcon />}
                >
                  Monitor
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Grid container spacing={3}>
      {/* Launch Training Button */}
      <Grid item xs={12}>
        <Card 
          sx={{ 
            mb: 3, 
            textAlign: 'center', 
            py: 6,
            background: theme.palette.primary.main,
            transition: 'transform 0.2s',
          }}
        >
          <Typography variant="h5" sx={{ color: 'black', mb: 2 }}>
            Ready to start training your model?
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={onLaunchTraining}
            startIcon={<RocketLaunchIcon />}
            sx={{ 
              px: 6, 
              py: 2,
              fontSize: '1.2rem',
              background: 'white',
              boxShadow: '0 3px 5px 2px rgba(80, 200, 120, .3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #3DA75B 30%, #50C878 90%)',
              }
            }}
          >
            Launch Training
          </Button>
        </Card>
      </Grid>

      {/* Search and Filters Section */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
          <TextField
            placeholder="Search jobs..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ flexGrow: 1 }}
          />
          
          {/* Sort Buttons */}
          <ButtonGroup variant="outlined" size="small">
            {renderSortButton('date', 'Sort by Date')}
            {renderSortButton('name', 'Sort by Name')}
            {renderSortButton('accuracy', 'Sort by Accuracy')}
          </ButtonGroup>

          <ToggleButtonGroup
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
          >
            <ToggleButton value="grid">
              <GridViewIcon />
            </ToggleButton>
            <ToggleButton value="table">
              <ListViewIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Grid>

      {/* Active Training Jobs Section */}
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 2 }}>Active Training Jobs</Typography>
        {viewMode === 'grid' ? (
          <Grid container spacing={2} sx={{ maxHeight: '400px', overflowY: 'auto' }}>
            {filterJobs(activeJobs).map(job => (
              <Grid item xs={12} md={6} key={job.id}>
                <Card>
                  <CardContent>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center', 
                      mb: 2,
                      flexWrap: 'wrap', 
                      gap: 1 
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                        <Typography variant="h6">{job.name}</Typography>
                        <Chip 
                          label={job.status} 
                          color="primary" 
                          size="small" 
                        />
                      </Box>
                      <Box sx={{ 
                        display: 'flex', 
                        gap: 1,
                        flexDirection: { xs: 'column', md: 'row' },
                        width: { xs: '100%', md: 'auto' },
                        '& .MuiButton-root': { 
                          minWidth: { xs: '100%', md: 'auto' },
                          justifyContent: 'center' 
                        }
                      }}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleMonitorJob(job.id)}
                          startIcon={<MonitorIcon />}
                          sx={{ 
                            background: 'linear-gradient(45deg, #50C878 30%, #3DA75B 90%)',
                            boxShadow: '0 3px 5px 2px rgba(80, 200, 120, .3)',
                            '&:hover': {
                              background: 'linear-gradient(45deg, #3DA75B 30%, #50C878 90%)',
                              transform: 'translateY(-2px)'
                            },
                            transition: 'transform 0.2s',
                          }}
                        >
                          Monitor Job
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => onCancelJob(job.id)}
                          sx={{ 
                            color: 'red',
                            borderColor: 'red',
                            '&:hover': {
                              borderColor: 'darkred',
                              color: 'darkred'
                            }
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Box>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Dataset: {job.dataset}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        Progress: {job.progress}% (ETA: {job.eta})
                      </Typography>
                      <LinearProgress 
                        variant="determinate" 
                        value={job.progress} 
                        sx={{ mt: 1 }} 
                      />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        Current Metrics:
                      </Typography>
                      <Grid container spacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            Loss: {job.metrics.epochs[job.metrics.currentEpoch - 1].loss.toFixed(3)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body2">
                            Accuracy: {job.metrics.epochs[job.metrics.currentEpoch - 1].accuracy.toFixed(3)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          renderJobsTable(activeJobs)
        )}
      </Grid>

      {/* Completed Jobs Section */}
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ mb: 2 }}>Completed Jobs</Typography>
        {viewMode === 'grid' ? (
          <Grid container spacing={2} sx={{ maxHeight: '400px', overflowY: 'auto' }}>
            {filterJobs(completedJobs).map(job => (
              <Grid item xs={12} md={6} key={job.id}>
                <Card>
                  <CardContent>
                    <Box sx={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      mb: 2, 
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      gap: 1
                    }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexWrap: 'wrap' }}>
                        <Typography variant="h6">{job.name}</Typography>
                        <Chip 
                          label={`${job.completedAt}`}  
                          color="primary"   
                          size="small" 
                        />
                      </Box>
                      <Box sx={{ 
                        display: 'flex', 
                        gap: 1,
                        flexDirection: { xs: 'column', md: 'row' },
                        width: { xs: '100%', md: 'auto' },
                        '& .MuiButton-root': { 
                          minWidth: { xs: '100%', md: 'auto' },
                          justifyContent: 'center' 
                        }
                      }}>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleMonitorJob(job.id)}
                          startIcon={<MonitorIcon />}
                          sx={{ 
                            background: 'linear-gradient(45deg, #50C878 30%, #3DA75B 90%)',
                            boxShadow: '0 3px 5px 2px rgba(80, 200, 120, .3)',
                            '&:hover': {
                              background: 'linear-gradient(45deg, #3DA75B 30%, #50C878 90%)',
                              transform: 'translateY(-2px)'
                            },
                            transition: 'transform 0.2s',
                          }}
                        >
                          Monitor Job
                        </Button>
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => onDeleteJob(job.id)}
                          sx={{ 
                            color: 'red',
                            borderColor: 'red',
                            '&:hover': {
                              borderColor: 'darkred',
                              color: 'darkred'
                            }
                          }}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Box>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Dataset: {job.dataset}
                    </Typography>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="body2" color="text.secondary">
                        Final Metrics:
                      </Typography>
                      <Grid container spacing={1} sx={{ mt: 1 }}>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            Accuracy: {job.metrics.finalMetrics.accuracy.toFixed(3)}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            F1 Score: {job.metrics.finalMetrics.f1Score.toFixed(3)}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body2">
                            Loss: {job.metrics.finalMetrics.loss.toFixed(3)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          renderJobsTable(completedJobs)
        )}
      </Grid>
    </Grid>
  );
};

export default Overview;
