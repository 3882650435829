import React, { useState, useEffect } from 'react';
import { 
  Box, Typography, Button, Paper, List, ListItem,
  CircularProgress, Alert, IconButton, ListItemText,
  ListItemSecondary, Chip, Tooltip, Dialog, DialogTitle,
  DialogContent, DialogActions
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useParams } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { ingestFile, getFiles, deleteFile } from '../api/services/deployment/Deployment';

const RAGUpload = ({ domain }) => {
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const { serverId } = useParams();
  const { keycloak } = useKeycloak();
  const [loading, setLoading] = useState(false);

  // Updated fetch documents function
  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        setLoading(true);
        let allFiles = [];
        let currentPage = 1;
        let hasMorePages = true;

        while (hasMorePages) {
          const response = await getFiles(domain, currentPage);
          const files = response.data.files;
          
          if (!files || files.length === 0) {
            hasMorePages = false;
          } else {
            allFiles = [...allFiles, ...files];
            currentPage++;
          }
        }

        setDocuments(allFiles.map(file => ({
          id: file.id,
          name: file.name,
          size: file.size_bytes,
          type: file.name.split('.').pop().toLowerCase(),
          date: new Date(file.created_at).toISOString().split('T')[0]
        })));
      } catch (err) {
        setError('Failed to fetch documents');
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [domain]);

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length === 0) return;

    setUploading(true);
    setError(null);
    setSuccess(false);

    try {
      // Validate file types and sizes
      const validTypes = ['.pdf', '.txt', '.doc', '.docx', '.md', '.zip'];
      const maxSize = 50 * 1024 * 1024; // 50MB

      const invalidFiles = files.filter(file => {
        const extension = '.' + file.name.split('.').pop().toLowerCase();
        return !validTypes.includes(extension) || file.size > maxSize;
      });

      if (invalidFiles.length > 0) {
        throw new Error('Some files are invalid. Please check file types and sizes (max 50MB).');
      }

      // Upload all files at once
      await ingestFile(files, domain);

      // Refresh the document list
      const response = await getFiles(domain);
      setDocuments(response.data.files.map(file => ({
        id: file.id,
        name: file.name,
        size: file.size_bytes,
        type: file.name.split('.').pop().toLowerCase(),
        date: new Date(file.created_at).toISOString().split('T')[0]
      })));
      
      setSuccess(true);
    } catch (err) {
      setError(err.message || 'Failed to upload files. Please try again.');
    } finally {
      setUploading(false);
      event.target.value = '';
    }
  };

  const handleDelete = async (docId) => {
    try {
      await deleteFile(docId);
      setDocuments(prev => prev.filter(doc => doc.id !== docId));
    } catch (err) {
      setError(err.message || 'Failed to delete document');
    }
  };

  const getTypeColor = (type) => {
    const colors = {
      pdf: 'error',
      txt: 'success',
      docx: 'primary',
      md: 'warning',
      zip: 'secondary'
    };
    return colors[type] || 'default';
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>RAG Document Management</Typography>
      
      <Paper sx={{ p: 3, mt: 2 }}>
        <Box sx={{ mb: 4, textAlign: 'center' }}>
          <input
            accept=".pdf,.txt,.doc,.docx,.md,.zip"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleFileUpload}
            multiple
          />
          <label htmlFor="raised-button-file">
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
              disabled={uploading}
              size="large"
            >
              Upload Documents
            </Button>
          </label>

          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Supported formats: PDF, TXT, DOCX, MD, ZIP (max 50MB)
          </Typography>
        </Box>

        {uploading && (
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <CircularProgress size={24} />
            <Typography sx={{ mt: 1 }}>Uploading...</Typography>
          </Box>
        )}

        {loading && (
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <CircularProgress size={24} />
            <Typography sx={{ mt: 1 }}>Loading documents...</Typography>
          </Box>
        )}

        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        {success && (
          <Alert severity="success" sx={{ mb: 2 }}>
            Files uploaded successfully!
          </Alert>
        )}

        <List>
          {documents.map((doc) => (
            <ListItem
              key={doc.id}
              secondaryAction={
                <Box>
                  <Tooltip title="Preview">
                    <IconButton 
                      edge="end" 
                      onClick={() => {
                        setSelectedDoc(doc);
                        setPreviewOpen(true);
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton 
                      edge="end" 
                      onClick={() => handleDelete(doc.id)}
                      sx={{ ml: 1 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
              sx={{ 
                borderBottom: '1px solid',
                borderColor: 'divider',
                '&:hover': { bgcolor: 'action.hover' }
              }}
            >
              <ListItemText
                primary={doc.name}
                secondary={
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Chip 
                      label={doc.type.toUpperCase()} 
                      size="small" 
                      color={getTypeColor(doc.type)}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {doc.size} • {doc.date}
                    </Typography>
                  </Box>
                }
              />
            </ListItem>
          ))}
        </List>

        {documents.length === 0 && (
          <Box sx={{ textAlign: 'center', py: 4 }}>
            <Typography color="text.secondary">
              No documents uploaded yet
            </Typography>
          </Box>
        )}
      </Paper>

      <Dialog open={previewOpen} onClose={() => setPreviewOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Document Preview: {selectedDoc?.name}</DialogTitle>
        <DialogContent>
          {/* Add document preview logic here based on file type */}
          <Box sx={{ minHeight: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography color="text.secondary">
              Preview not available for this file type
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPreviewOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default RAGUpload;
