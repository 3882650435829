import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const DepartmentManagement = ({ departments, clients, addDepartment, updateDepartment, deleteDepartment, showNotification }) => {
  const [open, setOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [departmentData, setDepartmentData] = useState({ name: '', clientId: '' });
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentDepartmentId, setCurrentDepartmentId] = useState(null);
  
  const handleClickOpen = () => {
    setIsEditMode(false);
    setDepartmentData({ name: '', clientId: clients.length > 0 ? clients[0].id : '' });
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
    setDepartmentData({ name: '', clientId: '' });
  };
  
  const handleSubmit = () => {
    if (isEditMode) {
      updateDepartment({
        ...departmentData,
        id: currentDepartmentId
      });
    } else {
      const newDepartment = {
        ...departmentData,
        id: Date.now().toString()
      };
      addDepartment(newDepartment);
    }
    handleClose();
  };
  
  const handleEdit = (department) => {
    setIsEditMode(true);
    setCurrentDepartmentId(department.id);
    setDepartmentData({
      name: department.name,
      clientId: department.clientId
    });
    setOpen(true);
  };
  
  const handleDeleteClick = (departmentId) => {
    setCurrentDepartmentId(departmentId);
    setDeleteConfirmOpen(true);
  };
  
  const handleDeleteConfirm = () => {
    deleteDepartment(currentDepartmentId);
    setDeleteConfirmOpen(false);
  };
  
  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setCurrentDepartmentId(null);
  };
  
  const isFormValid = () => {
    return departmentData.name && departmentData.clientId;
  };
  
  const getClientName = (clientId) => {
    const client = clients.find(c => c.id === clientId);
    return client ? client.name : 'Unknown Client';
  };
  
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Department Management</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
          disabled={clients.length === 0}
        >
          Create Department
        </Button>
      </Box>
      
      {clients.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography>Please create a client first before adding departments.</Typography>
        </Paper>
      ) : departments.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography>No departments found. Create your first department.</Typography>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Department Name</TableCell>
                <TableCell>Client</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {departments.map((department) => (
                <TableRow key={department.id}>
                  <TableCell>{department.name}</TableCell>
                  <TableCell>{getClientName(department.clientId)}</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" color="primary" onClick={() => handleEdit(department)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton size="small" color="error" onClick={() => handleDeleteClick(department.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      
      {/* Create/Edit Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{isEditMode ? 'Edit Department' : 'Create New Department'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Department Name"
                value={departmentData.name}
                onChange={(e) => setDepartmentData({ ...departmentData, name: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Client</InputLabel>
                <Select
                  value={departmentData.clientId}
                  label="Client"
                  onChange={(e) => setDepartmentData({ ...departmentData, clientId: e.target.value })}
                >
                  {clients.map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={!isFormValid()} variant="contained">
            {isEditMode ? 'Save Changes' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this department? This will also remove all associated teams and users.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DepartmentManagement;
