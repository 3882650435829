import React, { useState, useEffect } from 'react';
import { Box, Typography, Tabs, Tab, ListItem, ListItemText, Chip } from '@mui/material';
import Overview from '../training/Overview';
import ModelSelection from '../training/ModelSelection';
import TrainingFineTuning from '../training/TrainingFineTuning';
import ModelDeployment from '../training/ModelDeployment';
import DataPreparation from '../training/DataPreparation'; // You'll need to create this component
import StatusOverview from '../training/StatusOverview';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { launchTraining } from '../api/services/vcai/Vcai';
import { useKeycloak } from '@react-keycloak/web';
// Mock data
const activeJobs = [
  { 
    id: 1, 
    name: "Mistral-7B Fine-tuning", 
    progress: 33, 
    eta: "2h 15m", 
    status: "In Progress", 
    dataset: "Customer Support Conversations",
    metrics: {
      epochs: [
        { epoch: 1, loss: 0.82, accuracy: 0.65, val_loss: 0.85, val_accuracy: 0.63 },
        { epoch: 2, loss: 0.76, accuracy: 0.71, val_loss: 0.79, val_accuracy: 0.69 },
        { epoch: 3, loss: 0.71, accuracy: 0.75, val_loss: 0.74, val_accuracy: 0.73 },
      ],
      currentEpoch: 3,
      totalEpochs: 10,
      learningRate: 0.001,
      batchSize: 32,
      samplesProcessed: "450K",
      timeElapsed: "1h 45m"
    }
  },
  { 
    id: 2, 
    name: "CodeLlama Python Fine-tuning", 
    progress: 67, 
    eta: "45m", 
    status: "In Progress", 
    dataset: "Python Code Examples",
    metrics: {
      epochs: [
        { epoch: 1, loss: 0.65, accuracy: 0.78, val_loss: 0.68, val_accuracy: 0.76 },
        { epoch: 2, loss: 0.58, accuracy: 0.83, val_loss: 0.61, val_accuracy: 0.81 },
        { epoch: 3, loss: 0.52, accuracy: 0.87, val_loss: 0.55, val_accuracy: 0.85 },
        { epoch: 4, loss: 0.48, accuracy: 0.89, val_loss: 0.51, val_accuracy: 0.87 },
      ],
      currentEpoch: 4,
      totalEpochs: 6,
      learningRate: 0.0005,
      batchSize: 64,
      samplesProcessed: "780K",
      timeElapsed: "2h 15m"
    }
  },
];

const completedJobs = [
  { 
    id: 3, 
    name: "Llama-3.2-3B Fine-tuning", 
    completedAt: "2 days ago", 
    dataset: "Social Media Posts",
    metrics: {
      finalMetrics: {
        accuracy: 0.92,
        loss: 0.32,
        val_accuracy: 0.90,
        val_loss: 0.35,
        f1Score: 0.91,
        precision: 0.89,
        recall: 0.93
      },
      trainingTime: "4h 32m",
      epochsCompleted: 8,
      finalLearningRate: 0.0001,
      totalSamplesProcessed: "2.1M"
    }
  },
];

const myModels = [
  { 
    id: 1, 
    name: "Mistral-7B-Instruct Custom", 
    description: "Fine-tuned instruction model", 
    version: "v1.0.1", 
    accuracy: "82%",
    accuracyIcon: <PrecisionManufacturingIcon />,
    trainingData: "1M+ samples", 
    updatedAt: "2 days ago", 
    dataset: "Customer Support Conversations",
    parameters: "7B",
    modelSize: "14GB",
    tokenSpeed: "180 tokens/s",
    maxContext: "8K tokens",
    baseModel: "mistralai/Mistral-7B-Instruct-v0.3"
  },
  { 
    id: 2, 
    name: "CodeLlama Python Custom", 
    description: "Fine-tuned Python code generation model", 
    version: "v1.1.0", 
    accuracy: "85%",
    accuracyIcon: <PrecisionManufacturingIcon />,
    trainingData: "800K samples", 
    updatedAt: "3 days ago", 
    dataset: "Python Code Examples",
    parameters: "7B",
    modelSize: "13GB", 
    tokenSpeed: "200 tokens/s",
    maxContext: "4K tokens",
    baseModel: "codellama/CodeLlama-7b-Python-hf"
  },
  { 
    id: 3, 
    name: "Llama-3.2 Chat Custom", 
    description: "Fine-tuned chat model", 
    version: "v1.0.2", 
    accuracy: "78%",
    accuracyIcon: <PrecisionManufacturingIcon />,
    trainingData: "2M+ samples", 
    updatedAt: "1 day ago", 
    dataset: "Conversation Dialogs",
    parameters: "3B",
    modelSize: "6GB",
    tokenSpeed: "250 tokens/s", 
    maxContext: "4K tokens",
    baseModel: "meta-llama/Llama-3.2-3B-Instruct"
  }
];
const publicModels = [
  { id: 1, name: "meta-llama/Llama-3.2-3B-Instruct", size: "3B parameters", parameters: "3B", type: "Instruction-tuned LLM", provider: "Meta" },
  { id: 2, name: "meta-llama/Llama-3.1-8B", size: "8B parameters", parameters: "8B", type: "Instruction-tuned LLM", provider: "Meta" },
  { id: 3, name: "nvidia/Llama3-ChatQA-2-8B", size: "8B parameters", parameters: "8B", type: "Chat QA Model", provider: "NVIDIA" },
  { id: 4, name: "NousResearch/Llama-2-7b-chat-hf", size: "7B parameters", parameters: "7B", type: "Chat Model", provider: "Nous Research" },
  { id: 5, name: "codellama/CodeLlama-7b-hf", size: "7B parameters", parameters: "7B", type: "Code Generation Model", provider: "Meta" },
  { id: 6, name: "codellama/CodeLlama-7b-Python-hf", size: "7B parameters", parameters: "7B", type: "Python Code Generation Model", provider: "Meta" },
  { id: 7, name: "mistralai/Mistral-7B-Instruct-v0.3", size: "7B parameters", parameters: "7B", type: "Instruction-tuned LLM", provider: "Mistral AI" },
  { id: 8, name: "mistralai/Mixtral-8x7B-Instruct-v0.1", size: "8x7B parameters", parameters: "56B", type: "Instruction-tuned LLM", provider: "Mistral AI" },
  { id: 9, name: "FlagAlpha/Llama2-Chinese-13b-Chat", size: "13B parameters", parameters: "13B", type: "Chinese Chat Model", provider: "FlagAlpha" },
  { id: 10, name: "inceptionai/jais-13b", size: "13B parameters", parameters: "13B", type: "General Purpose Model", provider: "Inception AI" }
];
const datasets = [
  { id: 1, name: "mlabonne/guanaco-llama2-1k", taskType: "", type: "General", size: "1K", samples: 1000, description: "A subset of the OpenAssistant-Guanaco dataset, formatted to match Llama 2's prompt style.", updated: "2 days ago" },
  { id: 2, name: "bitext/Bitext-customer-support-llm-chatbot-training-dataset", taskType: "QA", type: "QA", size: "10K-100K", samples: 26872, description: "Contains 26,872 question-answer pairs across 27 intents in customer service, totaling approximately 3.57 million tokens.", updated: "1 week ago" },
  { id: 3, name: "b-mc2/sql-create-context", taskType: "QA", type: "QA", size: "1K-10K", samples: 5000, description: "A SQL-focused dataset for generating context in QA tasks.", updated: "3 days ago" },
  { id: 4, name: "google/xtreme", taskType: "QA", type: "QA", size: "100K+", samples: 100000, description: "A benchmark for evaluating cross-lingual generalization across 40 languages and 9 tasks.", updated: "1 month ago" },
  { id: 5, name: "mandarjoshi/trivia_qa", taskType: "QA", type: "QA", size: "100K+", samples: 650000, description: "A reading comprehension dataset containing over 650K question-answer pairs.", updated: "2 weeks ago" },
  { id: 6, name: "riotu-lab/ArabicQA_2.1M", taskType: "QA", type: "QA", size: "1M+", samples: 2100000, description: "An Arabic question-answering dataset with 2.1 million samples.", updated: "5 days ago" },
  { id: 7, name: "llamafactory/alpaca_gpt4_en", taskType: "QA", type: "QA", size: "50K", samples: 50000, description: "A dataset tailored for Alpaca and GPT-4 QA tasks.", updated: "1 day ago" },
  { id: 8, name: "Quardo/gpt-4o-qa", taskType: "QA", type: "QA", size: "10K", samples: 10000, description: "A GPT-4 fine-tuning dataset for QA tasks.", updated: "4 days ago" },
  { id: 9, name: "LimYeri/LeetCode_Python_Solutions_v2", taskType: "QA", type: "CODE", size: "5K", samples: 5000, description: "A dataset containing Python solutions to LeetCode problems.", updated: "1 week ago" }
];


export default function Training() {
  const [activeTab, setActiveTab] = useState(0);
  const [hasStartedTraining, setHasStartedTraining] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [selectedServer, setSelectedServer] = useState(null);
  const [trainedModel, setTrainedModel] = useState(null);
  const [trainingConfig, setTrainingConfig] = useState(null);
  const [deploymentConfig, setDeploymentConfig] = useState(null);
  const { keycloak } = useKeycloak();


  
  // useEffect(() => {
  //   console.log('Training State Updated:', {
  //     activeTab,
  //     hasStartedTraining,
  //     selectedModel,
  //     selectedDataset,
  //     selectedServer,
  //     trainedModel, 
  //     trainingConfig,
  //     deploymentConfig
  //   });
  // }, [activeTab, hasStartedTraining, selectedModel, selectedDataset, selectedServer, trainedModel, trainingConfig, deploymentConfig]);

  const handleTabChange = (event, newValue) => {
    if (!hasStartedTraining) return;
    
    if (newValue === 1 && !hasStartedTraining) return;
    if (newValue === 2 && !hasStartedTraining) return;
    if (newValue === 3 && !selectedModel) return;
    if (newValue === 4 && !selectedDataset) return;
    if (newValue === 5 && !trainedModel) return;
    
    setActiveTab(newValue);
  };

  const handleLaunchTraining = () => {
    console.log('Launching training...');
    setHasStartedTraining(true);
    setActiveTab(1); // Move to model selection
  };

  const handleModelSelect = (model) => {
    console.log('Selected model:', model);
    setSelectedModel(model);
    setActiveTab(2); // Move to data preparation
  };

  const handleDatasetSelect = (dataset) => {
    console.log('Selected dataset:', dataset);
    setSelectedDataset(dataset);
    setActiveTab(3); // Move to training & fine-tuning
  };

  const handleStartTraining = (config) => {
    console.log('Starting training with config:', config);
    setTrainingConfig(config);
    setTrainedModel(selectedModel);
    setActiveTab(4);
  };
 
  const handleInitiateTraining = async (config) => {
    console.log('Starting training with config:', config);
    console.log('Setting training config:', config);
    setTrainingConfig(config);
    console.log('Setting trained model:', selectedModel);
    setTrainedModel(selectedModel);
    console.log('Setting selected server:', selectedServer); 
    setSelectedServer(selectedServer);
  
    try {
      // Ensure the token is refreshed
      await keycloak.updateToken(30);
  
      const trainingParams = {
        // Training Data
        model: selectedModel.name || '',
        inputModel: selectedModel.name || '',
        outputModel: `${selectedModel.name || 'model'}-finetuned`,
        dataPath: selectedDataset.name || '',
  
        // Training Config
        epochs: config.basic?.epochs || 3,
        accumulationSteps: config.advanced?.accumulationSteps || 1,
        saveSteps: config.advanced?.saveSteps || 1000,
        learningRate: config.advanced?.learningRate || 0.00002,
        dtype: config.basic?.dataPrecision || 'fp16',
        maxSteps: config.advanced?.maxSteps || null,
        groupByLength: config.advanced?.groupByLength || false,
        batchSize: config.basic?.batchSizePerDevice || 16,
        optim: config.advanced?.optimizer || 'adamw_torch',
        loggingSteps: config.advanced?.loggingSteps || 500,
        weightDecay: config.advanced?.weightDecay || 0.01,
        maxGradNorm: config.advanced?.maxGradientNorm || 1,
        warmupRatio: config.advanced?.warmupRatio || 0.1,
        schedulerType: config.advanced?.lrScheduler || 'linear',
        taskType: selectedDataset?.taskType || 'QA',
        pushToHub: config.advanced?.pushToHub || false,
        repoId: config.advanced?.repoId || '',
  
        // Instance Details
        instanceId: selectedServer?.id || 'default-instance',
        timeStart: new Date().toISOString(),
        timeEnd: '', // Placeholder for the end time
        numOfInstances: config.instance?.numInstances || 1,
        numOfGpusPerInstance: config.instance?.numOfGpus || 1,
        gpuInfo: {
          is_AMD: selectedServer?.gpuType === 'AMD',
          is_NVIDIA: selectedServer?.gpuType === 'NVIDIA',
          gpu_name: selectedServer?.gpu || '',
          gpu_indices: ['0']
        },
        cpuInfo: config.instance?.cpu || {},
        duration: config.instance?.duration || '24h',
  
        // Auth token
        token: keycloak.token
      };

      // Log GPU info
      console.log('GPU Info:', {
        gpuType: selectedServer?.gpuType,
        isAMD: selectedServer?.gpuType === 'AMD',
        isNVIDIA: selectedServer?.gpuType === 'NVIDIA', 
        gpuName: selectedServer?.gpu,
        gpuIndices: ['0']
      });
  
      console.log('Launching training with parameters:', trainingParams);
  
      const response = await launchTraining(trainingParams);
  
      if (response.success) {
        console.log('Training launched successfully:', response);
        setActiveTab(4); // Move to deployment tab
      } else {
        console.error('Failed to launch training:', response.error);
        alert(`Training launch failed: ${response.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error launching training:', error);
      alert(`An error occurred while launching training: ${error.message}`);
    }
  };
  


  return (
    <Box sx={{ p: 3 }}>
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'flex-start', 
          justifyContent: 'flex-start',
          mb: 5,
          gap: 10
        }}
      >
        <Typography variant="h4">Training</Typography>
        
        {hasStartedTraining && (
          <Box sx={{ flexGrow: 1, maxWidth: '80%' }}>
            <StatusOverview
              selectedModel={selectedModel}
              selectedDataset={selectedDataset}
              trainingConfig={trainingConfig}
              deploymentConfig={deploymentConfig}
              activeTab={activeTab}
            />
          </Box>
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange} 
          sx={{ flexGrow: 1 }}
        >
          <Tab label="Overview" />
          <Tab label="1. Choose Model" disabled={!hasStartedTraining} />
          <Tab label="2. Data Preparation" disabled={!hasStartedTraining || !selectedModel} />
          <Tab label="3. Training & Fine-tuning" disabled={!hasStartedTraining || !selectedDataset} />
          <Tab label="4. Deployment" disabled={!hasStartedTraining || !trainedModel} />
        </Tabs>
      </Box>

      {/* Overview Tab */}
      {activeTab === 0 && (
        <Overview 
          onLaunchTraining={handleLaunchTraining}
          activeJobs={activeJobs}
          completedJobs={completedJobs}
          myModels={myModels}
        />
      )}

      {/* Model Selection Tab */}
      {activeTab === 1 && (
        <ModelSelection 
          myModels={myModels}
          publicModels={publicModels}
          onModelSelect={handleModelSelect}
        />
      )}

      {/* Data Preparation Tab */}
      {activeTab === 2 && (
        <DataPreparation 
          datasets={datasets}
          selectedModel={selectedModel}
          onDatasetSelect={handleDatasetSelect}
        />
      )}

      {/* Training & Fine-tuning Tab */}
      {activeTab === 3 && (
        <TrainingFineTuning 
          selectedModel={selectedModel}
          selectedDataset={selectedDataset}
          activeJobs={activeJobs}
          completedJobs={completedJobs}
          onStartTraining={handleStartTraining}
        />
      )}

      {/* Deployment Tab */}
      {activeTab === 4 && (
        <ModelDeployment 
          selectedServer={selectedServer}
          setSelectedServer={setSelectedServer}
          handleInitiateTraining={handleInitiateTraining}
        />
      )}
    </Box>
  );
}
