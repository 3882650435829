import React, { useState, useEffect } from 'react';
import { Typography, Card, CardHeader, CardContent, Avatar,
   Grid, TextField, Button, Box, Chip, Paper,
  Divider, FormControl, InputLabel, Select, MenuItem, Stack,
   Checkbox, InputAdornment, ButtonGroup,
   List, ListItem, ListItemText, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, ListItemAvatar, CardActions,
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   LinearProgress,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsIcon from '@mui/icons-material/Settings';
import SearchIcon from '@mui/icons-material/Search';
import MonitorIcon from '@mui/icons-material/Monitor';
import StyledLinearProgress from '../helpers/StyledLinearProgress';
import { useNavigate } from 'react-router-dom';
import MapChart from '../cloudmanagement/MapChart';
import { 
  ToggleButton, 
  ToggleButtonGroup 
} from '@mui/material';
import {
  GridView as GridViewIcon,
  ViewList as ViewListIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  PowerOff as PowerOffIcon,
  Delete as DeleteIcon
} from '@mui/icons-material';
import { getUserInventory } from '../api/services/computeService/Inventory';
import { useKeycloak } from '@react-keycloak/web';

const geoUrl = require('../cloudmanagement/world-countries.json');

export default function Deployment() {
  const [showAddServer, setShowAddServer] = useState(false);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedServers, setSelectedServers] = useState([]);
  const [filters, setFilters] = useState({
    status: 'all',
    location: 'all',
    os: 'all'
  });
  const [viewType, setViewType] = useState('tiles'); // 'tiles' or 'list'
  const [openConfigDialog, setOpenConfigDialog] = useState(false);
  const [selectedServer, setSelectedServer] = useState(null);
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(true);
  const { keycloak } = useKeycloak();

  // Add useEffect to fetch servers
  useEffect(() => {
    const fetchServers = async () => {
      try {
        await keycloak.updateToken(30);
        setLoading(true);
        const response = await getUserInventory(keycloak.subject, keycloak.token);
        console.log('Server fetch response:', response);
        
        if (response.success) {
          const transformedServers = response.data.map(server => {
            const countryData = geoUrl.features.find(
              feature => feature.id === server.location
            );
            let coordinates = [0, 0];
            if (countryData && countryData.geometry) {
              if (countryData.geometry.type === 'Polygon') {
                const coords = countryData.geometry.coordinates[0];
                coordinates = coords.reduce((acc, curr) => [acc[0] + curr[0], acc[1] + curr[1]], [0, 0])
                  .map(sum => sum / coords.length);
              } else if (countryData.geometry.type === 'MultiPolygon') {
                const coords = countryData.geometry.coordinates[0][0];
                coordinates = coords.reduce((acc, curr) => [acc[0] + curr[0], acc[1] + curr[1]], [0, 0])
                  .map(sum => sum / coords.length);
              }
            }

            return {
              id: server.id,
              hostname: server.hostname,
              location: server.location, 
              coordinates: coordinates,
              status: server.status === "active" ? "Online" : "Offline",
              type: server.type.charAt(0).toUpperCase() + server.type.slice(1),
              machineId: server.id,
              ipAddress: server.ipAddress,
              port: server.port,
              power: "N/A",
              specification: `${server.numCpu} CPUs, ${server.ram}GB RAM`,
              function: server.slurmHead ? "Slurm Head Node" : "Compute Node",
              gpuType: server.gpuType.toUpperCase(),
              gpu: server.gpu,
              numGpus: server.numGpus,
              mem: server.mem,
              health: "Good",
            };
          });
          
          setServers(transformedServers);
        } else {
          console.error('Failed to fetch servers:', response.error);
        }
      } catch (error) {
        console.error('Error fetching servers:', error);
        if (error.message?.includes('token')) {
          await keycloak.updateToken(30);
        }
      } finally {
        setLoading(false);
      }
    };

    if (keycloak.token) {
      fetchServers();
    }
  }, [keycloak.token, keycloak.subject]);


  // Add loading state handling in the render
  if (loading) {
    return (
      <Box sx={{ padding: 3 }}>
        <LinearProgress />
        <Typography sx={{ mt: 2 }}>Loading servers...</Typography>
      </Box>
    );
  }

  const handleAddServer = () => {
    navigate('/discover-instances');
  };

  // Filter options
  const filterOptions = {
    status: ['all', 'online', 'offline'],
    location: ['all', 'US East', 'EU West', 'Asia Pacific'],
    os: ['all', 'ubuntu', 'windows', 'centos']
  };

  // Filter servers based on search and filters
  const filteredServers = servers.filter(server => {
    const matchesSearch = server.hostname.toLowerCase().includes(searchQuery.toLowerCase()) ||
                         server.location.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesStatus = filters.status === 'all' || 
                         server.status.toLowerCase() === filters.status;
    const matchesLocation = filters.location === 'all' || 
                           server.location.toLowerCase().includes(filters.location);
    const matchesOS = filters.os === 'all' || 
                     server.os === filters.os;
    
    return matchesSearch && matchesStatus && matchesLocation && matchesOS;
  });

  // Bulk actions handler
  const handleBulkAction = (action) => {
    console.log(`Performing ${action} on servers:`, selectedServers);
    // Implement bulk action logic here
  };

  const handleServerSelect = (serverId) => {
    setSelectedServers(prev => 
      prev.includes(serverId) 
        ? prev.filter(id => id !== serverId)
        : [...prev, serverId]
    );
  };

  const handleOpenConfigDialog = (server) => {
    setSelectedServer(server);
    setOpenConfigDialog(true);
  };

  const handleCloseConfigDialog = () => {
    setOpenConfigDialog(false);
    setSelectedServer(null);
  };

  const SearchAndFilterBar = ({ searchQuery, setSearchQuery, filters, setFilters, viewType, setViewType }) => (
    <Paper 
      sx={{ 
        p: 2, 
        backgroundColor: '#1a1a1a',
        borderRadius: '12px',
        mt: 4,
        mb: 2
      }}
    >
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            placeholder="Search servers..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'text.secondary' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <Stack direction="row" spacing={2}>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>Status</InputLabel>
              <Select
                value={filters.status}
                onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                label="Status"
              >
                <MenuItem value="all">All Status</MenuItem>
                <MenuItem value="online">Online</MenuItem>
                <MenuItem value="offline">Offline</MenuItem>
              </Select>
            </FormControl>
            
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>Location</InputLabel>
              <Select
                value={filters.location}
                onChange={(e) => setFilters({ ...filters, location: e.target.value })}
                label="Location"
              >
                <MenuItem value="all">All Locations</MenuItem>
                <MenuItem value="us">US</MenuItem>
                <MenuItem value="eu">Europe</MenuItem>
                <MenuItem value="asia">Asia</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel>OS</InputLabel>
              <Select
                value={filters.os}
                onChange={(e) => setFilters({ ...filters, os: e.target.value })}
                label="OS"
              >
                <MenuItem value="all">All OS</MenuItem>
                <MenuItem value="ubuntu">Ubuntu</MenuItem>
                <MenuItem value="windows">Windows</MenuItem>
                <MenuItem value="centos">CentOS</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Grid>
        <Grid item xs={12} md={1}>
          <ToggleButtonGroup
            value={viewType}
            exclusive
            onChange={(e, newView) => newView && setViewType(newView)}
            size="small"
          >
            <ToggleButton value="tiles" aria-label="grid view">
              <GridViewIcon />
            </ToggleButton>
            <ToggleButton value="list" aria-label="list view">
              <ViewListIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
      </Grid>
    </Paper>
  );

  const BulkActions = ({ selectedCount, onAction }) => (
    <Paper 
      sx={{ 
        p: 2, 
        mb: 3, 
        backgroundColor: '#1a1a1a',
        display: selectedCount > 0 ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '12px',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Typography 
          variant="body1" 
          sx={{ 
            color: '#fff',
            backgroundColor: 'primary.main',
            px: 2,
            py: 0.5,
            borderRadius: '20px',
          }}
        >
          {selectedCount} selected
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            color: 'warning.main',
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          ⚠️ These actions are reserved for Superadmins only
        </Typography>
      </Box>
      <Stack direction="row" spacing={1}>
        <Button 
          variant="contained" 
          color="primary"
          disabled
          startIcon={<PowerSettingsNewIcon />}
        >
          Power On
        </Button>
        <Button 
          variant="contained" 
          color="warning"
          disabled
          startIcon={<PowerOffIcon />}
        >
          Power Off
        </Button>
        <Button 
          variant="contained" 
          color="error"
          disabled
          startIcon={<DeleteIcon />}
        >
          Remove
        </Button>
        <Button 
          variant="contained" 
          color="error"
          disabled
          startIcon={<DeleteIcon />}
        >
          Release Allocation
        </Button>
        {/* <ButtonGroup variant="contained" color="primary">
          <Button disabled onClick={() => onAction('updateBios')}>Update BIOS</Button>
          <Button disabled onClick={() => onAction('installOS')}>Install OS</Button>
          <Button disabled onClick={() => onAction('getSpecs')}>Get Specs</Button>
          <Button disabled onClick={() => onAction('validate')}>Validate</Button>
        </ButtonGroup> */}
      </Stack>
    </Paper>
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <Typography variant="h4">Cloud Management</Typography>
        <Box>
          <Button 
            variant="contained" 
            startIcon={<AddIcon />} 
            onClick={handleAddServer}
            sx={{ mr: 2 }}
          >
            Discover Servers
          </Button>
        </Box>
      </Box>

      <SearchAndFilterBar 
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filters={filters}
        setFilters={setFilters}
        viewType={viewType}
        setViewType={setViewType}
      />
      
      <BulkActions 
        selectedCount={selectedServers.length}
        onAction={handleBulkAction}
      />

      {/* Map and Server Info Section */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={8}>
          <Paper 
            sx={{ 
              p: 3,
              backgroundColor: '#1a1a1a',
              borderRadius: '12px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              height: '400px',
              width: '100%'
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
              Server Locations
            </Typography>
            <Box sx={{ height: 'calc(100% - 40px)', width: '100%' }}>
              <MapChart servers={servers} />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper 
            sx={{ 
              p: 2, 
              backgroundColor: '#1a1a1a', 
              borderRadius: '12px',
              height: '400px'
            }}
          >
            <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
              Quick Overview
            </Typography>
            <Box sx={{ 
              mb: 3, 
              height: 'calc(100% - 40px)', 
              overflowY: 'auto'
            }}>
              <Typography variant="subtitle2" sx={{ color: '#fff', mb: 1 }}>
                Active Servers
              </Typography>
              {servers.map((server) => (
                <Box 
                  key={server.id} 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    mb: 1,
                    p: 1,
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.05)',
                      borderRadius: '4px'
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: 8,
                      height: 8,
                      borderRadius: '50%',
                      backgroundColor: server.status === 'Online' ? '#4caf50' : '#f44336',
                      mr: 1
                    }}
                  />
                  <Box>
                    <Typography variant="body2" sx={{ color: '#fff' }}>
                      {server.hostname}
                    </Typography>
                    <Typography variant="caption" sx={{ color: '#aaa' }}>
                      {server.location}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Divider sx={{ my: 3 }} />

      {showAddServer && (
        <Card sx={{ mb: 4 }}>
          <CardHeader 
            title="Add New Server" 
            subheader="Enter the details of the new server to add it to your deployment."
            avatar={<Avatar sx={{ bgcolor: 'primary.main' }}><CloudUploadIcon /></Avatar>}
          />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Server Name" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField fullWidth label="Server IP" variant="outlined" />
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary">
                  Add Server
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {viewType === 'tiles' ? (
        <Grid container spacing={3}>
          {filteredServers.map((server) => (
            <Grid item xs={12} sm={6} md={4} key={server.id}>
              <Card 
                sx={{ 
                  height: '100%', 
                  backgroundColor: '#1a1a1a',
                  borderRadius: '12px',
                  border: selectedServers.includes(server.id) ? '2px solid #1976d2' : '1px solid rgba(255, 255, 255, 0.1)',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.05)',
                    cursor: 'pointer',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.4)'
                  },
                  transition: 'all 0.3s ease',
                  position: 'relative',
                  overflow: 'visible'
                }}
              >
                <CardHeader 
                  sx={{
                    borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
                    pb: 2,
                    '& .MuiCardHeader-content': { width: '100%' }
                  }}
                  title={
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Avatar 
                          sx={{ 
                            bgcolor: 'primary.dark',
                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)'
                          }}
                        >
                          <StorageIcon />
                        </Avatar>
                        <Box>
                          <Typography variant="h6" sx={{ fontSize: '1.1rem', fontWeight: 600 }}>
                            {server.name}
                          </Typography>
                          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            {server.location}
                          </Typography>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <Typography variant="caption" color="text.secondary">
                            Health
                          </Typography>
                          <Chip 
                            label={server.health}
                            color={
                              server.health === 'Good' ? 'success' :
                              server.health === 'Moderate' ? 'warning' :
                              'error'
                            }
                            size="small"
                            sx={{ 
                              fontWeight: 500,
                              minWidth: 70,
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                            }}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <Typography variant="caption" color="text.secondary">
                            Status
                          </Typography>
                          <Chip 
                            label={server.status} 
                            color={server.status === "Online" ? "success" : "error"} 
                            size="small"
                            sx={{ 
                              fontWeight: 500,
                              minWidth: 70,
                              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
                            }}
                          />
                        </Box>
                        <Checkbox
                          checked={selectedServers.includes(server.id)}
                          onChange={() => handleServerSelect(server.id)}
                          sx={{ 
                            color: 'primary.main',
                            p: 0.5,
                          }}
                        />
                      </Box>
                    </Box>
                  }
                />
                <CardContent sx={{ pt: 2, pb: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box sx={{ 
                        display: 'flex', 
                        gap: 2, 
                        mb: 2,
                        p: 1.5, 
                        bgcolor: 'rgba(0, 0, 0, 0.2)', 
                        borderRadius: 1,
                        border: '1px solid rgba(255, 255, 255, 0.05)'
                      }}>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
                            IP Address
                          </Typography>
                          <Typography variant="body2" sx={{ fontFamily: 'monospace', wordBreak: 'break-all' }}>
                            {server.ipAddress}
                          </Typography>
                        </Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="caption" color="text.secondary" display="block" gutterBottom>
                            Port
                          </Typography>
                          <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                            {server.port}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid item xs={12}>
                      <Box sx={{ 
                        p: 1.5, 
                        bgcolor: 'rgba(0, 0, 0, 0.2)', 
                        borderRadius: 1,
                        border: '1px solid rgba(255, 255, 255, 0.05)'
                      }}>
                        <Typography 
                          variant="subtitle2" 
                          color="primary" 
                          gutterBottom 
                          sx={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            gap: 1,
                            fontWeight: 600
                          }}
                        >
                          GPU Configuration
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Box>
                              <Typography variant="caption" color="text.secondary" display="block">
                                GPU Type
                              </Typography>
                              <Typography variant="body2">
                                {server.gpuType}
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                              <Typography variant="caption" color="text.secondary" display="block">
                                Vendor
                              </Typography>
                              <Typography variant="body2">
                                {server.gpu}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <Box>
                              <Typography variant="caption" color="text.secondary" display="block">
                                GPU Count
                              </Typography>
                              <Typography variant="body2">
                                {server.gpus} Units
                              </Typography>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                              <Typography variant="caption" color="text.secondary" display="block">
                                VRAM
                              </Typography>
                              <Typography variant="body2">
                                {server.vram} GB
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions 
                  sx={{ 
                    justifyContent: 'space-between', 
                    p: 2,
                    pt: 1,
                    borderTop: '1px solid rgba(255, 255, 255, 0.1)'
                  }}
                >
                  <Chip
                    label={server.type}
                    size="small"
                    sx={{
                      backgroundColor: 
                        server.type === 'High Performance' ? 'rgba(76, 175, 80, 0.1)' :
                        server.type === 'General Purpose' ? 'rgba(33, 150, 243, 0.1)' :
                        'rgba(158, 158, 158, 0.1)',
                      color: 
                        server.type === 'High Performance' ? '#4caf50' :
                        server.type === 'General Purpose' ? '#2196f3' :
                        '#9e9e9e',
                      fontWeight: 500
                    }}
                  />
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Button 
                      size="small" 
                      startIcon={<MonitorIcon />}
                      onClick={() => navigate(`/monitoring/${server.ipAddress}/${server.port}/${server.hostname}`)}
                      sx={{
                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                        '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
                      }}
                    >
                      Monitor
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Paper sx={{ backgroundColor: '#1a1a1a', borderRadius: '12px', overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedServers(filteredServers.map(server => server.id));
                      } else {
                        setSelectedServers([]);
                      }
                    }}
                    checked={selectedServers.length === filteredServers.length}
                    indeterminate={selectedServers.length > 0 && selectedServers.length < filteredServers.length}
                  />
                </TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Hostname</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Machine ID</TableCell>
                <TableCell>IP Address</TableCell>
                <TableCell>Port</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>GPU Type</TableCell>
                <TableCell>GPU Vendor</TableCell>
                <TableCell>GPU Count</TableCell>
                <TableCell>VRAM (GB)</TableCell>
                <TableCell>Health</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredServers.map((server) => (
                <TableRow
                  key={server.id}
                  sx={{
                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.05)' },
                    backgroundColor: selectedServers.includes(server.id) ? 'rgba(25, 118, 210, 0.08)' : 'transparent',
                  }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedServers.includes(server.id)}
                      onChange={() => handleServerSelect(server.id)}
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: server.status === 'Online' ? '#4caf50' : '#f44336',
                          mr: 1
                        }}
                      />
                      <Chip
                        label={server.status}
                        color={server.status === 'Online' ? 'success' : 'error'}
                        size="small"
                      />
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                      {server.hostname}
                    </Typography>
                  </TableCell>
                  <TableCell>{server.location}</TableCell>
                  <TableCell>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                      {server.machineId}
                    </Typography>
                  </TableCell>
                  <TableCell>{server.ipAddress}</TableCell>
                  <TableCell>{server.port}</TableCell>
                  <TableCell>
                    <Chip
                      label={server.type}
                      size="small"
                      sx={{
                        backgroundColor: 
                          server.type === 'High Performance' ? 'rgba(76, 175, 80, 0.1)' :
                          server.type === 'General Purpose' ? 'rgba(33, 150, 243, 0.1)' :
                          'rgba(158, 158, 158, 0.1)',
                        color: 
                          server.type === 'High Performance' ? '#4caf50' :
                          server.type === 'General Purpose' ? '#2196f3' :
                          '#9e9e9e'
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap>
                      {server.gpuType}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap>
                      {server.gpu}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap>
                      {server.numGpus}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" noWrap>
                      {server.mem}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={server.health}
                      color={
                        server.health === 'Good' ? 'success' :
                        server.health === 'Moderate' ? 'warning' :
                        'error'
                      }
                      size="small"
                      sx={{ 
                        fontWeight: 500,
                        ml: 1
                      }}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <ButtonGroup size="small" variant="outlined">
                      <IconButton
                        size="small"
                        onClick={() => navigate(`/monitoring/${server.ipAddress}/${server.port || '22'}`)}
                        sx={{ 
                          bgcolor: 'rgba(255, 255, 255, 0.05)',
                          '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' }
                        }}
                      >
                        <MonitorIcon fontSize="small" />
                      </IconButton>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Box>
  );
}
