import React, { useState } from 'react';
import { testApi } from '../api/services/vcai/Vcai';
import { getServerMetrics } from '../api/services/monitoring/Monitoring';
import { 
  Container, 
  Typography, 
  TextField, 
  Button, 
  Box, 
  Grid, 
  Paper,
  CircularProgress,
  Tabs,
  Tab,
  Divider
} from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';

function Test() {
  // Common state
  const { keycloak } = useKeycloak();
  const [activeTab, setActiveTab] = useState(0);

  // VCAI Test states
  const [request, setRequest] = useState('');
  const [response, setResponse] = useState('');

  // Server Monitoring states
  const [serverAddress, setServerAddress] = useState({
    ip: '',
    port: '',
    host: ''
  });
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState(null);
  const [error, setError] = useState('');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Original VCAI test handler
  const handleVcaiTest = async () => {
    try {
      await keycloak.updateToken(30);
      const token = keycloak.token;
      const result = await testApi(request, token);
      if (result.success) {
        setResponse(result.response);
      } else {
        setResponse(`Error: ${result.error}`);
      }
    } catch (error) {
      setResponse(`Error: ${error.message || 'Unknown error'}`);
    }
  };

  // Server monitoring test handler
  const handleMonitoringTest = async () => {
    try {
      setLoading(true);
      setError('');
      setMetrics(null);
      
      await keycloak.updateToken(30);

      const formattedServerAddress = {
        ipAddress: serverAddress.ip,
        portNumber: parseInt(serverAddress.port, 10),
        hostName: serverAddress.host
      };

      const result = await getServerMetrics({
        serverAddress: formattedServerAddress
      });

      if (result.success) {
        setMetrics(result.metrics);
      } else {
        setError(`Error: ${result.error}`);
      }
    } catch (error) {
      setError(`Error: ${error.message || 'Unknown error'}`);
    } finally {
      setLoading(false);
    }
  };

  const renderMetrics = () => {
    if (!metrics || metrics.length === 0) return null;

    const latestMetrics = metrics[metrics.length - 1];

    return (
      <Paper sx={{ p: 2, mt: 3 }}>
        <Typography variant="h6" gutterBottom>Server Metrics</Typography>
        
        <Box mb={2}>
          <Typography variant="subtitle1" color="primary">CPU</Typography>
          <Typography>Usage: {latestMetrics.cpu?.usage.toFixed(2)}%</Typography>
          <Typography>Cores: {latestMetrics.cpu?.cores}</Typography>
        </Box>

        <Box mb={2}>
          <Typography variant="subtitle1" color="primary">Memory</Typography>
          <Typography>Used: {latestMetrics.memory?.used.toFixed(2)} GB</Typography>
          <Typography>Total: {latestMetrics.memory?.total.toFixed(2)} GB</Typography>
          <Typography>Utilization: {latestMetrics.memory?.utilization.toFixed(2)}%</Typography>
        </Box>

        <Box mb={2}>
          <Typography variant="subtitle1" color="primary">GPU</Typography>
          <Typography>Utilization: {latestMetrics.gpu?.utilization.toFixed(2)}%</Typography>
          <Typography>Memory Utilization: {latestMetrics.gpu?.memoryUtilization.toFixed(2)}%</Typography>
        </Box>
      </Paper>
    );
  };

  return (
    <Container maxWidth="sm">
      <Box mt={5}>
        <Typography variant="h4" align="center">API Test</Typography>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="VCAI Test" />
          <Tab label="Server Monitoring" />
        </Tabs>
        <Divider />
        {activeTab === 0 && (
          <>
            <TextField
              type="text"
              value={request}
              onChange={(e) => setRequest(e.target.value)}
              placeholder="Enter test request"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleVcaiTest} 
              fullWidth
            >
              Test API
            </Button>
            <Typography 
              variant="body1" 
              align="center" 
              color="textSecondary" 
              sx={{ mt: 2 }}
            >
              {response}
            </Typography>
          </>
        )}
        {activeTab === 1 && (
          <>
            <TextField
              type="text"
              value={serverAddress.ip}
              onChange={(e) => setServerAddress({ ...serverAddress, ip: e.target.value })}
              placeholder="IP"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              type="text"
              value={serverAddress.port}
              onChange={(e) => setServerAddress({ ...serverAddress, port: e.target.value })}
              placeholder="Port"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <TextField
              type="text"
              value={serverAddress.host}
              onChange={(e) => setServerAddress({ ...serverAddress, host: e.target.value })}
              placeholder="Host"
              variant="outlined"
              fullWidth
              margin="normal"
            />
            <Button 
              variant="contained" 
              color="primary" 
              onClick={handleMonitoringTest} 
              fullWidth
            >
              Test Server
            </Button>
            {loading && (
              <CircularProgress sx={{ mt: 2 }} />
            )}
            {error && (
              <Typography 
                variant="body1" 
                align="center" 
                color="error" 
                sx={{ mt: 2 }}
              >
                {error}
              </Typography>
            )}
            {metrics && renderMetrics()}
          </>
        )}
      </Box>
    </Container>
  );
}

export default Test;