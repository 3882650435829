import React from 'react';
import { Card, CardContent, Typography, Box, Grid } from '@mui/material';
import { 
  Storage as StorageIcon,
  Memory as MemoryIcon,
  Code as CodeIcon,
  People as PeopleIcon,
  LanOutlined as LanIcon,
  PieChartOutlined as PieChartIcon
} from '@mui/icons-material';

const MetricDisplay = ({ title, value, icon, color, subvalue }) => (
  <Grid item xs={4}>
    <Box sx={{ py: 0.75, px: 1.25, borderRadius: 1.5, '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.03)' } }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
        <Box 
          sx={{ 
            backgroundColor: `${color}15`, 
            color: color, 
            borderRadius: 1, 
            p: 0.5, 
            display: 'flex',
            mr: 0.75,
            '& svg': { fontSize: '0.9rem' }
          }}
        >
          {icon}
        </Box>
        <Typography variant="caption" color="rgba(255, 255, 255, 0.7)" fontSize="0.7rem" fontWeight={500} noWrap>
          {title}
        </Typography>
      </Box>
      
      <Box sx={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1.1rem', lineHeight: 1.2 }}>
          {value}
        </Typography>
        
        {subvalue && (
          <Typography variant="caption" color="rgba(255, 255, 255, 0.5)" fontSize="0.65rem" sx={{ ml: 0.5 }} noWrap>
            {subvalue}
          </Typography>
        )}
      </Box>
    </Box>
  </Grid>
);

export default function SystemActivity() {
  const systemMetrics = {
    servers: { 
      total: 24, 
      active: 22,
      color: '#3B82F6',
      icon: <StorageIcon fontSize="small" />,
      subvalue: '22 active'
    },
    deployments: { 
      total: 18, 
      active: 12,
      color: '#10B981',
      icon: <CodeIcon fontSize="small" />,
      subvalue: '12 running'
    },
    trainingRuns: { 
      total: 47, 
      completed: 42,
      color: '#8B5CF6',
      icon: <MemoryIcon fontSize="small" />,
      subvalue: '5 active'
    },
    users: { 
      total: 36, 
      active: 8,
      color: '#F59E0B',
      icon: <PeopleIcon fontSize="small" />,
      subvalue: '8 online'
    },
    clusters: { 
      total: 4, 
      color: '#EC4899',
      icon: <LanIcon fontSize="small" />,
      subvalue: '128 cores'
    },
    services: { 
      total: 15, 
      color: '#14B8A6',
      icon: <PieChartIcon fontSize="small" />,
      subvalue: 'all active'
    }
  };

  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      backgroundColor: '#1a1a1a',
      color: 'white',
      borderRadius: 3,
      boxShadow: 'none',
      border: '1px solid rgba(255, 255, 255, 0.05)'
    }}>
      <CardContent sx={{ 
        p: 0,
        '&:last-child': { pb: 0 },
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
        <Box sx={{ px: 2, py: 1, borderBottom: '1px solid rgba(255, 255, 255, 0.05)' }}>
          <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '0.9rem' }}>
            System Overview
          </Typography>
        </Box>
        
        <Box sx={{ p: 1, flex: 1, display: 'flex', alignItems: 'center' }}>
          <Grid container spacing={1}>
            <MetricDisplay 
              title="Servers" 
              value={systemMetrics.servers.total} 
              icon={systemMetrics.servers.icon}
              color={systemMetrics.servers.color}
              subvalue={systemMetrics.servers.subvalue}
            />
            
            <MetricDisplay 
              title="Deployments" 
              value={systemMetrics.deployments.total} 
              icon={systemMetrics.deployments.icon}
              color={systemMetrics.deployments.color}
              subvalue={systemMetrics.deployments.subvalue}
            />
            
            <MetricDisplay 
              title="Train Runs" 
              value={systemMetrics.trainingRuns.total} 
              icon={systemMetrics.trainingRuns.icon}
              color={systemMetrics.trainingRuns.color}
              subvalue={systemMetrics.trainingRuns.subvalue}
            />
            
            <MetricDisplay 
              title="Users" 
              value={systemMetrics.users.total} 
              icon={systemMetrics.users.icon}
              color={systemMetrics.users.color}
              subvalue={systemMetrics.users.subvalue}
            />
            
            <MetricDisplay 
              title="GPU Clusters" 
              value={systemMetrics.clusters.total} 
              icon={systemMetrics.clusters.icon}
              color={systemMetrics.clusters.color}
              subvalue={systemMetrics.clusters.subvalue}
            />
            
            <MetricDisplay 
              title="Services" 
              value={systemMetrics.services.total} 
              icon={systemMetrics.services.icon}
              color={systemMetrics.services.color}
              subvalue={systemMetrics.services.subvalue}
            />
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
} 