import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Container, 
  Tabs, 
  Tab, 
  Typography, 
  Paper,
  Button,
  Alert,
  Snackbar,
  TextField,
  InputAdornment
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// Import components
import ClientManagement from '../admin/ClientManagement';
import DepartmentManagement from '../admin/DepartmentManagement';
import TeamManagement from '../admin/TeamManagement';
import UserManagement from '../admin/UserManagement';
import HierarchyView from '../admin/HierarchyView';
import SetupWizard from '../admin/SetupWizard';

// Mock data for testing
const mockData = {
  clients: [
    { id: 'c1', name: 'Acme Corporation', abbreviation: 'ACME', tenantId: 'tenant-acme-01' },
    { id: 'c2', name: 'Global Industries', abbreviation: 'GI', tenantId: 'tenant-gi-02' }
  ],
  departments: [
    { id: 'd1', name: 'Engineering', clientId: 'c1' },
    { id: 'd2', name: 'Marketing', clientId: 'c1' },
    { id: 'd3', name: 'Sales', clientId: 'c2' },
    { id: 'd4', name: 'Research & Development', clientId: 'c2' }
  ],
  teams: [
    { id: 't1', name: 'Frontend Team', departmentId: 'd1', parentTeamId: null },
    { id: 't2', name: 'Backend Team', departmentId: 'd1', parentTeamId: null },
    { id: 't3', name: 'Digital Marketing', departmentId: 'd2', parentTeamId: null },
    { id: 't4', name: 'Enterprise Sales', departmentId: 'd3', parentTeamId: null },
    { id: 't5', name: 'Product Innovation', departmentId: 'd4', parentTeamId: null }
  ],
  users: [
    { id: 'u1', name: 'John Doe', email: 'john@acme.com', role: 'TEAM_LEAD', teamId: 't1' },
    { id: 'u2', name: 'Jane Smith', email: 'jane@acme.com', role: 'MEMBER', teamId: 't1' },
    { id: 'u3', name: 'Mike Johnson', email: 'mike@acme.com', role: 'TEAM_LEAD', teamId: 't2' },
    { id: 'u4', name: 'Sarah Williams', email: 'sarah@acme.com', role: 'DEPARTMENT_ADMIN', teamId: 't3' },
    { id: 'u5', name: 'Robert Chen', email: 'robert@globalind.com', role: 'CLIENT_ADMIN', teamId: 't4' },
    { id: 'u6', name: 'Lisa Taylor', email: 'lisa@globalind.com', role: 'SUPER_ADMIN', teamId: 't5' }
  ]
};

// Empty initial data structure
const initialData = {
  clients: [],
  departments: [],
  teams: [],
  users: []
};

const AdminPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState(initialData);
  const [isNewUser, setIsNewUser] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'success' });
  const [useMockData, setUseMockData] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  
  // Effect to load mock data on component mount
  useEffect(() => {
    // Load mock data immediately when component mounts
    setData(mockData);
  }, []);

  // Filter data based on search query and active tab
  const getFilteredData = () => {
    if (!searchQuery.trim()) return data;
    
    const query = searchQuery.toLowerCase();
    
    const filteredData = {...data};
    
    switch(activeTab) {
      case 1: // Clients tab
        filteredData.clients = data.clients.filter(client => 
          client.name.toLowerCase().includes(query) || 
          client.abbreviation.toLowerCase().includes(query) ||
          client.tenantId.toLowerCase().includes(query)
        );
        break;
      case 2: // Departments tab
        filteredData.departments = data.departments.filter(dept => 
          dept.name.toLowerCase().includes(query)
        );
        break;
      case 3: // Teams tab
        filteredData.teams = data.teams.filter(team => 
          team.name.toLowerCase().includes(query)
        );
        break;
      case 4: // Users tab
        filteredData.users = data.users.filter(user => 
          user.name.toLowerCase().includes(query) || 
          user.email.toLowerCase().includes(query) || 
          user.role.toLowerCase().includes(query)
        );
        break;
      case 0: // Dashboard (hierarchy view)
      default:
        // For dashboard, we don't filter as it shows the whole hierarchy
        break;
    }
    
    return filteredData;
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Clear search when changing tabs
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setSearchQuery('');
  };
  
  const handleWizardComplete = () => {
    setIsNewUser(false);
    showNotification('Setup completed successfully!', 'success');
  };

  const showNotification = (message, severity = 'success') => {
    setNotification({
      open: true,
      message,
      severity
    });
  };

  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  // Functions for CRUD operations - will connect to API later
  const addClient = (client) => {
    setData({...data, clients: [...data.clients, client]});
    showNotification('Client added successfully');
  };
  
  const addDepartment = (department) => {
    setData({...data, departments: [...data.departments, department]});
    showNotification('Department added successfully');
  };
  
  const addTeam = (team) => {
    setData({...data, teams: [...data.teams, team]});
    showNotification('Team added successfully');
  };
  
  const addUser = (user) => {
    setData({...data, users: [...data.users, user]});
    showNotification('User added successfully');
  };
  
  const updateClient = (updatedClient) => {
    setData({
      ...data,
      clients: data.clients.map(client => 
        client.id === updatedClient.id ? updatedClient : client
      )
    });
    showNotification('Client updated successfully');
  };

  const updateDepartment = (updatedDepartment) => {
    setData({
      ...data,
      departments: data.departments.map(department => 
        department.id === updatedDepartment.id ? updatedDepartment : department
      )
    });
    showNotification('Department updated successfully');
  };

  const updateTeam = (updatedTeam) => {
    setData({
      ...data,
      teams: data.teams.map(team => 
        team.id === updatedTeam.id ? updatedTeam : team
      )
    });
    showNotification('Team updated successfully');
  };

  const updateUser = (updatedUser) => {
    setData({
      ...data,
      users: data.users.map(user => 
        user.id === updatedUser.id ? updatedUser : user
      )
    });
    showNotification('User updated successfully');
  };

  const deleteClient = (clientId) => {
    setData({
      ...data,
      clients: data.clients.filter(client => client.id !== clientId),
      // Also remove related departments
      departments: data.departments.filter(dept => dept.clientId !== clientId)
    });
    showNotification('Client and related departments removed');
  };

  const deleteDepartment = (departmentId) => {
    setData({
      ...data,
      departments: data.departments.filter(dept => dept.id !== departmentId),
      // Also remove related teams
      teams: data.teams.filter(team => team.departmentId !== departmentId)
    });
    showNotification('Department and related teams removed');
  };

  const deleteTeam = (teamId) => {
    setData({
      ...data,
      teams: data.teams.filter(team => team.id !== teamId),
      // Also reassign or remove users from this team
      users: data.users.filter(user => user.teamId !== teamId)
    });
    showNotification('Team and team members removed');
  };

  const deleteUser = (userId) => {
    setData({
      ...data,
      users: data.users.filter(user => user.id !== userId)
    });
    showNotification('User removed');
  };
  
  const updateHierarchy = (updatedData) => {
    setData(updatedData);
    showNotification('Hierarchy updated successfully');
  };

  const toggleSetupWizard = () => {
    setIsNewUser(!isNewUser);
  };

  const clearAllData = () => {
    setData(initialData);
    showNotification('All data has been cleared', 'info');
  };

  const loadMockData = () => {
    setUseMockData(true);
  };

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" component="h1" gutterBottom sx={{ mt: 4 }}>
        Organization Management
      </Typography>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
        <Button 
          variant="outlined" 
          color={isNewUser ? "secondary" : "primary"} 
          onClick={toggleSetupWizard}
        >
          {isNewUser ? 'Exit Setup Wizard' : 'Launch Setup Wizard'}
        </Button>
      </Box>
      
      {isNewUser ? (
        <SetupWizard 
          onComplete={handleWizardComplete} 
          addClient={addClient}
          addDepartment={addDepartment}
          addTeam={addTeam}
          addUser={addUser}
          existingData={data}
        />
      ) : (
        <Paper sx={{ mt: 3, p: 2 }}>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Dashboard" />
            <Tab label="Clients" />
            <Tab label="Departments" />
            <Tab label="Teams" />
            <Tab label="Users" />
          </Tabs>
          
          {/* Search Bar - Only show for data tabs, not for dashboard */}
          {activeTab > 0 && (
            <Box sx={{ mt: 2, mb: 1 }}>
              <TextField
                fullWidth
                placeholder={`Search ${activeTab === 1 ? 'clients' : 
                              activeTab === 2 ? 'departments' : 
                              activeTab === 3 ? 'teams' : 'users'}`}
                value={searchQuery}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                size="small"
              />
            </Box>
          )}
          
          <Box sx={{ mt: 3, p: 2 }}>
            {activeTab === 0 && (
              <HierarchyView 
                data={data} 
                updateHierarchy={updateHierarchy} 
              />
            )}
            {activeTab === 1 && (
              <ClientManagement 
                clients={getFilteredData().clients} 
                addClient={addClient}
                updateClient={updateClient}
                deleteClient={deleteClient}
                showNotification={showNotification}
              />
            )}
            {activeTab === 2 && (
              <DepartmentManagement 
                departments={getFilteredData().departments} 
                clients={data.clients} 
                addDepartment={addDepartment}
                updateDepartment={updateDepartment}
                deleteDepartment={deleteDepartment}
                showNotification={showNotification}
              />
            )}
            {activeTab === 3 && (
              <TeamManagement 
                teams={getFilteredData().teams} 
                departments={data.departments} 
                addTeam={addTeam}
                updateTeam={updateTeam}
                deleteTeam={deleteTeam}
                showNotification={showNotification}
              />
            )}
            {activeTab === 4 && (
              <UserManagement 
                users={getFilteredData().users} 
                teams={data.teams} 
                departments={data.departments} 
                addUser={addUser}
                updateUser={updateUser}
                deleteUser={deleteUser}
                showNotification={showNotification}
              />
            )}
          </Box>
        </Paper>
      )}

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseNotification} 
          severity={notification.severity} 
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminPage;
