import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button, List, ListItem, ListItemText,
  Paper, TextField, Dialog, DialogTitle, DialogContent,
  DialogActions, IconButton, Tooltip, Tab, Tabs, Grid,
  Card, CardContent, CardActions, Collapse, Switch, FormControlLabel, Select, MenuItem, FormControl, InputLabel, Chip, Alert, AlertTitle
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BlockIcon from '@mui/icons-material/Block';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams, useNavigate } from 'react-router-dom';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, 
  Tooltip as ChartTooltip, ResponsiveContainer
} from 'recharts';
import { getApiKeys, createApiKey, deleteApiKey, revokeApiKey, updateApiKey } from '../api/services/deployment/Deployment';

const UsageChart = ({ usage }) => {
  // Calculate moving average for trend line
  const movingAverage = usage.map((day, idx, arr) => {
    const window = arr.slice(Math.max(0, idx - 6), idx + 1);
    const avg = window.reduce((sum, d) => sum + d.requests, 0) / window.length;
    return { ...day, average: Math.round(avg) };
  });

  return (
    <Box sx={{ height: 300, mt: 2 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart 
          data={movingAverage} 
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#333" />
          <XAxis 
            dataKey="date" 
            tickFormatter={(date) => new Date(date).toLocaleDateString()}
            style={{ fontSize: '0.75rem' }}
            stroke="#999"
          />
          <YAxis 
            style={{ fontSize: '0.75rem' }} 
            stroke="#999"
            tickFormatter={(value) => value.toLocaleString()}
          />
          <ChartTooltip 
            contentStyle={{ 
              backgroundColor: 'rgba(33, 33, 33, 0.95)',
              border: 'none',
              borderRadius: 8,
              boxShadow: '0 2px 10px rgba(0,0,0,0.3)',
              color: '#fff'
            }}
            formatter={(value, name) => [
              `${value.toLocaleString()} requests`, 
              name === 'average' ? '7-day Average' : 'Daily Usage'
            ]}
            labelFormatter={(label) => new Date(label).toLocaleDateString()}
          />
          <Line 
            type="monotone" 
            dataKey="requests" 
            stroke="#2196f3"
            strokeWidth={2}
            dot={false}
            activeDot={{ r: 6 }}
          />
          <Line 
            type="monotone" 
            dataKey="average" 
            stroke="#4caf50"
            strokeWidth={2}
            dot={false}
            strokeDasharray="5 5"
          />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

const KeyDialog = ({ open, onClose, onSave }) => {
  const [formData, setFormData] = useState({
    role: 'user',
    expiry: 30,
    rate_limit: 50
  });

  const handleChange = (field) => (event) => {
    let value = event.target.value;
    
    // Handle numeric fields
    if (field === 'expiry' || field === 'rate_limit') {
      // Remove leading zeros
      value = value.replace(/^0+/, '');
      // If empty or invalid, use empty string instead of converting to 0
      value = value === '' ? '' : parseInt(value, 10);
      
      // Additional validation for rate_limit
      if (field === 'rate_limit' && value !== '') {
        if (value > 50) value = 50;
        if (value < 0) value = 0;
      }
    }

    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const isValid = () => {
    return formData.expiry > 0 && 
           Number.isInteger(formData.expiry) &&
           formData.rate_limit > 0 && 
           formData.rate_limit <= 50 &&
           Number.isInteger(formData.rate_limit);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Create New API Key</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Role</InputLabel>
            <Select
              value={formData.role}
              onChange={handleChange('role')}
              label="Role"
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Expiry (days)"
            type="number"
            fullWidth
            value={formData.expiry}
            onChange={handleChange('expiry')}
            InputProps={{
              inputProps: { 
                min: 1,
                step: 1 // Ensures only integer values
              }
            }}
            helperText="Must be a positive integer"
          />
          <TextField
            label="Daily Rate Limit"
            type="number"
            fullWidth
            value={formData.rate_limit}
            onChange={handleChange('rate_limit')}
            InputProps={{
              inputProps: { 
                min: 1,
                max: 50,
                step: 1
              }
            }}
            helperText="Must be a positive integer (max 50)"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={() => onSave(formData)} 
          variant="contained"
          disabled={!isValid()}
        >
          Create Key
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const KeyItem = ({ keyData, onDelete }) => {
  return (
    <ListItem
      sx={{ 
        borderBottom: '1px solid',
        borderColor: 'divider',
        py: 2
      }}
    >
      <ListItemText
        primary={
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography variant="subtitle1">
              {keyData.id}
            </Typography>
            <Chip 
              label={keyData.role}
              color={keyData.role === 'admin' ? 'primary' : 'default'}
              size="small"
            />
            {!keyData.is_active && (
              <Chip label="Inactive" color="error" size="small" />
            )}
          </Box>
        }
        secondary={
          <Box sx={{ mt: 1 }}>
            <Typography variant="body2" color="text.secondary">
              Rate Limit: {keyData.rate_limit || 'N/A'} requests/day
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Created: {new Date(keyData.created_at).toLocaleString()}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Expires: {new Date(keyData.expires_at).toLocaleString()}
            </Typography>
          </Box>
        }
      />
      <IconButton onClick={() => onDelete(keyData.id)}>
        <DeleteIcon />
      </IconButton>
    </ListItem>
  );
};

const KeyManager = () => {
  const { serverId } = useParams();
  const navigate = useNavigate();
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedServer, setSelectedServer] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newKeyData, setNewKeyData] = useState(null);

  useEffect(() => {
    fetchKeys();
  }, []);

  const fetchKeys = async () => {
    try {
      setLoading(true);
      const response = await getApiKeys();
      // Extract the keys array from the nested response structure
      const keysArray = response?.data?.keys || [];
      setKeys(keysArray);
    } catch (error) {
      console.error('Failed to fetch API keys:', error);
      setError('Failed to load API keys. Please try again later.');
      setKeys([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateKey = async (formData) => {
    try {
      const response = await createApiKey(formData);
      setNewKeyData(response.api_key);
      await fetchKeys(); // Refresh the list
      setDialogOpen(false);
    } catch (error) {
      console.error('Failed to create API key:', error);
      setError('Failed to create API key. Please try again.');
    }
  };

  const handleDeleteKey = async (keyId) => {
    try {
      await deleteApiKey(keyId);
      await fetchKeys();
    } catch (error) {
      console.error('Failed to delete API key:', error);
      setError('Failed to delete API key. Please try again.');
    }
  };

  const handleCopyKey = async (key) => {
    try {
      await navigator.clipboard.writeText(key);
      // Optional: You could add a state to show a "Copied!" message
    } catch (error) {
      console.error('Failed to copy key:', error);
    }
  };

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      overflow: 'hidden',
      p: 2
    }}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h5">API Keys</Typography>
          <Button
            variant="contained"
            onClick={() => setDialogOpen(true)}
            startIcon={<AddIcon />}
          >
            Create New Key
          </Button>
        </Box>
        
        {newKeyData && (
          <Alert 
            severity="success" 
            sx={{ mb: 2 }}
            onClose={() => setNewKeyData(null)}
          >
            <AlertTitle>API Key Created Successfully</AlertTitle>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              bgcolor: 'rgba(0, 0, 0, 0.04)',
              p: 1,
              borderRadius: 1,
              my: 1
            }}>
              <Typography 
                component="code" 
                sx={{ 
                  flexGrow: 1,
                  fontFamily: 'monospace',
                  fontSize: '0.9rem'
                }}
              >
                {newKeyData}
              </Typography>
              <IconButton 
                size="small"
                onClick={() => handleCopyKey(newKeyData)}
                title="Copy to clipboard"
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography variant="body2">
              Make sure to copy this key now. You won't be able to see it again!
            </Typography>
          </Alert>
        )}
      </Box>

      <Paper sx={{ 
        flexGrow: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column'
      }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography variant="body1" color="error" sx={{ p: 2 }}>{error}</Typography>
        ) : (
          <List sx={{ 
            flexGrow: 1,
            overflow: 'auto',
            '& > :last-child': {
              borderBottom: 'none'
            }
          }}>
            {keys.map((key) => (
              <KeyItem
                key={key.id}
                keyData={key}
                onDelete={handleDeleteKey}
              />
            ))}
          </List>
        )}
      </Paper>

      <KeyDialog 
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onSave={handleCreateKey}
      />
    </Box>
  );
};

export default KeyManager;
