import React, { useState } from 'react';
import { Box, Grid, Typography, Button, IconButton } from '@mui/material';
import { 
  Settings as SettingsIcon, 
  Person as PersonIcon,
  Add as AddIcon,
  Computer as ComputerIcon,
  Code as CodeIcon
} from '@mui/icons-material';
import ServerHealthChart from '../dashboard/ServerHealthChart';
import Notifications from '../dashboard/Notifications';
import TrainingJobs from '../dashboard/TrainingJobs';
import SystemActivity from '../dashboard/SystemActivity';
import DeployedAgents from '../dashboard/DeployedAgents';
import ServerGridDisplay from '../dashboard/ServerGridDisplay';

export default function Dashboard() {
  const [selectedMetric, setSelectedMetric] = useState('cpuUsage');
  const [selectedTimeframe, setSelectedTimeframe] = useState('hour');

  return (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column',
      p: { xs: 1.5, sm: 2.5 },
      overflow: 'auto',
      backgroundColor: 'transparent',
      color: 'white',
      fontFamily: '"Inter", "IBM Plex Sans", sans-serif',
    }}>
      {/* Header Section */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between', 
        alignItems: { xs: 'stretch', sm: 'center' },
        mb: 2.5,
        py: 1.5,
        borderBottom: '1px solid rgba(255, 255, 255, 0.08)'
      }}>
        <Typography variant="h4">Dashboard</Typography>

        
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1
        }}>
          <Button 
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ 
              borderRadius: 2,
              backgroundColor: '#10B981',
              fontWeight: 500,
              boxShadow: 'none',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#0EA875',
                boxShadow: '0 0 0 3px rgba(16, 185, 129, 0.15)'
              }
            }}
          >
            New Training
          </Button>
          
          <Button 
            variant="contained"
            startIcon={<ComputerIcon />}
            sx={{ 
              borderRadius: 2,
              backgroundColor: '#3B82F6',
              fontWeight: 500,
              boxShadow: 'none',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#2563EB',
                boxShadow: '0 0 0 3px rgba(59, 130, 246, 0.15)'
              }
            }}
          >
            Add Server
          </Button>
          
          <Button 
            variant="contained"
            startIcon={<CodeIcon />}
            sx={{ 
              borderRadius: 2,
              backgroundColor: '#8B5CF6',
              fontWeight: 500,
              boxShadow: 'none',
              textTransform: 'none',
              '&:hover': {
                backgroundColor: '#7C3AED',
                boxShadow: '0 0 0 3px rgba(139, 92, 246, 0.15)'
              }
            }}
          >
            Agent Studio
          </Button>
        </Box>
      </Box>

      {/* Main Content */}
      <Grid container spacing={2.5}>
        {/* Left Column - 50/50 split */}
        <Grid item xs={12} md={4}>
          {/* Global Server Health Score - 50% */}
          <Box sx={{ height: 'calc(50vh - 110px)', mb: 2.5 }}>
            <ServerHealthChart 
              selectedTimeframe={selectedTimeframe}
              setSelectedTimeframe={setSelectedTimeframe}
            />
          </Box>
          
          {/* Server Grid Display - 50% */}
          <Box sx={{ height: 'calc(50vh - 110px)' }}>
            <ServerGridDisplay 
              selectedMetric={selectedMetric}
              setSelectedMetric={setSelectedMetric}
            />
          </Box>
        </Grid>
        
        {/* Middle Column - 2/3 and 1/3 split */}
        <Grid item xs={12} md={4}>
          {/* Notifications - 2/3 */}
          <Box sx={{ height: 'calc(67vh - 120px)', mb: 2.5 }}>
            <Notifications />
          </Box>
          
          {/* System Activity - 1/3 */}
          <Box sx={{ height: 'calc(33vh - 100px)' }}>
            <SystemActivity />
          </Box>
        </Grid>
        
        {/* Right Column - 50/50 split */}
        <Grid item xs={12} md={4}>
          {/* Training Jobs - 50% */}
          <Box sx={{ height: 'calc(50vh - 110px)', mb: 2.5 }}>
            <TrainingJobs />
          </Box>
          
          {/* Deployed Agents - 50% */}
          <Box sx={{ height: 'calc(50vh - 110px)' }}>
            <DeployedAgents />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
