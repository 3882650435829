import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { keycloak, initOptions } from "./Keycloak";
import { theme } from './components/helpers/theme';
import { useKeycloak } from '@react-keycloak/web';
import DrawerContent from './components/helpers/DrawerContent';

// Import all page components
import LandingPage from './components/pages/LandingPage';
import Home from './components/pages/Home';
import Dashboard from './components/pages/Dashboard';
import Models from './components/pages/Models';
import Training from './components/pages/Training';
import CloudManagement from './components/pages/CloudManagement';
import Marketplace from './components/pages/Marketplace';
import MeetingSummarizer from './components/marketplace/MeetingSummarizer';
import Monitoring from './components/cloudmanagement/Monitoring';
import DiscoverInstances from './components/cloudmanagement/DiscoverInstances';
import TrainingJobMonitoring from './components/training/TrainingJobMonitoring';
import Registration from './components/authentication/Registration';
import PaaS from './components/pages/PaaS';
import Settings from './components/pages/Settings';
import Test from './components/pages/Test';
import HashCat from './components/marketplace/HashCat';
import KeyManager from './components/deployment/KeyManager';
import Deployment from './components/pages/Deployment';
import RAGUpload from './components/deployment/RAGUpload';
import PrivateRoute from './components/authentication/PrivateRoute';
import ChatAgent from './components/marketplace/ChatAgent';
import ManageDeployment from './components/deployment/ManageDeployment';
import AdminPage from './components/pages/AdminPage';

const drawerWidth = 200;
const collapsedDrawerWidth = 60;

function AppContent() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { keycloak } = useKeycloak();

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);
  const handleDrawerCollapseToggle = () => setIsCollapsed(!isCollapsed);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', m: 0, p: 0 }}>
        <CssBaseline />
        {keycloak.authenticated && (
          <Box component="nav" sx={{ width: { sm: isCollapsed ? collapsedDrawerWidth : drawerWidth }, flexShrink: { sm: 0 } }}>
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
              sx={{ display: { xs: 'block', sm: 'none' }, '& .MuiDrawer-paper': { 
                boxSizing: 'border-box', 
                width: isCollapsed ? collapsedDrawerWidth : drawerWidth 
              }}}
            >
              <DrawerContent isCollapsed={isCollapsed} onToggleCollapse={handleDrawerCollapseToggle} />
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{ display: { xs: 'none', sm: 'block' }, '& .MuiDrawer-paper': { 
                boxSizing: 'border-box', 
                width: isCollapsed ? collapsedDrawerWidth : drawerWidth 
              }}}
              open
            >
              <DrawerContent isCollapsed={isCollapsed} onToggleCollapse={handleDrawerCollapseToggle} />
            </Drawer>
          </Box>
        )}

        <Box component="main" sx={{ 
          flexGrow: 1, 
          p: 3, 
          pt: 0, 
          width: keycloak.authenticated ? { sm: `calc(100% - ${isCollapsed ? collapsedDrawerWidth : drawerWidth}px)` } : '100%'
        }}>
          {keycloak.authenticated && (
            <Toolbar sx={{ 
              minHeight: { xs: '48px', sm: '0px' },
              display: { xs: 'flex', sm: 'none' }
            }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
          )}

          <Routes>
            {/* Public routes */}
            <Route path="/" element={keycloak.authenticated ? <PrivateRoute><Home /></PrivateRoute> : <LandingPage />} />
            <Route path="/register" element={<Registration />} />

            {/* Protected routes */}
            <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/models" element={<PrivateRoute><Models /></PrivateRoute>} />
            <Route path="/training" element={<PrivateRoute><Training /></PrivateRoute>} />
            <Route path="/training/monitor/:jobId" element={<PrivateRoute><TrainingJobMonitoring /></PrivateRoute>} />
            <Route path="/cloud-management" element={<PrivateRoute><CloudManagement title="Cloud Management" /></PrivateRoute>} />
            <Route path="/discover-instances" element={<PrivateRoute><DiscoverInstances /></PrivateRoute>} />
            <Route path="/marketplace" element={<PrivateRoute><Marketplace /></PrivateRoute>} />
            <Route path="/meeting-summarizer" element={<PrivateRoute><MeetingSummarizer /></PrivateRoute>} />
            <Route path="/hashcat" element={<PrivateRoute><HashCat /></PrivateRoute>} />
            <Route path="/paas" element={<PrivateRoute><PaaS /></PrivateRoute>} />
            <Route path="/monitoring/:serverIp/:port/:hostname" element={<PrivateRoute><Monitoring /></PrivateRoute>} />
            <Route path="/test" element={<PrivateRoute><Test /></PrivateRoute>} />
            <Route path="/deployment" element={<PrivateRoute><Deployment /></PrivateRoute>} />
            <Route path="/api-keys/:serverId" element={<PrivateRoute><KeyManager /></PrivateRoute>} />
            <Route path="/rag-upload/:serverId" element={<PrivateRoute><RAGUpload /></PrivateRoute>} />
            <Route path="/chat-agent" element={<PrivateRoute><ChatAgent /></PrivateRoute>} />
            <Route path="/settings" element={<PrivateRoute><Settings /></PrivateRoute>} />
            <Route path="/deployments/:deploymentId/manage" element={<PrivateRoute><ManageDeployment /></PrivateRoute>} />
            <Route path="/admin" element={<PrivateRoute><AdminPage /></PrivateRoute>} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function App() {
  return (
    <ReactKeycloakProvider authClient={keycloak} initOptions={initOptions}>
      <Router>
        <AppContent />
      </Router>
    </ReactKeycloakProvider>
  );
}

export default App;
