import React, { useState } from 'react';
import { Card, CardContent, Avatar, List, ListItem, ListItemAvatar, ListItemText, Box, Tab, Tabs, Typography } from '@mui/material';
import { 
  Warning as WarningIcon, 
  Error as ErrorIcon, 
  Check as CheckIcon,
  Memory as MemoryIcon,
  LinearScale as LinearScaleIcon,
  Code as CodeIcon,
  Info as InfoIcon,
  Storage as StorageIcon
} from '@mui/icons-material';

export const notifications = [
  { id: 1, type: 'alerts', message: 'High CPU usage warning', details: 'on Production Server 1', severity: 'warning', icon: <WarningIcon fontSize="small" />, time: '2 min ago' },
  { id: 2, type: 'alerts', message: 'GPU temperature error', details: 'on Staging Server', severity: 'error', icon: <ErrorIcon fontSize="small" />, time: '15 min ago' },
  { id: 3, type: 'alerts', message: 'Memory usage exceeding threshold', details: 'on Data Processing Node', severity: 'warning', icon: <WarningIcon fontSize="small" />, time: '37 min ago' },
  { id: 4, type: 'alerts', message: 'Network latency spike', details: 'on Inference Cluster', severity: 'warning', icon: <WarningIcon fontSize="small" />, time: '1 hour ago' },
  { id: 5, type: 'alerts', message: 'Disk space critical', details: 'on Storage Server 2', severity: 'error', icon: <ErrorIcon fontSize="small" />, time: '2 hours ago' },
  { id: 6, type: 'alerts', message: 'Resolved Issue', details: 'on Backup Server', severity: 'success', icon: <CheckIcon fontSize="small" />, time: '3 hours ago' },
  { id: 7, type: 'alerts', message: 'API rate limit reached', details: 'on External API Gateway', severity: 'warning', icon: <WarningIcon fontSize="small" />, time: '4 hours ago' },
  { id: 8, type: 'alerts', message: 'Database connection timeout', details: 'on Vector DB Cluster', severity: 'error', icon: <ErrorIcon fontSize="small" />, time: '5 hours ago' }
];

export const aiEvents = [
  { id: 1, type: 'events', message: 'PioAgent completed training', details: 'Model version 2.4.0 ready', severity: 'success', icon: <MemoryIcon fontSize="small" />, time: '1 hour ago' },
  { id: 2, type: 'events', message: 'HiringAgent deployed to production', details: 'v1.2.3 successfully deployed', severity: 'success', icon: <LinearScaleIcon fontSize="small" />, time: '3 hours ago' },
  { id: 3, type: 'events', message: 'New fine-tuning started', details: 'CustomerSupportAgent on GPU cluster', severity: 'info', icon: <InfoIcon fontSize="small" />, time: '5 hours ago' },
  { id: 4, type: 'events', message: 'Model evaluation completed', details: 'SalesAgent accuracy: 92.7%', severity: 'success', icon: <CheckIcon fontSize="small" />, time: '8 hours ago' },
  { id: 5, type: 'events', message: 'Training halted', details: 'CodeAssistant resource allocation', severity: 'warning', icon: <WarningIcon fontSize="small" />, time: '12 hours ago' },
  { id: 6, type: 'events', message: 'New model version registered', details: 'LegalResearchAgent v0.9.5', severity: 'info', icon: <CodeIcon fontSize="small" />, time: '1 day ago' }
];

export const logEntries = [
  { id: 1, type: 'logs', message: 'System backup completed', details: 'Full snapshot stored to cloud', severity: 'info', icon: <StorageIcon fontSize="small" />, time: '30 min ago' },
  { id: 2, type: 'logs', message: 'User authentication failed', details: 'admin@company.com - 3 attempts', severity: 'warning', icon: <WarningIcon fontSize="small" />, time: '45 min ago' },
  { id: 3, type: 'logs', message: 'Database migration complete', details: 'Upgraded to version 4.2.1', severity: 'success', icon: <CheckIcon fontSize="small" />, time: '2 hours ago' },
  { id: 4, type: 'logs', message: 'New user registered', details: 'johndoe@company.com', severity: 'info', icon: <InfoIcon fontSize="small" />, time: '3 hours ago' },
  { id: 5, type: 'logs', message: 'API key rotation', details: 'All service keys updated', severity: 'info', icon: <InfoIcon fontSize="small" />, time: '4 hours ago' },
  { id: 6, type: 'logs', message: 'Storage optimization complete', details: 'Recovered 1.2TB of space', severity: 'success', icon: <CheckIcon fontSize="small" />, time: '6 hours ago' },
  { id: 7, type: 'logs', message: 'Scheduled maintenance started', details: 'Cluster 3 resources offline', severity: 'warning', icon: <WarningIcon fontSize="small" />, time: '8 hours ago' }
];

export default function Notifications() {
  const [activeTab, setActiveTab] = useState('alerts');

  const handleTabChange = (_, newValue) => {
    setActiveTab(newValue);
  };

  const renderNotificationList = (items) => (
    <List dense disablePadding sx={{ maxHeight: '100%', overflow: 'auto' }}>
      {items.map((item) => (
        <ListItem 
          key={item.id} 
          sx={{ 
            py: 1.75,
            px: 3,
            borderBottom: '1px solid rgba(255, 255, 255, 0.03)'
          }}
        >
          <ListItemAvatar sx={{ minWidth: 42 }}>
            <Avatar sx={{ 
              bgcolor: item.severity === 'error' ? '#EF4444' : 
                      item.severity === 'warning' ? '#F59E0B' : 
                      item.severity === 'success' ? '#10B981' : '#3B82F6',
              width: 32,
              height: 32
            }}>
              {item.icon}
            </Avatar>
          </ListItemAvatar>
          <ListItemText 
            primary={
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body2" fontWeight={500} color="white" fontSize="0.9rem">
                  {item.message}
                </Typography>
                <Typography variant="caption" color="rgba(255, 255, 255, 0.5)" fontSize="0.75rem">
                  {item.time}
                </Typography>
              </Box>
            }
            secondary={item.details}
            secondaryTypographyProps={{
              fontSize: '0.8125rem',
              color: 'rgba(255, 255, 255, 0.6)',
              mt: 0.5
            }}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      backgroundColor: '#1a1a1a',
      color: 'white',
      borderRadius: 3,
      boxShadow: 'none',
      border: '1px solid rgba(255, 255, 255, 0.05)'
    }}>
      <Box sx={{ px: 3, pt: 3, pb: 0 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'rgba(255, 255, 255, 0.05)' }}>
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange} 
            aria-label="notification tabs"
            textColor="inherit"
            TabIndicatorProps={{
              style: {
                backgroundColor: '#10B981'
              }
            }}
            sx={{
              '& .MuiTab-root': {
                color: 'rgba(255, 255, 255, 0.6)',
                fontWeight: 500,
                padding: '12px 16px',
                minWidth: 'auto',
                textTransform: 'none',
                fontSize: '0.9rem',
                marginRight: 2
              },
              '& .Mui-selected': {
                color: '#10B981',
                fontWeight: 600
              }
            }}
          >
            <Tab label="Alerts" value="alerts" />
            <Tab label="AI Events" value="events" />
            <Tab label="Logs" value="logs" />
          </Tabs>
        </Box>
      </Box>
      <CardContent sx={{ 
        flex: 1,
        overflowY: 'auto',
        p: 0,
        '&:last-child': { pb: 0 }
      }}>
        {activeTab === 'alerts' && renderNotificationList(notifications)}
        {activeTab === 'events' && renderNotificationList(aiEvents)}
        {activeTab === 'logs' && renderNotificationList(logEntries)}
      </CardContent>
    </Card>
  );
}
