import React from 'react';
import { Card, CardContent, Typography, Box, useTheme, Select, MenuItem } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';

// More data points for a smoother line
export const healthScoreData = [
  { time: '00:00', score: 85 },
  { time: '02:00', score: 83 },
  { time: '04:00', score: 82 },
  { time: '06:00', score: 85 },
  { time: '08:00', score: 88 },
  { time: '10:00', score: 89 },
  { time: '12:00', score: 90 },
  { time: '14:00', score: 89 },
  { time: '16:00', score: 87 },
  { time: '18:00', score: 86 },
  { time: '20:00', score: 85 },
  { time: '22:00', score: 85 },
  { time: '24:00', score: 86 },
];

export const timeframes = [
  { value: 'hour', label: 'Last Hour' },
  { value: 'day', label: 'Last Day' },
  { value: 'week', label: 'Last Week' }
];

export default function ServerHealthChart({ selectedTimeframe, setSelectedTimeframe }) {
  const theme = useTheme();
  
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            border: '1px solid rgba(255, 255, 255, 0.1)',
            p: 1.5,
            borderRadius: 1.5,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)'
          }}
        >
          <Typography sx={{ fontSize: '0.85rem', color: '#10B981', fontWeight: 600 }}>
            Score: {payload[0].value}
          </Typography>
          <Typography sx={{ fontSize: '0.75rem', color: 'rgba(255, 255, 255, 0.6)', mt: 0.5 }}>
            {payload[0].payload.time}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      backgroundColor: '#1a1a1a',
      color: 'white',
      borderRadius: 3,
      boxShadow: 'none',
      border: '1px solid rgba(255, 255, 255, 0.05)'
    }}>
      <CardContent sx={{ 
        p: 0,
        '&:last-child': { pb: 0 },
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
        <Box sx={{ 
          px: 2, 
          py: 1.5, 
          borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1rem' }}>
            Global Server Health
          </Typography>
          <Select
            size="small"
            value={selectedTimeframe}
            onChange={(e) => setSelectedTimeframe(e.target.value)}
            sx={{ 
              minWidth: 110, 
              height: 32,
              '& .MuiOutlinedInput-root': { 
                borderRadius: 1.5,
              },
              '& .MuiSelect-select': { 
                fontSize: '0.75rem',
                py: 0.5,
                px: 1.5,
              }
            }}
          >
            {timeframes.map((timeframe) => (
              <MenuItem key={timeframe.value} value={timeframe.value}>
                {timeframe.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        
        <Box sx={{ 
          flex: 1, 
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart 
              data={healthScoreData}
              margin={{ top: 10, right: 20, bottom: 25, left: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.1)" />
              <XAxis 
                dataKey="time" 
                tick={{ fontSize: 10, fill: 'rgba(255, 255, 255, 0.7)' }}
                stroke="rgba(255, 255, 255, 0.2)"
                tickLine={{ stroke: 'rgba(255, 255, 255, 0.2)' }}
                axisLine={{ stroke: 'rgba(255, 255, 255, 0.2)' }}
                dy={5}
              />
              <YAxis
                domain={[70, 100]}
                tick={{ fontSize: 10, fill: 'rgba(255, 255, 255, 0.7)' }}
                stroke="rgba(255, 255, 255, 0.2)"
                tickCount={4}
                tickFormatter={(value) => value}
                tickLine={{ stroke: 'rgba(255, 255, 255, 0.2)' }}
                axisLine={{ stroke: 'rgba(255, 255, 255, 0.2)' }}
                orientation="left"
                dx={-5}
              />
              <Tooltip content={<CustomTooltip />} />
              <ReferenceLine y={90} stroke="#10B981" strokeDasharray="3 3" />
              <ReferenceLine y={80} stroke="#FBBF24" strokeDasharray="3 3" />
              <ReferenceLine y={75} stroke="#EF4444" strokeDasharray="3 3" />
              <Line
                type="monotone"
                dataKey="score"
                stroke="#10B981"
                strokeWidth={3}
                dot={{ r: 0, strokeWidth: 0 }}
                activeDot={{ r: 6, fill: '#10B981' }}
                animationDuration={1500}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
} 