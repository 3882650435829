import React, { useState, useEffect } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  Box,
  Paper,
  Chip,
  FormControlLabel,
  Checkbox,
  Divider,
} from '@mui/material';
import { getUserInventory } from '../api/services/computeService/Inventory';
import { useKeycloak } from '@react-keycloak/web';

const ModelDeployment = ({selectedServer, setSelectedServer, handleInitiateTraining }) => {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    const fetchServers = async () => {
      try {
        await keycloak.updateToken(30);
        setLoading(true);
        const response = await getUserInventory(keycloak.subject, keycloak.token);
        
        if (response.success) {
          const transformedServers = response.data.map(server => ({
            id: server.id,
            hostname: server.hostname,
            ipAddress: server.ipAddress,
            port: String(server.port),
            status: server.status === "active" ? "Online" : "Offline",
            gpu: server.gpu,
            gpuType: server.gpuType.toUpperCase(),
            numOfGpus: server.numOfGpus,
            numOfCpus: server.numOfCpus,
            ram: server.ram,
            location: server.location
          }));
          
          setServers(transformedServers);
        } else {
          console.error('Failed to fetch servers:', response.error);
        }
      } catch (error) {
        console.error('Error fetching servers:', error);
        if (error.message?.includes('token')) {
          await keycloak.updateToken(30);
        }
      } finally {
        setLoading(false);
      }
    };

    if (keycloak.token) {
      fetchServers();
    }
  }, [keycloak.token, keycloak.subject]);


  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Deploy to Servers</Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              Select a server for deployment
            </Typography>
            
            {loading ? (
              <Typography>Loading servers...</Typography>
            ) : (
              servers.map((server) => (
                <Paper 
                  key={server.id}
                  sx={{ 
                    p: 2, 
                    mb: 2,
                    border: selectedServer?.id === server.id ? '2px solid #1976d2' : '1px solid #e0e0e0',
                    transition: 'all 0.2s ease',
                    '&:hover': {
                      boxShadow: 3,
                      borderColor: '#1976d2'
                    }
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControlLabel
                      control={
                        <Checkbox 
                          checked={selectedServer?.id === server.id}
                          onChange={() => setSelectedServer(server)}
                          disabled={server.status !== "Online"}
                        />
                      }
                      label=""
                    />
                    <Box sx={{ flexGrow: 1 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                        <Typography variant="subtitle1" sx={{ mr: 1 }}>
                          {server.hostname}
                        </Typography>
                        <Chip 
                          label={server.status} 
                          color={server.status === "Online" ? "success" : "error"} 
                          size="small" 
                          sx={{ mr: 1 }}
                        />
                      </Box>
                      <Typography variant="body2" color="text.secondary">
                        {server.gpuType} • {server.gpu}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        IP: {server.ipAddress}:{server.port}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Location: {server.location}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              ))
            )}

            <Button
              variant="contained"
              fullWidth
              disabled={!selectedServer}
              onClick={handleInitiateTraining}
              sx={{ mt: 2 }}
            >
              Deploy to Selected Server
            </Button>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ModelDeployment;
