import React, { useState } from 'react';
import { Typography, Card, CardContent, Grid, TextField, InputAdornment, Button, Tabs, Tab, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNavigate } from 'react-router-dom';
import { marketplaceItems } from '../helpers/MockData';

export default function Marketplace() {
  const [tabValue, setTabValue] = useState(0);
  const [filteredItems, setFilteredItems] = useState(marketplaceItems);
  const navigate = useNavigate();

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    filterItems(newValue);
  };

  const filterItems = (tabIndex) => {
    const categories = ["All", "Productivity", "Analysis", "Integration"];
    const selectedCategory = categories[tabIndex];
    setFilteredItems(
      selectedCategory === "All"
        ? marketplaceItems
        : marketplaceItems.filter(item => item.category === selectedCategory)
    );
  };

  const handleItemClick = (item) => {
    if (item.name === "Meeting Summarizer" && item.installed) {
      navigate('/meeting-summarizer');
    } else if (item.name === "PioChat" && item.installed) {
      navigate('/piochat');
    } else if (item.name === "PaaS" && item.installed) {
      navigate('/paas');
    } else if (item.name === "No-Code AI" && item.installed) {
      navigate('/no-code-ai');
    } else if (item.name === "HashCat" && item.installed) {
      navigate('/hashcat');
    } else if (item.name === "PioAgent" && item.installed) {
      navigate('/chat-agent');
    }
  };

  return (
    <Box sx={{ 
      p: { xs: 1, sm: 2, md: 3 },
      width: '100%',
      height: '100%',
      boxSizing: 'border-box',
      overflow: 'auto'
    }}>
      <Typography variant="h4" 
        gutterBottom 
        sx={{ 
          wordBreak: 'break-word',
          fontSize: { xs: '1.5rem', sm: '2rem', md: '2.125rem' }
        }}>
        Marketplace
      </Typography>
      
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Search apps"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        sx={{ mb: 2 }}
      />
      
      <Box sx={{ 
        borderBottom: 1, 
        borderColor: 'divider',
        width: '100%',
        mb: 2
      }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={true}
          allowScrollButtonsMobile
          sx={{
            maxWidth: '100%',
            '& .MuiTabs-flexContainer': {
              flexWrap: 'wrap'
            }
          }}
        >
          <Tab label="All" />
          <Tab label="Productivity" />
          <Tab label="Analysis" />
          <Tab label="Integration" />
        </Tabs>
      </Box>

      <Grid 
        container 
        sx={{ 
          display: 'grid',
          gridTemplateColumns: {
            xs: 'repeat(auto-fill, minmax(250px, 1fr))',
            sm: 'repeat(auto-fill, minmax(280px, 1fr))',
            md: 'repeat(auto-fit, minmax(200px, 1fr))',
            lg: 'repeat(4, 1fr)'
          },
          gap: { xs: 1, sm: 2 },
          width: '100%',
          margin: '0 auto',
          padding: 0
        }}
      >
        {filteredItems.map((item) => (
          <Card 
            key={item.id}
            onClick={() => handleItemClick(item)} 
            sx={{ 
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              cursor: 'pointer',
              '& .MuiCardContent-root:last-child': {
                paddingBottom: { xs: 1, sm: 2 }
              }
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>{item.name}</Typography>
              {item.installed && (
                <Typography variant="body2" color="success.main" sx={{ mb: 1 }}>
                  Installed
                </Typography>
              )}
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                {item.description}
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <StarIcon sx={{ color: 'gold', mr: 0.5 }} />
                  <Typography variant="body2" sx={{ mr: 1 }}>{item.rating}</Typography>
                </div>
                <Typography variant="body2" color="text.secondary">{item.downloads} downloads</Typography>
              </div>
            </CardContent>
            <CardContent>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={item.installed ? null : <GetAppIcon />}
              >
                {item.installed ? 'Launch' : 'Install'}
              </Button>
            </CardContent>
          </Card>
        ))}
      </Grid>
    </Box>
  );
}
