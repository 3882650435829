import React, { useState } from 'react';
import {
  Box, Paper, Typography, FormControl, InputLabel,
  Select, MenuItem, TextField, Button, Grid, List,
  ListItem, ListItemText, Card, CardContent, Chip,
  Stepper, Step, StepLabel, IconButton, Tabs, Tab,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const hardwareServers = [
  {
    id: 't4',
    name: 'NVIDIA T4',
    specs: '16GB VRAM, 8.1 TFLOPS',
    cost: '$0.35/hour',
    availability: 'High'
  },
  { 
    id: 'a100',
    name: 'NVIDIA A100',
    specs: '80GB VRAM, 19.5 TFLOPS',
    cost: '$3.00/hour',
    availability: 'Medium'
  },
  {
    id: 'h100',
    name: 'NVIDIA H100',
    specs: '80GB VRAM, 26.2 TFLOPS',
    cost: '$5.00/hour',
    availability: 'Limited'
  }
];

const datasets = [
  { id: 1, name: "mlabonne/guanaco-llama2-1k", taskType: "" },
  { id: 2, name: "bitext/Bitext-customer-support-llm-chatbot-training-dataset", taskType: "QA" },
  { id: 3, name: "b-mc2/sql-create-context", taskType: "QA" },
  { id: 4, name: "google/xtreme", taskType: "QA" },
  { id: 5, name: "mandarjoshi/trivia_qa", taskType: "QA" },
  { id: 6, name: "riotu-lab/ArabicQA_2.1M", taskType: "QA" },
  { id: 7, name: "llamafactory/alpaca_gpt4_en", taskType: "QA" },
  { id: 8, name: "Quardo/gpt-4o-qa", taskType: "QA" },
  { id: 9, name: "LimYeri/LeetCode_Python_Solutions_v2", taskType: "QA" },
];

export default function ModelEvaluation({ myModels, publicModels }) {
  const [evaluationType, setEvaluationType] = useState('human'); // 'human' or 'auto'
  const [step, setStep] = useState(0);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedServer, setSelectedServer] = useState(null);
  const [isConfigured, setIsConfigured] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const allModels = [...myModels, ...publicModels];

  const handleStartEvaluation = () => {
    setIsConfigured(true);
  };

  const handleCancel = () => {
    setIsConfigured(false);
    setSelectedModel(null);
    setSelectedServer(null);
    setMessages([]);
    setStep(0);
  };

  const handleModelSelect = (model) => {
    setSelectedModel(model);
  };

  const handleServerSelect = (server) => {
    setSelectedServer(server);
  };

  const handleSendMessage = () => {
    if (input.trim()) {
      const userMessage = { role: 'user', content: input };
      setMessages([...messages, userMessage]);
      setInput('');

      // Simulate model response
      setTimeout(() => {
        const modelResponse = {
          role: 'assistant',
          content: `This is a simulated response from ${selectedModel.name} running on ${selectedServer.name}.`
        };
        setMessages(prev => [...prev, modelResponse]);
      }, 1000);
    }
  };

  const steps = [
    {
      label: 'Select Model',
      component: (
        <ModelSelection
          models={allModels}
          selectedModel={selectedModel}
          onSelect={handleModelSelect}
        />
      )
    },
    {
      label: 'Select Server',
      component: (
        <ServerSelection
          servers={hardwareServers}
          selectedServer={selectedServer}
          onSelect={handleServerSelect}
        />
      )
    },
    {
      label: 'Evaluate',
      component: (
        <EvaluationComponent
          model={selectedModel}
          server={selectedServer}
        />
      )
    }
  ];

  if (!isConfigured) {
    return (
      <Box sx={{ 
        height: 'calc(100vh - 64px)', // Assuming standard AppBar height
        display: 'flex', 
        flexDirection: 'column',
        bgcolor: 'background.default',
        p: { xs: 2, md: 4 }, // Responsive padding
        gap: 3
      }}>
        {/* Header Section */}
        <Box sx={{ 
          p: 3,
          borderRadius: 2,
        }}> 
          <Typography variant="h5" gutterBottom>
            Model Evaluation
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            Quantify your model's performance through human interaction or automated evaluation metrics.
          </Typography>

          <Tabs
            value={evaluationType}
            onChange={(e, val) => setEvaluationType(val)}
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              '& .MuiTab-root': {
                minWidth: 120,
                fontWeight: 500
              }
            }}
          >
            <Tab value="human" label="Human Evaluation" />
            <Tab value="auto" label="Auto Evaluation" />
          </Tabs>
        </Box>

        {/* Main Content Section */}
        <Box sx={{ 
          flex: 1,
          borderRadius: 2,
          overflow: 'hidden',
        }}>
          {evaluationType === 'human' ? (
            <Box sx={{ p: 3 }}>
              <Stepper 
                activeStep={step} 
                sx={{ 
                  mb: 4,
                  '& .MuiStepLabel-label': {
                    fontWeight: 500
                  }
                }}
              >
                {steps.map((s, index) => (
                  <Step key={index}>
                    <StepLabel>{s.label}</StepLabel>
                  </Step>
                ))}
              </Stepper>

              {/* Step Content */}
              <Box sx={{ 
                minHeight: 400,
                display: 'flex',
                flexDirection: 'column'
              }}>
                {steps[step].component}
              </Box>

              {/* Navigation Buttons */}
              <Box sx={{ 
                mt: 4,
                display: 'flex',
                justifyContent: 'space-between'
              }}>
                <Button
                  variant="outlined"
                  disabled={step === 0}
                  onClick={() => setStep(prev => prev - 1)}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  disabled={!canProceed(step, selectedModel, selectedServer)}
                  onClick={() => {
                    if (step < steps.length - 1) {
                      setStep(prev => prev + 1);
                    } else {
                      handleStartEvaluation();
                    }
                  }}
                >
                  {step === steps.length - 1 ? 'Start Evaluation' : 'Next'}
                </Button>
              </Box>
            </Box>
          ) : (
            <AutoEvaluation 
              models={allModels}
              datasets={datasets}
              selectedModel={selectedModel}
              onModelSelect={handleModelSelect}
            />
          )}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      height: 'calc(100vh - 180px)', 
      display: 'flex', 
      flexDirection: 'column',
      p: 3,
      bgcolor: 'background.default'
    }}>
      <Paper sx={{ 
        p: 2, 
        mb: 2, 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        bgcolor: 'background.paper',
        color: 'text.primary'
      }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Chip label={`Model: ${selectedModel.name}`} />
          <Chip label={`Server: ${selectedServer.name}`} />
          <Chip label="Evaluate" />
        </Box>
        <Button
          onClick={handleCancel}
          startIcon={<CloseIcon />}
        >
          Cancel
        </Button>
      </Paper>

      <Paper sx={{ 
        flex: 1,
        display: 'flex', 
        flexDirection: 'column',
        overflow: 'hidden',
        bgcolor: 'background.paper',
        position: 'relative'
      }}>
        {messages.length === 0 && (
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'text.primary',
            width: '100%'
          }}>
            <Typography variant="h4" gutterBottom>
              What can I help with?
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Human Evaluate the Performance of {selectedModel.name}
            </Typography>
          </Box>
        )}

        <Box sx={{ 
          flex: 1, 
          overflow: 'auto', 
          p: 2,
          visibility: messages.length === 0 ? 'hidden' : 'visible'
        }}>
          <List>
            {messages.length === 0 && (
              <ListItem>
                <Box sx={{
                  width: '100%',
                  color: 'white',
                  textAlign: 'center',
                  py: 2
                }}>
                  <Typography variant="body1">
                    Welcome! How can I assist you today?
                  </Typography>
                </Box>
              </ListItem>
            )}
            {messages.map((msg, index) => (
              <ListItem
                key={index}
                sx={{
                  justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start',
                  mb: 1,
                  bgcolor: msg.role === 'user' ? 'background.default' : 'background.paper',
                  py: 2,
                  width: '100%'
                }}
              >
                <Box
                  sx={{
                    maxWidth: '70%',
                    color: 'white',
                    px: 3
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    {msg.role === 'user' ? <PersonIcon /> : <SmartToyIcon />}
                    <Typography variant="caption">
                      {msg.role === 'user' ? 'You' : selectedModel.name}
                    </Typography>
                  </Box>
                  <Typography>{msg.content}</Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Box>

        <Box sx={{ p: 2, borderTop: 1, borderColor: '#565869' }}>
          <Box sx={{ 
            display: 'flex', 
            gap: 1,
            bgcolor: '#40414f',
            borderRadius: '8px',
            p: 1,
            maxWidth: '768px',
            margin: '0 auto'
          }}>
            <TextField
              fullWidth
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Message ChatGPT..."
              variant="standard"
              sx={{
                '& .MuiInput-root': {
                  color: 'white',
                  '&:before, &:after': { display: 'none' }
                },
                '& .MuiInput-input': {
                  p: 1
                }
              }}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
            />
            <IconButton 
              onClick={handleSendMessage}
              sx={{ 
                color: input.trim() ? '#10A37F' : 'white',
                '&:hover': {
                  backgroundColor: 'rgba(16, 163, 127, 0.1)'
                }
              }}
            >
              <SendIcon />
            </IconButton>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}

function ModelSelection({ models, selectedModel, onSelect }) {
  const [modelType, setModelType] = useState('my');
  
  const filteredModels = models.filter(model => 
    modelType === 'my' ? model.hasOwnProperty('accuracy') : !model.hasOwnProperty('accuracy')
  );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {/* Model Type Tabs */}
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6" gutterBottom>Select Model</Typography>
        <Tabs
          value={modelType}
          onChange={(e, val) => setModelType(val)}
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              minWidth: 120,
              fontWeight: 500
            }
          }}
        >
          <Tab value="my" label="My Models" />
          <Tab value="public" label="Public Models" />
        </Tabs>
      </Paper>

      {/* Model Grid */}
      <Grid container spacing={3}>
        {filteredModels.map(model => (
          <Grid item xs={12} sm={6} md={4} key={model.id}>
            <Card
              onClick={() => onSelect(model)}
              sx={{
                cursor: 'pointer',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                border: selectedModel?.id === model.id ? 2 : 1,
                borderColor: selectedModel?.id === model.id ? 'primary.main' : 'divider',
                transition: 'all 0.2s',
                '&:hover': {
                  transform: 'translateY(-4px)',
                  boxShadow: 4
                }
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom noWrap>
                  {model.name}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ 
                    mb: 2,
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden'
                  }}
                >
                  {model.description}
                </Typography>
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  <Chip 
                    size="small" 
                    label={model.parameters}
                    sx={{ bgcolor: 'primary.main', color: 'white' }}
                  />
                  <Chip 
                    size="small" 
                    label={model.modelSize}
                    sx={{ bgcolor: 'secondary.main', color: 'white' }}
                  />
                  {model.accuracy && (
                    <Chip 
                      size="small" 
                      label={`${(model.accuracy * 100).toFixed(1)}% Acc`}
                      sx={{ bgcolor: 'success.main', color: 'white' }}
                    />
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function ServerSelection({ servers, selectedServer, onSelect }) {
  return (
    <Grid container spacing={2}>
      {servers.map(server => (
        <Grid item xs={12} sm={6} md={4} key={server.id}>
          <Card
            onClick={() => onSelect(server)}
            sx={{
              cursor: 'pointer',
              border: selectedServer?.id === server.id ? 2 : 1,
              borderColor: selectedServer?.id === server.id ? 'primary.main' : 'divider'
            }}
          >
            <CardContent>
              <Typography variant="h6" gutterBottom>{server.name}</Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {server.specs}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                <Chip
                  size="small"
                  label={server.availability}
                  color={
                    server.availability === 'High' ? 'success' :
                    server.availability === 'Medium' ? 'warning' :
                    'error'
                  }
                />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

function canProceed(step, selectedModel, selectedServer) {
  switch (step) {
    case 0:
      return selectedModel !== null;
    case 1:
      return selectedServer !== null;
    case 2:
      return true;
    default:
      return false;
  }
}

function EvaluationComponent({ model, server }) {
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Evaluation Configuration
      </Typography>
      
      <Paper sx={{ p: 3, mb: 3 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              Selected Model
            </Typography>
            <Card sx={{ p: 2, bgcolor: 'action.hover' }}>
              <Typography variant="body1">{model.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {model.description}
              </Typography>
              <Box sx={{ mt: 1 }}>
                <Chip size="small" label={model.parameters} sx={{ mr: 1 }} />
                <Chip size="small" label={model.modelSize} />
              </Box>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom>
              Selected Server
            </Typography>
            <Card sx={{ p: 2, bgcolor: 'action.hover' }}>
              <Typography variant="body1">{server.name}</Typography>
              <Typography variant="body2" color="text.secondary">
                {server.specs}
              </Typography>
              <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <Chip
                  size="small"
                  label={server.availability}
                  color={
                    server.availability === 'High' ? 'success' :
                    server.availability === 'Medium' ? 'warning' :
                    'error'
                  }
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="body2" color="text.secondary">
        Click "Start Evaluation" to begin testing your model on the selected hardware.
      </Typography>
    </Box>
  );
}

function AutoEvaluation({ models, datasets, selectedModel, onModelSelect }) {
  const [selectedDataset, setSelectedDataset] = useState(null);
  const [evaluationResults, setEvaluationResults] = useState(null);
  const [evaluationInProgress, setEvaluationInProgress] = useState(false);
  
  const handleStartEvaluation = () => {
    setEvaluationInProgress(true);
    // Simulate evaluation results
    setTimeout(() => {
      const mockResults = [
        { task: 'lambada_openai', version: 1, metric: 'acc', value: 0.7993, stderr: 0.0056, trend: '↑' },
        { task: 'lambada_openai', version: 1, metric: 'perplexity', value: 2.5794, stderr: 0.0423, trend: '↓' },
      ];
      setEvaluationResults(mockResults);
      setEvaluationInProgress(false);
    }, 2000);
  };

  return (
    <Box sx={{ p: 3 }}>
      {/* Configuration Section */}
      <Paper elevation={2} sx={{ p: 3, mb: 4, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
          Evaluation Configuration
        </Typography>
        
        <Grid container spacing={4}>
          {/* Model Selection */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
              Select Model
            </Typography>
            <Card elevation={0} sx={{ 
              p: 2, 
              bgcolor: 'background.default',
              border: 1,
              borderColor: 'divider',
              borderRadius: 2
            }}>
              <FormControl fullWidth>
                <Select
                  value={selectedModel?.id || ''}
                  onChange={(e) => {
                    const model = models.find(m => m.id === e.target.value);
                    onModelSelect(model);
                  }}
                  displayEmpty
                  sx={{ 
                    '& .MuiSelect-select': { 
                      py: 1.5,
                      bgcolor: 'background.paper' 
                    }
                  }}
                >
                  <MenuItem value="" disabled>Select a model</MenuItem>
                  {models.map(model => (
                    <MenuItem key={model.id} value={model.id}>
                      {model.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Card>
          </Grid>

          {/* Dataset Selection */}
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 500 }}>
              Select Dataset
            </Typography>
            <Card elevation={0} sx={{ 
              p: 2, 
              bgcolor: 'background.default',
              border: 1,
              borderColor: 'divider',
              borderRadius: 2
            }}>
              <FormControl fullWidth>
                <Select
                  value={selectedDataset || ''}
                  onChange={(e) => setSelectedDataset(e.target.value)}
                  displayEmpty
                  sx={{ 
                    '& .MuiSelect-select': { 
                      py: 1.5,
                      bgcolor: 'background.paper' 
                    }
                  }}
                >
                  <MenuItem value="" disabled>Select a dataset</MenuItem>
                  {datasets.map(dataset => (
                    <MenuItem key={dataset.id} value={dataset.id}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography>{dataset.name}</Typography>
                        {dataset.taskType && (
                          <Chip 
                            label={dataset.taskType} 
                            size="small"
                            sx={{ bgcolor: 'primary.main', color: 'white' }}
                          />
                        )}
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Card>
          </Grid>
        </Grid>

        <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            size="large"
            disabled={!selectedModel || !selectedDataset || evaluationInProgress}
            onClick={handleStartEvaluation}
            sx={{ 
              minWidth: 200,
              py: 1.5
            }}
          >
            {evaluationInProgress ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CircularProgress size={20} color="inherit" />
                <span>Evaluating...</span>
              </Box>
            ) : (
              'Start Auto Evaluation'
            )}
          </Button>
        </Box>
      </Paper>

      {/* Results Section */}
      {evaluationResults && (
        <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
          <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
            Evaluation Results
          </Typography>
          
          <TableContainer component={Paper} elevation={0} sx={{ 
            mb: 4,
            border: 1,
            borderColor: 'divider',
            borderRadius: 2
          }}>
            <Table>
              <TableHead>
                <TableRow sx={{ bgcolor: 'background.default' }}>
                  <TableCell sx={{ fontWeight: 500 }}>Tasks</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 500 }}>Version</TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>Filter</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 500 }}>n-shot</TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>Metric</TableCell>
                  <TableCell align="right" sx={{ fontWeight: 500 }}>Value</TableCell>
                  <TableCell sx={{ fontWeight: 500 }}>Stderr</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {evaluationResults.map((result, idx) => (
                  <TableRow key={idx} sx={{ 
                    '&:nth-of-type(odd)': { bgcolor: 'background.default' }
                  }}>
                    <TableCell>{result.task}</TableCell>
                    <TableCell align="right">{result.version}</TableCell>
                    <TableCell>none</TableCell>
                    <TableCell align="right">0</TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                        {result.metric}
                        <Typography 
                          color={result.trend === '↑' ? 'success.main' : 'error.main'}
                          sx={{ fontWeight: 500 }}
                        >
                          {result.trend}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 500 }}>
                      {result.value.toFixed(4)}
                    </TableCell>
                    <TableCell sx={{ color: 'text.secondary' }}>
                      ±{result.stderr.toFixed(4)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography variant="h6" gutterBottom sx={{ mb: 3 }}>
            Performance Trends
          </Typography>
          <Box sx={{ 
            p: 3, 
            bgcolor: 'background.default',
            borderRadius: 2,
            border: 1,
            borderColor: 'divider'
          }}>
            <LineChart
              width={800}
              height={400}
              data={evaluationResults}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#565869" />
              <XAxis 
                dataKey="task" 
                stroke="#565869"
                tick={{ fill: '#565869' }}
              />
              <YAxis 
                stroke="#565869"
                tick={{ fill: '#565869' }}
              />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: '#2A2B3A',
                  border: 'none',
                  borderRadius: '8px',
                  color: 'white'
                }}
              />
              <Legend />
              <Line 
                type="monotone" 
                dataKey="value" 
                stroke="#8884d8" 
                name="Metric Value"
                strokeWidth={2}
                dot={{ strokeWidth: 2 }}
              />
            </LineChart>
          </Box>
        </Paper>
      )}
    </Box>
  );
}