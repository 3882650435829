import React, { useState } from 'react';
import { 
  Box, Typography, Stepper, Step, StepLabel, Paper, Button, Grid,
  TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
  Chip, Card, CardContent, List, ListItem, ListItemText, Divider,
  IconButton, Stack, Alert, Tab, Tabs, Checkbox, Dialog, DialogTitle,
  DialogContent, DialogContentText, DialogActions, CircularProgress, Switch
} from '@mui/material';
import { 
  Person as PersonIcon,
  Storage as StorageIcon,
  CloudUpload as CloudUploadIcon,
  Extension as ExtensionIcon,
  Memory as MemoryIcon,
  Cloud as CloudIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  Check as CheckIcon,
  Delete as DeleteIcon,
  Celebration as CelebrationIcon
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { DEMO_MODELS, hardwareOptions } from '../helpers/MockData';
import AgentStatusOverview from './AgentStatusOverview';
import { modelCategories, getModelsByType, getPerformanceColor, getCostDisplay, getModelById } from './ModelMapping';

// Add this near the top of the file with other imports
const ModelCard = ({ model, selected, onSelect }) => {
  return (
    <Card 
      sx={{ 
        cursor: 'pointer',
        border: selected ? '2px solid' : '1px solid',
        borderColor: selected ? 'primary.main' : 'divider',
        height: '100%',
        transition: 'all 0.2s'
      }}
      onClick={() => onSelect(model.id)}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
          <Typography variant="subtitle1">{model.name}</Typography>
          <Chip 
            label={model.provider}
            size="small"
            variant="outlined"
          />
        </Box>
        
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {model.description}
        </Typography>
        
        <Stack direction="row" spacing={1}>
          <Chip 
            label={`Context: ${model.contextWindow}`}
            size="small"
            variant="outlined"
          />
          <Chip 
            label={model.performance}
            size="small"
            color={getPerformanceColor(model.performance)}
          />
          <Chip 
            label={getCostDisplay(model.cost).label}
            size="small"
            color={getCostDisplay(model.cost).color}
          />
          {model.finetuned && (
            <Chip 
              label="Fine-tuned"
              size="small"
              color="secondary"
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

// Step 1: Define the Agent
export const DefineAgent = ({ agentConfig, setAgentConfig, onNext }) => {
  const [templates] = useState([
    { id: 'customer-support', name: 'Customer Support', description: 'Assist customers with product inquiries and issue resolution' },
    { id: 'content-creator', name: 'Content Creator', description: 'Generate and optimize content for marketing and engagement' },
    { id: 'data-analyst', name: 'Data Analyst', description: 'Analyze data and provide insights based on structured information' },
    { id: 'research-assistant', name: 'Research Assistant', description: 'Help with literature review and information summarization' }
  ]);

  const handleTemplateSelect = (templateId) => {
    const template = templates.find(t => t.id === templateId);
    setAgentConfig(prev => ({
      ...prev,
      name: template ? `${template.name} Agent` : prev.name,
      purpose: template ? template.description : prev.purpose,
      templateId: templateId
    }));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Define Your AI Agent</Typography>
      
      <Box sx={{ mb: 4, mt: 3 }}>
        <TextField
          fullWidth
          label="Agent Name"
          value={agentConfig.name}
          onChange={(e) => setAgentConfig(prev => ({ ...prev, name: e.target.value }))}
          sx={{ mb: 3 }}
        />
        
        <TextField
          fullWidth
          label="Purpose"
          value={agentConfig.purpose}
          onChange={(e) => setAgentConfig(prev => ({ ...prev, purpose: e.target.value }))}
          multiline
          rows={2}
          sx={{ mb: 3 }}
        />
        
        <TextField
          fullWidth
          label="Primary Tasks (comma separated)"
          value={agentConfig.tasks}
          onChange={(e) => setAgentConfig(prev => ({ ...prev, tasks: e.target.value }))}
          multiline
          rows={2}
        />
      </Box>
      
      <Typography variant="h6" gutterBottom>Use Case Templates (Optional)</Typography>
      <Grid container spacing={2}>
        {templates.map((template) => (
          <Grid item xs={12} sm={6} key={template.id}>
            <Card 
              sx={{ 
                cursor: 'pointer',
                border: agentConfig.templateId === template.id ? '2px solid' : '1px solid',
                borderColor: agentConfig.templateId === template.id ? 'primary.main' : 'divider',
                transition: 'all 0.2s'
              }}
              onClick={() => handleTemplateSelect(template.id)}
            >
              <CardContent>
                <Typography variant="subtitle1">{template.name}</Typography>
                <Typography variant="body2" color="text.secondary">{template.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          onClick={onNext}
          disabled={!agentConfig.name || !agentConfig.purpose}
          endIcon={<ArrowForwardIcon />}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

// Step 2: Select AI Model Type
export const SelectModelType = ({ agentConfig, setAgentConfig, onNext, onBack }) => {
  const [includeFinetuned, setIncludeFinetuned] = useState(true);
  
  const modelTypes = [
    { 
      id: 'rag', 
      name: 'RAG-Enabled Model', 
      description: 'Best for applications requiring real-time knowledge access and dynamic information retrieval' 
    },
    { 
      id: 'standard', 
      name: 'Standard Model', 
      description: 'Traditional model deployment with optional fine-tuning for specific tasks' 
    }
  ];

  const handleModelTypeSelect = (modelTypeId) => {
    setAgentConfig(prev => ({ 
      ...prev, 
      modelType: modelTypeId,
      selectedModelId: '' 
    }));
  };

  const handleModelSelect = (modelId) => {
    setAgentConfig(prev => ({
      ...prev,
      selectedModelId: modelId
    }));
  };

  const availableModels = agentConfig.modelType ? 
    getModelsByType(agentConfig.modelType, includeFinetuned) : [];

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Select Model Type</Typography>
      
      <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
        Choose between RAG-enabled or standard model deployment
      </Typography>
      
      {/* Model Type Selection Cards */}
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {modelTypes.map((type) => (
          <Grid item xs={12} sm={6} key={type.id}>
            <Card 
              sx={{ 
                cursor: 'pointer',
                border: agentConfig.modelType === type.id ? '2px solid' : '1px solid',
                borderColor: agentConfig.modelType === type.id ? 'primary.main' : 'divider'
              }}
              onClick={() => handleModelTypeSelect(type.id)}
            >
              <CardContent>
                <Typography variant="subtitle1">{type.name}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {type.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {agentConfig.modelType && (
        <>
          <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">
              Select a {modelCategories[agentConfig.modelType].name}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={includeFinetuned}
                  onChange={(e) => setIncludeFinetuned(e.target.checked)}
                />
              }
              label="Include Fine-tuned Models"
            />
          </Box>
          
          {/* Model Selection Cards */}
          <Grid container spacing={2}>
            {availableModels.map((model) => (
              <Grid item xs={12} md={6} key={model.id}>
                <ModelCard
                  model={model}
                  selected={agentConfig.selectedModelId === model.id}
                  onSelect={handleModelSelect}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      
      {/* Navigation Buttons */}
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onBack}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          disabled={!agentConfig.modelType || !agentConfig.selectedModelId}
          endIcon={<ArrowForwardIcon />}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

// Step 3: Add Knowledge & Data Sources
export const AddKnowledge = ({ agentConfig, setAgentConfig, onNext, onBack }) => {
  const [activeTab, setActiveTab] = useState(0);
  
  const handleAddDocument = (documentId) => {
    setAgentConfig(prev => ({
      ...prev,
      documents: prev.documents.includes(documentId)
        ? prev.documents.filter(id => id !== documentId)
        : [...prev.documents, documentId]
    }));
  };
  
  const mockDocuments = [
    { id: 'doc1', name: 'Product Documentation.pdf', type: 'PDF', size: '2.4 MB' },
    { id: 'doc2', name: 'Customer FAQs.docx', type: 'DOCX', size: '1.1 MB' },
    { id: 'doc3', name: 'Sales Data 2023.csv', type: 'CSV', size: '3.7 MB' },
    { id: 'doc4', name: 'API Documentation.json', type: 'JSON', size: '0.8 MB' }
  ];
  
  const mockAPIs = [
    { id: 'api1', name: 'Product Catalog API', endpoint: '/api/products', status: 'Connected' },
    { id: 'api2', name: 'Customer Database', endpoint: '/api/customers', status: 'Not Connected' },
    { id: 'api3', name: 'Weather Service', endpoint: 'https://api.weather.com', status: 'Not Connected' }
  ];

  const handleConnectAPI = (apiId) => {
    setAgentConfig(prev => ({
      ...prev,
      connectedAPIs: prev.connectedAPIs.includes(apiId)
        ? prev.connectedAPIs.filter(id => id !== apiId)
        : [...prev.connectedAPIs, apiId]
    }));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Add Knowledge & Data Sources</Typography>
      
      <Box sx={{ mb: 3 }}>
        <Tabs value={activeTab} onChange={(e, v) => setActiveTab(v)}>
          <Tab label="Documents" />
          <Tab label="APIs" />
          <Tab label="Databases" />
        </Tabs>
      </Box>
      
      {activeTab === 0 && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
            <Button
              variant="outlined"
              startIcon={<CloudUploadIcon />}
            >
              Upload New Documents
            </Button>
          </Box>
          
          <List>
            {mockDocuments.map((doc) => (
              <ListItem 
                key={doc.id}
                secondaryAction={
                  <Chip 
                    label={agentConfig.documents.includes(doc.id) ? "Selected" : "Select"}
                    color={agentConfig.documents.includes(doc.id) ? "primary" : "default"}
                    onClick={() => handleAddDocument(doc.id)}
                    clickable
                  />
                }
                sx={{ 
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  mb: 1
                }}
              >
                <ListItemText 
                  primary={doc.name}
                  secondary={`${doc.type} • ${doc.size}`}
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
      
      {activeTab === 1 && (
        <List>
          {mockAPIs.map((api) => (
            <ListItem 
              key={api.id}
              secondaryAction={
                <Button
                  variant={api.status === 'Connected' || agentConfig.connectedAPIs.includes(api.id) ? 'contained' : 'outlined'}
                  size="small"
                  color={api.status === 'Connected' || agentConfig.connectedAPIs.includes(api.id) ? 'success' : 'primary'}
                  onClick={() => handleConnectAPI(api.id)}
                >
                  {api.status === 'Connected' || agentConfig.connectedAPIs.includes(api.id) ? 'Connected' : 'Connect'}
                </Button>
              }
              sx={{ 
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                mb: 1
              }}
            >
              <ListItemText 
                primary={api.name}
                secondary={api.endpoint}
              />
            </ListItem>
          ))}
        </List>
      )}
      
      {activeTab === 2 && (
        <Typography variant="body1" color="text.secondary" sx={{ p: 3, textAlign: 'center' }}>
          No databases configured. Use the connection panel to add a database.
        </Typography>
      )}
      
      
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onBack}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          endIcon={<ArrowForwardIcon />}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

// Step 4: Customize & Extend the Agent
export const CustomizeAgent = ({ agentConfig, setAgentConfig, onNext, onBack }) => {
  const tools = [
    { id: 'tool1', name: 'Web Search', description: 'Search the internet for up-to-date information' },
    { id: 'tool2', name: 'Code Execution', description: 'Execute code in Python, JavaScript, and other languages' },
    { id: 'tool3', name: 'Data Visualization', description: 'Generate charts and visualizations from data' },
    { id: 'tool4', name: 'Image Generation', description: 'Create images based on text descriptions' },
    { id: 'tool5', name: 'Calculator', description: 'Perform complex mathematical calculations' }
  ];

  const handleToolToggle = (toolId) => {
    setAgentConfig(prev => ({
      ...prev,
      tools: prev.tools.includes(toolId)
        ? prev.tools.filter(id => id !== toolId)
        : [...prev.tools, toolId]
    }));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Customize & Extend the Agent</Typography>
      
      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>Define Prompt Structure</Typography>
        <TextField
          fullWidth
          label="System Prompt"
          value={agentConfig.systemPrompt}
          onChange={(e) => setAgentConfig(prev => ({ ...prev, systemPrompt: e.target.value }))}
          multiline
          rows={4}
          placeholder="You are a helpful assistant that..."
          sx={{ mb: 2 }}
        />
      </Box>
      
      <Typography variant="h6" gutterBottom>Integrate Tools & Functions</Typography>
      <Grid container spacing={2}>
        {tools.map((tool) => (
          <Grid item xs={12} sm={6} key={tool.id}>
            <Card sx={{ height: '100%' }}>
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                  <Typography variant="subtitle1">{tool.name}</Typography>
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={agentConfig.tools.includes(tool.id)}
                        onChange={() => handleToolToggle(tool.id)}
                      />
                    }
                    label=""
                  />
                </Box>
                <Typography variant="body2" color="text.secondary">{tool.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onBack}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          endIcon={<ArrowForwardIcon />}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

// Step 5: Configure Memory & Context
export const ConfigureMemory = ({ agentConfig, setAgentConfig, onNext, onBack }) => {
  return (
    <Box>
      <Typography variant="h5" gutterBottom>Configure Memory & Context</Typography>
      
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Short-Term Memory</Typography>
          <FormControl component="fieldset" sx={{ mb: 2 }}>
            <FormLabel component="legend">Conversation Context</FormLabel>
            <RadioGroup
              value={agentConfig.shortTermMemory}
              onChange={(e) => setAgentConfig(prev => ({ ...prev, shortTermMemory: e.target.value }))}
            >
              <FormControlLabel value="basic" control={<Radio />} label="Basic (Last 10 messages)" />
              <FormControlLabel value="enhanced" control={<Radio />} label="Enhanced (Last 20 messages with summarization)" />
              <FormControlLabel value="advanced" control={<Radio />} label="Advanced (Dynamic context management)" />
            </RadioGroup>
          </FormControl>
          
          <TextField
            fullWidth
            label="Context Window Size (tokens)"
            type="number"
            value={agentConfig.contextSize}
            onChange={(e) => setAgentConfig(prev => ({ ...prev, contextSize: e.target.value }))}
            sx={{ mb: 2 }}
          />
        </Grid>
        
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Long-Term Memory</Typography>
          <FormControl sx={{ mb: 2 }}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={agentConfig.enableLongTermMemory}
                  onChange={(e) => setAgentConfig(prev => ({ ...prev, enableLongTermMemory: e.target.checked }))}
                />
              }
              label="Enable persistent knowledge storage"
            />
          </FormControl>
          
          {agentConfig.enableLongTermMemory && (
            <>
              <FormControl component="fieldset" sx={{ mb: 2 }}>
                <FormLabel component="legend">Memory Management</FormLabel>
                <RadioGroup
                  value={agentConfig.memoryManagement}
                  onChange={(e) => setAgentConfig(prev => ({ ...prev, memoryManagement: e.target.value }))}
                >
                  <FormControlLabel value="automatic" control={<Radio />} label="Automatic (System managed)" />
                  <FormControlLabel value="manual" control={<Radio />} label="Manual (User defined storage periods)" />
                </RadioGroup>
              </FormControl>
              
              <FormControl sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox 
                      checked={agentConfig.memoryRetrieval}
                      onChange={(e) => setAgentConfig(prev => ({ ...prev, memoryRetrieval: e.target.checked }))}
                    />
                  }
                  label="Enable semantic memory retrieval"
                />
              </FormControl>
            </>
          )}
        </Grid>
      </Grid>
      
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onBack}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={onNext}
          endIcon={<ArrowForwardIcon />}
        >
          Continue
        </Button>
      </Box>
    </Box>
  );
};

// Step 6: Deploy Agent
export const DeployAgent = ({ agentConfig, setAgentConfig, onBack, onDeploy }) => {
  const deploymentOptions = [
    { id: 'cloud', name: 'Cloud Deployment', description: 'Deploy on our managed infrastructure for optimal performance and scaling' },
    { id: 'self-hosted', name: 'Self-Hosted', description: 'Deploy on your own infrastructure for maximum control and data privacy' }
  ];
  
  const apiOptions = [
    { id: 'rest', name: 'REST API', description: 'Standard HTTP API with JSON payloads' },
    { id: 'grpc', name: 'gRPC API', description: 'High-performance RPC framework for efficient communication' }
  ];

  const [showAPIKeyForm, setShowAPIKeyForm] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [apiKeyName, setApiKeyName] = useState('');

  const handleDeploymentOptionSelect = (optionId) => {
    setAgentConfig(prev => ({ ...prev, deploymentOption: optionId }));
  };
  
  const handleApiOptionSelect = (optionId) => {
    setAgentConfig(prev => ({ ...prev, apiType: optionId }));
  };
  
  // Assuming hardware options come from a common file like MockData.js
  const availableNodes = hardwareOptions.slice(0, 4);
  
  const handleNodeToggle = (nodeId) => {
    setAgentConfig(prev => ({
      ...prev,
      nodes: prev.nodes.includes(nodeId)
        ? prev.nodes.filter(id => id !== nodeId)
        : [...prev.nodes, nodeId]
    }));
  };
  
  const handleAddApiKey = () => {
    if (apiKey && apiKeyName) {
      const newKey = {
        id: `key-${Date.now()}`,
        name: apiKeyName,
        key: apiKey.slice(0, 5) + '...' + apiKey.slice(-5),
        fullKey: apiKey,
        created: new Date().toISOString()
      };
      
      setAgentConfig(prev => ({
        ...prev,
        apiKeys: [...(prev.apiKeys || []), newKey]
      }));
      
      setApiKey('');
      setApiKeyName('');
      setShowAPIKeyForm(false);
    }
  };
  
  const handleDeleteApiKey = (keyId) => {
    setAgentConfig(prev => ({
      ...prev,
      apiKeys: (prev.apiKeys || []).filter(key => key.id !== keyId)
    }));
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Deploy Your Agent</Typography>
      
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>1. Select Deployment Environment</Typography>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {deploymentOptions.map((option) => (
          <Grid item xs={12} sm={6} key={option.id}>
            <Card 
              sx={{ 
                cursor: 'pointer',
                border: agentConfig.deploymentOption === option.id ? '2px solid' : '1px solid',
                borderColor: agentConfig.deploymentOption === option.id ? 'primary.main' : 'divider',
                transition: 'all 0.2s'
              }}
              onClick={() => handleDeploymentOptionSelect(option.id)}
            >
              <CardContent>
                <Typography variant="subtitle1">{option.name}</Typography>
                <Typography variant="body2" color="text.secondary">{option.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <Typography variant="h6" gutterBottom>2. Select API Type</Typography>
      <Grid container spacing={2} sx={{ mb: 4 }}>
        {apiOptions.map((option) => (
          <Grid item xs={12} sm={6} key={option.id}>
            <Card 
              sx={{ 
                cursor: 'pointer',
                border: agentConfig.apiType === option.id ? '2px solid' : '1px solid',
                borderColor: agentConfig.apiType === option.id ? 'primary.main' : 'divider',
                transition: 'all 0.2s'
              }}
              onClick={() => handleApiOptionSelect(option.id)}
            >
              <CardContent>
                <Typography variant="subtitle1">{option.name}</Typography>
                <Typography variant="body2" color="text.secondary">{option.description}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      
      <Typography variant="h6" gutterBottom>3. API Key Management</Typography>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="subtitle1">API Keys</Typography>
            <Button 
              variant="outlined" 
              size="small"
              onClick={() => setShowAPIKeyForm(!showAPIKeyForm)}
            >
              {showAPIKeyForm ? 'Cancel' : 'Add API Key'}
            </Button>
          </Box>
          
          {showAPIKeyForm && (
            <Box sx={{ mb: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1 }}>
              <TextField
                fullWidth
                label="Key Name"
                value={apiKeyName}
                onChange={(e) => setApiKeyName(e.target.value)}
                sx={{ mb: 2 }}
                size="small"
              />
              <TextField
                fullWidth
                label="API Key"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                sx={{ mb: 2 }}
                size="small"
                type="password"
              />
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button 
                  variant="contained" 
                  size="small"
                  onClick={handleAddApiKey}
                  disabled={!apiKey || !apiKeyName}
                >
                  Add Key
                </Button>
              </Box>
            </Box>
          )}
          
          {(!agentConfig.apiKeys || agentConfig.apiKeys.length === 0) ? (
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', py: 2 }}>
              No API keys configured. Add keys to authenticate your API calls.
            </Typography>
          ) : (
            <List>
              {agentConfig.apiKeys.map((key) => (
                <ListItem
                  key={key.id}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteApiKey(key.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                  sx={{ 
                    border: '1px solid',
                    borderColor: 'divider',
                    borderRadius: 1,
                    mb: 1
                  }}
                >
                  <ListItemText
                    primary={key.name}
                    secondary={
                      <React.Fragment>
                        <Typography variant="body2" component="span" sx={{ display: 'block' }}>
                          {key.key}
                        </Typography>
                        <Typography variant="caption" component="span">
                          Created: {new Date(key.created).toLocaleDateString()}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}
        </CardContent>
      </Card>
      
      {agentConfig.deploymentOption === 'cloud' && (
        <>
          <Typography variant="h6" gutterBottom>4. Select Compute Resources</Typography>
          <List>
            {availableNodes.map((node) => (
              <ListItem 
                key={node.id}
                secondaryAction={
                  <FormControlLabel
                    control={
                      <Checkbox 
                        checked={agentConfig.nodes.includes(node.id)}
                        onChange={() => handleNodeToggle(node.id)}
                      />
                    }
                    label=""
                  />
                }
                sx={{ 
                  border: '1px solid',
                  borderColor: 'divider',
                  borderRadius: 1,
                  mb: 1
                }}
              >
                <ListItemText 
                  primary={node.name}
                  secondary={
                    <React.Fragment>
                      <Typography variant="body2" component="span">
                        {node.specs} • {node.location}, {node.country}
                      </Typography>
                      <Chip 
                        size="small" 
                        label={`Availability: ${node.availability}`}
                        color={
                          node.availability === 'High' ? 'success' :
                          node.availability === 'Medium' ? 'warning' : 'error'
                        }
                        sx={{ ml: 1 }}
                      />
                    </React.Fragment>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
      
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onBack}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={onDeploy}
          disabled={!agentConfig.deploymentOption || !agentConfig.apiType || (agentConfig.deploymentOption === 'cloud' && agentConfig.nodes.length === 0)}
          endIcon={<CloudIcon />}
        >
          Deploy Agent
        </Button>
      </Box>
    </Box>
  );
};

// Main DeploymentWizard component
const DeploymentWizard = ({ onComplete }) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [isDeploying, setIsDeploying] = useState(false);
  const [deploymentCompleted, setDeploymentCompleted] = useState(false);
  const [agentConfig, setAgentConfig] = useState({
    name: '',
    purpose: '',
    tasks: '',
    templateId: '',
    modelType: '',
    selectedModelId: '',
    documents: [],
    connectedAPIs: [],
    autoEmbeddings: true,
    systemPrompt: 'You are an AI assistant that helps users with their tasks. You are helpful, friendly, and informative.',
    tools: [],
    shortTermMemory: 'basic',
    contextSize: '4000',
    enableLongTermMemory: false,
    memoryManagement: 'automatic',
    memoryRetrieval: true,
    deploymentOption: '',
    apiType: '',
    nodes: [],
    apiKeys: []
  });

  // Get selected model details for display in the status overview
  const selectedModel = agentConfig.selectedModelId ? getModelById(agentConfig.selectedModelId) : null;
  
  // Create an enhanced config for the status overview that includes the model name
  const enhancedConfig = {
    ...agentConfig,
    selectedModelName: selectedModel ? selectedModel.name : ''
  };

  const steps = [
    { label: 'Define Agent', icon: <PersonIcon /> },
    { label: 'Select Model', icon: <StorageIcon /> },
    { label: 'Add Knowledge', icon: <CloudUploadIcon /> },
    { label: 'Customize Agent', icon: <ExtensionIcon /> },
    { label: 'Configure Memory', icon: <MemoryIcon /> },
    { label: 'Deploy', icon: <CloudIcon /> }
  ];

  const handleNext = () => {
    setActiveStep(prevStep => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevStep => prevStep - 1);
  };

  const handleDeploy = () => {
    setIsDeploying(true);
    
    // Simulate deployment process
    setTimeout(() => {
      setIsDeploying(false);
      setDeploymentCompleted(true);
    }, 3000);
  };
  
  const handleFinish = () => {
    // Send final configuration to parent component
    onComplete(agentConfig);
  };

  if (deploymentCompleted) {
    return (
      <Box sx={{ p: 5, textAlign: 'center' }}>
        <CelebrationIcon sx={{ fontSize: 80, color: 'success.main', mb: 3 }} />
        <Typography variant="h4" gutterBottom>Deployment Successful!</Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Your AI agent "{agentConfig.name}" has been successfully deployed and is now online.
        </Typography>
        
        <Card sx={{ mb: 4, p: 3, maxWidth: 600, mx: 'auto', textAlign: 'left' }}>
          <Typography variant="h6" gutterBottom>Deployment Details</Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">Agent Name:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">{agentConfig.name}</Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">Model:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">{selectedModel?.name || agentConfig.modelType}</Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">Environment:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">{agentConfig.deploymentOption === 'cloud' ? 'Cloud' : 'Self-Hosted'}</Typography>
            </Grid>
            
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">API Type:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2">{agentConfig.apiType.toUpperCase()}</Typography>
            </Grid>
            
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
            </Grid>
            
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>API Endpoint:</strong> https://api.piosphere.ai/agents/{agentConfig.name.toLowerCase().replace(/\s+/g, '-')}
              </Typography>
            </Grid>
          </Grid>
        </Card>
        
        <Button
          variant="contained"
          size="large"
          onClick={handleFinish}
          sx={{ px: 4 }}
        >
          Return to Dashboard
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 4 }}>
        <AgentStatusOverview 
          agentConfig={enhancedConfig}
          activeStep={activeStep}
        />
      </Box>

      <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4 }}>
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              StepIconProps={{
                icon: step.icon ? step.icon : index + 1
              }}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <Paper 
        elevation={1}
        sx={{ 
          p: 4,
          mb: 3,
          borderRadius: 2
        }}
      >
        {activeStep === 0 && (
          <DefineAgent 
            agentConfig={agentConfig}
            setAgentConfig={setAgentConfig}
            onNext={handleNext}
          />
        )}
        
        {activeStep === 1 && (
          <SelectModelType 
            agentConfig={agentConfig}
            setAgentConfig={setAgentConfig}
            onNext={handleNext}
            onBack={handleBack}
          />
        )}
        
        {activeStep === 2 && (
          <AddKnowledge 
            agentConfig={agentConfig}
            setAgentConfig={setAgentConfig}
            onNext={handleNext}
            onBack={handleBack}
          />
        )}
        
        {activeStep === 3 && (
          <CustomizeAgent 
            agentConfig={agentConfig}
            setAgentConfig={setAgentConfig}
            onNext={handleNext}
            onBack={handleBack}
          />
        )}
        
        {activeStep === 4 && (
          <ConfigureMemory 
            agentConfig={agentConfig}
            setAgentConfig={setAgentConfig}
            onNext={handleNext}
            onBack={handleBack}
          />
        )}
        
        {activeStep === 5 && (
          <DeployAgent 
            agentConfig={agentConfig}
            setAgentConfig={setAgentConfig}
            onBack={handleBack}
            onDeploy={handleDeploy}
          />
        )}
      </Paper>
      
      {/* Deployment Progress Dialog */}
      <Dialog open={isDeploying} fullWidth maxWidth="sm">
        <DialogContent sx={{ textAlign: 'center', py: 4 }}>
          <CircularProgress size={60} sx={{ mb: 3 }} />
          <Typography variant="h6" gutterBottom>
            Deploying Your AI Agent
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Please wait while we set up your agent infrastructure...
          </Typography>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default DeploymentWizard; 