import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const PageLoading = ({ size = 40, color = '#50C878' }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      gap={3}
    >
      <CircularProgress size={size} style={{ color: color }} data-testid="loading-spinner" />
      <Typography variant="h6" style={{ color: color }}>
        You will be redirected shortly...
      </Typography>
    </Box>
  );
};

export default PageLoading;
