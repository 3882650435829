export const landingPageContent = {
    en: {
      nav: {
        home: "Home",
        solutions: "Solutions",
        pricing: "Pricing",
        training: "Training",
        about: "About"
      },
      hero: {
        title: "AI Agent Platform for Your Infrastructure",
        subtitle: "Build and deploy AI solutions on your own hardware without writing code",
        demo: "Schedule Demo"
      },
      features: {
        title: "Key Features",
        items: [
          {
            iconName: 'brainCircuit',
            title: "Local Models",
            description: "Manage and explore your AI models without relying on external APIs"
          },
          {
            iconName: 'code',
            title: "In-House Training",
            description: "Train your models on your own hardware, maintaining full control over your data"
          },
          {
            iconName: 'rocket',
            title: "Private Deployment",
            description: "Deploy models securely within your own datacenter"
          },
          {
            iconName: 'clock',
            title: "PioScheduler",
            description: "Maximize GPU utilization in your datacenter with our advanced scheduling system"
          },
          {
            iconName: 'shoppingBag',
            title: "Internal Marketplace",
            description: "Discover and install AI-powered applications that run entirely on your infrastructure"
          },
          {
            iconName: "messageCircle",
            title: "Real-time Chat",
            description: "Engage with your AI assistant in real-time conversations"
          }
        ]
      },
      solutions: {
        title: "Enterprise AI Solutions",
        subtitle: "Comprehensive AI solutions for your business needs",
        cards: [
          {
            title: "AI Agents as a Service (AaaS)",
            description: "KI-Agenten, die sich nahtlos in Ihre bestehenden Geschäftsprozesse integrieren",
            benefits: [
              "Maßgeschneiderte KI-Agenten",
              "Prozessautomatisierung",
              "Intelligente Assistenz"
            ]
          },
          {
            title: "Retrieval as a Service (RaaS)",
            description: "Fortschrittliche Dokumentenverarbeitung und Informationsextraktion",
            benefits: [
              "Semantische Suche",
              "Dokumentenanalyse",
              "Wissensextraktion"
            ]
          },
          {
            title: "LLM Operations",
            description: "Professionelles Management und Optimierung von Sprachmodellen",
            benefits: [
              "Modell-Finetuning",
              "Performance-Optimierung",
              "Skalierbare Infrastruktur"
            ]
          },
          {
            title: "High Performance Computing",
            description: "Hochleistungsrechnen für anspruchsvolle KI-Workloads",
            benefits: [
              "GPU-Cluster Management",
              "Verteiltes Training",
              "Ressourcenoptimierung"
            ]
          },
          {
            title: "Platform as a Service",
            description: "Komplette Plattform für KI-Entwicklung und Deployment",
            benefits: [
              "End-to-End Entwicklung",
              "Monitoring & Analytics",
              "Sicherheit & Compliance"
            ]
          }
        ]
      },
      training: {
        title: "AI Training Programs",
        subtitle: "Practice-oriented training for businesses and public administration",
        levels: [
          {
            level: "Beginner",
            title: "AI Agents for Business and Public Administration",
            description: "Fundamentals and implementation of AI agents in a business context",
            duration: "2 weeks",
            topics: [
              "AI Fundamentals & Use Cases",
              "No-Code AI Development",
              "Business Process Integration",
              "Data Protection & Compliance"
            ]
          },
          {
            level: "Advanced",
            title: "Advanced AI Agent Implementation",
            description: "Advanced concepts and complex use cases",
            duration: "4 weeks",
            topics: [
              "Complex Workflows & Process Automation",
              "System Integration & APIs",
              "Performance Optimization",
              "Scaling & Maintenance"
            ]
          }
        ]
      },
      pricing: {
        title: "AI Agent Pricing",
        subtitle: "All agents are hosted in Germany, ensuring data sovereignty and GDPR compliance",
        plans: [
          {
            name: "ChatAgent",
            price: "Price on Request",
            period: "per month",
            features: [
              "Text-based AI assistant",
              "Hosted in Germany",
              "GDPR compliant",
              "Custom knowledge base",
              "Real-time chat interface"
            ],
            recommended: false
          },
          {
            name: "MeetingAgent",
            price: "Price on Request",
            period: "per month",
            features: [
              "Meeting summarization",
              "Action item extraction",
              "Calendar integration",
              "Meeting transcription",
              "Hosted in Germany"
            ],
            recommended: true
          },
          {
            name: "CallAgent",
            price: "Price on Request",
            period: "per month",
            features: [
              "Voice interaction",
              "Call transcription",
              "Real-time assistance",
              "Custom voice training",
              "Hosted in Germany"
            ],
            recommended: false
          },
          {
            name: "Individual Agent",
            price: "Price on Request",
            period: "custom solution",
            features: [
              "Customized AI agent",
              "Dedicated support",
              "Custom integration",
              "Specialized training",
              "German hosting"
            ],
            recommended: false
          }
        ]
      },
      testimonials: {
        title: "What Our Clients Say",
        subtitle: "Success stories from industry leaders",
        items: [
          {
            quote: "Piosphere transformed our AI implementation process completely.",
            author: "Dr. Michael Schmidt",
            position: "CTO",
            company: "TechCorp GmbH"
          },
          {
            quote: "The no-code approach saved us months of development time.",
            author: "Sarah Weber",
            position: "Head of Innovation",
            company: "InnovateTech AG"
          },
          {
            quote: "Outstanding support and powerful AI capabilities.",
            author: "Thomas Mueller",
            position: "AI Director",
            company: "DataSys Solutions"
          }
        ]
      },
      about: {
        title: "About Piovation",
        subtitle: "European Leadership in AI Innovation",
        founderTitle: "Meet Our Founder & CEO",
        story: "Founded by Mazda Sabony in Munich, Piovation leads European AI innovation with secure, sovereign AI solutions. Our mission is to democratize AI technology while maintaining the highest standards of data protection and compliance. With deep expertise in AI and enterprise software, we're committed to helping organizations harness the power of artificial intelligence within their own infrastructure.",
        team: {
          title: "Leadership Team",
          members: [
            {
              name: "Mazda Sabony",
              role: "Co-Founder & CEO",
              description: "Visionary and strategic leader of PioSphere",
              imagePath: "/images/team/sabony.jpg"
            },
            {
              name: "M. Kamer",
              role: "Co-Founder & CTO",
              description: "Responsible for technological architecture & scaling",
              imagePath: "/images/team/kamer.jpg"
            },
            {
              name: "Walter Berger",
              role: "Strategic Advisor",
              description: "Market strategy, business development & scaling in the DACH region",
              imagePath: "/images/team/berger.jpg"
            },
            {
              name: "Rene Meyer",
              role: "Technical Advisor",
              description: "Consulting on technological advancement & integration",
              imagePath: "/images/team/meyer.jpg"
            },
            // {
            //   name: "J. Stapelfeld",
            //   role: "Technical Advisor",
            //   description: "Consulting on technological advancement & integration",
            //   imagePath: "/images/team/stapelfeld.jpg"
            // }
          ]
        }
      },
      cta: {
        title: "Ready to Revolutionize Your AI Workflow?",
        subtitle: "Join companies leveraging Piosphere's No-Code AI platform",
        button: "Schedule a Demo"
      },
      auth: {
        login: "Login",
        signup: "Sign Up"
      },
      footer: {
        company: {
          title: "Company",
          links: [
            "About Us",
            "Contact"
          ]
        },
        legal: {
          title: "Legal",
          links: [
            "Privacy Policy",
            "Terms of Service"
          ]
        },
        contact: {
          title: "Contact",
          address: "Zehentmeier Straße 8 a, 85630 Grasbrunn",
          email: "m.sabony@piovation.com",
          phone: "+49 89 123456789",
          social: {
            linkedin: "https://www.linkedin.com/company/piovation"
          }
        }
      }
    },
    de: {
      nav: {
        home: "Startseite",
        solutions: "Lösungen",
        pricing: "Preise",
        training: "Training",
        about: "Über uns"
      },
      hero: {
        title: "Die erste KI-Cloud für Deutschland",
        subtitle: "Entwickeln und implementieren Sie maßgeschneiderte KI-Agenten für verschiedene Branchen",
        demo: "Demo vereinbaren"
      },
      features: {
        title: "Hauptfunktionen",
        items: [
          {
            iconName: 'brainCircuit',
            title: "Lokale Modelle",
            description: "Verwalten Sie KI-Modelle direkt in Ihrer bestehenden IT-Infrastruktur"
          },
          {
            iconName: 'code',
            title: "Inhouse-Training",
            description: "Trainieren Sie Ihre Modelle auf Ihrer eigenen Hardware mit voller Kontrolle über Ihre Daten"
          },
          {
            iconName: 'rocket',
            title: "Private Bereitstellung",
            description: "Implementieren Sie Modelle sicher in Ihrer vorhandenen IT-Umgebung"
          },
          {
            iconName: 'clock',
            title: "PioScheduler",
            description: "Maximieren Sie die GPU-Auslastung in Ihrem Rechenzentrum mit unserem fortschrittlichen Scheduling-System"
          },
          {
            iconName: 'shoppingBag',
            title: "Interner Marktplatz",
            description: "Entdecken und installieren Sie KI-gestützte Anwendungen, die vollständig auf Ihrer Infrastruktur laufen"
          },
          {
            iconName: "messageCircle",
            title: "Real-time Chat",
            description: "Engage with your AI assistant in real-time conversations"
          }
        ]
      },
      solutions: {
        title: "KI-Lösungen für Deutschland",
        subtitle: "Maßgeschneiderte KI-Lösungen für unterschiedliche Anforderungen",
        cards: [
          {
            title: "Mittelständische Unternehmen",
            description: "Digitalisieren und automatisieren Sie Ihre Geschäftsprozesse mit KI-gestützten Lösungen",
            benefits: [
              "Automatisierte Dokumentenverarbeitung & Rechnungswesen",
              "KI-gestützte Kundenservice-Automation",
              "Intelligente Prozessoptimierung",
              "Predictive Maintenance für Produktionsanlagen",
              "DSGVO-konforme Datenanalyse"
            ],
            highlight: true
          },
          {
            title: "Öffentliche Verwaltung",
            description: "Modernisieren Sie Verwaltungsprozesse durch sichere KI-Integration",
            benefits: [
              "Automatisierte Antragsbearbeitung",
              "KI-gestützter Bürgerservice (24/7)",
              "Intelligente Dokumentenklassifizierung",
              "BSI-konforme Sicherheitsstandards",
              "Barrierefreie KI-Assistenten"
            ],
            highlight: true
          },
          {
            title: "KI-Entwickler & Data Scientists",
            description: "Professionelle Tools für KI-Entwicklung und Modell-Management",
            benefits: [
              "On-Premise LLM-Training & Finetuning",
              "GPU-Cluster Orchestrierung",
              "MLOps & Monitoring-Tools",
              "Versionskontrolle für KI-Modelle",
              "CI/CD für KI-Pipelines"
            ],
            highlight: true
          }
        ]
      },
      training: {
        title: "KI-Schulungsprogramme",
        subtitle: "Praxisorientierte Schulungen speziell für KMUs und öffentliche Verwaltung",
        levels: [
          {
            level: "Einsteiger",
            title: "KI-Grundlagen für Verwaltung und Mittelstand",
            description: "Einstieg in KI-Lösungen für kleine und mittlere Unternehmen",
            duration: "2 Wochen",
            topics: [
              "KI-Basics für den Mittelstand",
              "No-Code KI für Verwaltungsprozesse",
              "Integration in bestehende Systeme",
              "Datenschutz nach DSGVO"
            ]
          },
          {
            level: "Fortgeschritten",
            title: "Fortgeschrittene KI-Agenten Implementierung",
            description: "Erweiterte Konzepte und komplexe Anwendungsfälle",
            duration: "4 Wochen",
            topics: [
              "Komplexe Workflows & Prozessautomatisierung",
              "Systemintegration & APIs",
              "Performance-Optimierung",
              "Skalierung & Wartung"
            ]
          }
        ]
      },
      pricing: {
        title: "KI-Agenten Preise",
        subtitle: "Alle Agenten werden in Deutschland gehostet - volle Datensouveränität und DSGVO-Konformität",
        plans: [
          {
            name: "ChatAgent",
            price: "Preis auf Anfrage",
            period: "pro Monat",
            features: [
              "Textbasierter KI-Assistent",
              "Hosting in Deutschland",
              "DSGVO-konform",
              "Anpassbare Wissensbasis",
              "Echtzeit-Chat-Interface"
            ],
            recommended: false
          },
          {
            name: "MeetingAgent",
            price: "Preis auf Anfrage",
            period: "pro Monat",
            features: [
              "Meeting-Zusammenfassungen",
              "Aufgabenextraktion",
              "Kalenderintegration",
              "Meeting-Transkription",
              "Hosting in Deutschland"
            ],
            recommended: true
          },
          {
            name: "CallAgent",
            price: "Preis auf Anfrage",
            period: "pro Monat",
            features: [
              "Sprachinteraktion",
              "Anruftranskription",
              "Echtzeit-Assistenz",
              "Individuelles Sprachtraining",
              "Hosting in Deutschland"
            ],
            recommended: false
          },
          {
            name: "Individueller Agent",
            price: "Preis auf Anfrage",
            period: "individuelle Lösung",
            features: [
              "Maßgeschneiderter KI-Agent",
              "Dedizierter Support",
              "Kundenspezifische Integration",
              "Spezialisiertes Training",
              "Deutsches Hosting"
            ],
            recommended: false
          }
        ]
      },
      testimonials: {
        title: "Erfahrungen aus dem Mittelstand",
        subtitle: "Erfolgsgeschichten von KMUs und Behörden",
        items: [
          {
            quote: "Piosphere macht KI endlich auch für mittelständische Unternehmen zugänglich.",
            author: "Dr. Michael Schmidt",
            position: "Geschäftsführer",
            company: "Schmidt & Partner GmbH"
          },
          {
            quote: "Der No-Code-Ansatz hat uns Monate an Entwicklungszeit erspart.",
            author: "Sarah Weber",
            position: "Leiterin Innovation",
            company: "InnovateTech AG"
          },
          {
            quote: "Herausragender Support und leistungsstarke KI-Funktionen.",
            author: "Thomas Mueller",
            position: "KI-Direktor",
            company: "DataSys Solutions"
          }
        ]
      },
      about: {
        title: "Über Piovation",
        subtitle: "Europäische Führung in KI-Innovation",
        founderTitle: "Unser Gründer & CEO",
        story: "Piovation, gegründet von Mazda Sabony in München, ist führend in der europäischen KI-Innovation mit sicheren, souveränen KI-Lösungen. Unsere Mission ist es, KI-Technologie zu demokratisieren und dabei höchste Standards im Datenschutz und in der Compliance zu gewährleisten. Mit unserer umfassenden Expertise in KI und Unternehmenssoftware unterstützen wir Organisationen dabei, die Leistungsfähigkeit künstlicher Intelligenz in ihrer eigenen Infrastruktur zu nutzen.",
        team: {
          title: "Führungsteam",
          members: [
            {
              name: "Mazda Sabony",
              role: "Co-Founder & CEO",
              description: "Visionär und strategischer Leiter von PioSphere",
              imagePath: "/images/team/sabony.jpg"
            },
            {
              name: "M. Kamer",
              role: "Co-Founder & CTO",
              description: "Verantwortlich für die technologische Architektur & Skalierung",
              imagePath: "/images/team/kamer.jpg"
            },
            {
              name: "Walter Berger",
              role: "Strategic Advisor",
              description: "Marktstrategie, Business Development & Skalierung in der DACH-Region",
              imagePath: "/images/team/berger.jpg"
            },
            {
              name: "Rene Meyer",
              role: "Technical Advisor",
              description: "Beratung zur technologischen Weiterentwicklung & Integration",
              imagePath: "/images/team/meyer.jpg"
            },
            // {
            //   name: "J. Stapelfeld",
            //   role: "Technical Advisor",
            //   description: "Beratung zur technologischen Weiterentwicklung & Integration",
            //   imagePath: "/images/team/stapelfeld.jpg"
            // }
          ]
        }
      },
      cta: {
        title: "Bereit Ihren KI-Workflow zu revolutionieren?",
        subtitle: "Schließen Sie sich Unternehmen an, die Piospheres No-Code KI-Plattform nutzen",
        button: "Demo vereinbaren"
      },
      auth: {
        login: "Anmelden",
        signup: "Registrieren"
      },
      footer: {
        company: {
          title: "Unternehmen",
          links: [
            "Über uns",
            "Kontakt"
          ]
        },
        legal: {
          title: "Rechtliches",
          links: [
            "Datenschutz",
            "AGB"
          ]
        },
        contact: {
          title: "Kontakt",
          address: "Zehentmeier Straße 8 a, 85630 Grasbrunn",
          email: "m.sabony@piovation.com",
          phone: "+49 89 123456789",
          social: {
            linkedin: "https://www.linkedin.com/company/piovation"
          }
        }
      }
    }
  };