import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  DialogContentText
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const ClientManagement = ({ clients, addClient, updateClient, deleteClient, showNotification }) => {
  const [open, setOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [clientData, setClientData] = useState({ name: '', abbreviation: '', tenantId: '' });
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentClientId, setCurrentClientId] = useState(null);
  
  const handleClickOpen = () => {
    setIsEditMode(false);
    setClientData({ name: '', abbreviation: '', tenantId: '' });
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
    setClientData({ name: '', abbreviation: '', tenantId: '' });
  };
  
  const handleSubmit = () => {
    if (isEditMode) {
      updateClient({
        ...clientData,
        id: currentClientId
      });
    } else {
      const newClient = {
        ...clientData,
        id: Date.now().toString()
      };
      addClient(newClient);
    }
    handleClose();
  };
  
  const handleEdit = (client) => {
    setIsEditMode(true);
    setCurrentClientId(client.id);
    setClientData({
      name: client.name,
      abbreviation: client.abbreviation,
      tenantId: client.tenantId
    });
    setOpen(true);
  };
  
  const handleDeleteClick = (clientId) => {
    setCurrentClientId(clientId);
    setDeleteConfirmOpen(true);
  };
  
  const handleDeleteConfirm = () => {
    deleteClient(currentClientId);
    setDeleteConfirmOpen(false);
  };
  
  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setCurrentClientId(null);
  };
  
  const isFormValid = () => {
    return clientData.name && clientData.abbreviation && clientData.tenantId;
  };
  
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Client Management</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
        >
          Create Client
        </Button>
      </Box>
      
      {clients.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography>No clients found. Create your first client.</Typography>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Client Name</TableCell>
                <TableCell>Abbreviation</TableCell>
                <TableCell>Tenant ID</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients.map((client) => (
                <TableRow key={client.id}>
                  <TableCell>{client.name}</TableCell>
                  <TableCell>{client.abbreviation}</TableCell>
                  <TableCell>{client.tenantId}</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" color="primary" onClick={() => handleEdit(client)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton size="small" color="error" onClick={() => handleDeleteClick(client.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      
      {/* Create/Edit Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{isEditMode ? 'Edit Client' : 'Create New Client'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Client Name"
                value={clientData.name}
                onChange={(e) => setClientData({ ...clientData, name: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Abbreviation"
                value={clientData.abbreviation}
                onChange={(e) => setClientData({ ...clientData, abbreviation: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Tenant ID"
                value={clientData.tenantId}
                onChange={(e) => setClientData({ ...clientData, tenantId: e.target.value })}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={!isFormValid()} variant="contained">
            {isEditMode ? 'Save Changes' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this client? This will also remove all associated departments, teams, and users.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ClientManagement;
