import React, { useState } from 'react';
import {
  Typography, Box, Tabs, Tab, Stepper, Step, StepLabel, Button, Paper, Grid, Chip, Checkbox, Card, CardContent, LinearProgress
} from '@mui/material';
import BenchmarkHistory from './BenchmarkHistory'; // Import BenchmarkHistory component
import { myModels, publicModels, hardwareOptions} from '../helpers/MockData';


export default function Benchmark({ myModels, publicModels }) {
  const [view, setView] = useState('new'); // 'new' or 'history'
  const [selectedModels, setSelectedModels] = useState([]);
  const [benchmarkType, setBenchmarkType] = useState('latency'); // 'latency' or 'throughput'
  const [selectedHardware, setSelectedHardware] = useState(null);
  const [profiling, setProfiling] = useState(false);

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      p: { xs: 1, sm: 2, md: 3 },
      width: '100%',
      height: '100%',
      overflow: 'auto'
    }}>
      <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
        Model Benchmark
      </Typography>

      <Box sx={{ 
        display: 'flex', 
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'space-between', 
        alignItems: { xs: 'flex-start', sm: 'center' }, 
        mb: 3 
      }}>
        <Tabs 
          value={view} 
          onChange={(e, newValue) => setView(newValue)}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ maxWidth: '100%' }}
        >
          <Tab label="New Benchmark" value="new" />
          <Tab label="Benchmark History" value="history" />
        </Tabs>
      </Box>

      {view === 'new' ? (
        <NewBenchmark 
          myModels={myModels}
          publicModels={publicModels}
          selectedModels={selectedModels}
          setSelectedModels={setSelectedModels}
          benchmarkType={benchmarkType}
          setBenchmarkType={setBenchmarkType}
          selectedHardware={selectedHardware}
          setSelectedHardware={setSelectedHardware}
          profiling={profiling}
          setProfiling={setProfiling}
        />
      ) : (
        <BenchmarkHistory />
      )}
    </Box>
  );
}

function NewBenchmark({ 
  myModels, 
  publicModels, 
  selectedModels,
  setSelectedModels,
  benchmarkType,
  setBenchmarkType,
  selectedHardware,
  setSelectedHardware,
  profiling,
  setProfiling
}) {
  const [step, setStep] = useState(0);
  
  const steps = [
    {
      label: 'Select Models',
      component: (
        <ModelSelection 
          myModels={myModels}
          publicModels={publicModels}
          selectedModels={selectedModels}
          setSelectedModels={setSelectedModels}
        />
      )
    },
    {
      label: 'Select Benchmark Type',
      component: (
        <BenchmarkTypeSelection 
          benchmarkType={benchmarkType}
          setBenchmarkType={setBenchmarkType}
          profiling={profiling}
          setProfiling={setProfiling}
        />
      )
    },
    {
      label: 'Choose Hardware',
      component: (
        <HardwareSelection 
          selectedHardware={selectedHardware}
          setSelectedHardware={setSelectedHardware}
        />
      )
    },
    {
      label: 'Run Benchmark',
      component: (
        <BenchmarkExecution 
          selectedModels={selectedModels}
          benchmarkType={benchmarkType}
          selectedHardware={selectedHardware}
          profiling={profiling}
        />
      )
    }
  ];

  return (
    <Box>
      {/* Stepper */}
      <Stepper activeStep={step}>
        {steps.map((s, index) => (
          <Step key={index}>
            <StepLabel>{s.label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {/* Step Content */}
      <Box sx={{ mt: 4 }}>
        {steps[step].component}
      </Box>

      {/* Navigation */}
      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Button 
          disabled={step === 0}
          onClick={() => setStep(prev => prev - 1)}
        >
          Back
        </Button>
        <Button 
          variant="contained"
          disabled={!canProceed(step, selectedModels, selectedHardware)}
          onClick={() => step < steps.length - 1 ? setStep(prev => prev + 1) : handleBenchmarkStart()}
        >
          {step === steps.length - 1 ? 'Start Benchmark' : 'Next'}
        </Button>
      </Box>
    </Box>
  );
}

function ModelSelection({ myModels, publicModels, selectedModels, setSelectedModels }) {
  return (
    <Box sx={{ 
      maxHeight: { xs: '60vh', sm: '50vh' },
      overflowY: 'auto',
      pr: { xs: 1, sm: 2 }
    }}>
      <Grid container spacing={2}>
        {/* My Models Section */}
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>My Models</Typography>
          <Grid container spacing={1}>
            {myModels.map(model => (
              <Grid item xs={12} sm={6} md={4} key={`my-${model.id}`}>
                <ModelCard
                  model={model}
                  selected={selectedModels.some(m => m.id === model.id && m.source === 'my')}
                  onSelect={(model) => handleModelSelect(model, selectedModels, setSelectedModels, 'my')}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Public Models Section */}
        <Grid item xs={12} sx={{ mt: { xs: 2, sm: 4 } }}>
          <Typography variant="subtitle1" gutterBottom>Public Models</Typography>
          <Grid container spacing={1}>
            {publicModels.map(model => (
              <Grid item xs={12} sm={6} md={4} key={`public-${model.id}`}>
                <ModelCard
                  model={model}
                  selected={selectedModels.some(m => m.id === model.id && m.source === 'public')}
                  onSelect={(model) => handleModelSelect(model, selectedModels, setSelectedModels, 'public')}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

function BenchmarkTypeSelection({ benchmarkType, setBenchmarkType, profiling, setProfiling }) {
  return (
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 4 }}>
      <Typography variant="h5" gutterBottom sx={{ mb: 4, textAlign: 'center' }}>
        Select Benchmark Type
      </Typography>

      {/* Benchmark Options */}
      <Grid container spacing={3} sx={{ mb: 4 }}>
        {[
          {
            type: 'latency',
            title: 'Latency',
            description: 'Measure response time per request'
          },
          {
            type: 'throughput',
            title: 'Throughput',
            description: 'Measure total processing capacity'
          }
        ].map(({ type, title, description }) => (
          <Grid item xs={6} key={type}>
            <Paper
              onClick={() => setBenchmarkType(type)}
              sx={{
                p: 3,
                borderRadius: 3,
                cursor: 'pointer',
                backgroundColor: benchmarkType === type ? 'primary.light' : 'background.paper',
                color: benchmarkType === type ? 'primary.contrastText' : 'text.primary',
                transition: 'all 0.2s ease',
                '&:hover': { transform: 'translateY(-2px)' }
              }}
            >
              <Typography variant="h6" gutterBottom>
                {title}
              </Typography>
              <Typography variant="body2">
                {description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {/* Profiling Toggle */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          Profiling <Checkbox 
            checked={profiling}
            onChange={(e) => setProfiling(e.target.checked)}
          />
          <Typography variant="body2">
            Enable detailed performance profiling
          </Typography>
        </Box>
        {profiling && (
          <Typography variant="body2" color="error" sx={{ pl: 4 }}>
            Warning: Enabling profiling may significantly impact performance
          </Typography>
        )}
      </Box>
    </Box>
  );
}
function ModelCard({ model, selected, onSelect }) {
  return (
    <Card 
      sx={{ 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        border: selected ? 2 : 1,
        borderColor: selected ? 'primary.main' : 'divider',
        cursor: 'pointer'
      }}
      onClick={() => onSelect(model)}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" component="div" gutterBottom>
          {model.name}
        </Typography>
        
        {/* Basic model information */}
        <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
          <Chip 
            size="small"
            label={model.size || `${model.parameters} parameters`}
            color="primary"
          />
          {model.type && (
            <Chip 
              size="small"
              label={model.type}
              variant="outlined"
            />
          )}
        </Box>

        {model.description && (
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            {model.description}
          </Typography>
        )}
      </CardContent>
      
      <Box sx={{ p: 2, pt: 0 }}>
        <Checkbox 
          checked={selected}
          onChange={(e) => e.stopPropagation()}
          onClick={(e) => {
            e.stopPropagation();
            onSelect(model);
          }}
        />
      </Box>
    </Card>
  );
}

function HardwareSelection({ selectedHardware, setSelectedHardware }) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Typography variant="h6" gutterBottom>Select Hardware</Typography>
      <Grid container spacing={1}>
        {hardwareOptions.map(hw => (
          <Grid item xs={12} sm={6} md={4} key={hw.id}>
            <Card 
              onClick={() => setSelectedHardware(hw.id)}
              sx={{ 
                p: 3, 
                cursor: 'pointer',
                border: selectedHardware === hw.id ? 2 : 1,
                borderColor: selectedHardware === hw.id ? 'primary.main' : 'divider',
                boxShadow: selectedHardware === hw.id ? 4 : 1,
                transition: 'box-shadow 0.3s ease-in-out'
              }}
            >
              <Typography variant="subtitle1">{hw.name}</Typography>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {hw.specs}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Chip 
                  size="small" 
                  label={hw.availability} 
                  color={
                    hw.availability === 'High' ? 'success' : 
                    hw.availability === 'Medium' ? 'warning' : 
                    'error'
                  }
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

function BenchmarkExecution({ selectedModels, benchmarkType, selectedHardware, profiling }) {
  const [isRunning, setIsRunning] = useState(false);
  const [progress, setProgress] = useState(0);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {/* Summary of selections */}
      <Paper sx={{ p: 3, backgroundColor: 'background.paper', borderRadius: 2, boxShadow: 3 }}>
        <Typography variant="h6" gutterBottom>Benchmark Summary</Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Selected Models:</Typography>
            <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
              {selectedModels.map(model => (
                <Chip key={model.id} label={model.name} />
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Benchmark Type:</Typography>
            <Typography>{benchmarkType === 'latency' ? 'Latency' : 'Throughput'}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Selected Hardware:</Typography>
            <Typography>{hardwareOptions.find(h => h.id === selectedHardware)?.name}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Profiling:</Typography>
            <Typography>{profiling ? 'Enabled' : 'Disabled'}</Typography>
          </Grid>
        </Grid>
      </Paper>

      {/* Progress */}
      {isRunning && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
            {progress}% Complete
          </Typography>
        </Box>
      )}
    </Box>
  );
}

// Helper functions
function canProceed(step, selectedModels, selectedHardware) {
  switch (step) {
    case 0: // Model Selection
      return selectedModels.length >= 1;
    case 1: // Benchmark Type Selection
      return true;
    case 2: // Hardware Selection
      return selectedHardware !== null;
    case 3: // Benchmark Execution
      return true;
    default:
      return false;
  }
}

function handleModelSelect(model, selectedModels, setSelectedModels, source) {
  const modelWithSource = { ...model, source };
  if (selectedModels.find(m => m.id === model.id && m.source === source)) {
    setSelectedModels(selectedModels.filter(m => !(m.id === model.id && m.source === source)));
  } else {
    setSelectedModels([...selectedModels, modelWithSource]);
  }
}

function handleBenchmarkStart() {
  // This would typically make an API call to start the benchmark
  console.log('Starting benchmark...');
  // You could also show a success message or redirect to the history view
}