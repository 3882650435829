import React, { useState, useEffect, useRef } from 'react';
import { 
  Box, Typography, Tabs, Tab, Button, Grid, 
  Card, CardContent, Chip, Stack, List, ListItem,
  FormControlLabel, Checkbox, Divider, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { hardwareOptions, publicModels, DEMO_MODELS } from '../helpers/MockData';
import SaveIcon from '@mui/icons-material/Save';
import KeyManager from './KeyManager';
import RAGUpload from './RAGUpload';
import ApiUsage from './ApiUsage';
import { preDeployedAgents } from '../helpers/MockData';
import { generateQuery } from '../api/services/deployment/Deployment';
import SendIcon from '@mui/icons-material/Send';
import { Paper, CircularProgress, TextField, MenuItem } from '@mui/material';
import ReactMarkdown from 'react-markdown';


const ManageDeployment = () => {
  const { deploymentId } = useParams();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [deployment, setDeployment] = useState(null);
  const [selectedModels, setSelectedModels] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);

  useEffect(() => {
    const matchingDeployment = preDeployedAgents.find(d => d.id === deploymentId);
    if (matchingDeployment) {
      setDeployment(matchingDeployment);
    } else {
      // If not found, could redirect to 404 or deployments list
      navigate('/deployment');
    }
  }, [deploymentId, navigate]);

  const handleSave = async () => {
    // Save changes to API
    navigate('/deployment');
  };

  if (!deployment) {
    return <Box>Loading...</Box>;
  }

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Typography variant="h4">Manage Deployment: {deployment.name}</Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            onClick={() => navigate('/deployment')}
            sx={{ 
              borderColor: 'rgba(255, 255, 255, 0.23)',
              color: 'white',
              '&:hover': {
                borderColor: 'rgba(255, 255, 255, 0.5)',
              }
            }}
          >
            Exit
          </Button>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSave}
            sx={{
              backgroundColor: '#40c463',
              '&:hover': {
                backgroundColor: '#2ea043'
              }
            }}
          >
            Save Changes
          </Button>
        </Stack>
      </Box>

      <Tabs 
        value={activeTab} 
        onChange={(e, v) => setActiveTab(v)} 
        sx={{ 
          mb: 3,
          '& .MuiTab-root': {
            color: 'rgba(255, 255, 255, 0.7)',
            '&.Mui-selected': {
              color: '#40c463'
            }
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#40c463'
          }
        }}
      >
        <Tab label="OVERVIEW" />
        <Tab label="MODELS" />
        <Tab label="NODES" />
        <Tab label="API KEYS" />
        <Tab label="DOCUMENTS" />
        <Tab label="CHAT" />
        <Tab label="USAGE" />
      </Tabs>

      {activeTab === 0 && (
        <DeploymentOverview deployment={deployment} />
      )}

      {activeTab === 1 && (
        <ModelManagement 
          currentModels={deployment.models}
          onUpdateModels={models => setDeployment(prev => ({ ...prev, models }))}
        />
      )}

      {activeTab === 2 && (
        <NodeManagement 
          currentNodes={deployment.nodes}
          onUpdateNodes={nodes => setDeployment(prev => ({ ...prev, nodes }))}
        />
      )}

      {activeTab === 3 && (
        <KeyManager />
      )}

      {activeTab === 4 && (
        <RAGUpload 
            domain={deployment.domain}
        />
      )}

      {activeTab === 5 && (
        <ChatTab 
          domain={deployment.domain}
          model={deployment.models[0]?.name || "mistralai/Mistral-7B-Instruct-v0.3"}
        />
      )}

      {activeTab === 6 && (
        <ApiUsage 
            domain={deployment.domain}
        />
      )}
    </Box>
  );
};

const DeploymentOverview = ({ deployment }) => {
  const navigate = useNavigate();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const handleDelete = async () => {
    // In real app, make API call to delete deployment
    setDeleteDialogOpen(false);
    navigate('/deployments');
  };

  return (
    <Grid container spacing={2}>
      {/* Deployment Details & Resources Combined Card */}
      <Grid item xs={12}>
        <Card sx={{ backgroundColor: 'rgba(22, 27, 34, 0.8)' }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                  Deployment Details
                </Typography>
                <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                  <Chip 
                    label="ONLINE"
                    sx={{ backgroundColor: '#40c463', color: 'black', fontWeight: 500 }}
                    size="small"
                  />
                  <Chip 
                    label="Production"
                    sx={{ backgroundColor: '#40c463', color: 'black', fontWeight: 500 }}
                    size="small"
                  />
                  <Chip 
                    label="Auto-scaling"
                    sx={{ backgroundColor: '#40c463', color: 'black', fontWeight: 500 }}
                    size="small"
                  />
                </Stack>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Deployment ID
                    </Typography>
                    <Typography sx={{ color: 'white' }} variant="body2">
                      {deployment.id}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Type
                    </Typography>
                    <Typography sx={{ color: 'white' }} variant="body2">
                      {deployment.type}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Created At
                    </Typography>
                    <Typography sx={{ color: 'white' }} variant="body2">
                      January 15, 2024
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Last Updated
                    </Typography>
                    <Typography sx={{ color: 'white' }} variant="body2">
                      2 hours ago
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                  Resources
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)', display: 'block', mb: 1 }}>
                    Active Models
                  </Typography>
                  <Chip 
                    label="mistralai/Mistral-7B-Instruct-v0.3"
                    sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', color: 'white' }}
                    size="small"
                  />
                </Box>
                <Box>
                  <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)', display: 'block', mb: 1 }}>
                    Allocated Nodes
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Chip 
                      label="MI250"
                      sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', color: 'white' }}
                      size="small"
                    />
                    <Chip 
                      label="online"
                      sx={{ backgroundColor: '#40c463', color: 'black', fontWeight: 500 }}
                      size="small"
                    />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* Quick Stats & Actions Combined Card */}
      <Grid item xs={12}>
        <Card sx={{ backgroundColor: 'rgba(22, 27, 34, 0.8)' }}>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <Typography variant="h6" gutterBottom sx={{ color: 'white' }}>
                  Quick Statistics
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Total Requests (24h)
                    </Typography>
                    <Typography variant="h5" sx={{ color: 'white' }}>
                      1,234
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Avg Response Time
                    </Typography>
                    <Typography variant="h5" sx={{ color: 'white' }}>
                      156ms
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                      Success Rate
                    </Typography>
                    <Typography variant="h5" sx={{ color: 'white' }}>
                      99.9%
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Box sx={{ 
                  p: 2, 
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  borderRadius: 1,
                  backgroundColor: 'rgba(0, 0, 0, 0.2)'
                }}>
                  <Typography variant="subtitle2" sx={{ color: 'white', mb: 2 }}>
                    Deployment Actions
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      size="medium"
                      sx={{
                        backgroundColor: 'rgba(240, 136, 62, 0.2)',
                        color: '#f0883e',
                        '&:hover': {
                          backgroundColor: 'rgba(240, 136, 62, 0.3)',
                        }
                      }}
                    >
                      Restart
                    </Button>
                    <Button
                      fullWidth
                      variant="contained"
                      size="medium"
                      onClick={() => setDeleteDialogOpen(true)}
                      sx={{
                        backgroundColor: 'rgba(248, 81, 73, 0.2)',
                        color: '#f85149',
                        '&:hover': {
                          backgroundColor: 'rgba(248, 81, 73, 0.3)',
                        }
                      }}
                    >
                      Delete
                    </Button>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {/* Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Deployment</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this deployment? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

const ModelManagement = ({ currentModels, onUpdateModels }) => {
  const [selectedModels, setSelectedModels] = useState(
    new Set(currentModels.map(m => m.name))
  );

  const handleModelToggle = (model) => {
    const newSelected = new Set(selectedModels);
    if (newSelected.has(model.value)) {
      newSelected.delete(model.value);
    } else {
      newSelected.add(model.value);
    }
    setSelectedModels(newSelected);
    
    const updatedModels = DEMO_MODELS
      .filter(m => newSelected.has(m.value))
      .map((m, index) => ({
        id: index + 1,
        name: m.value
      }));
    onUpdateModels(updatedModels);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Available Models</Typography>
      <List>
        {DEMO_MODELS.map((model, index) => (
          <ListItem 
            key={index}
            sx={{ 
              border: 1,
              borderColor: 'divider',
              borderRadius: 1,
              mb: 1
            }}
          >
            <FormControlLabel
              control={
                <Checkbox 
                  checked={selectedModels.has(model.value)}
                  onChange={() => handleModelToggle(model)}
                />
              }
              label={
                <Box>
                  <Typography>{model.label}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {model.value}
                  </Typography>
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const NodeManagement = ({ currentNodes, onUpdateNodes }) => {
  const [selectedNodes, setSelectedNodes] = useState(
    new Set(currentNodes.map(n => n.id))
  );

  const handleNodeToggle = (node) => {
    const newSelected = new Set(selectedNodes);
    if (newSelected.has(node.id)) {
      newSelected.delete(node.id);
    } else {
      newSelected.add(node.id);
    }
    setSelectedNodes(newSelected);
    
    const updatedNodes = hardwareOptions
      .filter(n => newSelected.has(n.id))
      .map(n => ({
        id: n.id,
        name: n.name,
        status: 'online'
      }));
    onUpdateNodes(updatedNodes);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Available Nodes</Typography>
      <List>
        {hardwareOptions.map((node) => (
          <ListItem 
            key={node.id}
            sx={{ 
              border: 1,
              borderColor: 'divider',
              borderRadius: 1,
              mb: 1
            }}
          >
            <FormControlLabel
              control={
                <Checkbox 
                  checked={selectedNodes.has(node.id)}
                  onChange={() => handleNodeToggle(node)}
                />
              }
              label={
                <Box>
                  <Typography>{node.name}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {node.specs}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                    Location: {node.location}, {node.country} ({node.provider})
                  </Typography>
                  <Chip 
                    size="small" 
                    label={`Availability: ${node.availability}`}
                    color={
                      node.availability === 'High' ? 'success' :
                      node.availability === 'Medium' ? 'warning' : 'error'
                    }
                    sx={{ mt: 1 }}
                  />
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

const ChatTab = ({ domain, model: initialModel }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [model, setModel] = useState(DEMO_MODELS[0].value);

  const models = DEMO_MODELS;

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (input.trim() === '') return;
    
    const userMessage = input.trim();
    setMessages([...messages, { text: userMessage, sender: 'user' }]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await generateQuery({
        query: userMessage,
        model: model,
        domain: domain
      });
      
      if (!response.body) {
        throw new Error('No response body');
      }
      
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let buffer = '';
      let accumulatedText = '';
      let isSystemPrompt = model.includes('DeepSeek');
      
      // Add an initial AI message with an empty text
      setMessages(prevMessages => [...prevMessages, { text: '', sender: 'ai', isSystemPrompt }]);

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }

        const chunk = decoder.decode(value);
        buffer += chunk;

        const lines = buffer.split('\n');
        buffer = lines.pop() || '';

        let previousLineWasEmpty = false;

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            let content = line.slice(6); // Remove just 'data:'
            accumulatedText += content;
            previousLineWasEmpty = false;
          } else if (line.trim() === '') {
            if (previousLineWasEmpty) {
              accumulatedText += '\n';
              previousLineWasEmpty = false;
            } else {
              previousLineWasEmpty = true;
            }
          }
        }

        setMessages(prevMessages => {
          const newMessages = [...prevMessages];
          const lastMessage = newMessages[newMessages.length - 1];
          if (lastMessage?.sender === 'ai') {
            return newMessages.map((msg, i) =>
              i === newMessages.length - 1 
                ? { ...msg, text: accumulatedText } 
                : msg
            );
          }
          return newMessages;
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        {
          text: 'Sorry, there was an error processing your request.',
          sender: 'ai',
        },
      ]);
    } finally {
      setIsLoading(false);
      scrollToBottom();
    }
  };

  return (
    <Box sx={{ height: 'calc(100vh - 250px)', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ flexGrow: 1, overflow: 'auto', mb: 2 }}>
        <List>
          {messages.map((message, index) => (
            <ListItem
              key={index}
              sx={{
                justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start',
                mb: 1
              }}
            >
              <Paper
                sx={{
                  p: 2,
                  maxWidth: '70%',
                  bgcolor: message.sender === 'user' ? '#40c463' : 'background.paper',
                  color: message.sender === 'user' ? 'black' : 'white',
                  borderRadius: message.sender === 'user' ? '20px 20px 5px 20px' : '20px 20px 20px 5px'
                }}
              >
                {message.sender === 'user' ? (
                  <Typography>{message.text}</Typography>
                ) : (
                  <>
                    {(message.isSystemPrompt || message.text.includes('</think>')) && (
                      <Box sx={{ 
                        position: 'relative',
                        mb: 3,
                        p: 2,
                        pl: 3,
                        borderRadius: 1,
                        bgcolor: 'rgba(0, 0, 0, 0.04)',
                        borderLeft: '3px solid',
                        borderColor: 'primary.light',
                        '&::before': {
                          content: '"Thinking..."',
                          position: 'absolute',
                          top: -10,
                          left: 12,
                          fontSize: '0.7rem',
                          fontWeight: 600,
                          color: 'primary.light',
                          bgcolor: 'background.paper',
                          px: 1,
                          borderRadius: 1,
                        }
                      }}>
                        <Typography 
                          variant="body2" 
                          sx={{ 
                            fontStyle: 'italic', 
                            color: 'text.secondary',
                            fontSize: '0.9rem',
                            lineHeight: 1.5,
                          }}
                        >
                          {message.text.includes('</think>') 
                            ? message.text.split('</think>')[0] 
                            : message.text}
                        </Typography>
                      </Box>
                    )}
                    {!message.isSystemPrompt || message.text.includes('</think>') ? (
                      <ReactMarkdown
                        components={{
                          p: ({ children }) => (
                            <Typography component="div" sx={{ mb: 1, '&:last-child': { mb: 0 } }}>
                              {children}
                            </Typography>
                          ),
                          ul: ({ children }) => (
                            <Box 
                              component="ul" 
                              sx={{ 
                                m: 0,
                                pl: 0,
                                listStylePosition: 'inside',
                              }}
                            >
                              {children}
                            </Box>
                          ),
                          ol: ({ children }) => (
                            <Box 
                              component="ol" 
                              sx={{ 
                                m: 0,
                                pl: 0,
                                listStylePosition: 'inside',
                              }}
                            >
                              {children}
                            </Box>
                          ),
                          li: ({ children }) => (
                            <Typography 
                              component="li" 
                              sx={{ 
                                mb: 0.5,
                                display: 'list-item',
                                '&::marker': {
                                  color: 'text.primary',
                                },
                                pl: 1,
                                textIndent: '-1em',
                                paddingInlineStart: '1em',
                              }}
                            >
                              {children}
                            </Typography>
                          ),
                        }}
                      >
                        {message.text.includes('</think>') 
                          ? message.text.split('</think>')[1] 
                          : (!message.isSystemPrompt ? message.text : '')}
                      </ReactMarkdown>
                    ) : null}
                  </>
                )}
              </Paper>
            </ListItem>
          ))}
          {isLoading && (
            <ListItem sx={{ justifyContent: 'flex-start' }}>
              <Paper sx={{
                p: 2,
                bgcolor: 'background.paper',
                borderRadius: '20px 20px 20px 5px',
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                animation: 'pulse 1.5s ease-in-out infinite',
                '@keyframes pulse': {
                  '0%, 100%': { opacity: 1 },
                  '50%': { opacity: 0.6 }
                }
              }}>
                <CircularProgress 
                  size={24}
                  thickness={4}
                  sx={{ color: 'primary.main' }}
                />
                <Typography variant="body2" sx={{ 
                  color: 'text.secondary',
                  fontWeight: 500,
                  background: 'linear-gradient(90deg, #fff 30%, rgba(255,255,255,0.5) 50%, #fff 70%)',
                  backgroundSize: '200% auto',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  color: 'transparent',
                  animation: 'shimmer 2s linear infinite',
                  '@keyframes shimmer': {
                    '0%': { backgroundPosition: '200% 0' },
                    '100%': { backgroundPosition: '-200% 0' }
                  }
                }}>
                  Generating response...
                </Typography>
              </Paper>
            </ListItem>
          )}
          <div ref={messagesEndRef} />
        </List>
      </Box>

      <Box sx={{ p: 2, maxWidth: '800px', mx: 'auto', width: '100%' }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 1,
          bgcolor: 'background.paper', 
          borderRadius: '12px', 
          p: 1,
          border: '1px solid rgba(255, 255, 255, 0.1)',
          '&:focus-within': {
            border: '1px solid #40c463',
          }
        }}>
          <TextField
            select
            size="small"
            value={model}
            onChange={(e) => setModel(e.target.value)}
            sx={{
              minWidth: 180,
              '& .MuiOutlinedInput-root': {
                bgcolor: 'background.paper',
                fontSize: '0.875rem',
                '&:hover': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.main',
                  }
                },
                '&.Mui-focused': {
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.main',
                  }
                }
              },
              '& .MuiSelect-select': {
                py: 1,
                px: 1.5,
              }
            }}
          >
            {models.map((option) => (
              <MenuItem 
                key={option.value} 
                value={option.value}
                sx={{
                  fontSize: '0.875rem',
                  py: 1,
                  px: 1.5,
                  '&:hover': {
                    bgcolor: 'primary.lighter',
                  },
                  '&.Mui-selected': {
                    bgcolor: 'primary.lighter',
                    '&:hover': {
                      bgcolor: 'primary.light',
                    }
                  }
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type your message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
            disabled={isLoading}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                bgcolor: 'background.default',
                '&:hover fieldset': { borderColor: '#40c463' },
                '&.Mui-focused fieldset': { borderColor: '#40c463' }
              },
              '& .MuiInput-input': {
                py: 1.2,
                px: 2
              },
            }}
          />
          <Button
            onClick={handleSend}
            disabled={isLoading || !input.trim()}
            sx={{
              minWidth: 'auto',
              p: 1,
              bgcolor: '#40c463',
              color: 'black',
              '&:hover': {
                bgcolor: '#2ea043',
              },
              '&.Mui-disabled': {
                bgcolor: 'rgba(64, 196, 99, 0.5)',
                color: 'rgba(0, 0, 0, 0.5)'
              }
            }}
          >
            <SendIcon />
          </Button>
        </Box>
      </Box>
      
      <Typography variant="caption" sx={{ textAlign: 'center', color: 'text.secondary', mt: 2 }}>
        The AI Assistant may make mistakes. Please verify important information.
      </Typography>
    </Box>
  );
};

export default ManageDeployment;
