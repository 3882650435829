import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Container, Grid, Card, CardContent, Avatar, Box, Link as MuiLink, List, ListItem, ListItemIcon, ListItemText, Chip } from '@mui/material';
import { styled, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { MessageCircle, Clock, BarChart2, BrainCircuit, Rocket, ShoppingBag, Code, FileText, Users, Server, Shield, Lock, Zap, Database, Settings, Cpu, Cloud, Layers, Network, Bot, Search, Check } from 'lucide-react';
import { useKeycloak } from '@react-keycloak/web';
import PageLoading from '../helpers/PageLoading';
import { landingPageContent } from '../landingpage/landingPageContent';

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const FeatureCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.grey[900],
  border: `1px solid ${theme.palette.grey[800]}`,
  borderRadius: '20px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  WebkitBackfaceVisibility: 'hidden',
  backfaceVisibility: 'hidden',
  WebkitFontSmoothing: 'antialiased',
  MozOsxFontSmoothing: 'grayscale',
}));

const GreenShape = styled('svg')({
  position: 'absolute',
  zIndex: 0,
  opacity: 0.05,
  width: '100%',
  height: '80%',
  top: 0,
  left: 0,
  objectFit: 'cover',
  pointerEvents: 'none',
  WebkitTransform: 'translateZ(0)',
  transform: 'translateZ(0)',
});

const HeroSection = ({ content }) => (
  <Box id="hero" sx={{ position: 'relative', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Container maxWidth="md" sx={{ textAlign: 'center', position: 'relative', zIndex: 1 }}>
      <Typography 
        variant="h1" 
        component="h1" 
        sx={{ 
          mb: 3, 
          fontSize: { xs: '4.5rem', sm: '6rem'} 
        }}
      >
        {content.hero.title}
      </Typography>
      <Typography variant="h5" sx={{ mb: 4 }}>
        {content.hero.subtitle}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, justifyContent: 'center' }}>
        <StyledButton 
          variant="contained" 
          size="large"
          href="https://meetings-eu1.hubspot.com/mazda?uuid=36465083-6274-4cc0-a076-e62c90c5450c"
          target="_blank"
          rel="noopener noreferrer"
        >
          {content.hero.demo}
        </StyledButton>
      </Box>
    </Container>
  </Box>
);


const AboutSection = ({ content }) => (
  <Box 
    id="about"
    sx={{ 
      textAlign: 'center',
      mt: 12,
      mb: 8,
      px: 4,
      py: 12,
      bgcolor: 'background.paper',
      width: '100vw',
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
    }}
  >
    <Container maxWidth="lg">
      <Typography 
        variant="h2" 
        sx={{ 
          mb: 2,
          background: 'linear-gradient(45deg, #50C878, #4CAF50)',
          WebkitBackgroundClip: 'text',
          backgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
          color: 'transparent',
        }}
      >
        {content.about.title}
      </Typography>
      
      <Typography 
        variant="h5" 
        color="text.secondary" 
        sx={{ mb: 6 }}
      >
        {content.about.subtitle}
      </Typography>

      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={4}>
          <Avatar
            src="/images/team/sabony.jpg"
            alt="CEO Mazda Sabony"
            sx={{
              width: 280,
              height: 280,
              mx: 'auto',
              mb: { xs: 4, md: 0 },
              border: '4px solid',
              borderColor: 'primary.main',
              boxShadow: theme => `0 8px 24px ${theme.palette.common.black}40`
            }}
          />
        </Grid>
        
        <Grid item xs={12} md={8}>
          <Box sx={{ textAlign: 'left' }}>
            <Typography 
              variant="h4" 
              sx={{ mb: 3, fontWeight: 600 }}
            >
              {content.about.founderTitle}
            </Typography>
            
            <Typography 
              variant="body1" 
              sx={{ 
                mb: 4,
                lineHeight: 1.8,
                color: 'text.secondary',
                fontSize: '1.1rem'
              }}
            >
              {content.about.story}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Team Section */}
      <Box sx={{ mt: 10 }}>
        <Typography 
          variant="h3" 
          sx={{ 
            mb: 6,
            fontWeight: 600 
          }}
        >
          {content.about.team.title}
        </Typography>
        
        <Grid container spacing={3} justifyContent="center">
          {content.about.team.members.map((member, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ 
                height: '100%',
                bgcolor: 'background.default',
                borderRadius: 2,
                boxShadow: theme => `0 4px 20px ${theme.palette.common.black}20`,
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                '&:hover': {
                  transform: 'translateY(-8px)',
                  boxShadow: theme => `0 8px 30px ${theme.palette.common.black}30`
                }
              }}>
                <CardContent sx={{ p: 4, textAlign: 'center' }}>
                  <Avatar 
                    src={member.imagePath || `/images/team/${member.name.split(' ')[1].toLowerCase()}.jpg`}
                    alt={member.name}
                    sx={{ 
                      width: 120, 
                      height: 120, 
                      mx: 'auto', 
                      mb: 3,
                      border: '4px solid',
                      borderColor: 'primary.main'
                    }}
                  />
                  <Typography 
                    variant="h5" 
                    sx={{ 
                      fontWeight: 600,
                      mb: 1
                    }}
                  >
                    {member.name}
                  </Typography>
                  <Typography 
                    variant="subtitle1" 
                    color="primary"
                    sx={{ 
                      mb: 2,
                      fontWeight: 500
                    }}
                  >
                    {member.role}
                  </Typography>
                  <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ 
                      lineHeight: 1.6
                    }}
                  >
                    {member.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  </Box>
);

export default function PiosphereLandingPage() {
  const { keycloak, initialized } = useKeycloak();
  const [language, setLanguage] = useState('en');
  const theme = useTheme();

  useEffect(() => {
    fetch('https://ipapi.co/json/')
      .then(response => response.json())
      .then(data => {
        setLanguage(data.country_code === 'DE' ? 'de' : 'en');
      })
      .catch(error => {
        console.error('Error detecting country:', error);
      });
  }, []);

  const content = landingPageContent[language];

  if (!initialized) {
    return <PageLoading />;
  }

  if (keycloak.authenticated) {
    return <Navigate to="/home" replace />;
  }

  const toggleLanguage = () => {
    setLanguage(language === 'en' ? 'de' : 'en');
  };

  const solutions = content.solutions.cards;
  const testimonials = content.testimonials.items;

  return (
    <Box sx={{ 
      width: '100vw',
      margin: 0,
      padding: 0,
      position: 'relative',
      left: '50%',
      right: '50%',
      marginLeft: '-50vw',
      marginRight: '-50vw',
      overflowX: 'hidden'
    }}>
      <Box sx={{ 
        minHeight: '100vh', 
        bgcolor: 'background.default', 
        color: 'text.primary',
        position: 'relative'
      }}>
        <GreenShape viewBox="0 0 100 100" preserveAspectRatio="none" sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
          <path 
            d="M0,0 
               C25,20 75,20 100,0 
               L100,100 L0,100 Z" 
            fill="#50C878"
          />
        </GreenShape>
        
        <AppBar 
          position="sticky" 
          color="transparent" 
          elevation={0} 
          sx={{ 
            backdropFilter: 'blur(8px)',
            WebkitBackdropFilter: 'blur(8px)',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            transform: 'translateZ(0)',
            WebkitTransform: 'translateZ(0)',
          }}
        >
          <Container maxWidth="xl">
            <Toolbar sx={{ justifyContent: 'space-between', px: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img src="../../images/pv_white.png" alt="Piosphere Logo" style={{ height: '40px' }} />
                <Typography 
                  variant="h6" 
                  component="div" 
                  sx={{ 
                    ml: 1,
                    display: { xs: 'none', md: 'block' }
                  }}
                >
                  Piosphere
                </Typography>
              </Box>
              <Box 
                sx={{ 
                  display: { xs: 'none', lg: 'flex' }, 
                  justifyContent: 'center', 
                  flexGrow: 1 
                }}
              >
                <Box sx={{ display: 'flex', gap: 3 }}>
                  {Object.entries(content.nav).map(([key, value]) => {
                    if (key === 'home') {
                      return (
                        <MuiLink 
                          key={key}
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.scrollTo({ top: 0, behavior: 'smooth' });
                          }}
                          color="inherit" 
                          sx={{ 
                            textDecoration: 'none',
                            cursor: 'pointer'
                          }}
                        >
                          {value}
                        </MuiLink>
                      );
                    }
                    
                    return (
                      <MuiLink 
                        key={key}
                        href={`#${key}`}
                        onClick={(e) => {
                          e.preventDefault();
                          const element = document.getElementById(key);
                          if (element) {
                            const offset = 80; // Account for fixed header
                            const elementPosition = element.getBoundingClientRect().top;
                            const offsetPosition = elementPosition + window.pageYOffset - offset;
                            window.scrollTo({
                              top: offsetPosition,
                              behavior: 'smooth'
                            });
                          }
                        }}
                        color="inherit" 
                        sx={{ 
                          textDecoration: 'none',
                          cursor: 'pointer'
                        }}
                      >
                        {value}
                      </MuiLink>
                    );
                  })}
                </Box>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button 
                  variant="text"
                  onClick={toggleLanguage}
                  sx={{ 
                    color: 'common.white',
                    minWidth: { xs: '40px', sm: 'auto' },
                    px: { xs: 1, sm: 2 },
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <img 
                    src={`https://flagcdn.com/${language === 'en' ? 'de' : 'gb'}.svg`}
                    alt={language === 'en' ? 'German flag' : 'British flag'}
                    style={{
                      width: '24px',
                      height: '16px',
                      borderRadius: '2px',
                      objectFit: 'cover'
                    }}
                  />
                  {language === 'en' ? 'DE' : 'EN'}
                </Button>
                <Button 
                  variant="outlined" 
                  onClick={() => keycloak.login()}
                  sx={{ 
                    ml: 1,
                    color: 'common.white', 
                    borderColor: 'common.white',
                    display: { xs: 'none', sm: 'inline-flex' }
                  }}
                >
                  {content.auth.login}
                </Button>
                <StyledButton 
                  variant="contained"
                  component={Link} 
                  to="/register"
                  sx={{ 
                    ml: 1,
                    minWidth: { xs: '40px', sm: 'auto' },
                    px: { xs: 1, sm: 2 }
                  }}
                >
                  {content.auth.signup}
                </StyledButton>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>

        <Box sx={{ 
          position: 'relative',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '100%',
            background: 'radial-gradient(circle at 50% 50%, rgba(80, 200, 120, 0.1) 0%, rgba(0, 0, 0, 0) 70%)',
            pointerEvents: 'none',
          }
        }}>
          <HeroSection content={content} />
        </Box>
        {/* Solutions Section */}
        <Box 
          id="solutions"
          sx={{ 
            position: 'relative', 
            overflow: 'hidden', 
            width: '100vw', 
            bgcolor: 'background.paper',
            py: 12 
          }}
        >
          <Container maxWidth="lg">
            <Box sx={{ textAlign: 'center', mb: 8 }}>
              <Typography 
                variant="h2" 
                sx={{ 
                  mb: 2,
                  background: 'linear-gradient(45deg, #50C878, #4CAF50)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  color: 'transparent',
                }}
              >
                {content.solutions.title}
              </Typography>
              <Typography 
                variant="h5" 
                color="text.secondary" 
                sx={{ mb: 6 }}
              >
                {content.solutions.subtitle}
              </Typography>
            </Box>

            <Grid container spacing={4}>
              {content.solutions.cards.map((solution, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <FeatureCard sx={{ 
                    height: '100%',
                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: (theme) => `0 12px 24px ${theme.palette.common.black}`
                    }
                  }}>
                    <CardContent sx={{ 
                      p: 4,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column'
                    }}>
                      {/* Icon */}
                      <Avatar 
                        sx={{ 
                          bgcolor: 'primary.main',
                          width: 56,
                          height: 56,
                          mb: 3,
                          flexShrink: 0
                        }}
                      >
                        {getIconComponent(getSolutionIcon(index), { 
                          style: { 
                            width: 30, 
                            height: 30,
                            color: theme.palette.common.black 
                          } 
                        })}
                      </Avatar>

                      {/* Title */}
                      <Typography 
                        variant="h5" 
                        sx={{ 
                          mb: 2,
                          fontWeight: 600,
                          minHeight: '64px', // Fixed height for title
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden'
                        }}
                      >
                        {solution.title}
                      </Typography>

                      {/* Description */}
                      <Typography 
                        variant="body1" 
                        color="text.secondary" 
                        sx={{ 
                          mb: 3,
                          minHeight: '120px', // Fixed height for description
                          display: '-webkit-box',
                          WebkitLineClamp: 4,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden',
                          lineHeight: 1.5
                        }}
                      >
                        {solution.description}
                      </Typography>
                      
                      {/* Benefits List */}
                      <List sx={{ 
                        mt: 'auto',
                        pt: 2,
                        borderTop: 1,
                        borderColor: 'divider'
                      }}>
                        {solution.benefits.map((benefit, idx) => (
                          <ListItem 
                            key={idx} 
                            sx={{ 
                              px: 0,
                              py: 1
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: '32px' }}>
                              <Check 
                                size={20}
                                style={{ 
                                  color: theme.palette.primary.main,
                                  strokeWidth: 3
                                }} 
                              />
                            </ListItemIcon>
                            <ListItemText 
                              primary={benefit}
                              primaryTypographyProps={{
                                sx: { 
                                  fontWeight: 500,
                                  fontSize: '0.95rem',
                                  lineHeight: 1.4
                                }
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </FeatureCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>


        <Box sx={{ 
          bgcolor: 'background.paper',
          py: 12 
        }} id="training">
          <Container maxWidth="lg">
            <Box sx={{ textAlign: 'center', mb: 8 }}>
              <Typography 
                variant="h2" 
                sx={{ 
                  mb: 2,
                  background: 'linear-gradient(45deg, #50C878, #4CAF50)',
                  WebkitBackgroundClip: 'text',
                  backgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  color: 'transparent',
                }}
              >
                {content.training.title}
              </Typography>
              <Typography 
                variant="h5" 
                color="text.secondary" 
                sx={{ mb: 6 }}
              >
                {content.training.subtitle}
              </Typography>
            </Box>

            <Grid container spacing={4}>
              {content.training.levels.map((level, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <FeatureCard sx={{ 
                    height: '100%',
                    transition: 'transform 0.2s ease-in-out',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: (theme) => `0 12px 24px ${theme.palette.common.black}`
                    }
                  }}>
                    <CardContent sx={{ p: 4 }}>
                      <Chip 
                        label={level.level} 
                        color="primary" 
                        sx={{ mb: 2 }}
                      />
                      <Typography 
                        variant="h5" 
                        sx={{ 
                          mb: 1,
                          fontWeight: 600
                        }}
                      >
                        {level.title}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        color="text.secondary" 
                        sx={{ mb: 2 }}
                      >
                        {level.description}
                      </Typography>
                      <Typography 
                        variant="subtitle2" 
                        color="primary" 
                        sx={{ mb: 3 }}
                      >
                        {level.duration}
                      </Typography>
                      
                      <List>
                        {level.topics.map((topic, idx) => (
                          <ListItem 
                            key={idx} 
                            sx={{ 
                              px: 0,
                              py: 0.5
                            }}
                          >
                            <ListItemIcon>
                              <Check 
                                size={20}
                                style={{ 
                                  color: theme.palette.primary.main,
                                  strokeWidth: 3
                                }} 
                              />
                            </ListItemIcon>
                            <ListItemText 
                              primary={topic}
                              primaryTypographyProps={{
                                sx: { 
                                  fontWeight: 500,
                                  fontSize: '0.95rem'
                                }
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </FeatureCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        <Box 
          id="pricing"
          sx={{ position: 'relative', overflow: 'hidden', width: '100vw' }}
        >
          <Container maxWidth="xl" sx={{ py: 12 }}>
            <Typography variant="h2" align="center" sx={{ mb: 2 }}>
              {content.pricing.title}
            </Typography>
            <Typography variant="h5" align="center" sx={{ mb: 8 }}>
              {content.pricing.subtitle}
            </Typography>
            
            <Grid container spacing={3} justifyContent="center">
              {content.pricing.plans.map((plan, index) => (
                <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
                  <FeatureCard 
                    sx={{
                      height: '100%',
                      transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
                      ...(plan.name === "Individual Agent" || plan.name === "Individueller Agent") ? {
                        background: `linear-gradient(135deg, ${theme.palette.background.paper} 0%, ${theme.palette.primary.dark} 400%)`,
                        border: `2px solid ${theme.palette.primary.main}`,
                        boxShadow: `0 0 20px ${theme.palette.primary.main}25`,
                        '&:hover': {
                          transform: 'scale(1.03)',
                          boxShadow: `0 0 30px ${theme.palette.primary.main}40`
                        }
                      } : {
                        '&:hover': {
                          transform: 'scale(1.01)',
                          boxShadow: theme.shadows[10]
                        }
                      }
                    }}
                  >
                    <CardContent sx={{ 
                      p: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%'
                    }}>
                      <Typography 
                        variant="h5" 
                        sx={{ 
                          mb: 2,
                          fontWeight: 'bold',
                          fontSize: '1.5rem',
                          color: (plan.name === "Individual Agent" || plan.name === "Individueller Agent") ? 
                            'primary.main' : 'text.primary'
                        }}
                      >
                        {plan.name}
                      </Typography>
                      <Typography 
                        variant="h4" 
                        sx={{ 
                          mb: 1,
                          fontWeight: 'bold',
                          color: 'primary.main',
                          fontSize: '2rem'
                        }}
                      >
                        {plan.price}
                      </Typography>
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          mb: 3,
                          color: 'text.secondary',
                          fontSize: '0.9rem'
                        }}
                      >
                        {plan.period}
                      </Typography>
                      
                      <List sx={{ 
                        mb: 3,
                        flexGrow: 1
                      }}>
                        {plan.features.map((feature, idx) => (
                          <ListItem 
                            key={idx} 
                            sx={{ 
                              px: 0,
                              py: 0.75
                            }}
                          >
                            <ListItemIcon sx={{ minWidth: 32 }}>
                              <Check 
                                size={18}
                                style={{ 
                                  color: (plan.name === "Individual Agent" || plan.name === "Individueller Agent") ?
                                    theme.palette.primary.light : theme.palette.primary.main,
                                  strokeWidth: 3
                                }} 
                              />
                            </ListItemIcon>
                            <ListItemText 
                              primary={feature}
                              primaryTypographyProps={{
                                sx: { 
                                  fontSize: '0.9rem',
                                  fontWeight: 500,
                                  lineHeight: 1.4,
                                  color: (plan.name === "Individual Agent" || plan.name === "Individueller Agent") ?
                                    'text.primary' : 'inherit'
                                }
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                      
                      <Button
                        variant={(plan.name === "Individual Agent" || plan.name === "Individueller Agent") ? 
                          "contained" : "outlined"}
                        fullWidth
                        size="large"
                        sx={{ 
                          mt: 'auto',
                          py: 1.25,
                          fontSize: '1rem',
                          fontWeight: 'bold',
                          ...(plan.name === "Individual Agent" || plan.name === "Individueller Agent") && {
                            background: 'linear-gradient(45deg, #50C878, #4CAF50)',
                            '&:hover': {
                              background: 'linear-gradient(45deg, #4CAF50, #388E3C)'
                            }
                          }
                        }}
                        color="primary"
                      >
                        {content.cta.button}
                      </Button>
                    </CardContent>
                  </FeatureCard>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Box>

        <Box 
          id="testimonials"
          sx={{ 
            bgcolor: 'background.paper',
            py: 12,
            mt: 12,
            mb: 12
          }}
        >
          <Container maxWidth="lg">
            <Typography 
              variant="h2" 
              align="center" 
              sx={{ mb: 6 }}
            >
              {content.testimonials.title}
            </Typography>
            
            <Grid container spacing={4}>
              {testimonials.map((testimonial, index) => (
                <Grid item xs={12} md={4} key={index}>
                  <Card sx={{ 
                    height: '100%',
                    bgcolor: 'background.default',
                    borderRadius: 2,
                    textAlign: 'center'
                  }}>
                    <CardContent sx={{ p: 4 }}>
                      <Avatar 
                        src={testimonial.avatar} 
                        sx={{ 
                          width: 80, 
                          height: 80, 
                          mx: 'auto', 
                          mb: 2,
                          border: '4px solid',
                          borderColor: 'primary.main'
                        }}
                      />
                      <Typography 
                        variant="subtitle1" 
                        sx={{ 
                          fontWeight: 600,
                          mb: 0.5
                        }}
                      >
                        {testimonial.name}
                      </Typography>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ mb: 3 }}
                      >
                        {testimonial.company}
                      </Typography>
                      <Typography 
                        variant="body1" 
                        sx={{ 
                          fontStyle: 'italic',
                          color: 'text.primary',
                          lineHeight: 1.6
                        }}
                      >
                        "{testimonial.quote}"
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            <AboutSection content={content} />

            <Box 
              sx={{ 
                mt: 10, 
                textAlign: 'center',
                py: 6,
                px: 3,
                bgcolor: 'background.default',
                borderRadius: 4,
                maxWidth: 'md',
                mx: 'auto'
              }}
            >
              <Typography 
                variant="h3" 
                sx={{ 
                  mb: 2,
                  fontWeight: 600 
                }}
              >
                {content.cta.title || "Ready to get started?"}
              </Typography>
              <Typography 
                variant="h5" 
                color="text.secondary" 
                sx={{ mb: 4 }}
              >
                {content.cta.subtitle || "Schedule a demo to see how Piosphere can transform your business"}
              </Typography>
              <StyledButton 
                variant="contained" 
                size="large"
                href="https://meetings-eu1.hubspot.com/mazda?uuid=36465083-6274-4cc0-a076-e62c90c5450c"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  py: 2,
                  px: 4,
                  fontSize: '1.1rem'
                }}
              >
                {content.cta.button || "Schedule a Demo"}
              </StyledButton>
            </Box>
          </Container>
        </Box>

        <Box component="footer" sx={{ pb: 8, pt: 8, bgcolor: 'background.default' }}>
          <Container maxWidth="lg">
            <Grid 
              container 
              spacing={4} 
              sx={{ 
                justifyContent: 'center',
                textAlign: 'center'
              }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" sx={{ 
                  mb: 2,
                  textAlign: { xs: 'center', sm: 'left' }
                }}>
                  {content.footer.company.title}
                </Typography>
                <List sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: { xs: 'center', sm: 'flex-start' },
                  width: '100%',
                  p: 0
                }}>
                  {content.footer.company.links.map((link, index) => (
                    <ListItem 
                      key={index} 
                      sx={{ 
                        px: 0, 
                        py: 0.5,
                        justifyContent: { xs: 'center', sm: 'flex-start' },
                        width: '100%'
                      }}
                    >
                      <MuiLink 
                        href="#" 
                        color="inherit" 
                        sx={{ 
                          textDecoration: 'none',
                          textAlign: { xs: 'center', sm: 'left' }
                        }}
                      >
                        {link}
                      </MuiLink>
                    </ListItem>
                  ))}
                </List>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="h6" sx={{ 
                  mb: 2,
                  textAlign: { xs: 'center', sm: 'left' }
                }}>
                  {content.footer.legal.title}
                </Typography>
                <List sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  alignItems: { xs: 'center', sm: 'flex-start' },
                  width: '100%',
                  p: 0
                }}>
                  {content.footer.legal.links.map((link, index) => (
                    <ListItem 
                      key={index} 
                      sx={{ 
                        px: 0, 
                        py: 0.5,
                        justifyContent: { xs: 'center', sm: 'flex-start' },
                        width: '100%'
                      }}
                    >
                      <MuiLink 
                        href="#" 
                        color="inherit" 
                        sx={{ 
                          textDecoration: 'none',
                          textAlign: { xs: 'center', sm: 'left' }
                        }}
                      >
                        {link}
                      </MuiLink>
                    </ListItem>
                  ))}
                </List>
              </Grid>

              <Grid item xs={12} md={4}>
                <Typography variant="h6" sx={{ 
                  mb: 2,
                  textAlign: { xs: 'center', sm: 'left' }
                }}>
                  {content.footer.contact.title}
                </Typography>
                <Typography variant="body2" sx={{ 
                  mb: 1,
                  textAlign: { xs: 'center', sm: 'left' }
                }}>
                  {content.footer.contact.address}
                </Typography>
                <Typography variant="body2" sx={{ 
                  mb: 1,
                  textAlign: { xs: 'center', sm: 'left' }
                }}>
                  {content.footer.contact.email}
                </Typography>
                <Typography variant="body2" sx={{ 
                  mb: 2,
                  textAlign: { xs: 'center', sm: 'left' }
                }}>
                  {content.footer.contact.phone}
                </Typography>
                <Box sx={{ 
                  textAlign: { xs: 'center', sm: 'left' }
                }}>
                  <MuiLink 
                    href={content.footer.contact.social.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{ 
                      color: 'inherit',
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    LinkedIn
                  </MuiLink>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}

function getIconComponent(iconName, props) {
  const iconMap = {
    zap: Zap,
    brainCircuit: BrainCircuit,
    code: Code,
    rocket: Rocket,
    clock: Clock,
    shoppingBag: ShoppingBag,
    messageCircle: MessageCircle,
    fileText: FileText,
    users: Users,
    bot: Bot,
    search: Search,
    settings: Settings,
    cpu: Cpu,
    cloud: Cloud,
    layers: Layers,
    network: Network,
    database: Database,
  };
  
  const IconComponent = iconMap[iconName];
  return IconComponent ? <IconComponent {...props} /> : null;
}


const getSolutionIcon = (index) => {
  const icons = [
    'bot',           // For AaaS (AI Agents)
    'search',        // For RaaS (Retrieval)
    'settings',      // For LLM Ops
    'cpu',           // For HPC
    'cloud',         // For PaaS
  ];
  return icons[index] || 'brainCircuit';
};