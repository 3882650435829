import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  DialogContentText
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Send as SendIcon } from '@mui/icons-material';

const ROLES = {
  SUPER_ADMIN: { label: 'Super Admin', color: 'error' },
  CLIENT_ADMIN: { label: 'Client Admin', color: 'warning' },
  DEPARTMENT_ADMIN: { label: 'Department Admin', color: 'info' },
  TEAM_LEAD: { label: 'Team Lead', color: 'success' },
  MEMBER: { label: 'Member', color: 'default' }
};

const UserManagement = ({ users, teams, departments, addUser, updateUser, deleteUser, showNotification }) => {
  const [open, setOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [userData, setUserData] = useState({ name: '', email: '', role: 'MEMBER', teamId: '' });
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  
  const handleClickOpen = () => {
    setIsEditMode(false);
    setUserData({ 
      name: '', 
      email: '', 
      role: 'MEMBER', 
      teamId: teams.length > 0 ? teams[0].id : '' 
    });
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
    setUserData({ name: '', email: '', role: 'MEMBER', teamId: '' });
  };
  
  const handleSubmit = () => {
    if (isEditMode) {
      updateUser({
        ...userData,
        id: currentUserId
      });
    } else {
      const newUser = {
        ...userData,
        id: Date.now().toString()
      };
      addUser(newUser);
    }
    handleClose();
  };
  
  const handleEdit = (user) => {
    setIsEditMode(true);
    setCurrentUserId(user.id);
    setUserData({
      name: user.name,
      email: user.email,
      role: user.role,
      teamId: user.teamId
    });
    setOpen(true);
  };
  
  const handleDeleteClick = (userId) => {
    setCurrentUserId(userId);
    setDeleteConfirmOpen(true);
  };
  
  const handleDeleteConfirm = () => {
    deleteUser(currentUserId);
    setDeleteConfirmOpen(false);
  };
  
  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setCurrentUserId(null);
  };
  
  const handleInvite = (user) => {
    showNotification(`Invitation sent to ${user.email}`, 'success');
  };
  
  const isFormValid = () => {
    return userData.name && userData.email && userData.role && userData.teamId;
  };
  
  const getTeamName = (teamId) => {
    const team = teams.find(t => t.id === teamId);
    return team ? team.name : 'Unknown Team';
  };
  
  const getDepartmentNameByTeamId = (teamId) => {
    const team = teams.find(t => t.id === teamId);
    if (!team) return 'Unknown';
    
    const department = departments.find(d => d.id === team.departmentId);
    return department ? department.name : 'Unknown Department';
  };
  
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">User Management</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
          disabled={teams.length === 0}
        >
          Add User
        </Button>
      </Box>
      
      {teams.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography>Please create a team first before adding users.</Typography>
        </Paper>
      ) : users.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography>No users found. Add your first user.</Typography>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Team</TableCell>
                <TableCell>Department</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    <Chip 
                      label={ROLES[user.role]?.label || user.role} 
                      color={ROLES[user.role]?.color || 'default'}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{getTeamName(user.teamId)}</TableCell>
                  <TableCell>{getDepartmentNameByTeamId(user.teamId)}</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" color="primary" onClick={() => handleEdit(user)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton size="small" color="success" title="Send Invite" onClick={() => handleInvite(user)}>
                      <SendIcon />
                    </IconButton>
                    <IconButton size="small" color="error" onClick={() => handleDeleteClick(user.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      
      {/* Create/Edit Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{isEditMode ? 'Edit User' : 'Add New User'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Full Name"
                value={userData.name}
                onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="email"
                label="Email"
                value={userData.email}
                onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Role</InputLabel>
                <Select
                  value={userData.role}
                  label="Role"
                  onChange={(e) => setUserData({ ...userData, role: e.target.value })}
                >
                  {Object.entries(ROLES).map(([key, role]) => (
                    <MenuItem key={key} value={key}>
                      {role.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Team</InputLabel>
                <Select
                  value={userData.teamId}
                  label="Team"
                  onChange={(e) => setUserData({ ...userData, teamId: e.target.value })}
                >
                  {teams.map((team) => (
                    <MenuItem key={team.id} value={team.id}>
                      {team.name} ({getDepartmentNameByTeamId(team.id)})
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={!isFormValid()} variant="contained">
            {isEditMode ? 'Save Changes' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UserManagement;
