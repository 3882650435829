import { MonitoringServiceClient } from '../../generated/monitoring_service/monitoring_messages_grpc_web_pb';
import {
  GetServerMetricsRequest,
  GetAllServersMetricsRequest,
  MetricsRequest,
  ProcessDataRequest,
  HealthCheckRequest,
  HistoricalMetricsRequest,
  AnomalyDetectionRequest,
  ThresholdPredictionRequest,
  CapacityForecastRequest,
  StartMonitoringRequest,
  ServerAddress,
  UserCredential,
  ProcessInfo,
  DockerContainer,
} from '../../generated/monitoring_service/monitoring_messages_pb';

const client = new MonitoringServiceClient(`${process.env.REACT_APP_BASE_URL}/monitoring`, null, null);

const handleError = (error) => {
  console.error('Monitoring API Error:', error);
  return {
    success: false,
    error: error.message || 'Operation failed'
  };
};

const getMetadata = () => ({
  'Content-Type': 'application/grpc-web-text',
  'X-Grpc-Web': '1',
});
export const getServerMetrics = async (ip, port, host) => {
  try {
    // Validate inputs
    if (!ip || !port || !host) {
      throw new Error('Invalid server address');
    }

    // Create request and populate fields
    const request = new GetServerMetricsRequest();
    const serverAddress = new ServerAddress();
    serverAddress.setIp(ip);
    serverAddress.setPort(port);
    serverAddress.setHost(host);
    request.setServerAddress(serverAddress);

    // Make the request with metadata
    const stream = client.getServerMetrics(request, getMetadata());

    // Handle stream events
    stream.on('data', (response) => {
      console.log('Received metrics data:', response.toObject());
    });
    stream.on('error', (error) => {
      console.error('Stream error:', error);
    });
    stream.on('end', () => {
      console.log('Stream ended');
    });

    return {
      stream,
      error: null
    };
  } catch (error) {
    // Handle errors
    return handleError(error);
  }
};


export const getAllServersMetrics = async () => {
  try {
    const request = new GetAllServersMetricsRequest();

    const response = await new Promise((resolve, reject) => {
      const stream = client.getAllServersMetrics(request, getMetadata());
      const results = [];

      stream.on('data', (response) => {
        results.push(response.toObject());
      });

      stream.on('end', () => resolve(results));
      stream.on('error', reject);
    });

    return {
      success: true,
      serversMetrics: response
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getMetrics = async ({ serverAddress }) => {
  try {
    const request = new MetricsRequest();
    request.setServerAddress(serverAddress);

    const response = await new Promise((resolve, reject) => {
      const stream = client.getMetrics(request, {});
      const results = [];

      stream.on('data', (response) => {
        results.push(response.toObject());
      });

      stream.on('end', () => resolve(results));
      stream.on('error', reject);
    });

    return {
      success: true,
      metrics: response
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getProcessData = async (
  userId, username, password, groups,  // user credential params
  ip, port, host,                     // server address params
  pid, isDocker, dockerContainerId    // process info params
) => {
  try {
    const request = new ProcessDataRequest();
    
    // Create ServerAddress message
    const serverAddress = new ServerAddress();
    serverAddress.setIp(ip);
    serverAddress.setPort(port);
    serverAddress.setHost(host);
    
    // Create UserCredential message
    const userCredential = new UserCredential();
    userCredential.setUserId(userId);
    userCredential.setUsername(username);
    // ... set other user credential fields

    // Create ProcessInfo message
    const processInfo = new ProcessInfo();
    processInfo.setPid(pid);
    processInfo.setIsDocker(isDocker);
    if (isDocker && dockerContainerId) {
      const dockerContainer = new DockerContainer();
      dockerContainer.setDockerId(dockerContainerId);
      processInfo.setDockerContainer(dockerContainer);
    }

    request.setUserCredential(userCredential);
    request.setServerAddress(serverAddress);
    request.setProcessInfo(processInfo);

    const response = await new Promise((resolve, reject) => {
      const stream = client.getProcessData(request, {});
      const results = [];

      stream.on('data', (response) => {
        results.push(response.toObject());
      });

      stream.on('end', () => resolve(results));
      stream.on('error', reject);
    });

    return {
      success: true,
      processData: response
    };
  } catch (error) {
    return handleError(error);
  }
};

export const queryHealthCheck = async ({
  userCredential,
  serverAddress,
  requestedMetrics
}) => {
  try {
    const request = new HealthCheckRequest();
    request.setUserCredential(userCredential);
    request.setServerAddress(serverAddress);
    request.setRequestedMetricsList(requestedMetrics);

    const response = await new Promise((resolve, reject) => {
      client.queryHealthCheck(request, {}, (err, response) => {
        if (err) reject(err);
        else resolve(response.toObject());
      });
    });

    return {
      success: true,
      healthCheck: response
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getHistoricalMetrics = async ({
  serverAddress,
  startTime,
  endTime,
  metricType
}) => {
  try {
    const request = new HistoricalMetricsRequest();
    request.setServerAddress(serverAddress);
    request.setStartTime(startTime);
    request.setEndTime(endTime);
    request.setMetricType(metricType);

    const response = await new Promise((resolve, reject) => {
      client.getHistoricalMetrics(request, {}, (err, response) => {
        if (err) reject(err);
        else resolve(response.toObject());
      });
    });

    return {
      success: true,
      historicalMetrics: response
    };
  } catch (error) {
    return handleError(error);
  }
};

export const detectAnomalies = async ({
  serverAddress,
  startTime,
  endTime,
  metricType
}) => {
  try {
    const request = new AnomalyDetectionRequest();
    request.setServerAddress(serverAddress);
    request.setStartTime(startTime);
    request.setEndTime(endTime);
    request.setMetricType(metricType);

    const response = await new Promise((resolve, reject) => {
      client.detectAnomalies(request, {}, (err, response) => {
        if (err) reject(err);
        else resolve(response.toObject());
      });
    });

    return {
      success: true,
      anomalies: response
    };
  } catch (error) {
    return handleError(error);
  }
};

export const predictThresholdBreach = async ({
  serverAddress,
  metricType,
  threshold
}) => {
  try {
    const request = new ThresholdPredictionRequest();
    request.setServerAddress(serverAddress);
    request.setMetricType(metricType);
    request.setThreshold(threshold);

    const response = await new Promise((resolve, reject) => {
      client.predictThresholdBreach(request, {}, (err, response) => {
        if (err) reject(err);
        else resolve(response.toObject());
      });
    });

    return {
      success: true,
      prediction: response
    };
  } catch (error) {
    return handleError(error);
  }
};

export const forecastCapacity = async ({
  serverAddress,
  forecastPeriod
}) => {
  try {
    const request = new CapacityForecastRequest();
    request.setServerAddress(serverAddress);
    request.setForecastPeriod(forecastPeriod);

    const response = await new Promise((resolve, reject) => {
      client.forecastCapacity(request, {}, (err, response) => {
        if (err) reject(err);
        else resolve(response.toObject());
      });
    });

    return {
      success: true,
      forecast: response
    };
  } catch (error) {
    return handleError(error);
  }
};


