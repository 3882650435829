import React, { useState } from 'react';
import {
  Box, Paper, Typography, FormControl, InputLabel,
  Select, MenuItem, TextField, Button, Grid, List,
  ListItem, ListItemText, Card, CardContent, Chip,
  Stepper, Step, StepLabel, IconButton, Tabs, Tab,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress,
  Checkbox
} from '@mui/material';
import HumanEvaluation from './HumanEvaluation';
import AutoEvaluation from './AutoEvaluation';
import AutoEvaluationHistory from './AutoEvaluationHistory';
import { datasets } from '../helpers/MockData';

export default function ModelEvaluation({ myModels, publicModels }) {
  const [evaluationType, setEvaluationType] = useState('human');
  const [selectedModel, setSelectedModel] = useState(null);
  
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      p: { xs: 1, sm: 2, md: 3 },
      width: '100%',
      height: '100%',
      overflow: 'auto'
    }}>
      <Typography variant="h5" gutterBottom sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>
        Model Evaluation
      </Typography>

      <Tabs 
        value={evaluationType} 
        onChange={(e, val) => setEvaluationType(val)} 
        sx={{ 
          mb: 3,
          '.MuiTabs-flexContainer': {
            flexWrap: { xs: 'wrap', sm: 'nowrap' }
          }
        }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab value="human" label="Human Evaluation" />
        <Tab value="auto" label="Auto Evaluation" />
        <Tab value="autoHistory" label="Auto Evaluation History" />
      </Tabs>

      <Box sx={{ 
        maxWidth: '100%',
        overflow: 'auto'
      }}>
        {evaluationType === 'auto' ? (
          <AutoEvaluation 
            myModels={myModels}
            publicModels={publicModels}
            datasets={datasets}
            onStartEvaluation={handleStartEvaluation}
          />
        ) : evaluationType === 'human' ? (
          <HumanEvaluation 
            models={[...myModels, ...publicModels]}
            datasets={datasets}
            selectedModel={selectedModel}
            onModelSelect={setSelectedModel}
          />
        ) : (
          <AutoEvaluationHistory />
        )}
      </Box>
    </Box>
  );
}

function handleStartEvaluation() {
  console.log('Starting evaluation...');
}