import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, Button, Typography, Paper, List, ListItem, ListItemText, ThemeProvider, IconButton, Tooltip, MenuItem, Drawer, Badge, Divider, Switch, FormControlLabel } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { theme } from '../helpers/theme';
import { validateApiKey, generateResponse, createSession, sendChatMessage, getFiles, ingestFile, deleteFile, getModels, sendMessageFeedback } from '../api/services/deployment/Deployment';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const customerSuggestions = {
  'Bitkom': [
    'Können Sie mir etwas über Bitkom und seine Rolle in der Technologiebranche erzählen?',
    'Was sind die technischen Mindestanforderungen?',
    'In welche Kategorien fallen Benchmark-Entwickler?',
    'Können Sie das Verpackungsgesetz (VerpackG16) erklären?'
  ],
  'AI Mag': [
    'What services does AI Mag offer?',
    'Can you explain AI Mag\'s approach to machine learning?',
    'What industries does AI Mag specialize in?',
    'Tell me about AI Mag\'s latest projects'
  ],
  'Piovation': [
    'Was sind die Öffnungszeiten des Landkreises Diepholz?',
    'Wie kann ich einen Termin im Bürgerbüro vereinbaren?',
    'Welche Dokumente benötige ich für die KFZ-Zulassung?',
    'Wo finde ich Informationen zu Bauanträgen?'
  ],
  'Grasbrunn': [
    'Wer kann in Grasbrunn einen Glasfaser-Hausanschluss bestellen?',
    'Wie hoch sind die Bodenrichtwerte in Grasbrunn?',
    'Wie kann ich Briefwahlunterlagen für kommende Wahlen beantragen?',
    'An wen kann ich mich bei Fragen zum Artenschutz wenden?'
  ],
  'Diepholz': [
    'Welche Dienstleistungen bietet Diepholz an?',
    'Wie kann Diepholz bei der digitalen Transformation unterstützen?',
    'In welchen Branchen ist Diepholz tätig?',
    'Erzählen Sie mir von Diepholz\'s Ansatz zur KI-Integration'
  ],
  'default': [
    'How can I help you with your question?',
    'What would you like to know more about?',
    'Do you have any specific questions about our services?',
    'What information are you looking for today?'
  ]
};

const domains = [
  { value: 'rag', label: 'Knowledge Base' },
  { value: 'resume', label: 'Hiring Agent' }
];

export default function ChatAgent() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [hasTyped, setHasTyped] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [domain, setDomain] = useState('rag');
  const [models, setModels] = useState([]);
  const [model, setModel] = useState('');
  const [isModelsLoading, setIsModelsLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [uploadLoading, setUploadLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [sessionId, setSessionId] = useState(null);
  const sessionIdRef = useRef(null);
  const drawerOpenRef = useRef(drawerOpen);
  const pollingIntervalRef = useRef(null);
  const [useChatApi, setUseChatApi] = useState(true);
  const [showReferences, setShowReferences] = useState(true);
  const inputRef = useRef(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userIdentity, setUserIdentity] = useState(null);
  const [feedbackGiven, setFeedbackGiven] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);
  const [isLoadingMoreDocs, setIsLoadingMoreDocs] = useState(false);
  const [totalDocCount, setTotalDocCount] = useState(0);
  const [copyFeedback, setCopyFeedback] = useState({});

  useEffect(() => {
    drawerOpenRef.current = drawerOpen;
  }, [drawerOpen]);

  useEffect(() => {
    sessionIdRef.current = sessionId;
  }, [sessionId]);

  // Initialize session on component mount
  useEffect(() => {
    const initializeSession = async () => {
      try {
        const sessionResponse = await createSession(`Chat ${new Date().toLocaleString()}`);
        if (sessionResponse?.sessionId) {
          setSessionId(sessionResponse.sessionId);
          sessionIdRef.current = sessionResponse.sessionId;
        }
      } catch (error) {
        console.error('Failed to create session:', error);
      }
    };

    if (!sessionId) {
      initializeSession();
    }
  }, []);

  // Add this new useEffect to load document count when component mounts
  useEffect(() => {
    // Load documents when component mounts
    loadDocuments(1, true);
  }, [domain]); // Re-load when domain changes

  // Keep the existing useEffect for drawer state
  useEffect(() => {
    if (drawerOpen) {
      loadDocuments(1, true);
    }
  }, [drawerOpen, domain]);

  // Add a new useEffect to fetch models when component mounts
  useEffect(() => {
    const fetchModels = async () => {
      try {
        setIsModelsLoading(true);
        const response = await getModels();
        const modelsData = response.data || [];
        
        // Process models data - mark DeepSeek models as thinking models
        const processedModels = modelsData.map(model => ({
          value: model.key,
          label: formatModelName(model.key),
          isThinkingModel: model.key.toLowerCase().includes('deepseek')
        }));
        
        setModels(processedModels);
        
        // Set default model if we have models and none is selected yet
        if (processedModels.length > 0 && !model) {
          setModel(processedModels[0].value);
        }
      } catch (error) {
        console.error('Failed to fetch models:', error);
      } finally {
        setIsModelsLoading(false);
      }
    };
    
    fetchModels();
  }, []);
  
  // Helper function to format model names for display
  const formatModelName = (modelKey) => {
    // Extract model name from key (after the last slash if present)
    const modelName = modelKey.split('/').pop() || modelKey;
    
    // Split by camel case, hyphens, or underscores and capitalize each word
    return modelName
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Split camelCase
      .replace(/[_-]/g, ' ') // Replace underscores and hyphens with spaces
      .replace(/\b\w/g, c => c.toUpperCase()); // Capitalize first letter of each word
  };
  
  // Helper function to check if current model is a thinking model
  const isCurrentModelThinkingModel = () => {
    const currentModel = models.find(m => m.value === model);
    return currentModel?.isThinkingModel || model.toLowerCase().includes('deepseek');
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSuggestionClick = (suggestion) => {
    setInput(suggestion);
    setHasTyped(true);
  };

  const handleSend = async () => {
    if (input.trim() === '') return;
    
    const userMessage = input.trim();
    setMessages([...messages, { text: userMessage, sender: 'user' }]);
    setInput('');
    setIsLoading(true);

    try {
      if (useChatApi) {
        if (!sessionIdRef.current) {
          const sessionResponse = await createSession(`Chat ${new Date().toLocaleString()}`);
          if (sessionResponse?.sessionId) {
            setSessionId(sessionResponse.sessionId);
            sessionIdRef.current = sessionResponse.sessionId;
          } else {
            throw new Error('Failed to create session');
          }
        }
        
        const response = await sendChatMessage(sessionIdRef.current, userMessage, domain, model);
        
        if (!response.body) {
          throw new Error('No response body');
        }
        
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';
        let isThinkingModel = isCurrentModelThinkingModel();
        let isThinking = isThinkingModel;
        let thinkingContent = '';
        let responseContent = '';
        let messageId = null;
        
        setMessages(prevMessages => [...prevMessages, { 
          text: '', 
          sender: 'ai', 
          isThinking,
          thinkingContent: '',
          responseContent: '',
          messageId: null
        }]);

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }

          const chunk = decoder.decode(value);
          buffer += chunk;

          const lines = buffer.split('\n');
          buffer = lines.pop() || '';

          let previousLineWasEmpty = false;

          for (const line of lines) {
            if (line.startsWith('data: metadata')) {
              try {
                const metadataStr = line.slice(14);
                const metadata = JSON.parse(metadataStr);
                if (metadata.message_id) {
                  messageId = metadata.message_id;
                }
              } catch (e) {
                console.error('Error parsing metadata:', e);
              }
              continue;
            }
            
            if (line.startsWith('data: ')) {
              let content = line.slice(6);

              if (isThinking) {
                thinkingContent += content;

                if (content.includes('</think>')) {
                  isThinking = false;
                  const parts = thinkingContent.split('</think>');
                  thinkingContent = parts[0];
                  if (parts.length > 1) {
                    responseContent += parts[1];
                  }
                }
              } else {
                responseContent += content;
              }

              previousLineWasEmpty = false;
            } else if (line.trim() === '') {
              if (previousLineWasEmpty) {
                if (isThinking) {
                  thinkingContent += '\n';
                } else {
                  responseContent += '\n';
                }
                previousLineWasEmpty = false;
              } else {
                previousLineWasEmpty = true;
              }
            }
          }

          setMessages(prevMessages => {
            const newMessages = [...prevMessages];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage?.sender === 'ai') {
              const displayText = isThinkingModel 
                ? (thinkingContent + (responseContent ? '</think>' + responseContent : ''))
                : responseContent;
                
              return newMessages.map((msg, i) =>
                i === newMessages.length - 1 
                  ? { 
                      ...msg, 
                      text: displayText,
                      isThinking,
                      thinkingContent,
                      responseContent,
                      messageId
                    } 
                  : msg
              );
            }
            return newMessages;
          });
        }
      } else {
        const response = await generateResponse({
          query: userMessage,
          model,
          domain
        });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = '';
        let isThinkingModel = isCurrentModelThinkingModel();
        let isThinking = isThinkingModel;
        let thinkingContent = '';
        let responseContent = '';
        let messageId = null;
        
        setMessages(prevMessages => [...prevMessages, { 
          text: '', 
          sender: 'ai',
          isThinking,
          thinkingContent: '',
          responseContent: '',
          messageId: null
        }]);

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }

          const chunk = decoder.decode(value);
          buffer += chunk;

          const lines = buffer.split('\n');
          buffer = lines.pop() || '';

          let previousLineWasEmpty = false;

          for (const line of lines) {
            if (line.startsWith('data: metadata')) {
              try {
                const metadataStr = line.slice(14);
                const metadata = JSON.parse(metadataStr);
                if (metadata.message_id) {
                  messageId = metadata.message_id;
                }
              } catch (e) {
                console.error('Error parsing metadata:', e);
              }
              continue;
            }
            
            if (line.startsWith('data: ')) {
              let content = line.slice(6);

              if (isThinking) {
                thinkingContent += content;

                if (content.includes('</think>')) {
                  isThinking = false;
                  const parts = thinkingContent.split('</think>');
                  thinkingContent = parts[0];
                  if (parts.length > 1) {
                    responseContent += parts[1];
                  }
                }
              } else {
                responseContent += content;
              }

              previousLineWasEmpty = false;
            } else if (line.trim() === '') {
              if (previousLineWasEmpty) {
                if (isThinking) {
                  thinkingContent += '\n';
                } else {
                  responseContent += '\n';
                }
                previousLineWasEmpty = false;
              } else {
                previousLineWasEmpty = true;
              }
            }
          }

          setMessages(prevMessages => {
            const newMessages = [...prevMessages];
            const lastMessage = newMessages[newMessages.length - 1];
            if (lastMessage?.sender === 'ai') {
              const displayText = isThinkingModel 
                ? (thinkingContent + (responseContent ? '</think>' + responseContent : ''))
                : responseContent;
                
              return newMessages.map((msg, i) =>
                i === newMessages.length - 1 
                  ? { 
                      ...msg, 
                      text: displayText,
                      isThinking,
                      thinkingContent,
                      responseContent,
                      messageId
                    } 
                  : msg
              );
            }
            return newMessages;
          });
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        {
          text: 'Sorry, there was an error processing your request.',
          sender: 'ai',
        },
      ]);
    } finally {
      setIsLoading(false);
      scrollToBottom();
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (!hasTyped && e.target.value !== '') {
      setHasTyped(true);
    }
  };
  
  // Update loadDocuments function to work even when drawer is closed
  const loadDocuments = async (page = 1, isInitialLoad = true) => {
    try {
      if (isInitialLoad) {
        setUploadLoading(true);
        setDocuments([]);
        setCurrentPage(1);
        setHasMorePages(true);
      } else {
        setIsLoadingMoreDocs(true);
      }
      
      const response = await getFiles(domain, page);
      const files = response.data?.files || [];
      
      // Get total count from API response if available
      const totalCount = response.data?.total_count;
      if (totalCount !== undefined) {
        setTotalDocCount(totalCount);
      }
      
      if (files.length === 0) {
        setHasMorePages(false);
      } else {
        setCurrentPage(page);
        setDocuments(prev => isInitialLoad ? files : [...prev, ...files]);
      }
    
      const hasPreprocessingFiles = files.some(file => file.status === 'preprocessing');
      
      if (hasPreprocessingFiles && drawerOpenRef.current) {
        startPolling();
      } else {
        stopPolling();
      }
    } catch (error) {
      console.error('Error loading documents:', error);
      setHasMorePages(false);
    } finally {
      setUploadLoading(false);
      setIsLoadingMoreDocs(false);
    }
  };
  
  // Add a handler for loading more documents
  const handleLoadMoreDocuments = () => {
    if (!isLoadingMoreDocs && hasMorePages) {
      loadDocuments(currentPage + 1, false);
    }
  };

  // Update loadDocuments call in useEffect hooks to use the new function
  useEffect(() => {
    // Load documents when component mounts
    if (drawerOpen) {
      loadDocuments(1, true);
    }
  }, [drawerOpen, domain]);

  // Add an observer for infinite scrolling
  const documentsEndRef = useRef(null);

  useEffect(() => {
    if (!documentsEndRef.current || !drawerOpen) return;
    
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasMorePages && !isLoadingMoreDocs) {
        handleLoadMoreDocuments();
      }
    }, { threshold: 0.5 });
    
    observer.observe(documentsEndRef.current);
    
    return () => {
      if (documentsEndRef.current) {
        observer.unobserve(documentsEndRef.current);
      }
    };
  }, [drawerOpen, hasMorePages, isLoadingMoreDocs, currentPage]);

  const handleFileUpload = async (event) => {
    const files = Array.from(event.target.files || []);
    if (files.length === 0) return;

    setUploadLoading(true);
    try {
      // Upload all files sequentially
      for (const file of files) {
        await ingestFile(file, domain);
        setMessages(prev => [...prev, {
          text: `Document "${file.name}" has been uploaded and is being processed.`,
          sender: 'ai'
        }]);
      }
      
      // Reset pagination and load first page
      loadDocuments(1, true);
      event.target.value = '';
    } catch (error) {
      console.error('Error uploading files:', error);
      setMessages(prev => [...prev, {
        text: 'Sorry, there was an error uploading one or more documents.',
        sender: 'ai'
      }]);
    } finally {
      setUploadLoading(false);
    }
  };
  
  const handleDeleteDocument = async (fileId) => {
    try {
      await deleteFile(fileId, domain);
      // Reset pagination and load first page
      loadDocuments(1, true);
    } catch (error) {
      console.error('Error deleting document:', error);
    }
  };
  
  const getStatusColor = (status) => {
    switch (status) {
      case 'complete':
        return 'success.main';
      case 'preprocessing':
        return 'warning.main';
      default:
        return 'info.main';
    }
  };

  useEffect(() => {
    if (drawerOpen) {
      loadDocuments(1, true);
    }
  }, [drawerOpen, domain]);

  useEffect(() => {
    const timer = setTimeout(scrollToBottom, 50);
    return () => clearTimeout(timer);
  }, [messages]);

  const startPolling = () => {
    if (!pollingIntervalRef.current && drawerOpenRef.current) {
      pollingIntervalRef.current = setInterval(() => {
        if (drawerOpenRef.current) {
          loadDocuments();
        }
      }, 5000);
    }
  };

  const stopPolling = () => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }
  };

  useEffect(() => {
    if (drawerOpenRef.current) {
      loadDocuments();
    } else {
      stopPolling();
    }
    return () => stopPolling();
  }, [drawerOpenRef]);

  // Extract references from AI response text
  const extractReferences = (text) => {
    // Look for <ref> tags in the text
    const refRegex = /<ref>(.*?)<\/ref>/gs;
    const matches = [...text.matchAll(refRegex)];
    
    // Return original text without ref tags and the extracted references
    const cleanText = text.replace(refRegex, '').trim();
    const references = matches.map(match => match[1].trim());
    
    return { cleanText, references };
  };

  // Add a new useEffect to get user identity from API key
  useEffect(() => {
    const fetchUserIdentity = async () => {
      try {
        const keyDetails = await validateApiKey();
        if (keyDetails) {
          setUserIdentity({
            name: keyDetails.name,
            role: keyDetails.role,
            id: keyDetails.id
          });
        }
      } catch (error) {
        console.error('Failed to fetch user identity:', error);
      }
    };

    fetchUserIdentity();
  }, []);

  // Get the appropriate suggestions based on user identity
  const getSuggestions = () => {
    if (!userIdentity || !userIdentity.name) return customerSuggestions.default;
    return customerSuggestions[userIdentity.name] || customerSuggestions.default;
  };

  // Add a new function to handle feedback submission
  const handleFeedback = async (messageId, isGood) => {
    if (!messageId || feedbackGiven[messageId]) return;
    
    try {
      await sendMessageFeedback(messageId, isGood);
      setFeedbackGiven(prev => ({
        ...prev,
        [messageId]: isGood ? 'positive' : 'negative'
      }));
    } catch (error) {
      console.error('Error submitting feedback:', error);
    }
  };

  // Add this function to handle copying text to clipboard
  const handleCopyText = async (text, messageId) => {
    try {
      // Clean the text by removing refs and thinking content
      let textToCopy = text;
      
      // For DeepSeek models, only copy the response content, not thinking
      if (model.includes('DeepSeek') && text.includes('</think>')) {
        textToCopy = text.split('</think>')[1];
      }
      
      // Remove any reference tags
      textToCopy = textToCopy.replace(/<ref>.*?<\/ref>/gs, '');
      
      // Remove any message IDs
      textToCopy = textToCopy.replace(/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/, '');
      
      await navigator.clipboard.writeText(textToCopy.trim());
      
      // Show copy feedback
      setCopyFeedback(prev => ({
        ...prev,
        [messageId || Date.now()]: true
      }));
      
      // Hide feedback after 2 seconds
      setTimeout(() => {
        setCopyFeedback(prev => ({
          ...prev,
          [messageId || Date.now()]: false
        }));
      }, 2000);
    } catch (error) {
      console.error('Failed to copy text:', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ 
        bgcolor: 'background.default', 
        height: '97vh', 
        display: 'flex', 
        flexDirection: 'column',
        '@keyframes blink': {
          '0%, 100%': { opacity: 1 },
          '50%': { opacity: 0 }
        },
        '@keyframes typing': {
          'from': { width: 0 },
          'to': { width: '100%' }
        }
      }}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: { xs: 1, sm: 3 },
          py: 1,
          minHeight: '50px',
          borderBottom: '1px solid',
          borderColor: 'divider',
          flexWrap: 'nowrap', // Always keep in a single line
          gap: 1,
        }}>
          {/* Left section with logo and mobile menu button */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
          }}>
            <Typography 
              variant="subtitle1" 
              sx={{ 
                fontWeight: 600,
                color: 'primary.main',
              }}
            >
              PioAgent
            </Typography>
            
            {/* Mobile menu button - always visible on small screens */}
            <IconButton 
              onClick={() => setMobileMenuOpen(true)} 
              size="small"
              sx={{ 
                color: 'primary.main',
                display: { xs: 'flex', md: 'none' },
              }}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          {/* Middle section with domain and model selectors - ONLY visible on medium+ screens */}
          <Box sx={{ 
            display: { xs: 'none', md: 'flex' }, 
            alignItems: 'center', 
            gap: 1.5,
            flexWrap: 'nowrap',
            mx: 1,
          }}>
            <Box sx={{ minWidth: 100 }}>
              <TextField
                select
                size="small"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'background.paper',
                    fontSize: '0.875rem',
                    '&:hover': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                      }
                    },
                    '&.Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                      }
                    }
                  },
                  '& .MuiSelect-select': {
                    py: 1,
                    px: 1.5,
                  }
                }}
              >
                {domains.map((option) => (
                  <MenuItem 
                    key={option.value} 
                    value={option.value}
                    sx={{
                      fontSize: '0.875rem',
                      py: 1,
                      px: 1.5,
                      '&:hover': {
                        bgcolor: 'primary.lighter',
                      },
                      '&.Mui-selected': {
                        bgcolor: 'primary.lighter',
                        '&:hover': {
                          bgcolor: 'primary.light',
                        }
                      }
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Box sx={{ minWidth: 100 }}>
              <TextField
                select
                size="small"
                value={model}
                onChange={(e) => setModel(e.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    bgcolor: 'background.paper',
                    fontSize: '0.875rem',
                    '&:hover': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                      }
                    },
                    '&.Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'primary.main',
                      }
                    }
                  },
                  '& .MuiSelect-select': {
                    py: 1,
                    px: 1.5,
                  }
                }}
                disabled={isModelsLoading}
              >
                {isModelsLoading ? (
                  <MenuItem disabled>Loading models...</MenuItem>
                ) : (
                  models.map((option) => (
                    <MenuItem 
                      key={option.value} 
                      value={option.value}
                      sx={{
                        fontSize: '0.875rem',
                        py: 1,
                        px: 1.5,
                        '&:hover': {
                          bgcolor: 'primary.lighter',
                        },
                        '&.Mui-selected': {
                          bgcolor: 'primary.lighter',
                          '&:hover': {
                            bgcolor: 'primary.light',
                          }
                        }
                      }}
                    >
                      {option.label} {option.isThinkingModel && "(Thinking)"}
                    </MenuItem>
                  ))
                )}
              </TextField>
            </Box>
            
            <FormControlLabel
              control={
                <Switch
                  checked={useChatApi}
                  onChange={(e) => setUseChatApi(e.target.checked)}
                  color="primary"
                  size="small"
                />
              }
              label={
                <Typography variant="body2">
                  {useChatApi ? "Chat History" : "Chat History"}
                </Typography>
              }
            />
            
            <FormControlLabel
              control={
                <Switch
                  checked={showReferences}
                  onChange={(e) => setShowReferences(e.target.checked)}
                  color="primary"
                  size="small"
                />
              }
              label={
                <Typography variant="body2">
                  Show References
                </Typography>
              }
            />
          </Box>

          {/* Right section with action buttons - always visible */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: { xs: 1, sm: 2 },
            ml: 'auto',
          }}>
            {/* Keep only the "View Documents" button */}
            <Tooltip title="View Documents">
              <IconButton 
                onClick={() => setDrawerOpen(true)}
                size="small"
                sx={{ 
                  color: 'primary.main',
                  bgcolor: 'background.paper',
                  boxShadow: 2,
                  '&:hover': {
                    bgcolor: 'background.paper',
                    boxShadow: 4,
                  }
                }}
              >
                <Badge 
                  badgeContent={totalDocCount} 
                  color="primary"
                  sx={{
                    '& .MuiBadge-badge': {
                      fontSize: '0.8rem',
                      height: '22px',
                      minWidth: '22px',
                    }
                  }}
                >
                  <FolderIcon />
                </Badge>
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ 
          flexGrow: 1, 
          overflow: 'auto', 
          p: 2, 
          maxWidth: '800px', 
          mx: 'auto', 
          width: '100%',
          scrollBehavior: 'smooth'
        }}>
          <List sx={{ display: 'flex', flexDirection: 'column' }}>
            {messages.length === 0 && (
              <Box sx={{ 
                textAlign: 'center', 
                mt: 8,
                px: 2,
                maxWidth: '680px',
                mx: 'auto',
                animation: 'fadeIn 0.5s ease',
                '@keyframes fadeIn': {
                  '0%': { opacity: 0, transform: 'translateY(20px)' },
                  '100%': { opacity: 1, transform: 'translateY(0)' }
                }
              }}>
                <LightbulbIcon sx={{ 
                  fontSize: 60, 
                  color: 'primary.main', 
                  mb: 2,
                  filter: 'drop-shadow(0 4px 8px rgba(0, 150, 255, 0.3))'
                }} />
                <Typography variant="h5" gutterBottom sx={{ 
                  fontWeight: 600, 
                  color: 'text.primary',
                  letterSpacing: '-0.5px',
                  mb: 3
                }}>
                  How can I assist you today?
                </Typography>
                <Box sx={{ 
                  display: 'grid', 
                  gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' }, 
                  gap: 2,
                  maxWidth: '600px',
                  mx: 'auto'
                }}>
                  {getSuggestions().map((prompt, index) => (
                    <Paper
                      key={index}
                      onClick={() => handleSuggestionClick(prompt)}
                      sx={{
                        p: 2,
                        cursor: 'pointer',
                        border: '1px solid',
                        borderColor: 'divider',
                        bgcolor: 'background.paper',
                        transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          borderColor: 'primary.main',
                          boxShadow: 3,
                          bgcolor: 'background.default'
                        },
                        borderRadius: 3,
                      }}
                    >
                      <Typography variant="body2" sx={{ 
                        color: 'text.primary',
                        fontWeight: 500,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1
                      }}>
                        <span style={{ 
                          width: 8, 
                          height: 8, 
                          borderRadius: '50%', 
                          backgroundColor: theme.palette.primary.main 
                        }}/>
                        {prompt}
                      </Typography>
                    </Paper>
                  ))}
                </Box>
              </Box>
            )}
            {messages.map((message, index) => {
              // Process AI message text to extract or clean data
              let cleanText = message.text;
              let references = [];
              
              if (message.sender === 'ai') {
                // Extract and clean the message ID if it's appended to the text
                const idRegex = /([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/;
                const idMatch = message.text.match(idRegex);
                
                if (idMatch) {
                  // If ID is in the text, extract it and clean the text
                  cleanText = message.text.replace(idRegex, '');
                  message.messageId = idMatch[1];
                } else {
                  cleanText = message.text;
                }
                
                // Process references if needed
                if (showReferences) {
                  // For DeepSeek models, only extract references from the response content, not thinking content
                  if (model.includes('DeepSeek') && message.responseContent) {
                    const extracted = extractReferences(message.responseContent);
                    cleanText = message.text.replace(idRegex, '');  // Keep the full text but remove the ID
                    references = extracted.references;
                  } else if (model.includes('DeepSeek') && message.text.includes('</think>')) {
                    // If we have the full text with a </think> tag, extract references only from the part after </think>
                    const parts = message.text.split('</think>');
                    const responseText = parts.length > 1 ? parts[1] : '';
                    const extracted = extractReferences(responseText);
                    cleanText = message.text.replace(idRegex, '');  // Keep the full text but remove the ID
                    references = extracted.references;
                  } else {
                    // For non-DeepSeek models, extract references from the full text
                    const extracted = extractReferences(cleanText);  // Use already cleaned text without ID
                    cleanText = extracted.cleanText;
                    references = extracted.references;
                  }
                } else {
                  // If references are disabled, completely remove the reference tags and their content
                  cleanText = cleanText.replace(/<ref>.*?<\/ref>/gs, '');
                }
              }
              
              return (
                <ListItem
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: message.sender === 'user' ? 'flex-end' : 'flex-start',
                    px: 0,
                    position: 'relative', // Make the ListItem a positioning context
                    mb: message.sender === 'ai' && message.messageId ? 3 : 1, // Add extra margin for messages with feedback buttons
                  }}
                >
                  <Paper
                    elevation={1}
                    sx={{
                      maxWidth: message.sender === 'user' ? '80%' : '100%',
                      width: message.sender === 'user' ? 'auto' : '100%',
                      p: 2.5,
                      bgcolor: message.sender === 'user' ? theme.palette.primary.main : 'background.paper',
                      color: message.sender === 'user' ? 'black' : 'text.primary',
                      borderRadius: 2,
                      alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
                    }}
                  >
                    {message.sender === 'user' ? (
                      <Typography>{message.text}</Typography>
                    ) : (
                      <>
                        {/* Always show thinking box for thinking models if there's thinking content */}
                        {isCurrentModelThinkingModel() && message.thinkingContent && (
                          <Box sx={{ 
                            position: 'relative',
                            mb: 3,
                            p: 2,
                            pl: 3,
                            borderRadius: 1,
                            bgcolor: 'rgba(0, 0, 0, 0.04)',
                            borderLeft: '3px solid',
                            borderColor: 'primary.light',
                            '&::before': {
                              content: '"Thinking..."',
                              position: 'absolute',
                              top: -10,
                              left: 12,
                              fontSize: '0.7rem',
                              fontWeight: 600,
                              color: 'primary.light',
                              bgcolor: 'background.paper',
                              px: 1,
                              borderRadius: 1,
                            }
                          }}>
                            <Typography 
                              variant="body2" 
                              sx={{ 
                                fontStyle: 'italic', 
                                color: 'text.secondary',
                                fontSize: '0.9rem',
                                lineHeight: 1.5,
                              }}
                            >
                              {message.thinkingContent || (message.text.includes('</think>') ? message.text.split('</think>')[0] : '')}
                            </Typography>
                          </Box>
                        )}
                        
                        {/* For non-DeepSeek models or when we have response content */}
                        {(!model.includes('DeepSeek') || message.responseContent || 
                          (message.text.includes('</think>') && !message.isThinking)) && (
                          <ReactMarkdown
                            remarkPlugins={[remarkGfm]}
                            components={{
                              p: ({ children }) => {
                                return (
                                  <Typography 
                                    component="div" 
                                    sx={{ 
                                      mb: 1,
                                      '&:last-child': { mb: 0 }
                                    }}
                                  >
                                    {children}
                                  </Typography>
                                );
                              },
                              ul: ({ children }) => (
                                <Box 
                                  component="ul" 
                                  sx={{ 
                                    m: 0,
                                    pl: 0,
                                    listStylePosition: 'inside',
                                  }}
                                >
                                  {children}
                                </Box>
                              ),
                              ol: ({ children }) => (
                                <Box 
                                  component="ol" 
                                  sx={{ 
                                    m: 0,
                                    pl: 0,
                                    listStylePosition: 'inside',
                                  }}
                                >
                                  {children}
                                </Box>
                              ),
                              li: ({ children }) => (
                                <Typography 
                                  component="li" 
                                  sx={{ 
                                    mb: 0.5,
                                    display: 'list-item',
                                    '&::marker': {
                                      color: 'text.primary',
                                    },
                                    pl: 1,
                                    textIndent: '-1em',
                                    paddingInlineStart: '1em',
                                  }}
                                >
                                  {children}
                                </Typography>
                              ),
                              table: ({ children }) => (
                                <Box sx={{ 
                                  width: '100%', 
                                  overflowX: 'auto',
                                  my: 3,
                                  display: 'block',
                                }}>
                                  <Box
                                    component="table" 
                                    sx={{ 
                                      width: '100%',
                                      borderCollapse: 'separate',
                                      borderSpacing: 0,
                                      border: '1px solid',
                                      borderColor: 'divider',
                                      borderRadius: 1,
                                      overflow: 'hidden',
                                      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                                      minWidth: '500px',
                                      tableLayout: 'fixed',
                                      transition: 'all 0.2s ease',
                                    }}
                                  >
                                    {children}
                                  </Box>
                                </Box>
                              ),
                              thead: ({ children }) => (
                                <Box component="thead" sx={{ 
                                  bgcolor: 'primary.lighter',
                                }}>
                                  {children}
                                </Box>
                              ),
                              tbody: ({ children }) => (
                                <Box component="tbody">
                                  {children}
                                </Box>
                              ),
                              tr: ({ children, ...props }) => (
                                <Box 
                                  component="tr" 
                                  sx={{ 
                                    borderBottom: '1px solid',
                                    borderColor: 'divider',
                                    '&:last-child': {
                                      borderBottom: 'none',
                                    },
                                    '&:hover': {
                                      bgcolor: 'action.hover',
                                    },
                                    transition: 'background-color 0.15s ease',
                                  }}
                                  {...props}
                                >
                                  {children}
                                </Box>
                              ),
                              th: ({ children }) => (
                                <Box 
                                  component="th" 
                                  sx={{ 
                                    py: 2,
                                    px: 2.5,
                                    textAlign: 'left',
                                    fontWeight: 600,
                                    fontSize: '0.875rem',
                                    color: 'primary.dark',
                                    borderRight: '1px solid',
                                    borderColor: 'divider',
                                    '&:last-child': {
                                      borderRight: 'none',
                                    },
                                    position: 'relative',
                                  }}
                                >
                                  {children}
                                </Box>
                              ),
                              td: ({ children, node }) => {
                                // If this is just a cell with a hyphen, render it normally
                                const isJustHyphen = node.children?.length === 1 && 
                                                    node.children[0].type === 'text' && 
                                                    node.children[0].value === '-';
                                                    
                                return (
                                  <Box 
                                    component="td" 
                                    sx={{ 
                                      py: 1.5,
                                      px: 2.5,
                                      textAlign: 'left',
                                      fontSize: '0.875rem',
                                      borderRight: '1px solid',
                                      borderColor: 'divider',
                                      '&:last-child': {
                                        borderRight: 'none',
                                      },
                                      wordBreak: 'break-word',
                                    }}
                                  >
                                    {isJustHyphen ? '-' : (children || "-")}
                                  </Box>
                                );
                              },
                            }}
                          >
                            {model.includes('DeepSeek') 
                              ? (message.responseContent 
                                 ? message.responseContent.replace(/<ref>.*?<\/ref>/gs, '').replace(/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/, '') 
                                 : (message.text.includes('</think>') 
                                    ? message.text.split('</think>')[1].replace(/<ref>.*?<\/ref>/gs, '').replace(/([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})$/, '') 
                                    : ''))
                              : cleanText}
                          </ReactMarkdown>
                        )}
                      </>
                    )}
                  </Paper>
                  
                  {/* Feedback buttons for AI messages with a messageId - positioned below the message on the left */}
                  {message.sender === 'ai' && message.messageId && (
                    <Box sx={{ 
                      position: 'absolute',
                      left: '0px',
                      bottom: '-28px',
                      display: 'flex',
                      gap: 0.5,
                      zIndex: 2,
                    }}>
                      <Tooltip title="Helpful">
                        <IconButton
                          size="small"
                          onClick={() => handleFeedback(message.messageId, true)}
                          disabled={feedbackGiven[message.messageId]}
                          color={feedbackGiven[message.messageId] === 'positive' ? 'primary' : 'default'}
                          sx={{
                            padding: '4px',
                            opacity: feedbackGiven[message.messageId] ? (feedbackGiven[message.messageId] === 'positive' ? 1 : 0.5) : 0.7,
                            bgcolor: 'background.paper',
                            boxShadow: 1,
                            '&:hover': {
                              opacity: 1,
                              bgcolor: 'primary.lighter',
                            }
                          }}
                        >
                          <ThumbUpIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Not Helpful">
                        <IconButton
                          size="small"
                          onClick={() => handleFeedback(message.messageId, false)}
                          disabled={feedbackGiven[message.messageId]}
                          color={feedbackGiven[message.messageId] === 'negative' ? 'error' : 'default'}
                          sx={{
                            padding: '4px',
                            opacity: feedbackGiven[message.messageId] ? (feedbackGiven[message.messageId] === 'negative' ? 1 : 0.5) : 0.7,
                            bgcolor: 'background.paper',
                            boxShadow: 1,
                            '&:hover': {
                              opacity: 1,
                              bgcolor: 'error.lighter',
                            }
                          }}
                        >
                          <ThumbDownIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                      </Tooltip>
                      {/* Add copy button next to the thumbs up/down buttons */}
                      <Tooltip title={copyFeedback[message.messageId || index] ? "Copied!" : "Copy response"}>
                        <IconButton
                          size="small"
                          onClick={() => handleCopyText(message.text, message.messageId || index)}
                          sx={{
                            padding: '4px',
                            opacity: copyFeedback[message.messageId || index] ? 1 : 0.7,
                            bgcolor: 'background.paper',
                            boxShadow: 1,
                            color: copyFeedback[message.messageId || index] ? 'success.main' : 'text.secondary',
                            '&:hover': {
                              opacity: 1,
                              bgcolor: 'action.hover',
                            }
                          }}
                        >
                          <ContentCopyIcon sx={{ fontSize: '16px' }} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  )}
                  
                  {/* Only show references section if showReferences is true and there are references */}
                  {message.sender === 'ai' && showReferences && references.length > 0 && (
                    <Paper
                      elevation={0}
                      sx={{
                        width: '100%',
                        mt: 1.5,
                        p: 2,
                        bgcolor: 'background.default',
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 2,
                        boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      }}
                    >
                      <Typography 
                        variant="subtitle2" 
                        sx={{ 
                          fontWeight: 600, 
                          mb: 1,
                          color: 'text.secondary',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1
                        }}
                      >
                        <Box 
                          component="span" 
                          sx={{ 
                            height: 16, 
                            width: 3, 
                            bgcolor: 'primary.main',
                            borderRadius: 1,
                            display: 'inline-block'
                          }}
                        />
                        References
                      </Typography>
                      <Box component="ul" sx={{ m: 0, pl: 2 }}>
                        {references.map((ref, idx) => (
                          <Typography 
                            key={idx} 
                            component="li" 
                            variant="body2" 
                            sx={{ 
                              color: 'text.secondary',
                              mb: 0.5,
                              '&:last-child': { mb: 0 }
                            }}
                          >
                            {ref}
                          </Typography>
                        ))}
                      </Box>
                    </Paper>
                  )}
                </ListItem>
              );
            })}
            <div ref={messagesEndRef} />
          </List>
        </Box>
        <Box sx={{ p: 2, maxWidth: '800px', mx: 'auto', width: '100%' }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            bgcolor: 'background.paper', 
            borderRadius: '12px', 
            p: 1,
            border: '1px solid rgba(255, 255, 255, 0.1)',
            '&:focus-within': {
              border: `1px solid ${theme.palette.primary.main}`,
            }
          }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder={isLoading ? "Generating response..." : "Ask me anything..."}
              value={input}
              onChange={handleInputChange}
              onKeyPress={(e) => e.key === 'Enter' && !isLoading && handleSend()}
              inputRef={inputRef}
              disabled={isLoading}
              inputProps={{
                spellCheck: true,
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 2,
                  bgcolor: 'background.default',
                  '&:hover fieldset': { borderColor: 'primary.main' },
                  '&.Mui-focused fieldset': { borderColor: 'primary.main' }
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
                '& .MuiInputBase-input': {
                  py: 1.2,
                  px: 2
                },
              }}
            />
            {isLoading ? (
              <Box sx={{ p: 1 }}>
                <CircularProgress size={24} color="primary" />
              </Box>
            ) : (
              <Button
                onClick={handleSend}
                disabled={isLoading || !input.trim()}
                sx={{
                  minWidth: 'auto',
                  p: 1,
                  color: input.trim() ? theme.palette.primary.main : 'text.primary',
                  '&:hover': {
                    backgroundColor: `${theme.palette.primary.main}1A`,
                  },
                }}
              >
                <SendIcon />
              </Button>
            )}
          </Box>
        </Box>
        <Typography variant="caption" sx={{ textAlign: 'center', py: 1, color: 'text.secondary' }}>
          The AI Assistant may make mistakes. Please verify important information.
        </Typography>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Box sx={{ width: 350, height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ p: 3, borderBottom: '1px solid', borderColor: 'divider' }}>
              <Typography variant="h6" sx={{ mb: 1 }}>Document Management</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Upload documents to enhance the AI's knowledge base. Supported formats: PDF, DOC, DOCX, TXT
              </Typography>
              
              <Button
                variant="contained"
                component="label"
                fullWidth
                disabled={uploadLoading}
                startIcon={<AttachFileIcon />}
                sx={{ 
                  mb: 2,
                  height: '48px',
                  borderRadius: 2,
                  textTransform: 'none',
                  fontSize: '1rem'
                }}
              >
                {uploadLoading && documents.length === 0 ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={20} color="inherit" />
                    Processing...
                  </Box>
                ) : (
                  'Upload New Document'
                )}
                <input
                  ref={fileInputRef}
                  type="file"
                  hidden
                  onChange={handleFileUpload}
                  accept=".pdf,.doc,.docx,.txt"
                  multiple
                />
              </Button>
            </Box>
            
            <Box sx={{ p: 3, pt: 2, flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="subtitle2" sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
                <span>Uploaded Documents</span>
                <span>
                  {uploadLoading ? 'Loading...' : (
                    totalDocCount > 0 
                      ? `${documents.length} of ${totalDocCount} shown` 
                      : documents.length
                  )}
                </span>
              </Typography>
              
              {uploadLoading && documents.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 4 }}>
                  <CircularProgress size={30} />
                </Box>
              ) : (
                <Box sx={{ 
                  overflow: 'auto', 
                  flexGrow: 1,
                  maxHeight: 'calc(100vh - 240px)',
                  '&::-webkit-scrollbar': {
                    width: '8px',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    borderRadius: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'rgba(0,0,0,0.05)',
                  },
                }}>
                  <List disablePadding>
                    {documents.map((doc) => (
                      <ListItem
                        key={doc.id}
                        secondaryAction={
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            {doc.status === 'preprocessing' && (
                              <CircularProgress size={20} />
                            )}
                            <IconButton 
                              edge="end" 
                              onClick={() => handleDeleteDocument(doc.id)}
                              sx={{ 
                                color: 'error.main',
                                '&:hover': {
                                  bgcolor: 'error.light',
                                  color: 'error.dark',
                                }
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        }
                        sx={{
                          bgcolor: 'background.paper',
                          mb: 1,
                          borderRadius: 2,
                          border: '1px solid',
                          borderColor: 'divider',
                          '&:hover': { 
                            bgcolor: 'action.hover',
                            borderColor: 'primary.main',
                          }
                        }}
                        disablePadding
                      >
                        <ListItemText 
                          sx={{ p: 2 }}
                          primary={
                            <Tooltip title={doc.name} placement="top">
                              <Typography
                                sx={{
                                  width: '200px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  fontWeight: 500
                                }}
                              >
                                {doc.name}
                              </Typography>
                            </Tooltip>
                          }
                          secondary={
                            <Box>
                              <Typography 
                                variant="caption" 
                                display="block"
                                sx={{ 
                                  color: getStatusColor(doc.status),
                                  textTransform: 'capitalize',
                                  fontWeight: 500
                                }}
                              >
                                {doc.status === 'preprocessing' ? 'Processing...' : 'Complete'}
                              </Typography>
                              <Typography variant="caption" display="block">
                                {new Date(doc.created_at).toLocaleDateString()}
                              </Typography>
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                    
                    {/* Load more indicator */}
                    <Box 
                      ref={documentsEndRef} 
                      sx={{ 
                        py: 2, 
                        textAlign: 'center',
                        minHeight: hasMorePages ? '50px' : '20px' 
                      }}
                    >
                      {isLoadingMoreDocs && (
                        <CircularProgress size={24} sx={{ color: 'primary.main' }} />
                      )}
                      {!isLoadingMoreDocs && hasMorePages && documents.length > 0 && (
                        <Button 
                          variant="text" 
                          size="small"
                          onClick={handleLoadMoreDocuments}
                          sx={{ fontSize: '0.8rem' }}
                        >
                          Load more documents
                        </Button>
                      )}
                      {!isLoadingMoreDocs && !hasMorePages && documents.length > 0 && (
                        <Typography variant="caption" color="text.secondary">
                          {documents.length >= totalDocCount ? 'All documents loaded' : ''}
                        </Typography>
                      )}
                      {!isLoadingMoreDocs && documents.length === 0 && !uploadLoading && (
                        <Typography variant="body2" color="text.secondary" sx={{ py: 2 }}>
                          No documents uploaded yet
                        </Typography>
                      )}
                    </Box>
                  </List>
                </Box>
              )}
            </Box>
          </Box>
        </Drawer>
      </Box>
    </ThemeProvider>
  );
}
