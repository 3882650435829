import Keycloak from "keycloak-js";

// Create the Keycloak instance as a const
const keycloakConfig = {
  url: "https://auth.piosphere.ai",
  realm: "piocloud",
  clientId: "piocloud-client"
};

// Initialize Keycloak instance
const keycloak = new Keycloak(keycloakConfig);

// Add token expiration handler
keycloak.onAuthRefreshError = () => {
  keycloak.logout({ redirectUri: window.location.origin });
};

// Add initialization options
const initOptions = {
  checkLoginIframe: false,
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html'
};

export { keycloak, initOptions };
