import React from 'react';

export const myModels = [
  { 
    id: 1, 
    name: "Mistral-7B-Instruct Custom", 
    description: "Fine-tuned instruction model", 
    version: "v1.0.1", 
    accuracy: "92%", 
    trainingData: "1M+ samples", 
    updatedAt: "2 days ago", 
    dataset: "Customer Support Conversations",
    parameters: "7B",
    modelSize: "14GB",
    tokenSpeed: "180 tokens/s",
    maxContext: "8K tokens",
    baseModel: "mistralai/Mistral-7B-Instruct-v0.3"
  },
  { 
    id: 2, 
    name: "CodeLlama Python Custom", 
    description: "Fine-tuned Python code generation model", 
    version: "v1.1.0", 
    accuracy: "93%", 
    trainingData: "800K samples", 
    updatedAt: "3 days ago", 
    dataset: "Python Code Examples",
    parameters: "7B",
    modelSize: "13GB", 
    tokenSpeed: "200 tokens/s",
    maxContext: "4K tokens",
    baseModel: "codellama/CodeLlama-7b-Python-hf"
  },
  { 
    id: 3, 
    name: "Llama-3.2 Chat Custom", 
    description: "Fine-tuned chat model", 
    version: "v1.0.2", 
    accuracy: "94%", 
    trainingData: "2M+ samples", 
    updatedAt: "1 day ago", 
    dataset: "Conversation Dialogs",
    parameters: "3B",
    modelSize: "6GB",
    tokenSpeed: "250 tokens/s", 
    maxContext: "4K tokens",
    baseModel: "meta-llama/Llama-3.2-3B-Instruct"
  }
];

export const publicModels = [
  { id: 1, name: "meta-llama/Llama-3.2-3B-Instruct", size: "3B parameters", type: "Instruction-tuned LLM" },
  { id: 2, name: "meta-llama/Llama-3.1-8B", size: "8B parameters", type: "Instruction-tuned LLM" },
  { id: 3, name: "nvidia/Llama3-ChatQA-2-8B", size: "8B parameters", type: "Chat QA Model" },
  { id: 4, name: "NousResearch/Llama-2-7b-chat-hf", size: "7B parameters", type: "Chat Model" },
  { id: 5, name: "codellama/CodeLlama-7b-hf", size: "7B parameters", type: "Code Generation Model" },
  { id: 6, name: "codellama/CodeLlama-7b-Python-hf", size: "7B parameters", type: "Python Code Generation Model" },
  { id: 7, name: "mistralai/Mistral-7B-Instruct-v0.3", size: "7B parameters", type: "Instruction-tuned LLM" },
  { id: 8, name: "mistralai/Mixtral-8x7B-Instruct-v0.1", size: "8x7B parameters", type: "Instruction-tuned LLM" },
  { id: 9, name: "FlagAlpha/Llama2-Chinese-13b-Chat", size: "13B parameters", type: "Chinese Chat Model" },
  { id: 10, name: "inceptionai/jais-13b", size: "13B parameters", type: "General Purpose Model" }
];

export const datasets = [
  { id: 1, name: "mlabonne/guanaco-llama2-1k", taskType: "" },
  { id: 2, name: "bitext/Bitext-customer-support-llm-chatbot-training-dataset", taskType: "QA" },
  { id: 3, name: "b-mc2/sql-create-context", taskType: "QA" },
  { id: 4, name: "google/xtreme", taskType: "QA" },
  { id: 5, name: "mandarjoshi/trivia_qa", taskType: "QA" },
  { id: 6, name: "riotu-lab/ArabicQA_2.1M", taskType: "QA" },
  { id: 7, name: "llamafactory/alpaca_gpt4_en", taskType: "QA" },
  { id: 8, name: "Quardo/gpt-4o-qa", taskType: "QA" },
  { id: 9, name: "LimYeri/LeetCode_Python_Solutions_v2", taskType: "QA" },
];

export const dummyMetrics = {
  accuracy: [
    { time: '0min', "GPT-4o Fine-tuned": 92.5, "LlaMa3.1": 88.3, "Gemma": 85.7, "LlaMa3.1 Classifier": 93.5, "T5 Summarizer": 94.2, "Qwen": 90.1 },
    { time: '5min', "GPT-4o Fine-tuned": 93.1, "LlaMa3.1": 89.2, "Gemma": 86.4, "LlaMa3.1 Classifier": 94.1, "T5 Summarizer": 94.5, "Qwen": 90.5 },
    { time: '10min', "GPT-4o Fine-tuned": 94.2, "LlaMa3.1": 90.1, "Gemma": 87.2, "LlaMa3.1 Classifier": 94.3, "T5 Summarizer": 94.8, "Qwen": 91.0 },
    { time: '15min', "GPT-4o Fine-tuned": 92.8, "LlaMa3.1": 91.0, "Gemma": 88.5, "LlaMa3.1 Classifier": 93.8, "T5 Summarizer": 94.9, "Qwen": 91.2 },
    { time: '20min', "GPT-4o Fine-tuned": 93.5, "LlaMa3.1": 91.5, "Gemma": 89.0, "LlaMa3.1 Classifier": 94.0, "T5 Summarizer": 95.0, "Qwen": 91.5 },
    { time: '25min', "GPT-4o Fine-tuned": 93.8, "LlaMa3.1": 91.8, "Gemma": 89.3, "LlaMa3.1 Classifier": 94.2, "T5 Summarizer": 95.1, "Qwen": 91.8 },
    { time: '30min', "GPT-4o Fine-tuned": 94.0, "LlaMa3.1": 92.0, "Gemma": 89.5, "LlaMa3.1 Classifier": 94.3, "T5 Summarizer": 95.2, "Qwen": 92.0 },
    { time: '35min', "GPT-4o Fine-tuned": 94.1, "LlaMa3.1": 92.1, "Gemma": 89.7, "LlaMa3.1 Classifier": 94.4, "T5 Summarizer": 95.3, "Qwen": 92.1 },
    { time: '40min', "GPT-4o Fine-tuned": 94.2, "LlaMa3.1": 92.2, "Gemma": 89.8, "LlaMa3.1 Classifier": 94.5, "T5 Summarizer": 95.4, "Qwen": 92.2 },
  ],
  latency: [
    { time: '0min', "GPT-4o Fine-tuned": 125, "LlaMa3.1": 82, "Gemma": 63, "LlaMa3.1 Classifier": 120, "T5 Summarizer": 130, "Qwen": 110 },
    { time: '5min', "GPT-4o Fine-tuned": 128, "LlaMa3.1": 84, "Gemma": 65, "LlaMa3.1 Classifier": 122, "T5 Summarizer": 132, "Qwen": 112 },
    { time: '10min', "GPT-4o Fine-tuned": 124, "LlaMa3.1": 83, "Gemma": 64, "LlaMa3.1 Classifier": 121, "T5 Summarizer": 131, "Qwen": 111 },
    { time: '15min', "GPT-4o Fine-tuned": 126, "LlaMa3.1": 85, "Gemma": 66, "LlaMa3.1 Classifier": 123, "T5 Summarizer": 133, "Qwen": 113 },
    { time: '20min', "GPT-4o Fine-tuned": 123, "LlaMa3.1": 83, "Gemma": 65, "LlaMa3.1 Classifier": 122, "T5 Summarizer": 132, "Qwen": 112 },
    { time: '25min', "GPT-4o Fine-tuned": 125, "LlaMa3.1": 84, "Gemma": 64, "LlaMa3.1 Classifier": 123, "T5 Summarizer": 133, "Qwen": 113 },
    { time: '30min', "GPT-4o Fine-tuned": 127, "LlaMa3.1": 86, "Gemma": 67, "LlaMa3.1 Classifier": 124, "T5 Summarizer": 134, "Qwen": 114 },
    { time: '35min', "GPT-4o Fine-tuned": 124, "LlaMa3.1": 83, "Gemma": 65, "LlaMa3.1 Classifier": 122, "T5 Summarizer": 132, "Qwen": 112 },
    { time: '40min', "GPT-4o Fine-tuned": 126, "LlaMa3.1": 85, "Gemma": 66, "LlaMa3.1 Classifier": 123, "T5 Summarizer": 133, "Qwen": 113 },
  ],
  throughput: [
    { time: '0min', "GPT-4o Fine-tuned": 150, "LlaMa3.1": 200, "Gemma": 250, "LlaMa3.1 Classifier": 160, "T5 Summarizer": 170, "Qwen": 180 },
    { time: '5min', "GPT-4o Fine-tuned": 148, "LlaMa3.1": 195, "Gemma": 245, "LlaMa3.1 Classifier": 158, "T5 Summarizer": 168, "Qwen": 178 },
    { time: '10min', "GPT-4o Fine-tuned": 152, "LlaMa3.1": 198, "Gemma": 248, "LlaMa3.1 Classifier": 162, "T5 Summarizer": 172, "Qwen": 182 },
    { time: '15min', "GPT-4o Fine-tuned": 149, "LlaMa3.1": 197, "Gemma": 247, "LlaMa3.1 Classifier": 161, "T5 Summarizer": 171, "Qwen": 181 },
    { time: '20min', "GPT-4o Fine-tuned": 151, "LlaMa3.1": 199, "Gemma": 249, "LlaMa3.1 Classifier": 163, "T5 Summarizer": 173, "Qwen": 183 },
    { time: '25min', "GPT-4o Fine-tuned": 147, "LlaMa3.1": 196, "Gemma": 246, "LlaMa3.1 Classifier": 160, "T5 Summarizer": 170, "Qwen": 180 },
    { time: '30min', "GPT-4o Fine-tuned": 153, "LlaMa3.1": 201, "Gemma": 251, "LlaMa3.1 Classifier": 164, "T5 Summarizer": 174, "Qwen": 184 },
    { time: '35min', "GPT-4o Fine-tuned": 150, "LlaMa3.1": 198, "Gemma": 248, "LlaMa3.1 Classifier": 162, "T5 Summarizer": 172, "Qwen": 182 },
    { time: '40min', "GPT-4o Fine-tuned": 152, "LlaMa3.1": 200, "Gemma": 250, "LlaMa3.1 Classifier": 163, "T5 Summarizer": 173, "Qwen": 183 },
  ],
};

export const hardwareOptions = [
  {
    id: 'mi250',
    name: 'MI250',
    specs: 'IP: 147.160.62.86, Port: 22',
    availability: 'High',
    location: 'California',
    country: 'United States',
    provider: 'AMAX'
  },
  {
    id: 'gh200',
    name: 'GH200',
    specs: 'IP: 147.160.62.85, Port: 22',
    availability: 'Medium',
    location: 'California',
    country: 'United States',
    provider: 'AMAX'
  },
  {
    id: 'mi210-1',
    name: 'MI210',
    specs: 'IP: 80.239.149.59, Port: 22',
    availability: 'Limited',
    location: 'Bavaria',
    country: 'Germany',
    provider: 'EMC-Home of Data'
  },
  {
    id: 'mi210-2',
    name: 'MI210',
    specs: 'IP: 80.239.149.60, Port: 22',
    availability: 'Limited',
    location: 'Bavaria',
    country: 'Germany',
    provider: 'EMC-Home of Data'
  }
];


export const marketplaceItems = [
    { id: 1, name: "No-Code AI", description: "Build AI solutions without coding", rating: 4.9, downloads: "20,000", category: "Productivity", installed: true },
    { id: 2, name: "Meeting Summarizer", description: "Automatically generate meeting summaries", rating: 4.2, downloads: "5,000", category: "Productivity", installed: true },
    { id: 3, name: "PaaS", description: "Platform as a Service for AI model deployment", rating: 4.6, downloads: "12,000", category: "Integration", installed: true },
    { id: 4, name: "Document Reader", description: "Extract and analyze text from documents", rating: 4.7, downloads: "8,000", category: "Analysis", installed: false },
    { id: 5, name: "Code Assistant", description: "AI-powered code completion and suggestions", rating: 4.5, downloads: "10,000", category: "Productivity", installed: false },
    { id: 6, name: "HashCat", description: "Crack hashes with a powerful tool", rating: 4.3, downloads: "6,000", category: "Analysis", installed: true },
    { id: 7, name: "PioAgent", description: "AI-powered Chat Agent", rating: 4.3, downloads: "6,000", category: "Analysis", installed: true },
  ];
  

export const preDeployedAgents = [
    {
      id: 'pio-agent',
      name: 'PioAgent',
      type: 'Chat Application',
      status: 'online',
      domain: 'rag',
      models: [
        { 
          id: 1, 
          name: 'meta-llama/Llama-3.1-8B-Instruct'
        }
      ],
      nodes: [
        { id: 'mi250-1', name: 'MI250', status: 'online' },
        { id: 'gh200-1', name: 'GH200', status: 'online' }
      ]
    },
    {
      id: 'hiring-agent',
      name: 'HiringAgent',
      type: 'Question Answering',
      status: 'offline',
      domain: 'resume',
      models: [
        { 
          id: 2, 
          name: 'deepseek-ai/DeepSeek-R1-Distill-Qwen-7B'
        }
      ],
      nodes: [
        { id: 'mi210-1', name: 'MI210', status: 'unavailable' }
      ]
    }
];
  

export const DEMO_MODELS = [
  { value: 'meta-llama/Llama-3.1-8B-Instruct', label: 'Llama 3.1 8b Instruct' },
  { value: 'deepseek-ai/DeepSeek-R1-Distill-Qwen-7B', label: 'DeepSeek R1 Distill Qwen 7b' }
];

export default {
  preDeployedAgents,
  myModels,
  publicModels,
  datasets,
  dummyMetrics,
  hardwareOptions,
  marketplaceItems
};
