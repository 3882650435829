import React from 'react';
import { Card, CardContent, Typography, Box, Chip, IconButton, Tooltip, LinearProgress } from '@mui/material';
import { 
  PlayArrow as PlayArrowIcon,
  Stop as StopIcon,
  OpenInNew as OpenInNewIcon,
  CheckCircle as CheckCircleIcon,
  ErrorOutline as ErrorOutlineIcon
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

// Sample data - in a real app this would come from an API
const deployedAgents = [
  {
    id: 'pio-agent',
    name: 'PioAgent',
    type: 'Chat Application',
    status: 'online',
    model: 'meta-llama/Llama-3.1-8B-Instruct',
    requests: 1287,
    responseTime: 134,
    successRate: 99.8
  },
  {
    id: 'hiring-agent',
    name: 'HiringAgent',
    type: 'CV Processing',
    status: 'offline',
    model: 'deepseek-ai/DeepSeek-R1-Distill-Qwen-7B',
    requests: 457,
    responseTime: 156,
    successRate: 98.2
  },
  {
    id: 'sales-agent',
    name: 'SalesAgent',
    type: 'Customer Interaction',
    status: 'online',
    model: 'anthropic/claude-3-haiku',
    requests: 843,
    responseTime: 89,
    successRate: 99.5
  },
  {
    id: 'support-agent',
    name: 'SupportAgent',
    type: 'Customer Support',
    status: 'online',
    model: 'mistralai/Mistral-7B-Instruct-v0.2',
    requests: 1542,
    responseTime: 102,
    successRate: 97.8
  },
  {
    id: 'code-agent',
    name: 'CodeAssistant',
    type: 'Development',
    status: 'offline',
    model: 'bigcode/starcoder2-15b',
    requests: 329,
    responseTime: 187,
    successRate: 93.4
  },
  {
    id: 'research-agent',
    name: 'ResearchAgent',
    type: 'Data Analysis',
    status: 'online',
    model: 'meta-llama/Llama-3.1-70B-Instruct',
    requests: 124,
    responseTime: 310,
    successRate: 98.9
  }
];

const AgentItem = ({ agent }) => {
  const navigate = useNavigate();
  const isOnline = agent.status === 'online';
  
  return (
    <Box 
      sx={{ 
        mb: 1.5,
        position: 'relative',
        p: 1,
        borderRadius: 2,
        transition: 'all 0.2s',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.03)'
        }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.75, alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isOnline ? (
            <CheckCircleIcon sx={{ color: '#10B981', fontSize: 14, mr: 0.75 }} />
          ) : (
            <ErrorOutlineIcon sx={{ color: '#888888', fontSize: 14, mr: 0.75 }} />
          )}
          <Typography variant="body2" fontWeight={500} fontSize="0.85rem">
            {agent.name}
          </Typography>
        </Box>
        
        <Box sx={{ display: 'flex', gap: 0.25 }}>
          <Tooltip title={isOnline ? "Stop Agent" : "Start Agent"}>
            <IconButton size="small" sx={{ color: isOnline ? '#EF4444' : '#10B981', p: 0.5 }}>
              {isOnline ? <StopIcon fontSize="small" sx={{ fontSize: '0.9rem' }} /> : <PlayArrowIcon fontSize="small" sx={{ fontSize: '0.9rem' }} />}
            </IconButton>
          </Tooltip>
          
          <Tooltip title="View Agent">
            <IconButton 
              size="small" 
              sx={{ color: 'rgba(255, 255, 255, 0.6)', p: 0.5 }}
              onClick={() => navigate(`/deployment/${agent.id}`)}
            >
              <OpenInNewIcon fontSize="small" sx={{ fontSize: '0.9rem' }} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
        <Chip 
          label={agent.model.split('/')[1]} 
          size="small"
          sx={{ 
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
            color: 'rgba(255, 255, 255, 0.8)',
            fontSize: '0.65rem',
            height: 16,
            '& .MuiChip-label': { px: 0.75, py: 0 }
          }}
        />
        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: '0.65rem' }}>
          {agent.requests.toLocaleString()} reqs
        </Typography>
      </Box>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.25 }}>
        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: '0.65rem' }}>
          {agent.responseTime}ms
        </Typography>
        <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: '0.65rem', fontWeight: 500 }}>
          {agent.successRate}%
        </Typography>
      </Box>
      
      <Box>
        <LinearProgress 
          variant="determinate" 
          value={agent.successRate} 
          sx={{
            height: 3,
            borderRadius: 2,
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
            '& .MuiLinearProgress-bar': {
              backgroundColor: agent.successRate > 99 ? '#10B981' : 
                              agent.successRate > 95 ? '#FBBF24' : '#EF4444'
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default function DeployedAgents() {
  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      backgroundColor: '#1a1a1a',
      color: 'white',
      borderRadius: 3,
      boxShadow: 'none',
      border: '1px solid rgba(255, 255, 255, 0.05)'
    }}>
      <CardContent sx={{ 
        p: 0,
        '&:last-child': { pb: 0 },
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
        <Box sx={{ p: 2, pb: 1.5, borderBottom: '1px solid rgba(255, 255, 255, 0.05)' }}>
          <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1rem' }}>
            Deployed Agents
          </Typography>
        </Box>
        
        <Box sx={{ 
          flex: 1, 
          overflowY: 'auto', 
          p: 2,
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 4
          }
        }}>
          {deployedAgents.map((agent) => (
            <AgentItem key={agent.id} agent={agent} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
} 