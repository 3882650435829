// Model categories with descriptions
export const modelCategories = {
  rag: {
    name: 'RAG-Enabled Models',
    description: 'Models configured with Retrieval-Augmented Generation capabilities for dynamic knowledge access and real-time information retrieval.'
  },
  standard: {
    name: 'Standard Models',
    description: 'Traditional models that can be used as-is or with fine-tuning for specific tasks.'
  },
  'fine-tuned': {
    name: 'Fine-Tuned',
    description: 'These models have been trained on specific datasets to enhance performance on domain-specific tasks.'
  },
  hybrid: {
    name: 'Hybrid',
    description: 'Combining RAG capabilities with fine-tuning for both accuracy and flexibility in knowledge retrieval.'
  },
  base: {
    description: 'Pre-trained models without additional customization, ready for immediate deployment.'
  }
};

// Sample model data
const MODELS = [
  // RAG-Enabled Models
  {
    id: 'rag-mistral',
    name: 'RAG Mistral 7B',
    provider: 'Mistral AI',
    description: 'Mistral 7B optimized for retrieval tasks with Grouped-Query Attention for efficient knowledge access',
    contextWindow: '32K',
    performance: 'High',
    cost: 'low',
    type: 'rag',
    finetuned: false
  },
  {
    id: 'rag-qwen-ft',
    name: 'RAG Qwen2.5-14B (Fine-tuned)',
    provider: 'Alibaba Cloud',
    description: 'Fine-tuned Qwen model with retrieval capabilities for domain-specific knowledge access',
    contextWindow: '32K',
    performance: 'Very High',
    cost: 'medium',
    type: 'rag',
    finetuned: true
  },
  {
    id: 'rag-deepseek',
    name: 'RAG DeepSeek R1',
    provider: 'DeepSeek',
    description: 'DeepSeek R1 with added retrieval capabilities for enhanced knowledge integration',
    contextWindow: '32K',
    performance: 'High',
    cost: 'medium',
    type: 'rag',
    finetuned: false
  },

  // Standard Models
  {
    id: 'mistral-7b',
    name: 'Mistral 7B',
    provider: 'Mistral AI',
    description: 'Efficient 7.3B parameter model with Grouped-Query Attention and Sliding Window Attention',
    contextWindow: '8K',
    performance: 'Medium',
    cost: 'low',
    type: 'standard',
    finetuned: false
  },
  {
    id: 'mistral-7b-ft',
    name: 'Mistral 7B (Fine-tuned)',
    provider: 'Mistral AI',
    description: 'Domain-optimized version of Mistral 7B with enhanced task-specific performance',
    contextWindow: '8K',
    performance: 'High',
    cost: 'low',
    type: 'standard',
    finetuned: true
  },
  {
    id: 'phi3-medium',
    name: 'Phi-3 Medium',
    provider: 'Microsoft',
    description: '14B parameter model optimized for efficient resource usage',
    contextWindow: '32K',
    performance: 'High',
    cost: 'medium',
    type: 'standard',
    finetuned: false
  },
  {
    id: 'phi3-medium-ft',
    name: 'Phi-3 Medium (Fine-tuned)',
    provider: 'Microsoft',
    description: 'Fine-tuned version of Phi-3 optimized for specific domain tasks',
    contextWindow: '32K',
    performance: 'Very High',
    cost: 'medium',
    type: 'standard',
    finetuned: true
  },
  {
    id: 'gemma-12b',
    name: 'Gemma 12B',
    provider: 'Google DeepMind',
    description: '12B parameter model optimized for GPU usage with Gemini architecture',
    contextWindow: '32K',
    performance: 'High',
    cost: 'medium',
    type: 'standard',
    finetuned: false
  },

  // Fine-tuned Models
  {
    id: 'ft-mistral',
    name: 'FT Mistral 7B',
    provider: 'Mistral AI',
    description: 'Domain-optimized version of Mistral 7B with enhanced task-specific performance',
    contextWindow: '8K',
    performance: 'High',
    cost: 'low',
    type: 'fine-tuned'
  },
  {
    id: 'ft-phi3',
    name: 'FT Phi-3 Medium',
    provider: 'Microsoft',
    description: 'Fine-tuned version of Phi-3 optimized for specific domain tasks with efficient resource usage',
    contextWindow: '32K',
    performance: 'High',
    cost: 'medium',
    type: 'fine-tuned'
  },
  {
    id: 'ft-gemma',
    name: 'FT Gemma 12B',
    provider: 'Google DeepMind',
    description: 'Task-specific version of Gemma 12B optimized for targeted use cases',
    contextWindow: '32K',
    performance: 'High',
    cost: 'medium',
    type: 'fine-tuned'
  },

  // Hybrid Models
  {
    id: 'hy-mistral',
    name: 'Hybrid Mistral 7B',
    provider: 'Mistral AI',
    description: 'Mistral 7B combining fine-tuning and RAG capabilities for flexible knowledge access',
    contextWindow: '32K',
    performance: 'Very High',
    cost: 'medium',
    type: 'hybrid'
  },
  {
    id: 'hy-qwen',
    name: 'Hybrid Qwen2.5-14B',
    provider: 'Alibaba Cloud',
    description: 'Qwen model with both fine-tuning and retrieval capabilities for comprehensive task handling',
    contextWindow: '32K',
    performance: 'Very High',
    cost: 'high',
    type: 'hybrid'
  },

  // Base Models (existing)
  {
    id: 'openllama-13b',
    name: 'OpenLLaMA 13B',
    provider: 'Open Source',
    description: 'Open-source replication of Meta AI\'s LLaMA model trained on 200 billion tokens, available in 3B, 7B, and 13B parameter sizes.',
    contextWindow: '4K',
    performance: 'Medium',
    cost: 'low',
    type: 'base'
  },
  {
    id: 'deepseek-r1',
    name: 'DeepSeek R1',
    provider: 'DeepSeek',
    description: 'Efficient model from Chinese startup DeepSeek rivaling proprietary models like ChatGPT in performance.',
    contextWindow: '16K',
    performance: 'High',
    cost: 'low',
    type: 'base'
  },
  {
    id: 'jais-13b',
    name: 'Jais 13B',
    provider: 'UAE AI',
    description: 'A 13 billion-parameter model trained on both English and Arabic data with strong bilingual capabilities.',
    contextWindow: '8K',
    performance: 'Medium',
    cost: 'low',
    type: 'base'
  },
  {
    id: 'velvet-14b',
    name: 'Velvet 14B',
    provider: 'Almawave',
    description: 'Multilingual model trained on over 4 trillion tokens across six languages with emphasis on energy efficiency.',
    contextWindow: '16K',
    performance: 'High',
    cost: 'medium',
    type: 'base'
  },
  {
    id: 'qwen-14b',
    name: 'Qwen2.5-14B',
    provider: 'Alibaba Cloud',
    description: '14 billion-parameter open-source model supporting multiple languages, suitable for strong language understanding and generation.',
    contextWindow: '32K',
    performance: 'High',
    cost: 'medium',
    type: 'base'
  },
  {
    id: 'baichuan-13b',
    name: 'Baichuan 13B',
    provider: 'Baichuan',
    description: '13 billion-parameter multilingual model trained from scratch on 2.6 trillion tokens with strong benchmark performance.',
    contextWindow: '16K',
    performance: 'Medium',
    cost: 'low',
    type: 'base'
  }
];

// Function to get models by type and fine-tuning status
export const getModelsByType = (modelType, includeFinetuned = true) => {
  return MODELS.filter(model => 
    model.type === modelType && (includeFinetuned || !model.finetuned)
  );
};

// Function to get model by ID
export const getModelById = (modelId) => {
  return MODELS.find(model => model.id === modelId);
};

// Function to get performance color
export const getPerformanceColor = (performance) => {
  switch (performance) {
    case 'Very High':
      return 'success';
    case 'High':
      return 'primary';
    case 'Medium':
      return 'info';
    case 'Low':
      return 'warning';
    default:
      return 'default';
  }
};

// Function to get cost display information
export const getCostDisplay = (cost) => {
  switch (cost) {
    case 'very-high':
      return { label: '$$$$', color: 'error' };
    case 'high':
      return { label: '$$$', color: 'warning' };
    case 'medium':
      return { label: '$$', color: 'info' };
    case 'low':
      return { label: '$', color: 'success' };
    default:
      return { label: '$', color: 'default' };
  }
};
