import { useEffect } from 'react';
import { useKeycloak } from "@react-keycloak/web";
import { Navigate, useNavigate } from "react-router-dom";
import PageLoading from "../helpers/PageLoading";

const PrivateRoute = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();

  useEffect(() => {
    const handleTokenRefresh = async () => {
      try {
        if (keycloak.authenticated) {
          await keycloak.updateToken(30);
        }
      } catch (error) {
        console.error('Token refresh failed:', error);
        keycloak.logout();
        navigate('/');
      }
    };

    const interval = setInterval(handleTokenRefresh, 30000);
    return () => clearInterval(interval);
  }, [keycloak, navigate]);

  if (!initialized) {
    return <PageLoading />;
  }

  return keycloak.authenticated ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;