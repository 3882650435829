import React from 'react';
import { Box, Card, CardContent, Typography, Select, MenuItem, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

const GridCell = styled(Box)(({ theme, color, empty }) => ({
  width: '100%',
  paddingBottom: '68%',
  backgroundColor: color,
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  cursor: empty ? 'default' : 'pointer',
  transition: 'all 0.2s ease',
  border: empty ? '1px dashed rgba(255, 255, 255, 0.1)' : 'none',
  '&:hover': {
    opacity: empty ? 1 : 0.9,
    transform: empty ? 'none' : 'scale(1.02)',
  }
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(5, 1fr)',
  gap: theme.spacing(1.25),
  width: '100%'
}));

const ServerName = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: theme.typography.pxToRem(12),
  color: theme.palette.common.white,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '90%',
  fontWeight: 600
}));

export const metrics = [
  { value: 'cpuUsage', label: 'CPU Usage' },
  { value: 'memoryUsage', label: 'Memory' },
  { value: 'gpuUsage', label: 'GPU' },
  { value: 'diskUsage', label: 'Disk I/O' }
];

export const serverData = [
  { id: 1, name: "Server 1", cpuUsage: 40, memoryUsage: 65, gpuUsage: 20, diskUsage: 55, status: "Active", nodeHealth: "Healthy" },
  { id: 2, name: "Server 2", cpuUsage: 80, memoryUsage: 45, gpuUsage: 90, diskUsage: 30, status: "Active", nodeHealth: "Healthy" },
  { id: 3, name: "Server 5", cpuUsage: 60, memoryUsage: 70, gpuUsage: 30, diskUsage: 85, status: "Idle", nodeHealth: "Warning" },
  { id: 4, name: "Server 7", cpuUsage: 75, memoryUsage: 50, gpuUsage: 80, diskUsage: 25, status: "Active", nodeHealth: "Warning" },
  { id: 5, name: "Server 6", cpuUsage: 90, memoryUsage: 85, gpuUsage: 95, diskUsage: 70, status: "Active", nodeHealth: "Critical" },
  { id: 6, name: "Server 9", cpuUsage: 40, memoryUsage: 30, gpuUsage: 10, diskUsage: 45, status: "Active", nodeHealth: "Healthy" },
  { id: 7, name: "Server 4", cpuUsage: 30, memoryUsage: 60, gpuUsage: 50, diskUsage: 65, status: "Active", nodeHealth: "Healthy" },
  { id: 8, name: "Server 3", cpuUsage: 60, memoryUsage: 40, gpuUsage: 75, diskUsage: 15, status: "Idle", nodeHealth: "Warning" },
  { id: 9, name: "Server 8", cpuUsage: 75, memoryUsage: 80, gpuUsage: 60, diskUsage: 50, status: "Active", nodeHealth: "Warning" },
  { id: 10, name: "Server 10", cpuUsage: 65, memoryUsage: 55, gpuUsage: 40, diskUsage: 35, status: "Idle", nodeHealth: "Warning" }
];

const ColorBar = ({ metric }) => {
  const getColorBarLabels = (metric) => {
    if (metric === 'status') {
      return ['Active', 'Idle', 'Inactive'];
    }
    if (metric === 'nodeHealth') {
      return ['Healthy', 'Warning', 'Critical'];
    }
    return ['100%', '70%', '30%', '0%'];
  };

  return (
    <Box sx={{ 
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: 50,
      ml: 2
    }}>
      <Typography variant="caption" sx={{ textAlign: 'center', mb: 1 }}>High</Typography>
      <Box sx={{ 
        flex: 1,
        background: 'linear-gradient(to bottom, #f44336, #ff9800, #4caf50)',
        borderRadius: 1,
        my: 1,
        height: 200,
        position: 'relative'
      }}>
        {getColorBarLabels(metric).map((label, index, array) => (
          <Typography
            key={label}
            variant="caption"
            sx={{
              position: 'absolute',
              right: -5,
              transform: 'translateX(100%)',
              color: 'text.secondary',
              fontSize: '0.7rem',
              ...(index === 0 ? {
                top: '0%',
              } : index === array.length - 1 ? {
                bottom: '0%',
              } : {
                top: `${(index / (array.length - 1)) * 100}%`,
                transform: 'translateX(100%) translateY(-50%)',
              })
            }}
          >
            {label}
          </Typography>
        ))}
      </Box>
      <Typography variant="caption" sx={{ textAlign: 'center', mt: 1 }}>Low</Typography>
    </Box>
  );
};

export default function ServerGridDisplay({ selectedMetric, setSelectedMetric }) {
  const getColor = (value, metric) => {
    if (value === undefined) return '#2a2a2a';
    
    // Use CPU colors by default
    if (metric === 'cpuUsage' || !metric) {
      return value < 30 ? '#10B981' :
             value < 70 ? '#FBBF24' : '#EF4444';
    }
    
    // Custom colors for other metrics
    if (metric === 'memoryUsage') {
      return value < 30 ? '#10B981' :
             value < 70 ? '#3B82F6' : '#3B82F6';
    }
    if (metric === 'gpuUsage') {
      return value < 30 ? '#10B981' :
             value < 70 ? '#F59E0B' : '#F59E0B';
    }
    if (metric === 'diskUsage') {
      return value < 30 ? '#10B981' :
             value < 70 ? '#EF4444' : '#EF4444';
    }
    
    return '#10B981'; // Default to green
  };

  const getValue = (server, metric) => {
    if (!server) return undefined;
    return server[metric];
  };

  const getTooltipContent = (server, index) => {
    if (!server) return 'Empty slot - Add a new server to monitor';
    return (
      <Box sx={{ p: 1.5 }}>
        <Typography variant="subtitle2" fontWeight={600}>{server.name}</Typography>
        <Typography variant="body2">Status: {server.status}</Typography>
        <Typography variant="body2">Health: {server.nodeHealth}</Typography>
        <Typography variant="body2">CPU: {server.cpuUsage}%</Typography>
        <Typography variant="body2">Memory: {server.memoryUsage}%</Typography>
        <Typography variant="body2">GPU: {server.gpuUsage}%</Typography>
        <Typography variant="body2">Disk I/O: {server.diskUsage}%</Typography>
      </Box>
    );
  };

  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      backgroundColor: '#1a1a1a',
      color: 'white',
      borderRadius: 3,
      boxShadow: 'none',
      border: '1px solid rgba(255, 255, 255, 0.05)'
    }}>
      <CardContent sx={{ 
        p: 0,
        '&:last-child': { pb: 0 },
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
        <Box sx={{ 
          px: 2, 
          py: 1.5, 
          borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1rem' }}>
              Server Status
            </Typography>
            <IconButton size="small" sx={{ ml: 0.5, color: 'rgba(255, 255, 255, 0.5)' }}>
              <ArrowForwardIcon fontSize="small" sx={{ fontSize: '0.9rem' }} />
            </IconButton>
          </Box>
          <Select
            size="small"
            value={selectedMetric}
            onChange={(e) => setSelectedMetric(e.target.value)}
            sx={{ 
              minWidth: 110, 
              height: 32,
              '& .MuiOutlinedInput-root': { 
                borderRadius: 1.5,
              },
              '& .MuiSelect-select': { 
                fontSize: '0.75rem',
                py: 0.5,
                px: 1.5,
              }
            }}
          >
            {metrics.map((metric) => (
              <MenuItem key={metric.value} value={metric.value}>
                {metric.label}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ flex: 1, p: 2, overflow: 'auto' }}>
          <GridContainer>
            {/* First Row */}
            {Array(15).fill(null).map((_, index) => {
              const server = serverData[index] || null;
              const value = getValue(server, selectedMetric);
              return (
                <Tooltip key={index} title={getTooltipContent(server, index)} arrow placement="top">
                  <GridCell 
                    color={server ? getColor(value, selectedMetric) : 'transparent'} 
                    empty={!server}
                  >
                    {server ? (
                      <ServerName>{server.name}</ServerName>
                    ) : (
                      <ServerName sx={{ color: 'rgba(255, 255, 255, 0.3)', fontWeight: 400 }}>
                        Empty
                      </ServerName>
                    )}
                  </GridCell>
                </Tooltip>
              );
            })}
          </GridContainer>
          
          <Box sx={{ 
            display: 'flex',
            alignItems: 'center',
            mt: 2,
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, mb: 1 }}>
              <Box sx={{ 
                width: 8, 
                height: 8, 
                bgcolor: '#10B981', 
                borderRadius: '50%',
                mr: 0.75 
              }} />
              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Low</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, mb: 1 }}>
              <Box sx={{ 
                width: 8, 
                height: 8, 
                bgcolor: selectedMetric === 'memoryUsage' ? '#3B82F6' : 
                         selectedMetric === 'gpuUsage' ? '#F59E0B' : 
                         selectedMetric === 'diskUsage' ? '#EF4444' : '#FBBF24', 
                borderRadius: '50%',
                mr: 0.75  
              }} />
              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Medium</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2, mb: 1 }}>
              <Box sx={{ 
                width: 8, 
                height: 8, 
                bgcolor: '#EF4444', 
                borderRadius: '50%',
                mr: 0.75  
              }} />
              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>High</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Box sx={{ 
                width: 8, 
                height: 8, 
                border: '1px dashed rgba(255, 255, 255, 0.1)', 
                borderRadius: '50%',
                mr: 0.75  
              }} />
              <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>Empty</Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
} 