import React, { useState } from 'react';
import { Typography, Card, CardContent, Button, Grid, Tabs, Tab, Box, TextField, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Benchmark from '../models/Benchmark';
import Evaluation from '../models/Evaluation';
import ModelCard from '../helpers/ModelCard';
import { myModels, publicModels, datasets, dummyMetrics } from '../helpers/MockData';

export default function Models() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex', 
      flexDirection: 'column', 
      p: { xs: 1, sm: 2 },
      gap: 2,
      boxSizing: 'border-box',
    }}>
      <Typography variant="h4" sx={{ 
      }}>
        Models
      </Typography>

      <Box sx={{ 
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '100%',
      }}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange}
          variant="standard"
          sx={{ 
            minHeight: 'unset',
            width: '100%',
            '.MuiTabs-flexContainer': {
              flexWrap: 'wrap',
              gap: 1,
            },
            '.MuiTab-root': {
              minHeight: 'unset',
              padding: '8px 16px',
              fontSize: { xs: '0.875rem', sm: '1rem' },
            }
          }}
        >
          <Tab label="My Models" />
          <Tab label="Public Models" />
          <Tab label="Benchmark" />
          <Tab label="Evaluation" />
        </Tabs>
        
        {tabValue !== 2 && tabValue !== 3 && (
          <Button 
            variant="contained" 
            startIcon={<AddIcon />}
            sx={{ 
              alignSelf: 'flex-start'
            }}
          >
            New Model
          </Button>
        )}
      </Box>

      <Box sx={{ 
        width: '100%',
      }}>
        {tabValue === 0 && (
          <Grid 
            container 
            spacing={{ xs: 1, sm: 2 }}
            sx={{ 
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            {myModels.map((model) => (
              <Grid 
                item 
                xs={12} 
                sm={6} 
                lg={4} 
                key={model.id}
                sx={{ 
                  boxSizing: 'border-box',
                  width: '100%',
                }}
              >
                <ModelCard 
                  model={model} 
                  isMyModel={true}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {tabValue === 1 && (
          <Grid 
            container 
            spacing={{ xs: 1, sm: 2 }}
            sx={{ 
              width: '100%', 
              boxSizing: 'border-box',
            }}
          >
            {publicModels.map((model) => (
              <Grid 
                item 
                xs={12} 
                sm={6} 
                md={4} 
                key={model.id}
                sx={{ 
                  boxSizing: 'border-box',
                  width: '100%',
                }}
              >
                <ModelCard 
                  model={model} 
                  isMyModel={false}
                />
              </Grid>
            ))}
          </Grid>
        )}

        {tabValue === 2 && (
          <Benchmark 
            myModels={myModels} 
            publicModels={publicModels} 
            datasets={datasets} 
            dummyMetrics={dummyMetrics} 
          />
        )}

        {tabValue === 3 && (
          <Evaluation 
            myModels={myModels} 
            publicModels={publicModels} 
          />
        )}
      </Box>
    </Box>
  );
}
