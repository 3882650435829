import React from 'react';
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  IconButton
} from '@mui/material';
import {
  Business as BusinessIcon,
  AccountBalance as AccountBalanceIcon,
  Group as GroupIcon,
  Person as PersonIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon
} from '@mui/icons-material';

const HierarchyView = ({ data }) => {
  const [expanded, setExpanded] = React.useState({});

  const handleToggle = (id) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id]
    });
  };

  const getClientById = (clientId) => {
    return data.clients.find(client => client.id === clientId);
  };

  const getDepartmentById = (departmentId) => {
    return data.departments.find(dept => dept.id === departmentId);
  };

  const getTeamsByDepartmentId = (departmentId) => {
    return data.teams.filter(team => team.departmentId === departmentId);
  };

  const getUsersByTeamId = (teamId) => {
    return data.users.filter(user => user.teamId === teamId);
  };

  return (
    <Box>
      <Typography variant="h5" gutterBottom>
        Organization Hierarchy
      </Typography>

      {data.clients.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography>
            No organizations found. Use the wizard to create your first organization.
          </Typography>
        </Paper>
      ) : (
        <List component={Paper} sx={{ p: 0 }}>
          {data.clients.map(client => (
            <Box key={client.id}>
              <ListItem
                button
                onClick={() => handleToggle(`client-${client.id}`)}
                sx={{ bgcolor: 'primary.dark', color: 'white' }}
              >
                <ListItemIcon sx={{ color: 'white' }}>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText 
                  primary={client.name} 
                  secondary={client.abbreviation}
                  secondaryTypographyProps={{ color: 'white' }}
                />
                {expanded[`client-${client.id}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItem>

              <Collapse in={expanded[`client-${client.id}`]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {data.departments
                    .filter(dept => dept.clientId === client.id)
                    .map(department => (
                      <Box key={department.id}>
                        <ListItem 
                          button
                          onClick={() => handleToggle(`dept-${department.id}`)}
                          sx={{ pl: 4, bgcolor: 'secondary.dark', color: 'white' }}
                        >
                          <ListItemIcon sx={{ color: 'white' }}>
                            <AccountBalanceIcon />
                          </ListItemIcon>
                          <ListItemText primary={department.name} />
                          {expanded[`dept-${department.id}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItem>

                        <Collapse in={expanded[`dept-${department.id}`]} timeout="auto" unmountOnExit>
                          <List component="div" disablePadding>
                            {getTeamsByDepartmentId(department.id).map(team => (
                              <Box key={team.id}>
                                <ListItem 
                                  button
                                  onClick={() => handleToggle(`team-${team.id}`)}
                                  sx={{ pl: 8, bgcolor: 'grey.700', color: 'white' }}
                                >
                                  <ListItemIcon sx={{ color: 'white' }}>
                                    <GroupIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={team.name} />
                                  {expanded[`team-${team.id}`] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItem>

                                <Collapse in={expanded[`team-${team.id}`]} timeout="auto" unmountOnExit>
                                  <List component="div" disablePadding>
                                    {getUsersByTeamId(team.id).map(user => (
                                      <ListItem key={user.id} sx={{ pl: 12 }}>
                                        <ListItemIcon>
                                          <PersonIcon />
                                        </ListItemIcon>
                                        <ListItemText 
                                          primary={user.name} 
                                          secondary={user.role}
                                        />
                                      </ListItem>
                                    ))}
                                  </List>
                                </Collapse>
                              </Box>
                            ))}
                          </List>
                        </Collapse>
                      </Box>
                    ))}
                </List>
              </Collapse>
            </Box>
          ))}
        </List>
      )}
    </Box>
  );
};

export default HierarchyView;
