import React, { useState, useEffect } from 'react';
import { 
  Box, Card, CardContent, Typography, Grid,
  Paper, CircularProgress, Tabs, Tab,
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow
} from '@mui/material';
import { 
  LineChart, Line, XAxis, YAxis, CartesianGrid, 
  Tooltip, ResponsiveContainer, BarChart, Bar
} from 'recharts';
import { getApiKeys, getKeyUsageStats } from '../api/services/deployment/Deployment';

const ApiUsage = () => {
  const [usageData, setUsageData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [view, setView] = useState(0);

  useEffect(() => {
    const fetchAllUsageData = async () => {
      try {
        const keysResponse = await getApiKeys();
        const apiKeys = keysResponse.data.keys;
        
        const usagePromises = apiKeys.map(key => 
          getKeyUsageStats(key.id)
        );
        
        const usageResults = await Promise.all(usagePromises);
        
        const keyUsageData = apiKeys.map((key, index) => ({
          id: key.id,
          name: `API Key ${key.id}`,
          total_requests: usageResults[index].data.total_requests,
          total_tokens: usageResults[index].data.total_tokens,
          last_request_at: usageResults[index].data.last_request_at,
          usage_history: usageResults[index].data.usage_history
        }));
        
        setUsageData(keyUsageData);
      } catch (err) {
        setError(err.message);
        console.error('Error fetching usage data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchAllUsageData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
        <CircularProgress sx={{ color: '#40c463' }} />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography color="error">Error loading API usage data: {error}</Typography>
      </Box>
    );
  }

  const formatKeyLabel = (keyData) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="subtitle2" sx={{ color: '#40c463' }}>
          Key ID: {keyData.id}
        </Typography>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          {keyData.name}
        </Typography>
      </Box>
    );
  };

  const ChartView = () => {
    // Helper function to get last 4 characters of ID
    const getTruncatedId = (id) => {
      return `...${id.slice(-4)}`;
    };

    return (
      <Grid container spacing={3}>
        {/* Summary Cards */}
        <Grid item xs={12} md={6}>
          <Card sx={{ bgcolor: 'background.paper' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Total Requests</Typography>
              <Typography variant="h3" sx={{ color: '#40c463' }}>
                {usageData.reduce((sum, key) => sum + (key.total_requests || 0), 0).toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ bgcolor: 'background.paper' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Total Tokens</Typography>
              <Typography variant="h3" sx={{ color: '#40c463' }}>
                {usageData.reduce((sum, key) => sum + (key.total_tokens || 0), 0).toLocaleString()}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Updated Requests Chart */}
        <Grid item xs={12}>
          <Card sx={{ bgcolor: 'background.paper' }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Requests by API Key</Typography>
              <Box sx={{ height: 300 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart 
                    data={usageData}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 40
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="id"
                      tickFormatter={getTruncatedId}
                      tick={{ 
                        fontSize: 12,
                        fill: '#40c463'
                      }}
                      label={{ 
                        value: 'API Key (last 4)',
                        position: 'bottom',
                        offset: 20
                      }}
                    />
                    <YAxis 
                      label={{ 
                        value: 'Number of Requests',
                        angle: -90,
                        position: 'insideLeft',
                        offset: 10
                      }}
                    />
                    <Tooltip 
                      content={({ active, payload }) => {
                        if (active && payload && payload.length) {
                          const data = payload[0].payload;
                          return (
                            <Box sx={{ 
                              bgcolor: 'background.paper', 
                              p: 1.5, 
                              border: '1px solid #40c463',
                              borderRadius: 1
                            }}>
                              <Typography sx={{ color: '#40c463', fontWeight: 'bold', mb: 1 }}>
                                API Key: {data.id}
                              </Typography>
                              <Typography>
                                Requests: {data.total_requests?.toLocaleString()}
                              </Typography>
                              <Typography variant="caption" color="text.secondary">
                                Last used: {formatDateTime(data.last_request_at)}
                              </Typography>
                            </Box>
                          );
                        }
                        return null;
                      }}
                    />
                    <Bar 
                      dataKey="total_requests" 
                      fill="#40c463" 
                      name="Requests"
                    />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Usage History */}
        {usageData.map((keyData) => (
          keyData.usage_history && (
            <Grid item xs={12} key={keyData.id}>
              <Card sx={{ bgcolor: 'background.paper' }}>
                <CardContent>
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="h6" sx={{ color: '#40c463' }}>
                      API Key ID: {keyData.id}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {keyData.name}
                    </Typography>
                  </Box>
                  <Box sx={{ height: 300 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart 
                        data={keyData.usage_history}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 20
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="timestamp" />
                        <YAxis />
                        <Tooltip 
                          contentStyle={{
                            backgroundColor: 'background.paper',
                            border: '1px solid #40c463'
                          }}
                        />
                        <Line 
                          type="monotone" 
                          dataKey="requests" 
                          stroke="#40c463" 
                          name="Requests"
                          dot={{ fill: '#40c463' }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          )
        ))}
      </Grid>
    );
  };

  const formatDateTime = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp).toLocaleString();
  };

  const ListView = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>API Key ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell align="right">Total Requests</TableCell>
            <TableCell align="right">Total Tokens</TableCell>
            <TableCell align="right">Last Used</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usageData.map((key) => (
            <TableRow key={key.id}>
              <TableCell sx={{ color: '#40c463', fontWeight: 'bold' }}>
                {key.id}
              </TableCell>
              <TableCell>{key.name}</TableCell>
              <TableCell align="right">{key.total_requests?.toLocaleString() || 0}</TableCell>
              <TableCell align="right">{key.total_tokens?.toLocaleString() || 0}</TableCell>
              <TableCell align="right">
                {formatDateTime(key.last_request_at)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Box>
      <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>API Usage Statistics</Typography>
      
      <Tabs 
        value={view} 
        onChange={(_, newValue) => setView(newValue)}
        sx={{ 
          mb: 3,
          borderBottom: 1,
          borderColor: 'divider',
          '& .MuiTab-root': {
            color: 'rgba(255, 255, 255, 0.7)',
            '&.Mui-selected': { color: '#40c463' }
          },
          '& .MuiTabs-indicator': { backgroundColor: '#40c463' }
        }}
      >
        <Tab label="CHARTS" />
        <Tab label="LIST" />
      </Tabs>

      {view === 0 ? <ChartView /> : <ListView />}
    </Box>
  );
};

export default ApiUsage;
