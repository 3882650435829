import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper, Alert, Link, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { registerUser } from '../api/services/authentication/UserService';

export default function Registration() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    businessEmail: '',
    phoneNumber: '',
    company: '',
    position: '',
    department: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const requiredFields = [
      'firstName', 'lastName', 'businessEmail', 'phoneNumber',
      'company', 'position', 'department', 'password', 'confirmPassword'
    ];

    const emptyFields = requiredFields.filter(field => !formData[field]);
    if (emptyFields.length > 0) {
      setError(`Please fill in all required fields: ${emptyFields.join(', ')}`);
      return;
    }

    if (!formData.agreeToTerms) {
      setError('Please agree to the Terms and Conditions');
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(formData.businessEmail)) {
      setError('Please enter a valid business email address');
      return;
    }

    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    if (!phoneRegex.test(formData.phoneNumber)) {
      setError('Please enter a valid phone number');
      return;
    }

    setLoading(true);

    try {
      console.log('Attempting to register user with data:', formData);
      const response = await registerUser(formData);

      if (response.success) {
        console.log('User registered successfully:', response.user);
        keycloak.login();
        navigate('/dashboard');
      } else {
        console.error('Failed to register user:', response.error);
        setError(response.error);
      }
    } catch (err) {
      console.error('Failed to create account. Please try again.', err);
      setError('Failed to create account. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      minHeight: '100vh',
      bgcolor: 'background.default',
      p: { xs: 2, sm: 4 }
    }}>
      <Paper sx={{ 
        p: { xs: 2, sm: 4 },
        maxWidth: { xs: '100%', sm: 600 },
        width: '100%',
        boxShadow: { xs: 'none', sm: 1 }
      }}>
        <Typography 
          variant="h5" 
          gutterBottom 
          align="center"
          sx={{ mb: 3 }}
        >
          Create Account
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                required
                value={formData.firstName}
                onChange={(e) => setFormData({...formData, firstName: e.target.value})}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                required
                value={formData.lastName}
                onChange={(e) => setFormData({...formData, lastName: e.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Business Email"
                type="email"
                required
                value={formData.businessEmail}
                onChange={(e) => setFormData({...formData, businessEmail: e.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Phone Number"
                required
                value={formData.phoneNumber}
                onChange={(e) => setFormData({...formData, phoneNumber: e.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Company"
                required
                value={formData.company}
                onChange={(e) => setFormData({...formData, company: e.target.value})}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Position"
                required
                value={formData.position}
                onChange={(e) => setFormData({...formData, position: e.target.value})}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Department"
                required
                value={formData.department}
                onChange={(e) => setFormData({...formData, department: e.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                required
                value={formData.password}
                onChange={(e) => setFormData({...formData, password: e.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Confirm Password"
                type="password"
                required
                value={formData.confirmPassword}
                onChange={(e) => setFormData({...formData, confirmPassword: e.target.value})}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                sx={{
                  alignItems: 'flex-start',
                  marginLeft: '-9px',
                  '& .MuiCheckbox-root': {
                    paddingTop: '2px',
                  }
                }}
                control={
                  <Checkbox
                    checked={formData.agreeToTerms}
                    onChange={(e) => setFormData({...formData, agreeToTerms: e.target.checked})}
                    required
                  />
                }
                label={
                  <Typography 
                    variant="body2" 
                    sx={{ 
                      mt: '9px',
                      display: 'inline-block'
                    }}
                  >
                    I agree to the{' '}
                    <Link component={RouterLink} to="/terms">
                      Terms and Conditions
                    </Link>
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          <Button 
            fullWidth 
            variant="contained" 
            type="submit"
            disabled={loading}
            sx={{ 
              mt: 3,
              py: 1.5,
              fontSize: '1.1rem'
            }}
          >
            {loading ? 'Creating Account...' : 'Create Account'}
          </Button>
          <Box sx={{ 
            mt: 3,
            textAlign: 'center',
            '& a': {
              textDecoration: 'none',
              fontWeight: 500,
              '&:hover': {
                textDecoration: 'underline'
              }
            }
          }}>
            <Typography variant="body2">
              Already have an account?{' '}
              <Link component={RouterLink} to="/login">
                Login here
              </Link>
            </Typography>
          </Box>
        </form>
      </Paper>
    </Box>
  );
}

