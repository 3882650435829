import React, { useState } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const TeamManagement = ({ teams, departments, addTeam, updateTeam, deleteTeam, showNotification }) => {
  const [open, setOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [teamData, setTeamData] = useState({ name: '', departmentId: '', parentTeamId: null });
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentTeamId, setCurrentTeamId] = useState(null);
  
  const handleClickOpen = () => {
    setIsEditMode(false);
    setTeamData({ 
      name: '', 
      departmentId: departments.length > 0 ? departments[0].id : '', 
      parentTeamId: null 
    });
    setOpen(true);
  };
  
  const handleClose = () => {
    setOpen(false);
    setTeamData({ name: '', departmentId: '', parentTeamId: null });
  };
  
  const handleSubmit = () => {
    if (isEditMode) {
      updateTeam({
        ...teamData,
        id: currentTeamId
      });
    } else {
      const newTeam = {
        ...teamData,
        id: Date.now().toString()
      };
      addTeam(newTeam);
    }
    handleClose();
  };
  
  const handleEdit = (team) => {
    setIsEditMode(true);
    setCurrentTeamId(team.id);
    setTeamData({
      name: team.name,
      departmentId: team.departmentId,
      parentTeamId: team.parentTeamId
    });
    setOpen(true);
  };
  
  const handleDeleteClick = (teamId) => {
    setCurrentTeamId(teamId);
    setDeleteConfirmOpen(true);
  };
  
  const handleDeleteConfirm = () => {
    deleteTeam(currentTeamId);
    setDeleteConfirmOpen(false);
  };
  
  const handleDeleteCancel = () => {
    setDeleteConfirmOpen(false);
    setCurrentTeamId(null);
  };
  
  const isFormValid = () => {
    return teamData.name && teamData.departmentId;
  };
  
  const getDepartmentName = (departmentId) => {
    const department = departments.find(d => d.id === departmentId);
    return department ? department.name : 'Unknown Department';
  };
  
  const getParentTeamName = (parentTeamId) => {
    if (!parentTeamId) return 'None';
    const team = teams.find(t => t.id === parentTeamId);
    return team ? team.name : 'Unknown Team';
  };
  
  // Filter available parent teams to prevent circular references
  const getAvailableParentTeams = () => {
    if (!teamData.departmentId) return [];
    
    if (isEditMode) {
      // Exclude current team and its children to prevent circular references
      return teams.filter(t => 
        t.departmentId === teamData.departmentId && 
        t.id !== currentTeamId
      );
    } else {
      return teams.filter(t => t.departmentId === teamData.departmentId);
    }
  };
  
  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Team Management</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleClickOpen}
          disabled={departments.length === 0}
        >
          Create Team
        </Button>
      </Box>
      
      {departments.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography>Please create a department first before adding teams.</Typography>
        </Paper>
      ) : teams.length === 0 ? (
        <Paper sx={{ p: 3, textAlign: 'center' }}>
          <Typography>No teams found. Create your first team.</Typography>
        </Paper>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Team Name</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Parent Team</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teams.map((team) => (
                <TableRow key={team.id}>
                  <TableCell>{team.name}</TableCell>
                  <TableCell>{getDepartmentName(team.departmentId)}</TableCell>
                  <TableCell>{getParentTeamName(team.parentTeamId)}</TableCell>
                  <TableCell align="right">
                    <IconButton size="small" color="primary" onClick={() => handleEdit(team)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton size="small" color="error" onClick={() => handleDeleteClick(team.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      
      {/* Create/Edit Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{isEditMode ? 'Edit Team' : 'Create New Team'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Team Name"
                value={teamData.name}
                onChange={(e) => setTeamData({ ...teamData, name: e.target.value })}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required>
                <InputLabel>Department</InputLabel>
                <Select
                  value={teamData.departmentId}
                  label="Department"
                  onChange={(e) => setTeamData({ 
                    ...teamData, 
                    departmentId: e.target.value,
                    // Reset parent team when department changes
                    parentTeamId: null 
                  })}
                >
                  {departments.map((department) => (
                    <MenuItem key={department.id} value={department.id}>
                      {department.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Parent Team (Optional)</InputLabel>
                <Select
                  value={teamData.parentTeamId || ''}
                  label="Parent Team (Optional)"
                  onChange={(e) => setTeamData({ ...teamData, parentTeamId: e.target.value || null })}
                  disabled={!teamData.departmentId}
                >
                  <MenuItem value="">None</MenuItem>
                  {getAvailableParentTeams().map((team) => (
                    <MenuItem key={team.id} value={team.id}>
                      {team.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} disabled={!isFormValid()} variant="contained">
            {isEditMode ? 'Save Changes' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={handleDeleteCancel}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this team? This will also remove all users assigned to this team.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TeamManagement;
