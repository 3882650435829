import React, { useState } from 'react';
import { 
  Box, Typography, Card, CardContent, Button, Grid, 
  Chip, Stack
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { preDeployedAgents } from '../helpers/MockData';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import DeploymentWizard from '../deployment/AgentWizard';

const DeploymentCard = ({ deployment }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'online':
        return 'success';
      case 'offline':
        return 'warning';
      case 'unavailable':
        return 'error';
      default:
        return 'default';
    }
  };
  
  return (
    <Card 
      sx={{ 
        height: '100%',
        transition: 'transform 0.2s, box-shadow 0.2s',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: theme.shadows[4],
        },
        position: 'relative',
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <Chip 
            label={deployment.status.toUpperCase()}
            color={getStatusColor(deployment.status)}
            size="small"
            sx={{ 
              fontWeight: 500,
              minWidth: 80,
              '& .MuiChip-label': {
                px: 2
              }
            }}
          />
        </Box>

        <Typography variant="h6" sx={{ mb: 1 }}>
          {deployment.name}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {deployment.type}
        </Typography>

        <Box sx={{ mb: 2 }}>
          <Typography variant="caption" color="text.secondary" display="block" sx={{ mb: 0.5 }}>
            Models
          </Typography>
          <Stack direction="row" spacing={0.5} sx={{ flexWrap: 'wrap', gap: 0.5 }}>
            {deployment.models.map(model => (
              <Chip 
                key={model.id}
                label={model.name.includes('/') ? model.name.split('/')[1] : model.name}
                size="small"
                variant="outlined"
                sx={{ 
                  borderColor: 'primary.main',
                  color: 'primary.main',
                }}
              />
            ))}
          </Stack>
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="caption" color="text.secondary" display="block" sx={{ mb: 0.5 }}>
            Nodes
          </Typography>
          <Stack direction="row" spacing={0.5} sx={{ flexWrap: 'wrap', gap: 0.5 }}>
            {deployment.nodes.map(node => (
              <Chip 
                key={node.id}
                label={node.name}
                size="small"
                variant="outlined"
                sx={{ 
                  borderColor: getStatusColor(node.status),
                  color: getStatusColor(node.status),
                }}
              />
            ))}
          </Stack>
        </Box>

        <Button
          variant="contained"
          fullWidth
          onClick={() => navigate(`/deployments/${deployment.id}/manage`)}
          sx={{ 
            mt: 'auto',
            bgcolor: theme.palette.primary.main,
            '&:hover': { bgcolor: theme.palette.primary.dark },
          }}
        >
          Manage Deployment
        </Button>
      </CardContent>
    </Card>
  );
};

const DeploymentOverview = ({ onLaunchDeployment, activeDeployments = [] }) => {
  const theme = useTheme();
  return (
    <Box>
      <Card 
        sx={{ 
          mb: 4, 
          textAlign: 'center', 
          py: 6,
          background: theme.palette.primary.main,
        }}
      >
        <Typography variant="h5" sx={{ color: 'white', mb: 2 }}>
          Ready to build AI Agents for your business?
        </Typography>
        <Button
          variant="contained"
          size="large"
          onClick={onLaunchDeployment}
          startIcon={<RocketLaunchIcon />}
          sx={{ 
            px: 6, 
            py: 2,
            fontSize: '1.2rem',
            background: 'white',
            color: 'primary.main',
            '&:hover': {
              background: 'white',
              transform: 'translateY(-2px)'
            },
            transition: 'transform 0.2s'
          }}
        >
          Build AI Agent
        </Button>
      </Card>

      <Grid container spacing={3}>
        {activeDeployments.map((deployment) => (
          <Grid item xs={12} md={4} key={deployment.id}>
            <DeploymentCard deployment={deployment} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const Deployment = () => {
  const [showWizard, setShowWizard] = useState(false);
  const [activeDeployments, setActiveDeployments] = useState([...preDeployedAgents]);

  const handleStartDeployment = () => {
    setShowWizard(true);
  };

  const handleCancelDeployment = () => {
    setShowWizard(false);
  };

  const handleCompleteDeployment = (agentConfig) => {
    const newDeployment = {
      id: `deployment-${Date.now()}`,
      name: agentConfig.name,
      type: agentConfig.purpose ? agentConfig.purpose.substring(0, 30) : 'AI Agent',
      status: 'online',
      models: [
        {
          id: agentConfig.selectedModelId,
          name: agentConfig.selectedModelName || 'Custom Model'
        }
      ],
      nodes: agentConfig.nodes.map(nodeId => ({
        id: nodeId,
        name: `Node-${nodeId}`,
        status: 'online'
      }))
    };
    
    setActiveDeployments(prev => [...prev, newDeployment]);
    setShowWizard(false);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3
      }}>
        <Typography variant="h4">Agent Studio</Typography>
        
        {showWizard && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancelDeployment}
          >
            Cancel Agent Studio
          </Button>
        )}
      </Box>

      {!showWizard ? (
        <DeploymentOverview 
          onLaunchDeployment={handleStartDeployment}
          activeDeployments={activeDeployments}
        />
      ) : (
        <DeploymentWizard onComplete={handleCompleteDeployment} />
      )}
    </Box>
  );
};

DeploymentCard.propTypes = {
  deployment: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    models: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired
    })).isRequired,
    nodes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired
    })).isRequired
  }).isRequired
};

export default Deployment;
