import React, { useState } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  TextField,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid
} from '@mui/material';

const SetupWizard = ({ onComplete, addClient, addDepartment, addTeam, addUser }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [clientData, setClientData] = useState({ name: '', abbreviation: '', tenantId: '' });
  const [departmentData, setDepartmentData] = useState({ name: '', clientId: '' });
  const [teamData, setTeamData] = useState({ name: '', departmentId: '', parentTeamId: null });
  const [userData, setUserData] = useState({ name: '', email: '', role: 'MEMBER', teamId: '' });
  
  const steps = ['Create Client', 'Create Department', 'Create Team', 'Add Admin User'];
  
  const handleNext = () => {
    if (activeStep === 0) {
      const newClient = { ...clientData, id: Date.now().toString() };
      addClient(newClient);
      setDepartmentData({ ...departmentData, clientId: newClient.id });
    } else if (activeStep === 1) {
      const newDepartment = { ...departmentData, id: Date.now().toString() };
      addDepartment(newDepartment);
      setTeamData({ ...teamData, departmentId: newDepartment.id });
    } else if (activeStep === 2) {
      const newTeam = { ...teamData, id: Date.now().toString() };
      addTeam(newTeam);
      setUserData({ ...userData, teamId: newTeam.id });
    } else if (activeStep === 3) {
      const newUser = { ...userData, id: Date.now().toString() };
      addUser(newUser);
      onComplete();
      return;
    }
    
    setActiveStep((prevStep) => prevStep + 1);
  };
  
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  
  const isStepComplete = () => {
    switch (activeStep) {
      case 0:
        return clientData.name && clientData.abbreviation && clientData.tenantId;
      case 1:
        return departmentData.name && departmentData.clientId;
      case 2:
        return teamData.name && teamData.departmentId;
      case 3:
        return userData.name && userData.email && userData.role && userData.teamId;
      default:
        return false;
    }
  };
  
  return (
    <Paper sx={{ p: 3, mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        Organization Setup Wizard
      </Typography>
      
      <Stepper activeStep={activeStep} sx={{ mb: 4, mt: 3 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      
      <Box sx={{ mt: 4, mb: 4 }}>
        {activeStep === 0 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Create Your Client
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Client Name"
                  value={clientData.name}
                  onChange={(e) => setClientData({ ...clientData, name: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Abbreviation"
                  value={clientData.abbreviation}
                  onChange={(e) => setClientData({ ...clientData, abbreviation: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Tenant ID"
                  value={clientData.tenantId}
                  onChange={(e) => setClientData({ ...clientData, tenantId: e.target.value })}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        )}
        
        {activeStep === 1 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Create Department
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Department Name"
                  value={departmentData.name}
                  onChange={(e) => setDepartmentData({ ...departmentData, name: e.target.value })}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        )}
        
        {activeStep === 2 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Create Team
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Team Name"
                  value={teamData.name}
                  onChange={(e) => setTeamData({ ...teamData, name: e.target.value })}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        )}
        
        {activeStep === 3 && (
          <Box>
            <Typography variant="h6" gutterBottom>
              Add Admin User
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Full Name"
                  value={userData.name}
                  onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  type="email"
                  label="Email"
                  value={userData.email}
                  onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Role</InputLabel>
                  <Select
                    value={userData.role}
                    label="Role"
                    onChange={(e) => setUserData({ ...userData, role: e.target.value })}
                  >
                    <MenuItem value="SUPER_ADMIN">Super Admin</MenuItem>
                    <MenuItem value="CLIENT_ADMIN">Client Admin</MenuItem>
                    <MenuItem value="DEPARTMENT_ADMIN">Department Admin</MenuItem>
                    <MenuItem value="TEAM_LEAD">Team Lead</MenuItem>
                    <MenuItem value="MEMBER">Member</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
      
      <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
        <Button
          variant="outlined"
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={!isStepComplete()}
          onClick={handleNext}
        >
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </Box>
    </Paper>
  );
};

export default SetupWizard;
