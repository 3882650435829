import React from 'react';
import { Card, CardContent, Typography, Box, Tooltip, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { 
  TrendingUp as TrendingUpIcon, 
  Check as CheckIcon,
  Error as ErrorIcon,
  Pause as PauseIcon,
  Schedule as ScheduleIcon
} from '@mui/icons-material';

const StyledProgress = styled(Box)(({ color, value }) => ({
  position: 'relative',
  height: 4,
  borderRadius: 10,
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.08)',
  overflow: 'hidden',
  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: `${value}%`,
    height: '100%',
    backgroundColor: color,
    borderRadius: 10
  }
}));

const getStatusIcon = (status) => {
  switch(status) {
    case 'In Progress':
      return <TrendingUpIcon fontSize="small" sx={{ fontSize: '0.9rem' }} />;
    case 'Completed':
      return <CheckIcon fontSize="small" sx={{ fontSize: '0.9rem' }} />;
    case 'Failed':
      return <ErrorIcon fontSize="small" sx={{ fontSize: '0.9rem' }} />;
    case 'Paused':
      return <PauseIcon fontSize="small" sx={{ fontSize: '0.9rem' }} />;
    case 'Scheduled':
      return <ScheduleIcon fontSize="small" sx={{ fontSize: '0.9rem' }} />;
    default:
      return <TrendingUpIcon fontSize="small" sx={{ fontSize: '0.9rem' }} />;
  }
};

const getStatusColor = (status) => {
  switch(status) {
    case 'In Progress':
      return '#3B82F6';
    case 'Completed':
      return '#10B981';
    case 'Failed':
      return '#EF4444';
    case 'Paused':
      return '#F59E0B';
    case 'Scheduled':
      return '#8B5CF6';
    default:
      return '#3B82F6';
  }
};

const JobItem = ({ job }) => {
  const statusColor = getStatusColor(job.status);
  
  return (
    <Box 
      sx={{ 
        display: 'flex',
        alignItems: 'flex-start',
        mb: 1.25,
        py: 1,
        px: 1.5,
        borderRadius: 2,
        transition: 'all 0.2s',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.03)'
        }
      }}
    >
      <Box 
        sx={{ 
          backgroundColor: `${statusColor}15`,
          color: statusColor,
          borderRadius: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 0.75,
          mr: 1.5
        }}>
        {getStatusIcon(job.status)}
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.75, alignItems: 'center' }}>
          <Typography variant="body2" fontWeight={500} fontSize="0.85rem" noWrap sx={{ maxWidth: '70%' }}>
            {job.name}
          </Typography>
          <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: '0.7rem' }}>
            {job.model}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          <StyledProgress color={statusColor} value={job.progress} />
          <Typography variant="caption" sx={{ ml: 1, color: 'rgba(255, 255, 255, 0.6)', fontSize: '0.7rem', minWidth: '30px' }}>
            {job.progress}%
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: '0.7rem' }}>
            {job.startTime}
          </Typography>
          <Typography variant="caption" sx={{ color: 'rgba(255, 255, 255, 0.5)', fontSize: '0.7rem' }}>
            {job.status === 'In Progress' ? `ETA: ${job.eta}` : job.completedTime}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const trainingJobs = [
  { 
    id: 1, 
    name: "Monitoreol Fine-tuning", 
    status: "In Progress", 
    progress: 45, 
    model: "Llama-3.1-8B", 
    startTime: "10:30 AM",
    eta: "3h 20m"
  },
  { 
    id: 2, 
    name: "CodeLlama Python Fine-tuning", 
    status: "In Progress", 
    progress: 73, 
    model: "CodeLlama-7B", 
    startTime: "09:15 AM",
    eta: "1h 45m"
  },
  { 
    id: 3, 
    name: "EfficientNet Image Training", 
    status: "Completed", 
    progress: 100, 
    model: "EfficientNet-B3", 
    startTime: "08:00 AM",
    completedTime: "11:45 AM"
  },
  { 
    id: 4, 
    name: "SalesChatBot RLHF Training", 
    status: "Failed", 
    progress: 37, 
    model: "Claude-3-Haiku", 
    startTime: "Yesterday",
    completedTime: "Error at 37%"
  },
  { 
    id: 5, 
    name: "LegalDoc QA System", 
    status: "Paused", 
    progress: 62, 
    model: "Mistral-7B-v0.2", 
    startTime: "Yesterday",
    completedTime: "Paused by admin"
  },
  { 
    id: 6, 
    name: "Customer Support Assistant", 
    status: "Scheduled", 
    progress: 0, 
    model: "Llama-3.1-70B", 
    startTime: "Starts in 30m",
    completedTime: "Est. 8h runtime"
  },
  { 
    id: 7, 
    name: "Data Analysis Agent", 
    status: "In Progress", 
    progress: 12, 
    model: "DeepSeek-v2", 
    startTime: "11:50 AM",
    eta: "7h 15m"
  }
];

export default function TrainingJobs() {
  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      backgroundColor: '#1a1a1a',
      color: 'white',
      borderRadius: 3,
      boxShadow: 'none',
      border: '1px solid rgba(255, 255, 255, 0.05)'
    }}>
      <CardContent sx={{ 
        p: 0,
        '&:last-child': { pb: 0 },
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}>
        <Box sx={{ p: 2, pb: 1.5, borderBottom: '1px solid rgba(255, 255, 255, 0.05)' }}>
          <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '1rem' }}>
            Training Jobs
          </Typography>
        </Box>
        
        <Box sx={{ 
          flex: 1, 
          overflowY: 'auto', 
          p: 1,
          '&::-webkit-scrollbar': {
            width: '0.4em'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: 4
          }
        }}>
          {trainingJobs.map((job) => (
            <JobItem key={job.id} job={job} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}
