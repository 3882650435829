import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  Box,
  Chip,
  CircularProgress,
  Paper,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  Alert,
  Snackbar
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { createInventory, getAllInventories, assignInventoryToUser, deleteInventory } from '../api/services/computeService/Inventory';
import { useKeycloak } from '@react-keycloak/web';
import worldCountries from './world-countries.json';

const DiscoverInstances = () => {
  const [servers, setServers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortBy, setSortBy] = useState('name');
  const [openDialog, setOpenDialog] = useState(false);
  const [newServer, setNewServer] = useState({
    hostname: '',
    ipAddress: '',
    port: "22",
    location: '',
    singleNode: true,
    slurmHead: false,
    slurmOwner: '',
    status: 0,
    numOfCpus: 0,
    cpu: '',
    numOfGpus: 0,
    gpu: '',
    gpuType: '',
    ram: 0,
    mem: 0,
    type: 0,
    os: '',
    osVersion: '',
    kernelVersion: '',
    suser: '',
    spass: '',
    spriv: ''
  });
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const gpuVendors = ['NVIDIA', 'AMD'];
  const gpuModels = {
    NVIDIA: [
      'RTX 4090',
      'RTX 4080',
      'RTX 3090 Ti',
      'RTX 3090',
      'RTX 3080 Ti',
      'RTX 3080',
      'A100',
      'A6000',
      'L40',
      'H100',
      'Tesla V100',
      'Tesla P100',
      'Tesla P40', 
      'Tesla P4',
      'Quadro RTX 8000',
      'Quadro RTX 6000', 
      'Quadro RTX 4000',
      'Quadro P6000',
      'Quadro P4000',
      'A10',
      'A30',
      'A40',
      'A100 PCIe',
      'A100 SXM',
      'A100 HGX',
      'V100S',
      'V100 PCIe',
      'V100 SXM2',
      'P40',
      'P4',
      'M10',
      'M60',
      'M6',
      'K80',
      'K520',
      'GRID K2',
      'GRID K1',
      'GH200',
      'GH100',
      'GH50',
      'GH40',
      'GH30',
      'GH20',
      'H100'
    ],
    AMD: [
      'RX 7900 XTX',
      'RX 7900 XT',
      'RX 6950 XT',
      'RX 6900 XT',
      'RX 6800 XT',
      'MI300',
      'MI250',
      'MI210',
      'MI100',
      'Radeon Pro VII',
      'Radeon Pro WX 8200',
      'Radeon Pro WX 9100',
      'Radeon Instinct MI8',
      'Radeon Instinct MI6',
      'FirePro S7150',
      'FirePro S7100X',
      'AMD Radeon Pro W6800',
      'AMD Radeon Pro W6600',
      'AMD Radeon Pro W6400',
      'AMD Radeon Pro WX 8200',
      'AMD Radeon Pro WX 9100',
      'AMD Radeon Instinct MI60',
      'AMD Radeon Instinct MI50',
      'AMD Radeon Instinct MI25',
      'AMD Radeon Instinct MI8',
      'AMD Radeon Instinct MI6',
      'AMD FirePro S9170',
      'AMD FirePro S9150',
      'AMD FirePro S9050',
      'AMD FirePro S8150',
      'AMD FirePro S7150',
      'AMD FirePro S7100X',
      'AMD MI300X',
      'AMD MI200X',
      'AMD MI100X',
      'AMD MI50X',
      'AMD MI25X',
      'AMD MI8X'
    ]
  };

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [serverToDelete, setServerToDelete] = useState(null);

  const countries = worldCountries.features.map(feature => ({
    name: feature.properties.name,
    id: feature.id
  }));
  
  useEffect(() => {
    const fetchServers = async () => {
      try {
        await keycloak.updateToken(30);
        const response = await getAllInventories(keycloak.token);
        
        console.log('Response received:', response); // Log the response
        
        if (response.success) {
          const transformedServers = response.data.map(server => ({
            id: server.id,
            hostname: server.hostname,
            ipAddress: server.ipAddress,
            port: server.port,
            gpuType: server.gpuType.toUpperCase(),
            gpu: server.gpu,
            location: server.location,
            status: server.status,
            numCpu: server.numCpu,
            cpu: server.cpu,
            numGpus: server.numGpus,
            ram: server.ram,
            mem: server.mem,
            type: server.type,
            os: server.os,
            osVersion: server.osVersion,
            kernelVersion: server.kernelVersion,
            singleNode: server.singleNode,
            slurmHead: server.slurmHead,
            slurmOwner: server.slurmOwner
          }));
          
          setServers(transformedServers);
        } else {
          console.error('Failed to fetch servers:', response.message);
        }
      } catch (error) {
        console.error('Error fetching servers:', error);
        if (error.message?.includes('token')) {
          try {
            await keycloak.login();
          } catch (loginError) {
            console.error('Login failed:', loginError);
          }
        }
      } finally {
        setLoading(false);
      }
    };

    fetchServers();
  }, [keycloak]);

  const handleAddServer = (server) => {
    console.log('Adding server:', server);
    navigate('/cloud-management');
  };

  const handleSort = (property) => {
    const isAsc = sortBy === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const filteredServers = servers.filter(server =>
    server.hostname.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedServers = filteredServers.sort((a, b) => {
    if (typeof a[sortBy] === 'string') {
      return a[sortBy].localeCompare(b[sortBy]) * (sortDirection === 'asc' ? 1 : -1);
    } else {
      return (a[sortBy] - b[sortBy]) * (sortDirection === 'asc' ? 1 : -1);
    }
  });

  const handleAddNewServer = async () => {
    try {
      await keycloak.updateToken(30);

      const payload = {
        hostname: newServer.hostname,
        ipAddress: newServer.ipAddress,
        port: newServer.port,
        location: newServer.location,
        singleNode: newServer.singleNode,
        slurmHead: newServer.slurmHead,
        slurmOwner: newServer.slurmOwner,
        status: newServer.status,
        numOfCpus: newServer.numOfCpus,
        cpu: newServer.cpu,
        numOfGpus: newServer.numOfGpus,
        gpu: newServer.gpu,
        gpuType: newServer.gpuType,
        ram: newServer.ram,
        mem: newServer.mem,
        type: newServer.type,
        os: newServer.os,
        osVersion: newServer.osVersion,
        kernelVersion: newServer.kernelVersion,
        suser: newServer.suser,
        spass: newServer.spass,
        spriv: newServer.spriv,
        token: keycloak.token
      };

      console.log('Payload:', payload);

      const response = await createInventory(payload);

      if (response.success && response.data) {
        setServers(prevServers => [...prevServers, response.data]);
        setOpenDialog(false);
        setNewServer({
          hostname: '',
          ipAddress: '',
          port: '',
          location: '',
          singleNode: true,
          slurmHead: false,
          slurmOwner: '',
          status: '',
          numOfCpus: 0,
          cpu: '',
          numOfGpus: 0,
          gpu: '',
          gpuType: 0,
          ram: 0,
          mem: 0,
          type: 0,
          os: '',
          osVersion: '',
          kernelVersion: '',
          suser: '',
          spass: '',
          spriv: ''
        });
        
        setSnackbar({
          open: true,
          message: 'Server added successfully!',
          severity: 'success'
        });
      } else {
        const errorMessage = response.message || 'Failed to add server. Please try again.';
        setSnackbar({
          open: true,
          message: errorMessage,
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('Error adding server:', error);
      let errorMessage = 'An unexpected error occurred while adding the server.';
      
      if (error.response?.data?.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      setSnackbar({
        open: true,
        message: errorMessage,
        severity: 'error'
      });

      if (error.message?.includes('token')) {
        try {
          await keycloak.login();
        } catch (loginError) {
          console.error('Login failed:', loginError);
        }
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    console.log('Input changed:', name, value); // Debug log
    
    if (name === 'gpuType') {
      setNewServer(prev => ({
        ...prev,
        gpuType: value,
        gpu: '' // Reset GPU model when changing GPU type
      }));
    } else {
      setNewServer(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleAssignServer = async (server) => {
    try {
      await keycloak.updateToken(30);
      
      console.log(`Server ID: ${server.id}, Subject: ${keycloak.subject}, Token: ${keycloak.token}`);
      const response = await assignInventoryToUser(
        server.id,
        keycloak.subject,
        keycloak.token
      );
      if (response.success) {
        console.log('Server assigned successfully');
        navigate('/cloud-management');
      } else {
        console.error('Failed to assign server:', response.error);
      }
    } catch (error) {
      console.error('Error assigning server:', error);
      if (error.message?.includes('token')) {
        try {
          await keycloak.updateToken(30);
          const retryResponse = await assignInventoryToUser(
            server.id,
            keycloak.subject,
            keycloak.token
          );
          
          if (retryResponse.success) {
            console.log('Server assigned successfully after token refresh');
            navigate('/cloud-management');
          } else {
            console.error('Failed to assign server after token refresh:', retryResponse.error);
          }
        } catch (loginError) {
          console.error('Login failed:', loginError);
          keycloak.login();
        }
      }
    }
  };

  const handleDeleteServer = (server) => {
    setServerToDelete(server);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await keycloak.updateToken(30);
      
      const response = await deleteInventory(serverToDelete.id, keycloak.token);
      
      if (response.success) {
        const updatedServers = servers.filter(s => s.id !== serverToDelete.id);
        setServers(updatedServers);
        setSnackbar({
          open: true,
          message: 'Server deleted successfully',
          severity: 'success'
        });
      } else {
        setSnackbar({
          open: true,
          message: `Failed to delete server: ${response.error}`,
          severity: 'error'
        });
      }
    } catch (error) {
      console.error('Error deleting server:', error);
      setSnackbar({
        open: true,
        message: `Error deleting server: ${error.message}`,
        severity: 'error'
      });
    } finally {
      setDeleteDialogOpen(false);
      setServerToDelete(null);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h4" gutterBottom>
            Available Servers
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Add Server
          </Button>
        </Box>
        <TextField
          variant="outlined"
          placeholder="Search by server name..."
          fullWidth
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'hostname'}
                    direction={sortBy === 'hostname' ? sortDirection : 'asc'}
                    onClick={() => handleSort('hostname')}
                  >
                    Hostname
                  </TableSortLabel>
                </TableCell>
                <TableCell>Status</TableCell>
                <TableCell>IP Address</TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'gpuType'}
                    direction={sortBy === 'gpuType' ? sortDirection : 'asc'}
                    onClick={() => handleSort('gpuType')}
                  >
                    GPU Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === 'gpu'}
                    direction={sortBy === 'gpu' ? sortDirection : 'asc'}
                    onClick={() => handleSort('gpu')}
                  >
                    GPU Model
                  </TableSortLabel>
                </TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedServers.map((server) => (
                <TableRow key={server.id}>
                  <TableCell>{server.hostname}</TableCell>
                  <TableCell>
                    <Chip label={server.status === 'active' ? "Online" : "Offline"} 
                          color={server.status === 'active' ? "success" : "error"} />
                  </TableCell>
                  <TableCell>{server.ipAddress}</TableCell>
                  <TableCell>
                    {server.gpuType}
                  </TableCell>
                  <TableCell>{server.gpu}</TableCell>
                  <TableCell>
                    {countries.find(country => country.id === server.location)?.name || server.location}
                  </TableCell>
                  <TableCell>{server.type === 0 ? 'Storage' : 'Compute'}</TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleAssignServer(server)}
                      >
                        Assign
                      </Button>
                      <Button 
                        disabled={false}
                        variant="contained" 
                        color="error" 
                        onClick={() => handleDeleteServer(server)}
                      >
                        Delete
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
          <DialogTitle>Add New Server</DialogTitle>
          <DialogContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
              <TextField
                name="hostname"
                label="Hostname"
                fullWidth
                value={newServer.hostname}
                onChange={handleInputChange}
              />
              <FormControl fullWidth>
                <InputLabel>GPU Type</InputLabel>
                <Select
                  name="gpuType"
                  value={newServer.gpuType}
                  label="GPU Type"
                  onChange={handleInputChange}
                >
                  <MenuItem value={0}>AMD</MenuItem>
                  <MenuItem value={1}>NVIDIA</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel>GPU Model</InputLabel>
                <Select
                  name="gpu"
                  value={newServer.gpu}
                  label="GPU Model"
                  onChange={handleInputChange}
                >
                  {newServer.gpuType === 0 && gpuModels.AMD.map((model) => (
                    <MenuItem key={model} value={model}>{model}</MenuItem>
                  ))}
                  {newServer.gpuType === 1 && gpuModels.NVIDIA.map((model) => (
                    <MenuItem key={model} value={model}>{model}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                fullWidth
                options={countries}
                getOptionLabel={(option) => `${option.name} (${option.id})`}
                value={countries.find(country => country.id === newServer.location) || null}
                onChange={(event, newValue) => {
                  setNewServer(prev => ({
                    ...prev,
                    location: newValue ? newValue.id : ''
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="location"
                    label="Location"
                    fullWidth
                    required
                  />
                )}
              />
              <TextField
                name="ipAddress"
                label="IP Address"
                fullWidth
                value={newServer.ipAddress}
                onChange={handleInputChange}
              />
              <TextField
                name="port"
                label="Port"
                type="number"
                fullWidth
                value={newServer.port}
                onChange={handleInputChange}
              />
              <TextField
                name="suser"
                label="Username"
                fullWidth
                value={newServer.suser}
                onChange={handleInputChange}
              />
              <TextField
                name="spass"
                label="Password"
                type="password"
                fullWidth
                value={newServer.spass}
                onChange={handleInputChange}
              />
              <TextField
                name="spriv"
                label="SSH Key"
                fullWidth
                multiline
                rows={3}
                value={newServer.spriv}
                onChange={handleInputChange}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button onClick={handleAddNewServer} variant="contained" color="primary">
              Add Server
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to delete server "{serverToDelete?.hostname}"?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
            <Button onClick={confirmDelete} color="error" variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        >
          <Alert
            onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default DiscoverInstances;
