import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  Divider,
  Alert,
  AlertTitle,
  Paper,
  Chip,
  IconButton,
  Tooltip,
} from '@mui/material';
import {
  Memory as MemoryIcon,
  Speed as SpeedIcon,
  DeviceThermostat as TempIcon,
  Storage as StorageIcon,
  Warning as WarningIcon,
  CheckCircle as CheckIcon,
} from '@mui/icons-material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as ChartTooltip, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { theme } from '../helpers/theme';
import { getServerMetrics } from '../api/services/monitoring/Monitoring';

const MetricCard = ({ title, value, unit, icon, color, secondaryValue }) => (
  <Card sx={{ 
    height: '100%',
    bgcolor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    },
  }}>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {icon}
        <Typography variant="h6" sx={{ ml: 1 }}>{title}</Typography>
      </Box>
      <Typography variant="h4" sx={{ color: color }}>
        {value}{unit}
      </Typography>
      {secondaryValue && (
        <Typography variant="body2" color="text.secondary">
          {secondaryValue}
        </Typography>
      )}
    </CardContent>
  </Card>
);

const UsageChart = ({ data, dataKey, color, title }) => (
  <Box sx={{ height: '100%', minHeight: 150 }}>
    <Typography variant="subtitle2" sx={{ mb: 1 }}>{title}</Typography>
    <ResponsiveContainer width="100%" height="85%">
      <AreaChart data={data}>
        <defs>
          <linearGradient id={`color${dataKey}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8}/>
            <stop offset="95%" stopColor={color} stopOpacity={0}/>
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.divider} />
        <XAxis dataKey="time" stroke={theme.palette.text.secondary} />
        <YAxis 
          stroke={theme.palette.text.secondary} 
          domain={['dataMin', 'dataMax']}
          {...(dataKey === 'cpu' || dataKey === 'memory' ? { domain: [0, 100] } : {})}
        />
        <ChartTooltip />
        <Area 
          type="monotone" 
          dataKey={dataKey} 
          stroke={color} 
          fillOpacity={1} 
          fill={`url(#color${dataKey})`} 
        />
      </AreaChart>
    </ResponsiveContainer>
  </Box>
);

export default function Monitoring() {
  const { serverIp, port, hostname } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const [metricsData, setMetricsData] = useState([]);
  const [lastMetrics, setLastMetrics] = useState(null);

  useEffect(() => {
    let isSubscribed = true;
    let metricsStream = null;

    const startMetricsStream = async () => {
      try {
        console.log('Starting metrics stream for server:', serverIp, 'port:', port, 'hostname:', hostname);
        const { stream, error } = await getServerMetrics(serverIp, port, hostname);
        
        if (error) {
          console.error('Error starting metrics stream:', error);
          return;
        }

        metricsStream = stream;

        stream.on('data', (response) => {
          if (!isSubscribed) return;

          // Convert the protobuf response to a plain object
          const metrics = response.toObject();
          
          setMetricsData(prevData => {
            const newData = [...prevData, {
              time: new Date().toLocaleTimeString(),
              cpu: metrics?.cpu?.usage || 0,
              gpu: metrics?.gpu?.utilization || 0,
              memory: metrics?.memory?.utilization || 0,
              temperature: metrics?.gpu?.temperature || 0,
            }].slice(-50);
            return newData;
          });

          setLastMetrics(metrics);
        });

        stream.on('error', (error) => {
          console.error('Metrics stream error:', error);
          // You might want to implement reconnection logic here
          if (isSubscribed) {
            setTimeout(startMetricsStream, 5000); // Retry after 5 seconds
          }
        });

        stream.on('end', () => {
          console.log('Metrics stream ended');
          // Implement reconnection logic
          if (isSubscribed) {
            setTimeout(startMetricsStream, 1000); // Reconnect after 1 second
          }
        });

      } catch (error) {
        console.error('Error in metrics stream setup:', error);
        // Retry connection after error
        if (isSubscribed) {
          setTimeout(startMetricsStream, 5000);
        }
      }
    };

    startMetricsStream();

    return () => {
      isSubscribed = false;
      if (metricsStream) {
        metricsStream.cancel();
      }
    };
  }, [serverIp, port]);

  // Update MetricCard components to use real data
  const renderMetricCards = () => (
    <>
      <Grid item xs={12} md={3}>
        <MetricCard
          title="CPU Usage"
          value={lastMetrics?.cpu?.usage?.toFixed(1) || 0}
          unit="%"
          icon={<SpeedIcon color="primary" />}
          color={theme.palette.primary.main}
          secondaryValue={`${lastMetrics?.cpu?.cores || 0} cores active`}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MetricCard
          title="GPU Utilization"
          value={lastMetrics?.gpu?.utilization?.toFixed(1) || 0}
          unit="%"
          icon={<SpeedIcon color="secondary" />}
          color={theme.palette.secondary.main}
          secondaryValue={`${lastMetrics?.gpu?.memory_used?.toFixed(1) || 0}GB / ${lastMetrics?.gpu?.memory_total?.toFixed(1) || 0}GB`}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MetricCard
          title="Memory"
          value={lastMetrics?.memory?.utilization?.toFixed(1) || 0}
          unit="%"
          icon={<MemoryIcon color="info" />}
          color={theme.palette.info.main}
          secondaryValue={`${lastMetrics?.memory?.used?.toFixed(1) || 0}GB / ${lastMetrics?.memory?.total?.toFixed(1) || 0}GB`}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <MetricCard
          title="Temperature"
          value={lastMetrics?.gpu?.temperature?.toFixed(1) || 0}
          unit="°C"
          icon={<TempIcon color="warning" />}
          color={theme.palette.warning.main}
          secondaryValue="GPU Temperature"
        />
      </Grid>
    </>
  );

  return (
    <Box sx={{ p: 3, minHeight: '100vh', bgcolor: theme.palette.background.default }}>
      <Grid container spacing={3}>
        {/* Header Section */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, mb: 3, bgcolor: theme.palette.background.paper }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h4">Server Monitoring</Typography>
              <Chip 
                label="Online" 
                color="success" 
                icon={<CheckIcon />} 
                sx={{ px: 2 }}
              />
            </Box>
            <Typography color="text.secondary">Server IP: {serverIp}:{port}</Typography>
          </Paper>
        </Grid>

        {/* Metrics Cards */}
        {renderMetricCards()}

        {/* Charts Section - Update to use real-time data */}
        <Grid item xs={12} md={8}>
          <Card sx={{ height: '100%', minHeight: 400 }}>
            <CardHeader title="Resource Usage Over Time" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <UsageChart 
                    data={metricsData} 
                    dataKey="cpu" 
                    color={theme.palette.primary.main}
                    title="CPU Usage"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UsageChart 
                    data={metricsData} 
                    dataKey="gpu" 
                    color={theme.palette.secondary.main}
                    title="GPU Usage"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UsageChart 
                    data={metricsData} 
                    dataKey="memory" 
                    color={theme.palette.info.main}
                    title="Memory Usage"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <UsageChart 
                    data={metricsData} 
                    dataKey="temperature" 
                    color={theme.palette.warning.main}
                    title="Temperature"
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Alerts and Logs Section */}
        <Grid item xs={12} md={4}>
          <Card sx={{ height: '100%', minHeight: 400 }}>
            <CardHeader 
              title="Recent Alerts" 
              action={
                <IconButton>
                  <WarningIcon color="warning" />
                </IconButton>
              }
            />
            <CardContent>
              <List>
                <ListItem>
                  <Alert severity="warning" sx={{ width: '100%' }}>
                    <AlertTitle>High CPU Usage</AlertTitle>
                    CPU utilization exceeded 80% for 5 minutes
                    <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                      29 minutes ago
                    </Typography>
                  </Alert>
                </ListItem>
                <ListItem>
                  <Alert severity="info" sx={{ width: '100%' }}>
                    <AlertTitle>GPU Temperature Normal</AlertTitle>
                    Temperature stabilized below 75°C
                    <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                      51 minutes ago
                    </Typography>
                  </Alert>
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Additional sections can be added here */}
      </Grid>
    </Box>
  );
}
