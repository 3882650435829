import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Box, Typography, Button } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';

function ModelCard({ model, isMyModel }) {
    const navigate = useNavigate();
  
    const handleDeploy = () => {
      navigate('/deployment', { state: { modelId: model.id, modelName: model.name } });
    };
  
    return (
      <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flexGrow: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
            <Typography variant="h6" component="div">
              {isMyModel ? model.name : model.name.split('/')[1]}
            </Typography>
            <InfoOutlinedIcon color="success" />
          </Box>
          {isMyModel ? (
            <>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {model.description}
              </Typography>
              <Typography variant="body2" gutterBottom>
                {model.version}
              </Typography>
              <Typography variant="body2" gutterBottom>Accuracy: {model.accuracy}</Typography>
              <Typography variant="body2" gutterBottom>Training Data: {model.trainingData}</Typography>
              <Typography variant="body2" gutterBottom>Dataset: {model.dataset}</Typography>
              <Typography variant="body2" gutterBottom>Updated: {model.updatedAt}</Typography>
            </>
          ) : (
            <>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                By {model.name.split('/')[0]}
              </Typography>
              <Typography variant="body2" gutterBottom>Size: {model.size}</Typography>
              <Typography variant="body2" gutterBottom>Type: {model.type}</Typography>
            </>
          )}
        </CardContent>
        <Box sx={{ p: 2, pt: 0 }}>
          <Button variant="outlined" fullWidth onClick={handleDeploy}>Deploy</Button>
        </Box>
      </Card>
    );
}

ModelCard.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    version: PropTypes.string,
    accuracy: PropTypes.string,
    trainingData: PropTypes.string,
    dataset: PropTypes.string,
    updatedAt: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  isMyModel: PropTypes.bool.isRequired
};


ModelCard.defaultProps = {
  isMyModel: false
};

export default ModelCard;