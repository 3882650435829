import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
  InputAdornment,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#ffffff' },
    secondary: { main: '#cccccc' },
    background: { default: '#000000', paper: '#111111' },
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  components: {
    MuiButton: {
      styleOverrides: { root: { borderRadius: 8, textTransform: 'none' } },
    },
    MuiCard: {
      styleOverrides: { root: { borderRadius: 12, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' } },
    },
    MuiChip: {
      styleOverrides: { root: { borderRadius: 8 } },
    },
  },
});

const meetings = [
  { name: 'OGC', date: '2022-01-01', transcription: 'Upload', recording: 'Upload', summarisation: 'Upload' },
  { name: 'Deployment', date: '2022-02-01', transcription: 'Upload', recording: 'Upload', summarisation: 'Upload' },
  { name: 'API Meeting', date: '2022-03-01', transcription: 'Upload', recording: 'Upload', summarisation: 'Upload' },
  { name: 'Phosphore', date: '2022-04-01', transcription: 'Upload', recording: 'Upload', summarisation: 'Upload' },
  { name: 'Elevation', date: '2022-05-01', transcription: 'Upload', recording: 'Upload', summarisation: 'Upload' },
];

export default function MeetingSummarizer() {
  const [name, setName] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [meetingName, setMeetingName] = useState('');
  const [actions, setActions] = useState({
    transcribe: false,
    summarise: false,
    translate: false,
    record: false,
  });

  const handleActionChange = (event) => {
    setActions({ ...actions, [event.target.name]: event.target.checked });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', minHeight: '100vh', p: 0 }}>
        <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', textAlign: 'center', mb: 2 }}>
          Meeting Summariser
        </Typography>
        <Box component={Paper} sx={{ p: 3, mb: 4 }}>
          <TextField
            fullWidth
            label="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Enter Meeting Link / URL"
            value={meetingLink}
            onChange={(e) => setMeetingLink(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Enter Meeting Name"
            value={meetingName}
            onChange={(e) => setMeetingName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            What do you want to do?
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mb: 2 }}>
            <FormControlLabel
              control={<Checkbox checked={actions.transcribe} onChange={handleActionChange} name="transcribe" />}
              label="Transcribe"
            />
            <FormControlLabel
              control={<Checkbox checked={actions.summarise} onChange={handleActionChange} name="summarise" />}
              label="Summarise"
            />
            <FormControlLabel
              control={<Checkbox checked={actions.translate} onChange={handleActionChange} name="translate" />}
              label="Translate"
            />
            <FormControlLabel
              control={<Checkbox checked={actions.record} onChange={handleActionChange} name="record" />}
              label="Record"
            />
          </Box>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button variant="contained" color="primary">
              Run
            </Button>
            <Button variant="outlined" color="secondary">
              Cancel
            </Button>
          </Box>
        </Box>
        <Box component={Paper} sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            Summarised Meetings
          </Typography>
          <TextField
            fullWidth
            placeholder="Search here..."
            sx={{ mb: 2 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>MEETING</TableCell>
                  <TableCell>DATED</TableCell>
                  <TableCell>TRANSCRIPTION</TableCell>
                  <TableCell>RECORDING</TableCell>
                  <TableCell>SUMMARISATION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {meetings.map((meeting) => (
                  <TableRow key={meeting.name}>
                    <TableCell>{meeting.name}</TableCell>
                    <TableCell>{meeting.date}</TableCell>
                    <TableCell>
                      <Button variant="outlined" size="small">
                        {meeting.transcription}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button variant="outlined" size="small">
                        {meeting.recording}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button variant="outlined" size="small">
                        {meeting.summarisation}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
