import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
} from '@mui/material';
import { Send as SendIcon, Add as AddIcon, ContentCopy as CopyIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';
import MapChart from '../cloudmanagement/MapChart'; 

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#ffffff' },
    secondary: { main: '#cccccc' },
    background: { default: '#000000', paper: '#111111' },
    text: { primary: '#ffffff', secondary: '#cccccc' },
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: { root: { borderRadius: 12, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' } },
    },
  },
});

const baseModels = ['LLaMA-3', 'Falcon-180B', 'Mistral-7B'];
const datasets = ['The Pile v2', 'C4 (Colossal Clean Crawled Corpus)', 'OpenWebText2'];
const gpuServers = ['MI210 (AMD)', 'AMD EPYC™ 9754', 'AMD EPYC™ 9734P', 'AMD EPYC™ 9654'];

const suggestions = [
  'Explain the deployment process for a large language model',
  'What are the key considerations when selecting a GPU for model training?',
  'Compare and contrast different datasets for fine-tuning language models',
];

export default function DeployModel() {
  const location = useLocation();
  const [model, setModel] = useState('');
  const [dataset, setDataset] = useState('');
  const [gpu, setGpu] = useState('');
  const [instances, setInstances] = useState('1');
  const [options, setOptions] = useState('');
  const [config, setConfig] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [apiKeys, setApiKeys] = useState(['sk-1234567890abcdef', 'sk-0987654321fedcba']);
  const [hasTyped, setHasTyped] = useState(false);

  useEffect(() => {
    if (location.state) {
      const { modelId, modelName, jobId, jobName } = location.state;
      if (modelId && modelName) {
        setChatMessages([
          { text: `Deploying model: ${modelName} (ID: ${modelId})`, sender: 'system' }
        ]);
      } else if (jobId && jobName) {
        setChatMessages([
          { text: `Deploying completed job: ${jobName} (ID: ${jobId})`, sender: 'system' }
        ]);
      }
    }
  }, [location]);

  const handleSendMessage = () => {
    if (inputMessage.trim()) {
      setChatMessages([...chatMessages, { text: inputMessage, sender: 'user' }]);
      setInputMessage('');
      // Simulate a response
      setTimeout(() => {
        setChatMessages(prev => [...prev, { text: 'This is a simulated response.', sender: 'ai' }]);
      }, 1000);
    }
  };

  const handleCreateApiKey = () => {
    const newKey = `sk-${Math.random().toString(36).substr(2, 16)}`;
    setApiKeys([...apiKeys, newKey]);
  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
    if (!hasTyped && e.target.value !== '') {
      setHasTyped(true);
    }
  };

  const handleGpuSelect = (e) => {
    setGpu(e.target.value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ bgcolor: 'background.default', minHeight: '80vh', p: 0 }}>
        <Typography variant="h4" gutterBottom sx={{ color: 'primary.main', mb: 2, textAlign: 'center' }}>
          Deploy Model
        </Typography>

        {/* Deployment form and chat */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {/* Form Paper */}
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                Set the following parameters to start the Deployment process
              </Typography>
              {/* Form Controls */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="gpu-label">Select GPU Server</InputLabel>
                <Select
                  labelId="gpu-label"
                  value={gpu}
                  onChange={handleGpuSelect}
                  label="Select GPU Server"
                >
                  {gpuServers.map((server) => (
                    <MenuItem key={server} value={server}>
                      {server}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="No. of Instances"
                value={instances}
                onChange={(e) => setInstances(e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Time"
                value="none"
                InputProps={{ readOnly: true }}
                sx={{ mb: 2 }}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="options-label">Select Options</InputLabel>
                <Select
                  labelId="options-label"
                  value={options}
                  onChange={(e) => setOptions(e.target.value)}
                  label="Select Options"
                >
                  <MenuItem value="default">default</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="config-label">Select Config</InputLabel>
                <Select
                  labelId="config-label"
                  value={config}
                  onChange={(e) => setConfig(e.target.value)}
                  label="Select Config"
                >
                  <MenuItem value="default">default</MenuItem>
                </Select>
              </FormControl>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button variant="contained" color="primary">
                  Deploy Station
                </Button>
                <Button variant="outlined" color="secondary">
                  Cancel
                </Button>
              </Box>
            </Paper>
            {/* API Keys Section */}
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                API Keys
              </Typography>
              <List>
                {apiKeys.map((key, index) => (
                  <React.Fragment key={key}>
                    <ListItem
                      secondaryAction={
                        <IconButton edge="end" aria-label="copy">
                          <CopyIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={key} />
                    </ListItem>
                    {index < apiKeys.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </List>
              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={handleCreateApiKey}
                sx={{ mt: 2 }}
              >
                Create New API Key
              </Button>
            </Paper>
            {/* Server Locations */}
            <Paper sx={{ p: 2, mb: 3 }}>
              <Typography variant="h6" gutterBottom>
                Server Locations
              </Typography>
              {/* <Box sx={{ height: '400px', width: '100%' }}>
                <MapChart />
              </Box> */}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* Chat Interface */}
            <Paper sx={{ p: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" gutterBottom sx={{ mb: 2 }}>
                Chat Interface
              </Typography>
              <Box sx={{ flexGrow: 1, overflow: 'auto', mb: 2 }}>
                <List>
                  {chatMessages.map((message, index) => (
                    <ListItem key={index} sx={{ justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start' }}>
                      <Paper 
                        sx={{ 
                          p: 2, 
                          maxWidth: '70%', 
                          bgcolor: message.sender === 'user' ? 'primary.main' : 'background.paper',
                          borderRadius: '12px',
                          boxShadow: 2,
                        }}
                      >
                        <ListItemText 
                          primary={message.text} 
                          sx={{ 
                            color: message.sender === 'user' ? 'background.paper' : 'text.primary',
                            '& .MuiListItemText-primary': { fontWeight: 500 },
                          }} 
                        />
                      </Paper>
                    </ListItem>
                  ))}
                </List>
              </Box>
              {!hasTyped && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, flexWrap: 'wrap' }}>
                  {suggestions.map((suggestion, index) => (
                    <Button 
                      key={index} 
                      variant="outlined" 
                      size="small" 
                      onClick={() => {
                        setInputMessage(suggestion);
                        setHasTyped(true);
                      }}
                      sx={{ 
                        m: 0.5, 
                        borderColor: 'primary.main', 
                        color: 'primary.main',
                        '&:hover': {
                          borderColor: 'secondary.main',
                          color: 'secondary.main',
                          backgroundColor: 'rgba(129, 230, 217, 0.1)',
                        }
                      }}
                    >
                      {suggestion}
                    </Button>
                  ))}
                </Box>
              )}
              <Box sx={{ display: 'flex', alignItems: 'center', bgcolor: 'background.paper', borderRadius: '12px', p: 1 }}>
                <TextField
                  fullWidth
                  variant="standard"
                  placeholder="Message Deployment Assistant..."
                  value={inputMessage}
                  onChange={handleInputChange}
                  onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                  sx={{
                    '& .MuiInput-root': {
                      color: 'text.primary',
                      '&:before, &:after': { display: 'none' },
                    },
                    '& .MuiInput-input': {
                      p: 1,
                    },
                  }}
                />
                <IconButton
                  sx={{
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'rgba(16, 163, 127, 0.1)',
                    },
                  }}
                >
                  <AttachFileIcon />
                </IconButton>
                <IconButton
                  onClick={handleSendMessage}
                  sx={{
                    color: 'primary.main',
                    '&:hover': {
                      backgroundColor: 'rgba(16, 163, 127, 0.1)',
                    },
                  }}
                >
                  <SendIcon />
                </IconButton>
              </Box>
              <Typography variant="caption" sx={{ textAlign: 'center', py: 1, color: 'text.secondary' }}>
                Deployment Assistant can make mistakes. Always verify important information.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
}
