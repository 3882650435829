import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createInventory = async ({
  hostname,
  ipAddress,
  port,
  location,
  singleNode,
  slurmHead,
  slurmOwner,
  status,
  numOfCpus,
  cpu,
  numOfGpus,
  gpu,
  gpuType,
  ram,
  mem,
  type,
  os,
  osVersion,
  kernelVersion,
  suser,
  spass,
  spriv,
  token
}) => {
  try {
    const requestData = {
      hostname,
      ip_address: ipAddress,
      port: String(port),
      location,
      single_node: Boolean(singleNode),
      slurm_head: Boolean(slurmHead),
      slurm_owner: slurmOwner,
      status: status,
      num_of_cpus: parseInt(numOfCpus),
      cpu,
      num_of_gpus: parseInt(numOfGpus),
      gpu,
      gpu_type: gpuType,
      ram: parseInt(ram),
      mem: parseInt(mem),
      type: type,
      os,
      os_version: osVersion,
      kernel_version: kernelVersion,
      suser,
      spass,
      spriv
    };

    console.log('Sending request data:', requestData);

    const response = await axios.post(`${BASE_URL}/compute/v1/inventory`, requestData, {
      headers: { 
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }
    });

    console.log('Response:', response);
    return {
      success: true,
      data: response.data.data,
      status: response.data.status,
      message: response.data.message  
    };
  } catch (error) {
    console.error('Inventory API Error: ', error);
    return handleError(error);
  }
};

export const getInventoryById = async (id, token) => {
  try {
    const response = await axios.get(`${BASE_URL}/compute/v1/inventory/${id}`, {
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return {
      success: true,
      data: response.data.data,
      status: response.data.status,
      message: response.data.message
    };
  } catch (error) {
    return handleError(error);
  }
};

export const updateInventory = async ({
  id,
  hostname,
  ipAddress,
  port,
  location,
  singleNode,
  slurmHead,
  slurmOwner,
  status,
  numOfCpus,
  cpu,
  numOfGpus,
  gpu,
  gpuType,
  ram,
  mem,
  type,
  os,
  osVersion,
  kernelVersion,
  suser,
  spass,
  spriv,
  token
}) => {
  try {
    const requestData = {
      hostname,
      ip_address: ipAddress,
      port: port,
      location,
      single_node: Boolean(singleNode),
      slurm_head: Boolean(slurmHead),
      slurm_owner: slurmOwner,
      status: status,
      num_of_cpus: parseInt(numOfCpus),
      cpu,
      num_of_gpus: parseInt(numOfGpus),
      gpu,
      gpu_type: parseInt(gpuType),
      ram: parseInt(ram),
      mem: parseInt(mem),
      type: type,
      os,
      os_version: osVersion,
      kernel_version: kernelVersion,
      suser,
      spass,
      spriv
    };

    const response = await axios.patch(
      `${BASE_URL}/v1/inventory/${id}`, 
      requestData,
      { headers: { 'Authorization': 'Bearer ' + token } }
    );

    return {
      success: true,
      data: response.data.data,
      status: response.data.status,
      message: response.data.message
    };
  } catch (error) {
    return handleError(error);
  }
};

export const deleteInventory = async (id, token) => {
  try {
    const response = await axios.delete(`${BASE_URL}/compute/v1/inventory/${id}`, {
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return {
      success: response.data.success,
      status: response.data.status,
      message: response.data.message
    };
  } catch (error) {
    return handleError(error);
  }
};

export const getAllInventories = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/compute/v1/inventory`, {
      headers: { 'Authorization': 'Bearer ' + token }
    });

    return {
      success: true,
      data: response.data.data,
      status: response.data.status,
      message: response.data.message
    };
  } catch (error) {
    return handleError(error);
  }
};

// Assignment APIs
const createAssignmentAPI = (type) => async (inventoryId, entityId, token) => {
  try {
    console.log(`Assigning inventory ${inventoryId} to ${type} and entity ${entityId}`);
    
    const response = await axios.post(
      `${BASE_URL}/compute/v1/inventory/${inventoryId}/${type}/${entityId}/assign`,
      {},
      { headers: { 'Authorization': 'Bearer ' + token } }
    );

    console.log('Assignment response:', response.data);

    return {
      success: true,
      status: response.data.status,
      message: response.data.message
    };
  } catch (error) {
    return handleError(error);
  }
};

const createRemovalAPI = (type) => async (inventoryId, entityId, token) => {
  try {
    const response = await axios.delete(
      `${BASE_URL}/compute/v1/inventory/${inventoryId}/${type}/${entityId}/remove`,
      { headers: { 'Authorization': 'Bearer ' + token } }
    );

    return {
      success: true,
      status: response.data.status,
      message: response.data.message
    };
  } catch (error) {
    return handleError(error);
  }
};

const createGetEntityInventoryAPI = (type) => async (entityId, token) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/compute/v1/inventory/${type}/${entityId}`,
      { headers: { 'Authorization': 'Bearer ' + token } }
    );

    return {
      success: true,
      data: response.data.data,
      status: response.data.status,
      message: response.data.message
    };
  } catch (error) {
    return handleError(error);
  }
};

// Client APIs
export const assignInventoryToClient = createAssignmentAPI('client');
export const removeInventoryFromClient = createRemovalAPI('client');
export const getClientInventory = createGetEntityInventoryAPI('client');

// Department APIs
export const assignInventoryToDepartment = createAssignmentAPI('department');
export const removeInventoryFromDepartment = createRemovalAPI('department');
export const getDepartmentInventory = createGetEntityInventoryAPI('department');

// Team APIs
export const assignInventoryToTeam = createAssignmentAPI('team');
export const removeInventoryFromTeam = createRemovalAPI('team');
export const getTeamInventory = createGetEntityInventoryAPI('team');

// User APIs
export const assignInventoryToUser = createAssignmentAPI('user');
export const removeInventoryFromUser = createRemovalAPI('user');
export const getUserInventory = createGetEntityInventoryAPI('user');

const handleError = (error) => {
  console.error('Inventory API Error:', error);
  
  if (error.response?.status === 503) {
    return {
      success: false,
      error: 'Service temporarily unavailable'
    };
  }
  
  return {
    success: false,
    error: error.response?.data?.message || error.message || 'Operation failed'
  };
};
