import React from 'react';
import { Paper, Box, Typography, Chip, Divider, Stack } from '@mui/material';
import {
  Person as PersonIcon,
  Storage as StorageIcon,
  CloudUpload as CloudUploadIcon,
  Extension as ExtensionIcon,
  Memory as MemoryIcon,
  Cloud as CloudIcon,
} from '@mui/icons-material';

const AgentStatusOverview = ({ agentConfig, activeStep }) => {
  const steps = [
    {
      label: "Agent",
      icon: <PersonIcon />,
      value: agentConfig?.name,
      active: activeStep >= 0,
      completed: !!agentConfig?.name,
      details: agentConfig?.purpose ? `${agentConfig.purpose.slice(0, 40)}${agentConfig.purpose.length > 40 ? '...' : ''}` : null
    },
    {
      label: "Model Type",
      icon: <StorageIcon />,
      value: getModelValue(agentConfig),
      active: activeStep >= 1,
      completed: !!agentConfig?.modelType,
      details: getModelTypeDescription(agentConfig)
    },
    {
      label: "Knowledge",
      icon: <CloudUploadIcon />,
      value: getKnowledgeValue(agentConfig),
      active: activeStep >= 2,
      completed: activeStep > 2,
      details: agentConfig?.autoEmbeddings ? "Auto-embeddings enabled" : null
    },
    {
      label: "Customization",
      icon: <ExtensionIcon />,
      value: getCustomizationValue(agentConfig),
      active: activeStep >= 3,
      completed: activeStep > 3,
      details: agentConfig?.systemPrompt ? "Custom prompt defined" : null
    },
    {
      label: "Memory",
      icon: <MemoryIcon />,
      value: getMemoryValue(agentConfig),
      active: activeStep >= 4,
      completed: activeStep > 4,
      details: agentConfig?.enableLongTermMemory ? "Long-term memory enabled" : null
    },
    {
      label: "Deployment",
      icon: <CloudIcon />,
      value: agentConfig?.deploymentOption ? agentConfig.deploymentOption.toUpperCase() : null,
      active: activeStep >= 5,
      completed: !!agentConfig?.deploymentOption && !!agentConfig?.apiType,
      details: agentConfig?.apiType ? `${agentConfig.apiType.toUpperCase()} API` : null
    }
  ];

  function getModelValue(config) {
    if (!config) return null;
    
    if (config.selectedModelName) {
      return config.selectedModelName;
    }
    
    return config.modelType ? config.modelType.toUpperCase() : null;
  }

  function getModelTypeDescription(config) {
    if (!config) return null;
    
    if (config.selectedModelId && config.modelType) {
      return `${getModelTypeText(config.modelType)}`;
    }
    
    return getModelTypeText(config.modelType);
  }
  
  function getModelTypeText(modelType) {
    switch(modelType) {
      case 'rag':
        return 'Dynamic knowledge retrieval';
      case 'fine-tuned':
        return 'Custom trained model';
      case 'hybrid':
        return 'RAG + Fine-tuning';
      case 'base':
        return 'Pre-deployed model';
      default:
        return null;
    }
  }

  function getKnowledgeValue(config) {
    if (!config) return null;
    
    const docCount = config.documents?.length || 0;
    const apiCount = config.connectedAPIs?.length || 0;
    
    if (docCount === 0 && apiCount === 0) {
      return config.modelType === 'base' ? 'Not required' : 'None added';
    }
    
    return `${docCount} docs, ${apiCount} APIs`;
  }

  function getCustomizationValue(config) {
    if (!config) return null;
    
    const toolCount = config.tools?.length || 0;
    return toolCount > 0 ? `${toolCount} tools enabled` : 'Basic configuration';
  }

  function getMemoryValue(config) {
    if (!config) return null;
    
    if (config.enableLongTermMemory) {
      return 'Enhanced memory';
    }
    
    switch(config.shortTermMemory) {
      case 'basic':
        return 'Basic context';
      case 'enhanced':
        return 'Enhanced context';
      case 'advanced':
        return 'Advanced context';
      default:
        return 'Default settings';
    }
  }

  return (
    <Paper 
      elevation={1}
      sx={{ 
        py: 2,
        px: 3,
        background: 'linear-gradient(to right, rgba(122, 31, 162, 0.05), rgba(74, 20, 140, 0.1))',
        borderLeft: '4px solid #7b1fa2',
        height: '100%',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Stack 
        direction="row" 
        spacing={2}
        divider={
          <Divider orientation="vertical" flexItem sx={{ my: 1 }} />
        }
        sx={{ width: '100%' }}
      >
        {steps.map((step) => (
          <Box 
            key={step.label} 
            sx={{ 
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1.5,
              opacity: step.active ? 1 : 0.7,
              transition: 'all 0.2s ease'
            }}
          >
            <Box sx={{ 
              color: step.completed ? '#7b1fa2' : 'text.secondary',
              display: 'flex',
              '& > svg': { fontSize: '1.2rem' }
            }}>
              {step.icon}
            </Box>
            
            <Box sx={{ minWidth: 0, flex: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                <Typography 
                  variant="body2"
                  color="text.secondary"
                >
                  {step.label}:
                </Typography>
                <Typography 
                  variant="body2"
                  sx={{ 
                    fontWeight: step.completed ? 500 : 400,
                    color: step.completed ? 'text.primary' : 'text.disabled',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  {step.value || 'Not set'}
                </Typography>
              </Box>
              {step.details && (
                <Typography 
                  variant="caption"
                  color="text.secondary"
                  sx={{
                    display: 'block',
                    fontSize: '0.75rem',
                    lineHeight: 1.2
                  }}
                >
                  {step.details}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
      </Stack>
    </Paper>
  );
};

export default AgentStatusOverview; 